import { Component } from '@angular/core';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { JobsNavigation } from './jobs-nav';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
})
export class JobsComponent {

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly jobsNavigation: JobsNavigation
  ) {
    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.jobsNavigation.jobsSubMenu);
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

}
