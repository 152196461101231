<mat-card id='view-programmes'>
    <h1 class="page-title">{{eventName}}</h1>
    <mat-card-header>
        <span class="example-form">
            <mat-form-field id="search_service_user_form" appearance="fill" class="example-full-width">
                <mat-label>Search</mat-label>
                <input id="search_service_user_input" autocomplete="off" [(ngModel)]="searchTerm"
                    (keydown.enter)="onFilter(search_service_user_input.value)" matInput placeholder="Search by"
                    #search_service_user_input aria-label="Search a user">
                <button *ngIf="!searchTerm" id="do_search_button" matSuffix mat-icon-button aria-label="Search">
                    <mat-icon>search</mat-icon>
                </button>
                <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear"
                (click)="onClickClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </span>
        <span class="spacer"></span>
        <span>
            <button  class="right" id="exit_service_button" mat-stroked-button (click)="navigateToRoute()">Exit</button>
        </span>
       
        
    </mat-card-header>
    <mat-card-content class="learners">
        <span class="spacer"></span>
        <mat-table id="learners_table" #table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="FullName">
                <mat-header-cell *matHeaderCellDef id="FullName" mat-sort-header style="font-weight: bold;">
                    Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.fullName }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ServiceNumber">
                <mat-header-cell *matHeaderCellDef id="ServiceNumber" mat-sort-header style="font-weight: bold;">
                    Service number
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.serviceNumber }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Programme">
                <mat-header-cell *matHeaderCellDef id="Programme" mat-sort-header style="font-weight: bold;">
                    Programme
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="word-break: break-word;">
                    {{ element.programme ? element.programme : 'NA'  }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef id="Status" mat-sort-header style="font-weight: bold;">
                    Status
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.status ? element.status : 'N/A' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="RRCAllocated">
                <mat-header-cell *matHeaderCellDef id="RRCAllocated" mat-sort-header style="font-weight: bold;">
                    RRC allocated
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.rrcAllocated }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="careerConsultant">
                <mat-header-cell *matHeaderCellDef id="CarrerConsultant" mat-sort-header style="font-weight: bold;">
                    Career consultant
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.careerConsultant && element.careerConsultant.trim() !== '' ? element.careerConsultant : 'N/A' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="assign">
                <mat-header-cell *matHeaderCellDef style="font-weight: bold;">Assign</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button id="assign_course_button_{{element.id}}" mat-icon-button color="primary" (click) ="onConfrimClick(element.id)" matTooltip="Assign this service leaver"
                        [matTooltipShowDelay]="800" aria-label="view button">
                        <img src="../../assets/logo/assign_course.svg" alt="forward" class="custom-size">
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator id="user_pagination" [pageSizeOptions]="[25, 50, 100]" [showFirstLastButtons]="true" aria-label="Select page" >
        </mat-paginator>
    </mat-card-content>
</mat-card>