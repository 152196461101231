<!-- custome code replace dropzone -->
<input matInput type="file" #fileInput
  [multiple]="option?.multiple"
  [disabled]="disabled"
  (change)="onFilesSelected($event.target.files)"
  class="dropzone-container"
  accept="{{ option?.accept }}">

<div class="error-container">
  <p *ngFor="let error of errors" class="mat-error">{{error.message}}</p>
</div>

<div class="card-row">
  <mat-list class="card-column-75" *ngIf="!option.showPreviews">
    <mat-list-item *ngFor="let file of fileList">
      <span class="text">{{file.name}}</span>
      <button mat-icon-button (click)="removeFile(file)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
  <div class="card-column-25 actions">
    <span class="spacer"></span>
    <span>
      <button id="file_reset" mat-stroked-button color="primary" *ngIf="showReset"
        (click)="reset()">Reset</button>
    </span>
  </div>
</div>
