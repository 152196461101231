import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SessionsService } from 'src/app/sessions/sessions.service';
import { CourseManagementNavigation } from '../../course-management-nav';

@Component({
  selector: 'app-view-course-locaion',
  templateUrl: './view-course-locaion.component.html',
  styleUrls: ['./view-course-location.component.scss']
})
export class ViewCourseLocaionComponent {
  identifier: any;
  routeIntent: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  collection = '/courseLocations';
  pageName:any;
  CLid:any;
  collectionForView = 'courseLocations';
  fieldsForView = ['roomName', 'roomId','parentLocation','roomCapacity',];
  buttonLable ="room";
  baseRoute = '/courses-events';
  collectionRoom='roomLocations';
  viewId : number;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly sessionService: SessionsService
  ) {
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.titleInformation =[{
      'courseLocationName': 'Location name'
    },{
      'addressLine1': 'Location details'
    }];
    this.schemaUrl = 'api::course-location.course-location';
    this.apiUrl = '/course-locations'
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
      this.CLid = params.CLid;
      this.viewId=this.identifier
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
}

isAuthorized(fid, opId) {
  return this.sessionService.hasResource([fid.toString(), opId.toString()])
}
}
