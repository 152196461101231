import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicFormService } from 'src/app/features/dynamic-forms/dynamic-form/dynamic-form.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { SessionsService } from 'src/app/features/shared/services/sessions.service';

@Component({
    selector: 'app-user-notess',
    templateUrl: './user-notes.component.html',
    styleUrls: ['./user-notes.component.scss']
})
export class UserNotesComponent implements OnInit {

    @Input() identifier!: string;
    @Input() routeIntent!: string;
    @Input() schemaUrl!: string;
    @Input() apiUrl!: string;
    @Input() titleInformation!: any[];

    viewId: any;
    messagesDataSource = [];
    @ViewChild('mainScreen', { static: false }) elementView: ElementRef;
    pageSize = 200;
    pageIndex = 0;
    isFirst: boolean = false;
    isLast: boolean = false;
    collectionName = "/recordUserNotes"
    disableAddButton: boolean;
    featureMap : any= {
        "api::employer.employer" : {
          "fId":191,
          "create" :1,
          "view":2,
          "update":3
        },
        "api::dos-service-provider.dos-service-provider" : {
          "fId":163,
          "create" :1,
          "view":2,
          "update":3,
        },
        "api::dos-service.dos-service" : {
          "fId":161,
          "create" :1,
          "view":2,
          "update":3,
        },
        "api::dos-national-service.dos-national-service" : {
          "fId":162,
          "create" :1,
          "view":2,
          "update":3
        }
      }

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly dynamicFormService: DynamicFormService,
        private readonly snackBarService: SnackBarService,
        private readonly sessionService: SessionsService
    ) {
        this.scrollToTop();
    }

    ngOnInit(): void {
        this.viewId = this.identifier;
        this.loadMessages();
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onSubmit(message) {
        const messageJson = {
            notes: message.value,
            target_id: this.viewId,
            target_collection: this.schemaUrl,
        }
        const payload = { data: messageJson }
        this.disableAddButton= true;
        this.dynamicFormService.saveUserNotesCollectionEntry(payload, this.collectionName).subscribe(data => {
            if (data) {
                const newArray = new Array();
                message.value = "";
                newArray.push(data?.responseObject?.body?.data?.attributes);
                this.messagesDataSource = newArray.concat(this.messagesDataSource);
                // this.scrollToBottom(false);
                this.snackBarService.success(data?.message.applicationMessage);
                this.disableAddButton= false;
            }
        },
            error => {
                this.snackBarService.error(error.error.applicationMessage);
                this.disableAddButton= false;
            });
    }

    scrollToBottom(flag) {
        setTimeout(() => {
            const chatHistory = document.getElementById('messageBody');
            chatHistory.scrollIntoView(false);
            chatHistory.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            if (flag) {
                window.scrollTo(0, 0);
            } else {
                window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
            }
        }, 0);

    }

    @HostListener('scroll', ['$event'])
    scrolled(e) {

        const offsetHeight = this.elementView.nativeElement.offsetHeight;
        const scrollHeight = this.elementView.nativeElement.scrollHeight;
        const scrollTop = this.elementView.nativeElement.scrollTop;

        if (scrollTop === (offsetHeight - scrollHeight) && !this.isLast) {
            this.pageIndex = this.pageIndex + 1;
            this.dynamicFormService.getAllNotes(this.viewId, this.schemaUrl, this.collectionName, "createdAt,desc", this.pageIndex, this.pageSize, null).subscribe(response => {
                this.isFirst = response.first;
                this.isLast = response.last;
                if (response.content && response.content.length > 0) {
                    response.content.forEach(element => {
                        this.messagesDataSource.push(element);
                    });
                }
            });
        }
    }

    loadMessages() {
        this.dynamicFormService.getAllNotes(this.viewId, this.schemaUrl, this.collectionName, "createdAt,desc", this.pageIndex, this.pageSize, null).subscribe(response => {
            response?.body?.data?.recordUserNotes?.data?.forEach((obj: any) => {
                this.messagesDataSource.push(obj.attributes);
            })
            this.scrollToBottom(true);
        });

    }

    isAuthorized(operation) {
        var opId;
        var fId;
        if(this.featureMap.hasOwnProperty(this.schemaUrl)){
          var feature = this.featureMap[this.schemaUrl];
          if(feature.hasOwnProperty("fId"))
          {
            fId = feature["fId"]
          }
          if(feature.hasOwnProperty(operation))
          {
             opId=feature[operation]
          }
    
        }
    
          if(fId ==null || opId == null)
          {
            return false;
          }    
          else{
            return this.sessionService.hasResource([fId.toString(), opId.toString()])
          }
      }
}