<div class="sign-in-container white material-13" *ngIf="clientDetails">
  <img tabindex="0" src='../../assets/logo/Logo.svg' alt="ctp-logo" class="app-logo">
  <h2>{{route.snapshot.data['title']}}</h2>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()" autocomplete='off'>
    <h3>Please fill in your new password and<br />confirm password</h3>
    <div class="display-flex">
      <mat-form-field class="set-password-input-width darkModeBorderNone" appearance="fill">
        <mat-label class="white">
          New Password
        </mat-label>
        <input matInput type="password" id="initialPassword" formControlName="initialPassword" required>
        <mat-error *ngIf="resetPasswordForm.get('initialPassword').hasError('required')">
          New password is required
        </mat-error>
        <mat-error *ngIf="
          resetPasswordForm.get('initialPassword').hasError('pattern') ||
          resetPasswordForm.get('initialPassword').hasError('minlength') ||
          resetPasswordForm.get('initialPassword').hasError('maxlength')
        ">
          Password does not meet the policy
        </mat-error>
      </mat-form-field>
      <mat-form-field class="set-password-input-width darkModeBorderNone" appearance="fill">
        <mat-label class="white">
          Confirm Password
        </mat-label>
        <input matInput type="password" id="confirmPassword" formControlName="confirmPassword" required>
        <mat-error *ngIf="resetPasswordForm.get('confirmPassword').value === ''">
          Confirm password is required
        </mat-error>
        <mat-error
          *ngIf="resetPasswordForm.get('confirmPassword').hasError('notEquivalent') && resetPasswordForm.get('confirmPassword').value !== ''">
          Passwords do not match
        </mat-error>
      </mat-form-field>
    </div>
    <p *ngIf="action === 'create'"><small class="clr-black">* Minimum of 8 characters with at least one letter, at least
        one number and
        <br />at least one special character.</small></p>
    <button mat-raised-button [disabled]="resetPasswordForm.invalid" color="primary" type="submit" id="resetPasswordBtn">
      Submit
    </button>
  </form>
</div>