<div class="sessions_container">
  <img tabindex="0" src="../../assets/logo/Logo.svg" alt="captr logo" class="app-logo">
  <mat-card-title class="align-center">
    <h2 style="margin-bottom: 0px;">Recover Password</h2>
    <h3>Please fill in your username</h3>
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()" autocomplete='off'>
      <mat-form-field style="width: 100%;" appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput formControlName="userName" autocomplete="off" required>
        <mat-error *ngIf="forgotPasswordForm.get('userName')?.hasError('required')">
          Username is required
        </mat-error>

      </mat-form-field>
      <mat-card-actions class="align-right">
        <button type="button" style="margin-right: 10px;float:left" mat-stroked-button color="secondary"
          (click)="navigateHome()" (keydown.enter)="navigateHome()">Return to Log in page</button>
        <button mat-raised-button [disabled]="!forgotPasswordForm.valid" color="primary" type="submit" class="button">
          Submit</button>
      </mat-card-actions>
    </form>
  </mat-card-content>
  <div class="app-logo-footer align-center">
    <img src="../../assets/logo/new_logo.png" alt="captr logo">
  </div>
</div>