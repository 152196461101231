<div class="header">
    <div class="tabWrapper">
        <ul>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve">
                <button mat-button 
                        [routerLink]="['./details']"
                        [queryParams]="{ id: userId, name: userName, hideEdit: isActive }">
                    <a class="tab-item" id="year-tab" aria-label="Course delivery details">Course delivery details</a>
                </button>
            </li>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve" *ngIf="isActive ==='false'">
                <button mat-button 
                        [routerLink]="['./booking-request']"
                        [queryParams]="{ id: userId, name: userName, operation: 'view', hideEdit: isActive }">
                    <a class="tab-item" id="year-tab" aria-label="Booking requests">Booking requests</a>
                </button>
            </li>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve" *ngIf="isActive ==='false'">
                <button mat-button 
                        [routerLink]="['./confirm-booking']"
                        [queryParams]="{ id: userId, name: userName, operation: 'view', hideEdit: isActive }">
                    <a class="tab-item" id="year-tab" aria-label="Confirmed bookings">Confirmed bookings</a>
                </button>
            </li>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve" *ngIf="isActive ==='false'">
                <button mat-button [routerLink]="['./waiting-list']"
                    [queryParams]="{ id: userId, name: userName, operation: 'view',hideEdit: isActive }">
                    <a class="tab-item" id="year-tab" aria-label="Waiting list">Waiting list</a></button>
            </li>
        </ul>
    </div>
</div>
<router-outlet></router-outlet>