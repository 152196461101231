import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-workshop-delivery-tab',
  templateUrl: './workshop-delivery-tab.component.html',
  styleUrls: ['./workshop-delivery-tab.component.scss']
})
export class WorkshopDeliveryTabComponent {
  userName :any;
  userId : any
  isActive;

  constructor(
    private readonly route: ActivatedRoute

  ) {
    this.route.queryParams.subscribe((params: any) => {
      if (params.hasOwnProperty('id')) {
        this.route.queryParams.subscribe((params: any) => {
          this.userId = params.id;
          this.userName = params.name;
          this.isActive= params.hideEdit;
        })
      }
    })
  }
  
}
