import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseUrl } from "../../constants/url-constants";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DynamicTableService {
  constructor(
    private readonly http: HttpClient,
  ) { }

  getGraphQLList(sort: string, page: number, size: number, collection, body): Observable<any> {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy/${collection}/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getRefGraphQLList(sort: string, page: number, size: number, body, collectionName): Observable<any> {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy/${collectionName}/refData`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getExternalList(sort: string, page: number, size: number, body, externalEndpoint, apiName): Observable<any> {
    let href;
    switch (apiName) {
      case 'user':
        href = `${BaseUrl.USER}/${externalEndpoint}`;
        return this.http.post<any>(href, body, {
          params: new HttpParams()
            .set('sort', sort.toString())
            .set('page', page.toString())
            .set('size', size.toString())
        });

      case 'cv-builder':
        href = `${BaseUrl.CVB}/${externalEndpoint}`;
        return this.http.post<any>(href, body, {
          params: new HttpParams()
            .set('sort', sort.toString())
            .set('page', page.toString())
            .set('size', size.toString())
        });

      case 'filter-user':
        body = {
          "orgId":32,
          "keyword":body.keyword 
        };
        href = `${BaseUrl.USER}/${externalEndpoint}`;
        return this.http.post<any>(href, body, {
          params: new HttpParams()
            .set('sort', sort.toString())
            .set('page', page.toString())
            .set('size', size.toString())
        });

      default:
        href = `${BaseUrl.USER}/${externalEndpoint}`;
        break;
    }
  }

  delete(id, collectionName) {
    const href = `${BaseUrl.CMS_PROXY_API}proxy/${collectionName}/delete/${id}`;
    return this.http.delete<any>(href);
  }

  saveCollectionEntry(payload, collection) {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/create`;
    return this.http.post<any>(href, payload);
  }

  updateCollectionEntry(payload, id, collection) {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/update/${id}`;
    return this.http.put<any>(href, payload);
  }

  getCollectionEntry(collection, id) {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy/${collection}/read/${id}`;
    return this.http.get<any>(href);
  }

  saveNewRecord(apiName, externalEndpoint, payload): Observable<any> {
    let href;
    switch (apiName) {
      case 'user':
        href = `${BaseUrl.USER}/${externalEndpoint}`;
        break;
      case 'cv-builder':
        href = `${BaseUrl.CVB}/${externalEndpoint}`;
        break;
      default:
        href = `${BaseUrl.USER}/${externalEndpoint}`;
        break;
    }
    return this.http.post<any>(href, payload);
  }

  updateSingleCollectionEntry(payload, id, collection) {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy/${collection}/singleUpdate/${id}`;
    return this.http.put<any>(href, payload);
  }

  getBookingCountDetailsById(collection,entryId) {
    const href = `${BaseUrl.BOOKING}/open/${collection}/${entryId}`;
    return this.http.get<any>(href)
  }
  
  getCourseDetails(id: number) {
    const href = `${BaseUrl.BOOKING}/manual-assign-course/validate/${id}`;
    return this.http.get<any>(href);
  }

  getWorkshopDetails(id: number) {
    const href = `${BaseUrl.BOOKING}/manual-assign-workshop/validate/${id}`;
    return this.http.get<any>(href);
  }

  validateEventDetails(id) {
    const href = `${BaseUrl.BOOKING}/manual-assign-event/validate/${id}`;
    return this.http.get<any>(href);
  }
}