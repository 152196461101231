<div class="sessions_container">
   <img tabindex="0" src='../../assets/logo/Logo.svg' alt="ctp logo" class="app-logo">

  <mat-card-title class="align-center">
    <h2 >Two-Factor Authentication</h2>
    <div class="success-message-panel" *ngIf="message">
      <div class="success-icon">&#10006;</div>
      <div class="success-message">{{message}}</div>
    </div> 
  </mat-card-title>
  <mat-card-content>
    <form #otpForm="ngForm">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Enter one time password</mat-label>
        <input matInput type="text" id="validationCode" name="validationCode" required [(ngModel)]="otpCode" minlength="6" maxlength="6" #validationCode="ngModel" (ngModelChange)="onOtpChange()" (keyup.enter)="otpCode.length === 6 && verifyOTP()">
        <mat-error *ngIf="validationCode.errors?.required">
          OTP is required
        </mat-error>
      </mat-form-field>
    </form>

  </mat-card-content>
  <mat-card-actions class="align-right">
    <button mat-raised-button color="primary" (click)="verifyOTP()" 
    [disabled]="validationCode.invalid || otpButtonDisabled">
            Verify OTP
    </button>
    <button  mat-raised-button color="primary" (click)="sendOtp(otpForm)" [disabled]="isButtonDisabled">
      {{buttonText}}
    </button>
  </mat-card-actions>
  <div class="app-logo-footer align-center">
    <img src="../../assets/logo/new_logo.png" alt="captr logo">
  </div>

</div>