import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { CourseManagementService } from '../../../course-management.service';

@Component({
  selector: 'app-assign-workshop-popup',
  templateUrl: './assign-workshop-popup.component.html',
  styleUrls: ['./assign-workshop-popup.component.scss']
})
export class AssignWorkshopPopupComponent {
  selectedOption: string = "false";

  constructor(public dialogRef: MatDialogRef<AssignWorkshopPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly snackbar: SnackBarService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly courseManagementService: CourseManagementService,
  ) { }

  onConfirm(): void {
    let payload = {
      deliveryId: this.data.deliveryId,
      serviceLeaverId: this.data.serviceUserId,
      isDiscounted: this.selectedOption
    };

    this.courseManagementService.manuallyAssignWorkshopSL(payload).subscribe(
      response => {
        this.snackbar.success(response.message.applicationMessage);
        this.router.navigate(
          ['/courses-events/workshopDeliveries/view/booking-request'],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.data.deliveryId,
              operation: 'view',
              hideEdit: this.data.hideEdit
            }
          }
        );
      },
      error => {
        this.snackbar.error(`${error.error.applicationMessage}`);
      }
    );
    this.dialogRef.close(false);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

}
