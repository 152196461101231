<mat-card id='category'>
    <mat-card-header>
        <span class="example-form">
            <mat-form-field id="search_category_form" class="example-full-width" appearance="fill">
                <mat-label>Search</mat-label>
                <input id="search_category_input" matInput type="text" [(ngModel)]="searchTerm"
                    (keydown.enter)="onFilter(search_category_input.value)" placeholder="Search by"
                    #search_category_input>
                <button *ngIf="!searchTerm" id="do_search_button" matSuffix mat-icon-button aria-label="Search">
                    <mat-icon>search</mat-icon>
                </button>
                <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClose()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </span>
        <span class="spacer"></span>
        <span>
            <button id="exit_button" mat-stroked-button [routerLink]="['/courses-events/workshopDeliveries/view/workshop-delivery']" queryParamsHandling="merge">Exit</button>
        </span>
    </mat-card-header>
    <mat-card-content class="pl-0">
        <mat-table id="content_modules_table" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="serviceUserId">
                <mat-header-cell *matHeaderCellDef id="fullName_button" style="font-weight: bold;">
                    Service leaver name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.serviceUserName || "N/A"}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="serviceNumber">
                <mat-header-cell *matHeaderCellDef id="serviceNumber_button" style="font-weight: bold;">
                    Service number
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.serviceNumber || "N/A" }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdDate">
                <mat-header-cell *matHeaderCellDef id="createdDate_button" mat-sort-header style="font-weight: bold;">
                    Date requested
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.createdDate | date: 'dd/MM/yyyy' || "N/A"}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="bookingReferenceId">
                <mat-header-cell *matHeaderCellDef id="bookingReference_button" mat-sort-header style="font-weight: bold;">
                    Booking ID
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.bookingReference || "N/A" }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="bookingStatusId">
                <mat-header-cell *matHeaderCellDef id="bookingStatus_button" mat-sort-header style="font-weight: bold;">
                    Booking status
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.bookingStatus || "N/A" }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef style="font-weight: bold;">Actions</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button id="view_user_button_{{element.bookingId}}" mat-icon-button color="primary" matTooltip="View"
                        [matTooltipShowDelay]="800"  [routerLink]="['../workshop-booking-details']" attr.aria-label="view {{ element.serviceUserName || 'N/A' }}"
                        [queryParams]="{ id: element.bookingId,wdID:identifier,operation:'view',hideEdit: isActive  }">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator id="sl_booking_pagination" [pageSizeOptions]="[25, 50, 100]" [showFirstLastButtons]="true" aria-label="Select page">
        </mat-paginator>

    </mat-card-content>
</mat-card>