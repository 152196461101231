import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFormViewComponent } from './dynamic-form-view/dynamic-form-view.component';
import { SharedModule } from 'src/app/framework/shared/shared.module';
import { MaterialModule } from 'src/app/framework/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { QuillModule } from 'ngx-quill';
import { FileUploadTypeComponent } from 'src/app/features/dynamic-forms/file-upload-type/file-upload-type.component';
import { QuillFieldTypeComponent } from 'src/app/features/dynamic-forms/quill-field-type/quill-field-type.component';
import { RepeatTypeComponent } from 'src/app/features/dynamic-forms/repeat-type/repeat-type.component';
import { SingleTypeComponent } from 'src/app/features/dynamic-forms/single-type/single-type.component';
import { TimeTypeComponent } from 'src/app/features/dynamic-forms/time-type/time-type.component';
import { TitleTypeComponent } from 'src/app/features/dynamic-forms/title-type/title-type.component';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/framework/components/date-adapter/date-adapter';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RelationTypeComponent } from './relation-type/relation-type.component';
import { AddressTypeComponent } from './address-type/address-type.component';
import { ExternalRelationTypeComponent } from './external-relation-type/external-relation-type.component';
import { RegionTypeComponent } from './region-type/region-type.component';
import { NgHttpLoaderModule } from 'ng-http-loader';


@NgModule({
  declarations: [DynamicFormComponent, DynamicFormViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),
    FormlyModule.forRoot({
      types: [
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'relation', component: RelationTypeComponent },
        { name: 'single', component: SingleTypeComponent },
        { name: 'time', component: TimeTypeComponent },
        { name: 'richtext', component: QuillFieldTypeComponent },
        { name: 'file', component: FileUploadTypeComponent },
        { name: 'title', component: TitleTypeComponent },
        { name: 'postcode', component: AddressTypeComponent },
        { name: 'external-relation', component: ExternalRelationTypeComponent },
        { name: 'region', component: RegionTypeComponent },
        // ... your other types
      ],
    }),
    FormlyMaterialModule,
    FormlyMatToggleModule,
    FormlyMatDatepickerModule,
    QuillModule.forRoot(),
    MatMomentDateModule
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: DateAdapter, useClass: AppDateAdapter },
    ],
  exports: [DynamicFormComponent,DynamicFormViewComponent],
})
export class DynamicFormsModule { }
