
<mat-card id="edit-service">
    <mat-card-header class="flex-end">
        <h1 class="page-title" *ngIf="this?.routeIntent === 'edit'">Edit provider</h1>
        <h1 class="page-title" *ngIf="this?.routeIntent != 'edit'">Add provider</h1>
        <span class="spacer"></span>
        <span>
            <button id="exit_service_button" mat-stroked-button
            (click)="exitNavigation()">Exit</button>
        </span>
    </mat-card-header>

    <mat-card-content style="padding-left: 0px;">
        <app-dynamic-form [schemaJson]="fields" [identifier]="identifier" [apiUrl]="apiUrl"
        [routeIntent]="routeIntent" [routerPath]="routerPath" [queryParams]="queryParams"  [collection]="collection" (progressStatus)="isNotSaved = $event"></app-dynamic-form>
    </mat-card-content>
</mat-card>