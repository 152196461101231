<div class="sessions_container">
  <img tabindex="0" src='../../assets/logo/Logo.svg' alt="ctp-logo" class="app-logo">

  <mat-card-title class="align-center">
    <h2 style="margin-bottom: 0px;">Sign in</h2>
    <h3 class="hint-message">Please fill in your username and password</h3>
  </mat-card-title>

  <mat-card-content>
    <form [formGroup]="signinForm" (ngSubmit)="signin()" autocomplete="off">
      <mat-form-field style="width: 100%;" appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput type="username" formControlName="userName" autocomplete="off">
        <mat-error *ngIf="signinForm.get('userName')?.hasError('required')">
          Username is required
        </mat-error>
      </mat-form-field>

      <mat-form-field style="width: 100%;" appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="pass" autocomplete="off">
        <mat-error *ngIf="signinForm.get('pass')?.hasError('required')">
          Password is required
        </mat-error>
      </mat-form-field>
      <mat-card-actions class="align-right">
        <button mat-raised-button [disabled]="signinForm.invalid" color="primary" type="submit"
        class="button">Login</button>
        <button type="button" mat-flat-button (click)="forgotPassword()">Forgot
          password?</button>

      </mat-card-actions>
      <div class="sso-login">
        <button type="button" mat-stroked-button (click)="loginWithAzureAd()" >Login with Azure AD</button>
      </div>
    </form>

  </mat-card-content>
  <div class="app-logo-footer align-center">
    <img src="../../assets/logo/new_logo.png" alt="captr logo">
  </div>
</div>


