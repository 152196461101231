import { environment } from '../../../environments/environment';
 
export const BaseUrl = {
    AUTHENTICATE: environment.apiURL + '/sequation-user-api',
    USER: environment.apiURL + '/sequation-user-api',
    LOGO: environment.logo,
    APPTITLE: environment.appTitle,
    AUTHORIZATION: environment.apiURL + '/sequation-authorization-api',
    azureBlobStorage: environment.azureBlobStorage,
    CLIENT_URL: window.location.host, 
    GRAPHQL: environment.proxyURL + '/graphql',
    STRAPI: environment.proxyURL,
    CMS_PROXY_API : environment.proxyURL +'/sequation-cms-proxy-api',
    CONTENT_MANAGEMENT:  environment.apiURL +'/sequation-content-management-v2-api',
    ASSESSMENT: environment.apiURL + '/sequation-community-assessment-api',
    PLAN :  environment.apiURL +'/sequation-plan-v2-api',
    REPORT: environment.apiURL + '/sequation-report-api',
    SRM: environment.apiURL + '/sequation-srm-v2-api',
 
    Document: environment.apiURL + '/sequation-document-v2-api',
    CVB: environment.apiURL + '/sequation-cv-builder-v2-api',
    REFERENCE_DATA :environment.apiURL + '/sequation-reference-data-api',
    BOOKING: environment.apiURL + '/sequation-booking-api',
    JOBS : environment.apiURL + '/sequation-job-v2-api'
};
