import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter } from 'angular-calendar';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/framework/components/date-adapter/date-adapter';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { CanDeactivateGuard } from 'src/app/framework/guards/can-deactivate.guard';
import { Observable } from 'rxjs';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';

@Component({
    selector: 'app-add-edit-course',
    templateUrl: './add-edit-course.component.html',
    styleUrls: ['./add-edit-course.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 }))
            ])
        ])
    ]
})
export class AddEditCourseComponent implements CanDeactivateGuard {

    form = new FormGroup({});
    isNotSaved: boolean = false;
    model: any = {};
    options: FormlyFormOptions = {};
    fields: FormlyFieldConfig[] = [
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "type": "title",
                    "templateOptions": {
                      "label": "Course name"
                    }
                  },
                {
                    "key": "courseName",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Course name is required.",
                            "minlength": "Minimum length should be 3 characters.",
                            "maxlength": "Maximum length should be 100 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Course name",
                        "placeholder": "",
                        "description": "Minimum 3 and maximum 100 characters",
                        "required": true,
                        "options": [],
                        "minLength": 3,
                        "maxLength": 100
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "subTitle",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Subtitle is required.",
                            "minlength": "Minimum length should be 50 characters.",
                            "maxlength": "Maximum length should be 150 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Subtitle",
                        "placeholder": "",
                        "description": "Minimum 50 and maximum 150 characters",
                        "required": true,
                        "options": [],
                        "minLength": 50,
                        "maxLength": 150
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "courseId",
                    "type": "input",
                    "className": "col-md-6",

                    "validation": {
                        "messages": {}
                    },
                    "templateOptions": {
                        "label": "Course ID",
                        "placeholder": "",
                        "description": "",
                        "options": []
                    },

                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },   
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
              {
                "type": "title",
                "templateOptions": {
                  "label": "Course details"
                }
              },
            ]
          },  
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "ref_course_funding_type",
                    "type": "relation",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {}
                    },
                    "templateOptions": {
                        "label": "Funding type",
                        "placeholder": "",
                        "description": "Please select an option from the list",
                        "options": [],
                        "api": "ref-course-funding-types",
                        "relation": "one",
                        "mainField": "name",
                        "required": true
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "courseFee",
                    "type": "number",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Course fee (in £) is required.",
                            "pattern": "This field format is invalid.",
                            "min": "Minimum number should be 0.",
                            "max": "Maximum number should be 5000.",
                        }
                    },
                    "templateOptions": {
                        "label": "Course fee (in £)",
                        "placeholder": "",
                        "description": "Minimum 0 and maximum 5000",
                        "required": true,
                        "options": [],
                        "pattern": "^\\d+(\\.\\d{1,2})?$",
                        "max": 5000,
                        "min": 0
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "examFee",
                    "type": "number",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Exam fee (in £) is required.",
                            "pattern": "This field format is invalid.",
                            "min": "Minimum number should be 0.",
                            "max": "Maximum number should be 5000.",
                        }
                    },
                    "templateOptions": {
                        "label": "Exam fee (in £)",
                        "placeholder": "",
                        "description": "Minimum 0 and maximum 5000",
                        "required": true,
                        "options": [],
                        "pattern": "^\\d+(\\.\\d{1,2})?$",
                        "max": 5000,
                        "min": 0
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "courseMaterials",
                    "type": "number",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Course materials (in £) is required.",
                            "pattern": "This field format is invalid.",
                            "min": "Minimum number should be 0.",
                            "max": "Maximum number should be 5000.",
                        }
                    },
                    "templateOptions": {
                        "label": "Course materials (in £)",
                        "placeholder": "",
                        "description": "Minimum 0 and maximum 5000",
                        "required": true,
                        "options": [],
                        "pattern": "^\\d+(\\.\\d{1,2})?$",
                        "max": 5000,
                        "min": 0
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "courseCost",
                    "type": "number",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "pattern": "This field format is invalid.",
                        }
                    },
                    "templateOptions": {
                        "label": "Course cost (in £)",
                        "placeholder": "",
                        "description": "",
                        "options": [],
                        "pattern": "^\\d+(\\.\\d{1,2})?$",
                        "disabled": true
                    },
                    "expressionProperties": {
                        "model.courseCost": "(parseFloat(model.courseFee) + parseFloat(model.examFee) + parseFloat(model.courseMaterials)).toFixed(2)"
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "ref_course_quals",
                    "type": "relation",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {}
                    },
                    "templateOptions": {
                        "label": "Qualifications",
                        "placeholder": "",
                        "description": "Minimum 1 and maximum 5 qualifications",
                        "options": [],
                        "api": "ref-course-quals",
                        "relation": "many",
                        "mainField": "name",
                        "maxQuantity": 5,
                        "minQuantity": 1,
                        "required":true
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "accreditation",
                    "type": "radio",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Accreditation is required."
                        }
                    },
                    "templateOptions": {
                        "label": "Accreditation",
                        "placeholder": "",
                        "description": "",
                        "required": true,
                        "options": [{
                            "label": "Yes",
                            "value": true
                        },
                        {
                            "label": "No",
                            "value": false
                        },]
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "ref_course_course_type",
                    "type": "relation",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {}
                    },
                    "templateOptions": {
                        "label": "Course type",
                        "placeholder": "",
                        "description": "Please select an option from the list",
                        "options": [],
                        "api": "ref-course-course-types",
                        "relation": "one",
                        "mainField": "name",
                        "required": true
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "industries",
                    "type": "relation",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "industry is required.",
                            "minlength": "Minimum length should be 5 characters.",
                            "maxlength": "Maximum length should be 500 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Industry",
                        "placeholder": "Industry",
                        "description": "Minimum 0 and maximum 30 industry.",
                        "options": [],
                        "api": "industries",
                        "relation": "many",
                        "mainField": "name",
                        "maxQuantity": 30,
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "courseOverview",
                    "type": "richtext",
                    "className": "col-md-12",
                    "validation": {
                        "messages": {
                            "required": "Course overview is required.",
                            "minlength": "Minimum length should be 5 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Course overview",
                        "placeholder": "",
                        "description": "Minimum 5 characters",
                        "required": true,
                        "options": [],
                        "minLength": 5,
                        "attributes": {
                            "aria-label": "Course overview"
                            }
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "courseContent",
                    "type": "richtext",
                    "className": "col-md-12",
                    "validation": {
                        "messages": {
                            "required": "Course content is required.",
                            "minlength": "Minimum length should be 5 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Course content",
                        "placeholder": "",
                        "description": "Minimum 5 characters",
                        "required": true,
                        "options": [],
                        "minLength": 5,
                        "attributes": {
                            "aria-label": "Course content"
                            }
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "whyTakeThisCourse",
                    "type": "richtext",
                    "className": "col-md-12",
                    "validation": {
                        "messages": {
                            "required": "Why take this course? is required.",
                            "minlength": "Minimum length should be 5 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Why take this course?",
                        "placeholder": "",
                        "description": "Minimum 5 characters",
                        "required": true,
                        "options": [],
                        "minLength": 5,
                        "attributes": {
                            "aria-label": "Why take this course?"
                            }
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "careerOpportunities",
                    "type": "richtext",
                    "className": "col-md-12",
                    "validation": {
                        "messages": {
                            "required": "Career opportunities is required.",
                            "minlength": "Minimum length should be 5 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Career opportunities",
                        "placeholder": "",
                        "description": "Minimum 5 characters",
                        "required": true,
                        "options": [],
                        "minLength": 5,
                        "attributes": {
                            "aria-label": "Career opportunities"
                            }
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "ref_course_course_status",
                    "type": "relation",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {}
                    },
                    "templateOptions": {
                        "label": "Course status",
                        "placeholder": "",
                        "description": "Please select an option from the list",
                        "options": [],
                        "api": "ref-course-course-statuses",
                        "relation": "one",
                        "mainField": "name",
                        "required": true
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "courseImage",
                    "type": "file",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Course image is required."
                        }
                    },
                    "templateOptions": {
                        "label": "Course image*",
                        "placeholder": "",
                        "description": "Upload image in JPEG/JPG, PNG, GIF, SVG, ICO format",
                        "required": true,
                        "options": [],
                        "allowedTypes": [
                            "images"
                        ],
                        "attributes": {
                        "aria-label": "Course image"
                        }
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "contentFile",
                    "type": "file",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "fileSizeMax": "The maximum supported file size is 16 MB",
                        }
                    },
                    "templateOptions": {
                        "label": "Content file",
                        "placeholder": "",
                        "description": "Maximum file size is 16 MB. Allowed file types are pdf, ppt, pps, pptx, xls, xlsx, rtf, txt, doc, docx, pub, jpeg, png",
                        "options": [],
                        "allowedTypes": [
                            "images",
                            "files"
                        ],
                        "attributes": {
                        "aria-label": "Content file"
                        },
                        "fileSizeMax": 16777216,
                        "fileExtensions": "pdf,ppt,pps,pptx,xls,xlsx,rtf,txt,doc,docx,pub,jpg,jpeg,png"                        
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        }
    ]

    rowId: any;
    routeIntent: string;
    state: any;
    myGroup: FormGroup;
    courseProviderForm: FormGroup;
    disableButton: boolean;
    courseProviderData: any;
    providerId: any;

    identifier: any;
    schemaUrl: any;
    apiUrl: any;
    titleInformation: any[];
    routerPath = '/courses-events/courses/view';
    queryParams: any;

    collection = "/courses";

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly inPageNavService: InPageNavService,
        private readonly courseNavigation: CourseManagementNavigation,
        private readonly router: Router,
        private readonly appConfirmService: AppConfirmService
    ) {
        this.scrollToTop();
        this.activatedRoute.queryParams.subscribe((params: any) => {
            if (params.id) {
                this.rowId = params.id;
                this.state = params.state;
                this.routeIntent = "edit";
            } else {
                this.routeIntent = "new"
            }
        });
        this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
        this.queryParams = { id: this.rowId };
        this.schemaUrl = 'api::course.course';
        this.apiUrl = '/courses';
        this.activatedRoute.queryParams.subscribe((params: any) => {
        this.queryParams = params;
            if (params.id) {
                this.identifier = params.id;
                this.routeIntent = "edit";
            }

        });
    }

    ngOnInit(): void {
        this.addFieldHideExpression();
    }

    ngOnDestroy() {
        this.inPageNavService.setNavItems(null);
    }

    exitNavigation() {
        if (this.queryParams && this.queryParams.id) {
            // Navigate to the view page with both id and hideEdit parameters
            this.router.navigate(['/courses-events/courses/view'], {
                queryParams: {
                    id: this.queryParams.id,
                    hideEdit: "Active" // Ensure hideEdit is included
                }
            });
        } else {
            // Navigate to the default page if id is not available
            this.router.navigate(['/courses-events/courses']);
        }
      }
    addFieldHideExpression() {
        this.fields.forEach(fieldGroup => {
            fieldGroup.fieldGroup.forEach(field => {
                if (field.key == 'courseId') {
                    if (this.routeIntent == 'edit') {
                        field.hideExpression = false;
                        field.templateOptions.disabled = true;

                    } else {
                        field.hideExpression = true;
                        field.templateOptions.disabled = false;
                    }
                }
            });
        });
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    navigateToRoute(){
        this.router.navigate([`courses-events/${this.collection}`]);
    }


    canDeactivate(): Observable<boolean> | boolean {

        if (this.isNotSaved) {
            return this.appConfirmService.confirm(
                {
                    title: `Progress not saved`,
                    message: 'If you navigate away from this page your progress will not be saved'
                });
        } else {
            return true
        }
    }
}
