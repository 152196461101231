
<div class="container">
  <mat-dialog-content>
    <h2 class="title ml-20">{{data.title}} room</h2>
    <form [formGroup]="form">
      <formly-form [model]="model" [fields]="fields" [form]="form" tabindex="0"></formly-form></form>
    </mat-dialog-content>
  <mat-dialog-actions align="end" class="padding">
    <button mat-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid" (click)="onSubmit()">Save</button>
  </mat-dialog-actions>
</div>