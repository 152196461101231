<mat-card id='edit_content'>
  <mat-card-header>
    <span class="spacer">
      <app-stepper-navigation></app-stepper-navigation>
    </span>
    <span>
      <button id="exit_user_button" mat-stroked-button
        [routerLink]="['/content-management']">Exit</button>
    </span>
  </mat-card-header>
  <mat-card-content>
    <div class="info-text">
      <p class="mt0">Choose an image which best represents the resource you are uploading. This will be the image used
        to
        represent the resource in the Resource Library for the Service User.</p>
    </div>
    <div class="label-hr">
      <span>Images</span>
    </div>
    <div style="width: 33%">
      <span>
        <mat-form-field id="orgNameFilter" appearance="fill">
          <mat-label class="blur_color">Search</mat-label>
          <input matInput type="text" id="suNameFilter" [(ngModel)]="searchTerm" (keydown.enter)="onFilter(searchTerm)"
            placeholder="Search by">
          <button *ngIf="!searchTerm" id="do_search_button" matSuffix mat-icon-button aria-label="Search">
            <mat-icon>search</mat-icon>
          </button>
          <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClose(searchTerm)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </span>

    </div>
    <div class="card-row">
      <div *ngFor="let item of contentImageList" class="image-list">
        <mat-card (click)="selectImage(item)" [ngClass]="item.id === activeElement ? 'active' : 'none'">
          <img [src]="getImagePath(item.imageName)" alt="{{item.imageName}}">
          <mat-card-content>
            <p title="{{item.keywords}}">{{item.keywords}}</p>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <p class="text-center warning-style" *ngIf="contentImageList && contentImageList?.length === 0">No results found.
    </p>
    <mat-paginator id="image_pagination"  [pageSizeOptions]="[25, 50, 100]" hidePageSize="'false'" >
    </mat-paginator>

  </mat-card-content>
  <mat-card-footer>
    <span class="spacer"></span>
    <span>
      <button type="button" id="save_user_button" mat-flat-button color="primary" [disabled]="!contentImageCtrl.valid"
        (click)="saveImage()">Save & Next</button>
    </span>
  </mat-card-footer>
</mat-card>