import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { CourseManagementService } from '../../course-management.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { tap } from 'rxjs';
import { IBooking } from '../booking.interface';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SessionsService } from 'src/app/sessions/sessions.service';

@Component({
  selector: 'app-waiting-list',
  templateUrl: './waiting-list.component.html',
  styleUrls: ['./waiting-list.component.scss']
})
export class WaitingListComponent implements AfterViewInit {
  displayedColumns: string[] = ['serviceUserId','serviceNumber', 'createdDate','bookingStatusId','actions'];
  dataSource = new MatTableDataSource<IBooking>();

  sortColumn = 'createdDate';
  sortDirection = 'asc';
  pageSize = 25;
  filterBy = { 'keyword': '', 'deliveryId': '','genericId': null };
  deleteReasonsRefData: any;
  licencesDetail;
  userType = 2
  searchTerm: string = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  identifier: any;
  pageName: any;
  requestStatusTypeList: any;
  filteredId: any;
  select = "all status";
  showFilteredList: boolean;
  allBookingList: boolean = true;

  isActive = false;
  isAuthorized: any;
  isAuthorizedCD : any;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly inPageNavService: InPageNavService,
    private readonly courseManagementService: CourseManagementService,
    private readonly snackBarService: SnackBarService,
    private readonly sessionService: SessionsService,
    private readonly courseNavigation: CourseManagementNavigation,
    private cdr: ChangeDetectorRef,
  ) {
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
      this.isActive = params.hideEdit;
    });
  }
  ngOnInit(): void {
    this.getStatusRefData();
    this.isAuthorized = this.sessionService.hasResource(['190', '2']);
    this.isAuthorizedCD = this.sessionService.hasResource(['140', '2']);
    this.filterBy.deliveryId = this.identifier;
    this.resolveBookings(this.filterBy);
  }


  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.isAuthorized = false;
    this.isAuthorizedCD = false;
    this.inPageNavService.setNavItems(null);
  }

  onFilter(filterString: string) {
    this.filterBy.keyword = filterString;
    this.select = "all status";
    this.paginator.pageIndex = 0;
    this.resolveBookings(this.filterBy);
    this.cdr.detectChanges();
  }

  onClickClose(): Promise<void> {
    return new Promise((resolve) => {
        this.searchTerm = '';
        this.onFilter(this.searchTerm);
        resolve();
    });
}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(data => {
      this.sortColumn = data.active;
      this.sortDirection = data.direction;
      this.paginator.pageIndex = 0;
      if (this.allBookingList) {
        this.resolveBookings(this.filterBy);
      } else {
        this.resolveFilteredBookings(this.filterBy);
      }
      
    });

    this.paginator.page
      .pipe(
        tap(() => {
          if (this.allBookingList) {
            this.resolveBookings(this.filterBy);
          } else {
            this.resolveFilteredBookings(this.filterBy);
          }
          document.querySelector('#request-booking').scrollIntoView();
        }
        )
      )
      .subscribe();
  }

  resolveBookings(filterBy) {
    let currentPageIndex = 0;
    if (this.paginator) {
      currentPageIndex = this.paginator.pageIndex;
    }
    if(this.paginator.pageSize==undefined)
    {
      this.paginator.pageSize=25
    }
    this.courseManagementService.findAllWaitingBookings(`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.paginator.pageSize, filterBy)
    .subscribe(allBookings => {
      this.dataSource = allBookings.content;
      this.paginator.length = allBookings.totalElements;
      this.dataSource.sort = this.sort;
    }, error => {
      this.snackBarService.error(error.error.applicationMessage);
    });
  }

  findRequestBookingByStatus(event: any) {
    this.filteredId = event.value;
    this.showFilteredList = true;
    this.paginator.pageIndex = 0;

    if (event.value === 'all status') {
      this.filterBy.genericId = null;
      this.resolveBookings(this.filterBy);
    } else if (event.value === 0) {
      this.filterBy.genericId = null;
      this.resolveBookings(this.filterBy);
    } else if (event.value !== 'all status') {
      this.filterBy.genericId = this.filteredId;
      this.filterBy.keyword = '';
        this.resolveFilteredBookings(this.filterBy);
    }
  }


  resolveFilteredBookings(filterBy: { keyword: string; deliveryId: string; genericId: any; }) {
    let currentPageIndex = 0;
    if (this.paginator) {
      currentPageIndex = this.paginator.pageIndex;
    }
    if(this.paginator.pageSize==undefined)
    {
      this.paginator.pageSize=25
    }
    this.courseManagementService.findFilteredWaitingList(`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.paginator.pageSize, filterBy)
    .subscribe(filteredBookings => {
      this.dataSource = filteredBookings.content;
      this.paginator.length = filteredBookings.totalElements;
      this.dataSource.sort = this.sort;
    }, error => {
      this.snackBarService.error(error.error.applicationMessage);
    });
   
  }

  getStatusRefData(){
    this.courseManagementService.getRequestBookingStatus(5).subscribe(response =>{
      if(response){
      this.requestStatusTypeList = response;
      }
    })

  }
}
