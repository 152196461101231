<div class="header">
    <div class="tabWrapper">
        <ul>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve">
                <button mat-button [routerLink]="['./Attendance']"
                    [queryParams]="{ id: userId, name: userName }"> <a class="tab-item" id="year-tab"
                        aria-label="Attendance">Attendance</a></button>
            </li>
        </ul>

    </div>
</div>
<router-outlet></router-outlet>
