import { Injectable } from '@angular/core';

const jobsRoute = 'jobs'; 
 
@Injectable()
export class JobsNavigation {
 jobsSubMenu = {
  sectionName: 'Jobs',
  description: ``,
  state: null,
  icon: 'bar_chart',
  menuItems: [
    {
      name: 'Jobs',
      description: ``,
      state: `${jobsRoute}/jobs`,
      icon: null,
      submenu: [],
      featureId:[175]
    },
    {
      name: 'Employers',
      description: ``,
      state: `${jobsRoute}/employers`,
      icon: null,
      submenu: [],
      featureId:[191]
    }, 
  ]
 };
}
