import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-time-type',
  templateUrl: './time-type.component.html',
})
export class TimeTypeComponent extends FieldType implements OnInit{
  characterCount = 0;

  ngOnInit() {
    const [hoursStr, minutesStr] = this.formControl.value.split(':');

    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    // Format hours and minutes to hh:mm format
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    this.formControl.patchValue(`${formattedHours}:${formattedMinutes}`);
  }
  
  get timeFormControl(): FormControl {
    return this.formControl as FormControl;
  }
}
