import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { BaseUrl } from '../framework/constants/url-constants';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { IUserMessage } from './signin/signin.interface';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { SessionsService } from './sessions.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {

  public logoSrc = `${BaseUrl.LOGO}`;
  signinForm: FormGroup;
  signinError: IUserMessage;

  constructor(
    private readonly builder: UntypedFormBuilder,
    private readonly router: Router,
    private readonly sessionsService: SessionsService
  ) { }

  ngOnInit() {
    this.darkmode();
  }

  darkmode() {
    // const element = document.body;
    // if (element.classList) {
    //   element.classList.toggle('dark-mode');
    // }

    // if (localStorage.getItem('darkmode') === 'true') {
    //   localStorage.setItem('darkmode', 'false');
    // } else {
    //   localStorage.setItem('darkmode', 'true');
    // }
  }

  onload() {
    // if (document.body.classList) {
    //   document.body.classList.toggle('dark-mode', localStorage.getItem('darkmode') === 'true');
    // }
  }

}
