<mat-card id='view_content' *ngIf='!isOpenContent'>

  <mat-card-header>
    <h1 class="page-title">{{contents.title}} </h1>
    <span class="spacer"></span>
    <span>
      <button id="exit_user_button" mat-stroked-button color="primary"
        [routerLink]="['/content-management']">Exit</button>
    </span>
  </mat-card-header>

  <mat-card-content class="view_content_container">
    <div class="card-row mb20">
      <div class="card-column-two-third">
        <div class="infoSection">
          <div class="card-row">
            <div class="card-column">
              <p class="info">
                <span class="details_label">Author</span>
                {{contents.author || "N/A"}}
              </p>
            </div>
            <div class="card-column">
                <p class="info" *ngIf="contents.learningStyleIds">
                    <span class="details_label">Learning styles</span>
                    {{filterLearningStyleList(contents.learningStyleIds)}}
                  </p>
            </div>
            <div class="card-column">
              <p class="info">
                <span class="details_label">Curation start date</span>
                <span id="curationStartDateId">{{contents.curationStartDate  | date: 'dd/MM/yyyy' || "N/A"}}</span>
              </p>
            </div>
            <div class="card-column">    
              <p class="info">
                <span class="details_label">Curation end date</span>
                <span *ngIf="contents?.curationEndDate" id="curationEndDateId">{{contents?.curationEndDate | date: 'dd/MM/yyyy' || "N/A"}}</span>       
                <span *ngIf="!contents?.curationEndDate" id="curationEndDateId">N/A</span>       
              </p>
            </div>
          </div>
          <div class="card-row">
            <p class="info" *ngIf="contents.learningStyleIds">
              <span class="details_label">Description</span>
             <span id="descId">{{contents.desc}}</span>
            </p>
          </div>
        </div>
      </div>
    <div class="card-column-third">
      <img class="contentImg" src="{{contents.courseImgUrl}}" />
    </div>
    </div>
    <div class="card-row mb20">
        <div class="label-hr">
            <span>Keywords</span>
          </div>
      <div class="keyword-container" *ngIf="contents?.contentKeyWords?.length > 0">
        <mat-chip-list class="keyword-list" aria-label="Fish selection" multiple>
          <mat-chip color="primary" class="input_chip" *ngFor="let keyword of contents?.contentKeyWords" selected>
            <span id="keywordId" matLine>{{keyword}}</span>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
    
    <mat-card-content *ngIf="!hideModule" class="pl-0">
      <div class="label-hr">
        <span>Modules</span>
      </div>
      <mat-table id="content_modules_table" #moduleTable [dataSource]="dataSource" class="isMobile">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="width-40" id="contents_name_button">Module Name</mat-header-cell>
          <mat-cell *matCellDef="let element" class="width-40"><span class="mobile-label">Module Name: </span> <span id="nameId">{{element.name}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef id="subMenuCategory_button">Status</mat-header-cell>
          <mat-cell *matCellDef="let element">
              <span class="mobile-label call-status-label">Status: </span><span id="statusId">{{element.status}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell class="actions-column" *matHeaderCellDef>Action</mat-header-cell>
          <mat-cell class="actions-column" *matCellDef="let element"> <span class="mobile-label"></span>
            <button *auth="[172 ,2]" id="view_contents_button_{{element.id}}" mat-icon-button color="primary"
              (click)="launchModule(element)" matTooltip="View" [matTooltipShowDelay]="800" [disabled]="false" attr.aria-label="view {{element.name}}">
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card-content>
    <mat-card-content class="view_user-plan_container" *ngIf="hideModule">
      <div class="label-hr">
        <span>External</span>
    </div>
      <div class="custom-card-row-container mb20">
        <div class="custom-row-container-left">
          <div class="card-column-40 custom-card-column">
            <div class="card-row" *ngIf="selectedModule?.name">
              <p class="info">
                  <span class="details_label">Title</span>
                  <span id="title">{{selectedModule.name}}</span>
              </p>
            </div>
          </div>
          <div class="card-column-60 custom-card-column">
            <div class="card-row ml10" *ngIf="selectedModule?.path">
              <p class="info">
                  <span class="details_label">URL</span>
                  <a id="URL" *ngIf="!(selectedModule?.path.startsWith('http://') || selectedModule?.path.startsWith('https://'))"
                    href="http://{{selectedModule.path}}" target="_blank">{{selectedModule.path}}</a>
                  <a id="URL" *ngIf="selectedModule?.path.startsWith('http://') || selectedModule?.path.startsWith('https://')"
                    href="{{selectedModule.path}}" target="_blank">{{selectedModule.path}}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-row custom-row" *ngIf="selectedModule?.moduleDescription">
        <p class="info">
          <span class="details_label">Description</span>
          <span id="description">{{selectedModule.moduleDescription}}</span>
        </p>
    </div>
    </mat-card-content>
  </mat-card-content>

</mat-card>
<mat-card *ngIf="isOpenContent && !hideModule">
  <mat-card-header>
    <span id="titleId"> {{contents.title}} </span>
    <span class="spacer"></span>
    <span class="mb-20">
      <button id="exit_user_button" mat-stroked-button color="primary" (click)="isOpenContent = false">Exit</button>
    </span>
  </mat-card-header>
  <mat-card-content>
    <div class="width-100">
      <app-content-module [module]="selectedModule" [contentId]="contentId"></app-content-module>
    </div>
  </mat-card-content>
</mat-card>
