import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { JobsNavigation } from '../../jobs-nav';
import { CanDeactivateGuard } from 'src/app/framework/guards/can-deactivate.guard';
import { Observable } from 'rxjs';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';

@Component({
  selector: 'app-add-job-details',
  templateUrl: './add-job-details.component.html',
})
export class AddJobDetailsComponent implements CanDeactivateGuard{
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  isNotSaved: boolean = false;

  fields: FormlyFieldConfig[] = [
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "jobTitle",
          "type": "input",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Job title is required.",
              "minlength": "Minimum length should be 3 characters.",
              "maxlength": "Maximum length should be 100 characters."
            }
          },
          "templateOptions": {
            "label": "Job title",
            "placeholder": "",
            "description": "Minimum 3 and maximum 100 characters",
            "required": true,
            "options": [],
            "minLength": 3,
            "maxLength": 100
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "jobId",
          "type": "input",
          "className": "col-md-6",
          "validation": {
            "messages": {

            }
          },
          "templateOptions": {
            "label": "Job ID",
            "placeholder": "",
            "description": "",
            "options": []
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "type": "title",
          "templateOptions": {
            "label": "Job details"
          }
        },
        {
          "key": "employer",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {

            }
          },
          "templateOptions": {
            "label": "Employer",
            "placeholder": "",
            "description": "Please select an employer from the list",
            "options": [],
            "api": "employers",
            "relation": "one",
            "mainField": "employerName",
            "required": true
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "ref_job_job_type",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {

            }
          },
          "templateOptions": {
            "label": "Job type",
            "placeholder": "",
            "description": "Please select a job type from the list",
            "options": [],
            "api": "refJobJobTypes",
            "relation": "one",
            "mainField": "name",
            "required": true
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "ref_shared_industries",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Industry is required.",
              "minlength": "Minimum length should be 5 characters.",
              "maxlength": "Maximum length should be 500 characters."
            }
          },
          "templateOptions": {
            "label": "Industry",
            "placeholder": "Industry",
            "description": "Minimum 1 and maximum 3 industry.",
            "options": [],
            "api": "industries",
            "relation": "many",
            "mainField": "name",
            "maxQuantity": 3,
            "minQuantity": 1,
            "required": true
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "remoteHybridOnsite",
          "type": "select",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Remote / Hybrid / On-Site is required.",
            }
          },
          "templateOptions": {
            "label": "Remote / Hybrid / On-Site",
            "placeholder": "",
            "description": "Please select Remote / Hybrid / On-Site from the list",
            "options": [
              {
                "label": "Remote",
                "value": "Remote"
              },
              {
                "label": "Hybrid",
                "value": "Hybrid"
              },
              {
                "label": "On-Site",
                "value": "On-Site"
              }
            ],
            "change": (field: FormlyFieldConfig, event?: any) => {
              this.propagateValueToNestedComponents(field.formControl.value);
              this.previousWorkValue = field.formControl.value;
            },
            "required": true
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "address",
          "type": "postcode",
          "className": "col-md-12",
          "validation": {
            "messages": {

            }
          },
          "templateOptions": {
            "label": "Address",
            "placeholder": "",
            "description": "",
            "options": [],
          },
          "fieldArray": null,
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "postcode",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {

                    }
                  },
                  "templateOptions": {
                    "label": "Postcode",
                    "placeholder": "",
                    "description": "Minimum 4 characters required to get suggestions",
                    "options": [],
                    "required": true
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "addressLine1",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {
                      "minlength": "Minimum length should be 3 characters.",
                      "maxlength": "Maximum length should be 100 characters."
                    }
                  },
                  "templateOptions": {
                    "label": "Address line 1",
                    "placeholder": "",
                    "description": "Minimum 3 and maximum 100 characters",
                    "required": false,
                    "options": [],
                    "minLength": 3,
                    "maxLength": 100
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "addressLine2",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {
                      "minlength": "Minimum length should be 3 characters.",
                      "maxlength": "Maximum length should be 100 characters."
                    }
                  },
                  "templateOptions": {
                    "label": "Address line 2",
                    "placeholder": "",
                    "description": "Minimum 3 and maximum 100 characters",
                    "options": [],
                    "minLength": 3,
                    "maxLength": 100
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "townCity",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {

                    }
                  },
                  "templateOptions": {
                    "label": "Town/City",
                    "placeholder": "",
                    "description": "",
                    "required": false,
                    "options": [],
                    "disabled": true
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "region",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {

                    }
                  },
                  "templateOptions": {
                    "label": "Region",
                    "placeholder": "",
                    "description": "",
                    "required": false,
                    "options": [],
                    "disabled": true
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "country",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {

                    }
                  },
                  "templateOptions": {
                    "label": "Country",
                    "placeholder": "",
                    "description": "",
                    "options": [],
                    "disabled": true
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
          ]
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "salaryType",
          "type": "select",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Salary type is required."
            }
          },
          "templateOptions": {
            "label": "Salary type",
            "placeholder": "",
            "description": "Please select a salary type from the list",
            "required": true,
            "options": [
              {
                "label": "Hourly",
                "value": "Hourly"
              },
              {
                "label": "Daily",
                "value": "Daily"
              },
              {
                "label": "Weekly",
                "value": "Weekly"
              },
              {
                "label": "Monthly",
                "value": "Monthly"
              },
              {
                "label": "Yearly",
                "value": "Yearly"
              }
            ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "salaryRange1",
          "type": "number",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Salary range min is required.",
              "pattern": "This field format is invalid.",
              "min": "Minimum number should be 1.",
              "max": "Maximum number should be 9999999.",
            }
          },
          "templateOptions": {
            "label": "Salary range min (in £)",
            "placeholder": "",
            "description": "Minimum 1 and maximum 9999999",
            "required": true,
            "options": [],
            "pattern": "^\\d+(\\.\\d{1,2})?$",
            "max": 9999999,
            "min": 1
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        },
        {
          "key": "salaryRange2",
          "type": "number",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Salary range max is required.",
              "pattern": "This field format is invalid.",
              "min": "Minimum number should be 1.",
              "max": "Maximum number should be 9999999.",
            }
          },
          "templateOptions": {
            "label": "Salary range max (in £)",
            "placeholder": "",
            "description": "Minimum 1 and maximum 9999999",
            "options": [],
            "required": true,
            "pattern": "^\\d+(\\.\\d{1,2})?$",
            "max": 9999999,
            "min": 1
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "advertGoLiveDate",
          "type": "datepicker",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Advertisement go live date is required."
            }
          },
          "templateOptions": {
            "label": "Advertisement go live date",
            "placeholder": "",
            "description": "",
            "required": true,
            "options": []
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        },
        {
          "key": "advertEndDate",
          "type": "datepicker",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Advertisement end date is required.",
            }
          },
          "templateOptions": {
            "label": "Advertisement end date",
            "placeholder": "",
            "description": "End date cannot be more than 12 weeks in advance from start date",
            "options": [],
            "required": true,
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "targetStartDate",
          "type": "datepicker",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Target job start date is required.",
            }
          },
          "templateOptions": {
            "label": "Target job start date",
            "placeholder": "",
            "description": "",
            "options": [],
            "required": true
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "briefRoleDesc",
          "type": "input",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Brief role description is required.",
              "minlength": "Minimum length should be 50 characters.",
              "maxlength": "Maximum length should be 200 characters."
            }
          },
          "templateOptions": {
            "label": "Brief role description",
            "placeholder": "",
            "description": "Minimum 50 and maximum 200 characters",
            "required": true,
            "options": [],
            "minLength": 50,
            "maxLength": 200
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "hoursAndShiftPattern",
          "type": "input",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Hours and shift pattern is required.",
              "minlength": "Minimum length should be 3 characters.",
              "maxlength": "Maximum length should be 200 characters."
            }
          },
          "templateOptions": {
            "label": "Hours and shift pattern",
            "placeholder": "",
            "description": "Minimum 3 and maximum 200 characters",
            "required": true,
            "options": [],
            "minLength": 3,
            "maxLength": 200
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "ref_job_contract_types",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Contract type is required.",
              "minlength": "Minimum length should be 5 characters.",
              "maxlength": "Maximum length should be 500 characters."
            }
          },
          "templateOptions": {
            "label": "Contract type",
            "placeholder": "Contract type",
            "description": "Minimum 1 contract type",
            "options": [],
            "api": "refJobContractTypes",
            "relation": "many",
            "mainField": "name",
            "minQuantity": 1,
            "required": true
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        },
        {
          "key": "workHoursType",
          "type": "select",
          "className": "col-md-6",
          "validation": {
              "messages": {
                  "required": "Full-time / Part-time is required."
              }
          },
          "templateOptions": {
              "label": "Full-time / Part-time",
              "placeholder": "",
              "description": "Please select Full-time / Part-time from the list",
              "required": true,
              "options": [
                  {
                      "label": "Full-time",
                      "value": "Full-time"
                  },
                  {
                      "label": "Part-time",
                      "value": "Part-time"
                  }
              ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }        
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "roleDescription",
          "type": "richtext",
          "className": "col-md-12",
          "validation": {
            "messages": {
              "required": "Role description is required.",
              "minlength": "Minimum length should be 100 characters.",
            }
          },
          "templateOptions": {
            "label": "Role description",
            "placeholder": "",
            "description": "Minimum 100 characters",
            "options": [],
            "minLength": 100,
            "required": true,
            "attributes": {
              "aria-label": "Role description"
              }
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "roleRequirements",
          "type": "richtext",
          "className": "col-md-12",
          "validation": {
            "messages": {
              "required": "Role requirements is required.",
              "minlength": "Minimum length should be 50 characters."
            }
          },
          "templateOptions": {
            "label": "Role requirements",
            "placeholder": "",
            "description": "Minimum 50 characters",
            "options": [],
            "minLength": 50,
            "required": true,
            "attributes": {
              "aria-label": "Role requirements"
              }
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "roleBenifits",
          "type": "richtext",
          "className": "col-md-12",
          "validation": {
            "messages": {
              "minlength": "Minimum length should be 50 characters."
            }
          },
          "templateOptions": {
            "label": "Role benefits",
            "placeholder": "",
            "description": "Minimum 50 characters",
            "options": [],
            "minLength": 50,
            "attributes": {
              "aria-label": "Role benefits"
              }
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "veteranSpecific",
          "type": "radio",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Veteran specific job? is required."
            }
          },
          "templateOptions": {
            "label": "Veteran specific job?",
            "placeholder": "",
            "description": "",
            "required": true,
            "options": [
              {
                "label": "Yes",
                "value": true
              },
              {
                "label": "No",
                "value": false
              }
            ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null,
          "defaultValue": false
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "promoteThisJob",
          "type": "radio",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Promote this job? is required."
            }
          },
          "templateOptions": {
            "label": "Promote this job?",
            "placeholder": "",
            "description": "",
            "required": true,
            "options": [
              {
                "label": "Yes",
                "value": true
              },
              {
                "label": "No",
                "value": false
              }
            ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null,
          "defaultValue": false
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "promotionStartDate",
          "type": "datepicker",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Vacancy promotion start date is required."
            }
          },
          "templateOptions": {
            "label": "Vacancy promotion start date",
            "placeholder": "",
            "description": "Must be same date or after Advertisement Go Live date",
            "required": true,
            "options": []
          },
          "hideExpression": "!model.promoteThisJob || model.promoteThisJob !== true",
          "expressionProperties": {
            "templateOptions.required": "model.promoteThisJob === true"
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        },
        {
          "key": "promotionEndDate",
          "type": "datepicker",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Vacancy promotion end date is required."
            }
          },
          "templateOptions": {
            "label": "Vacancy promotion end date",
            "placeholder": "",
            "description": "Must be same date or less of Advertisement end date",
            "required": true,
            "options": []
          },
          "hideExpression": "!model.promoteThisJob || model.promoteThisJob !== true",
          "expressionProperties": {
            "templateOptions.required": "model.promoteThisJob === true"
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "numberOfVacancies",
          "type": "number",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Number of vacancies is required.",
              "pattern": "This field format is invalid.",
              "min": "Minimum number should be 1.",
              "max": "Maximum number should be 50.",
            }
          },
          "templateOptions": {
            "label": "Number of vacancies",
            "placeholder": "",
            "description": "Minimum 1 and maximum 50 vacancies is required",
            "required": true,
            "options": [],
            "pattern": "^[0-9]*$",
            "max": 50,
            "min": 1
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "status",
          "type": "select",
          "className": "col-md-6",
          "validation": {
            "messages": {
            }
          },
          "templateOptions": {
            "label": "Job status",
            "placeholder": "",
            "description": "Please select a job status from the list",
            "options": [
              {
                "label": "Active",
                "value": "Active"
              },
              {
                "label": "Pending",
                "value": "Pending"
              },
              {
                "label": "Closed",
                "value": "Closed"
              }
            ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
  ];
  identifier: any;
  routeIntent: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  routerPath = '/jobs/jobs/view';
  collection = '/jobs';
  rowId: any;
  state: any;
  previousWorkValue;
  queryParams: any;

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly jobsNavigation: JobsNavigation,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly appConfirmService: AppConfirmService
  ) {
    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.jobsNavigation.jobsSubMenu);
    this.queryParams = { id: this.rowId };
    this.schemaUrl = 'api::job.job';
    this.apiUrl = '/jobs'
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.queryParams = params;
      if (params.id) {
        this.rowId = params.id;
        this.state = params.state;
        this.routeIntent = "edit";
      } else {
        this.routeIntent = "new"
      }
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  exitNavigation() {
    if (this.queryParams && this.queryParams.id) {
        this.router.navigate(['/jobs/jobs/view'], {
            queryParams: {
                id: this.queryParams.id,
                hideEdit: "Active"
            }
        });
    } else {
        this.router.navigate(['/jobs']);
    }
}
  navigateToRoute() {
    this.router.navigate([`jobs/${this.collection}`]);
  }

  ngOnInit(): void {
    this.addFieldHideExpression();
    if (this.routeIntent === "edit") {
      this.waitForModel();
    }
  }

  waitForModel(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkModel = () => {
        if (this.model?.remoteHybridOnsite) {
          this.previousWorkValue = this.model?.remoteHybridOnsite;

          resolve();
        } else {
          setTimeout(checkModel, 100);
        }
      };
      checkModel();
    });
  }
  addFieldHideExpression() {
    this.fields.forEach(fieldGroup => {
      fieldGroup.fieldGroup.forEach(field => {
        if (field.key == 'jobId') {
          if (this.routeIntent == 'edit') {
            field.hideExpression = false;
            field.templateOptions.disabled = true;

          } else {
            field.hideExpression = true;
            field.templateOptions.disabled = false;
          }
        }
        if (field.key == 'status') {
          if (this.routeIntent == 'edit') {
            field.hideExpression = false;
            field.templateOptions.disabled = true;

          } else {
            field.hideExpression = true;
            field.templateOptions.disabled = false;
          }
        }
      });
    });
  }

  propagateValueToNestedComponents(value: any) {
    this.fields.forEach(fieldGroup => {
      for (let i = 0; i < fieldGroup.fieldGroup.length; i++) {
        let field = fieldGroup.fieldGroup[i];
        if (field.key == 'address' && value == 'Remote') {
          field.hide = true;
          field.fieldGroup[0].fieldGroup[0].templateOptions.required = false;
        } else if (field.key == 'address' && value != 'Remote') {
          field.hide = false;
          field.fieldGroup[0].fieldGroup[0].templateOptions.required = true;
          break;
        }
      }
    });
  }

  onDataLoad(event) {
    this.model = event
    this.fields.forEach(fieldGroup => {
      for (let i = 0; i < fieldGroup.fieldGroup.length; i++) {
        let field = fieldGroup.fieldGroup[i];
        if (field.key == 'remoteHybridOnsite') {
          let value = this.model[field.key]
          this.propagateValueToNestedComponents(value)
        }
      }
    });
  }

  canDeactivate(): Observable<boolean> | boolean {

    if (this.isNotSaved) {
        return this.appConfirmService.confirm(
            {
                title: `Progress not saved`,
                message: 'If you navigate away from this page your progress will not be saved'
            });
    } else {
        return true
    }
}
}
