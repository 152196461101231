import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SessionsService } from './sessions/sessions.service';
import { HttpRequestInterceptorModule } from './framework/service/http-interceptor.service';
import { AuthenticationGuard } from './framework/guards/authentication.guard';
import { StepperNavigationModule } from './features/shared/components/stepper-navigation/stepper-navigation.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { OverlayContainer } from '@angular/cdk/overlay';
import { SharedModule } from './framework/shared/shared.module';
import { UserActivityModule } from './sessions/user-activity/user-activity.module';
import { AuthorizationGuard } from './framework/guards/authorization.guard';
import { MaterialModule } from './framework/material/material.module';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterPipeModule } from './framework/pipes/filter.module';
import { QuillModule } from 'ngx-quill'
import { FormlyModule } from '@ngx-formly/core';
import { RepeatTypeComponent } from './features/dynamic-forms/repeat-type/repeat-type.component';
import { SingleTypeComponent } from './features/dynamic-forms/single-type/single-type.component';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TimeTypeComponent } from './features/dynamic-forms/time-type/time-type.component';
import { QuillFieldTypeComponent } from './features/dynamic-forms/quill-field-type/quill-field-type.component';
import { FileUploadTypeComponent } from './features/dynamic-forms/file-upload-type/file-upload-type.component';
import { CourseManagementModule } from './features/course-management/course-management.module';
import { SessionsModule } from './sessions/sessions.module';
import { TitleTypeComponent } from './features/dynamic-forms/title-type/title-type.component';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import { AddMorePopupComponent } from './features/dynamic-forms/relation-type/add-more-popup/add-more-popup.component';
import { RelationTypeComponent } from './features/dynamic-forms/relation-type/relation-type.component';
import { ContentManagementModule } from './features/content-management/content-management.module';
import { AddressTypeComponent } from './features/dynamic-forms/address-type/address-type.component';
import { ImageViewModule } from './framework/shared/components/image-viewer/image-viewer.module';
import { JobsModule } from './features/jobs/jobs.module';
import { AddMorePopupExternalComponent } from './features/dynamic-forms/external-relation-type/add-more-popup-external/add-more-popup-external.component';

import {  MsalBroadcastService, MsalGuard, MsalModule, MsalService, MSAL_INSTANCE} from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { ExternalRelationTypeComponent } from './features/dynamic-forms/external-relation-type/external-relation-type.component';
import { RegionTypeComponent } from './features/dynamic-forms/region-type/region-type.component';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './framework/state-management/state.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GlobalErrorHandler } from './framework/service/global-error-handler.service';
@NgModule({
  declarations: [
    AppComponent,
    RepeatTypeComponent,
    RelationTypeComponent,
    SingleTypeComponent,
    TimeTypeComponent,
    QuillFieldTypeComponent,
    FileUploadTypeComponent,
    TitleTypeComponent,
    AddMorePopupComponent,
    AddressTypeComponent,
    AddMorePopupExternalComponent,
    RegionTypeComponent,
    ExternalRelationTypeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpRequestInterceptorModule,
    StepperNavigationModule,
    UserActivityModule,
    TranslateModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    SharedModule,
    MaterialModule,
    FormsModule,
    FilterPipeModule,
    FormlyModule.forRoot({
      types: [
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'relation', component: RelationTypeComponent },
        { name: 'single', component: SingleTypeComponent },
        { name: 'time', component: TimeTypeComponent },
        { name: 'richtext', component: QuillFieldTypeComponent }
        // ... your other types
      ],
    }),
    FormlyMaterialModule,
    FormlyMatToggleModule,
    FormlyMatDatepickerModule,
    ReactiveFormsModule,
    FilterPipeModule,
    QuillModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    QuillModule.forRoot(),
    CourseManagementModule,
    SessionsModule,
    NgxMatTimepickerModule,
    ContentManagementModule,
    ImageViewModule,
    JobsModule,
    MsalModule,
    StoreModule.forRoot({ app: appReducer }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  providers: [SessionsService , AuthenticationGuard , AuthorizationGuard,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    overlayContainer.getContainerElement().classList.add('seq-theme');
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.issuer,
      redirectUri: environment.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    }
  });
}
