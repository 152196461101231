import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private lastVisitedUrl: string = '/';

  constructor(private router: Router, private injector: Injector) {

    console.log("Constructor called");
    

    this.router.events.subscribe(event => {

      console.log("Get router event", event);

      if (event instanceof NavigationEnd) {

        console.log("Get router event instance ", event.urlAfterRedirects);
        this.lastVisitedUrl = event.urlAfterRedirects || this.router.url;
        console.log("Get last visited route", this.lastVisitedUrl);
        
      }
    });
  }

  handleError(error: any): void {

    console.log("Invoked handle Error function", error);
    // Detect ChunkLoadError
    if (error instanceof Error && error.name === 'ChunkLoadError') {
      
      console.log('ChunkLoadError occurred. Asking user to reload...');
      
      // Show a native browser confirmation dialog
      const shouldReload = confirm('A new version of the app is available. Reload now to update?');

      if (shouldReload) {
        // Reload the page to get the latest version
        this.reloadLastVisitedPage();
      } else {
        console.log('User opted to stay on the current version.');
      }
    } else {
      console.error('An error occurred:', error);
    }
  }

  private reloadLastVisitedPage(): void {

    // Try to navigate to the last visited URL
    this.router.navigateByUrl(this.lastVisitedUrl).then(success => {

      if (!success) {

        // If navigation fails, fall back to a full reload
        console.warn('Navigation failed, performing full reload...');
        location.reload();
      }
    }).catch(err => {
      
      // Handle any unexpected errors during navigation
      console.error('Error during navigation:', err);
      location.reload(); // Fallback to full reload
    });
  }
}
