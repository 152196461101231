<mat-card id='content_type' >
  <mat-card-header>
    <span class="spacer">
      <app-stepper-navigation></app-stepper-navigation>
    </span>
    <span class="action-btn-alignment">
      <button id="exit_button" mat-stroked-button [routerLink]="['/content-management']">Exit</button>
    </span>
  </mat-card-header>
  <div class="label-hr">
    <span>Content Type</span>
  </div>
  <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" [(ngModel)]="selectedContentType" ngDefaultControl>
    <mat-radio-button class="radio-button" *ngFor="let contentType of contentType"
      [value]="contentType">
      {{contentType | titlecase}}
    </mat-radio-button>
  </mat-radio-group>
  <span class="action-btn-alignment">
    <button id="confirm_button" mat-stroked-button [routerLink]="[selectedContentType]"
      queryParamsHandling="preserve"> Confirm </button>
  </span>
</mat-card>