<div class="header">
    <div class="tabWrapper">
        <ul>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve">
                <button mat-button [routerLink]="['./workshop-delivery']"
                    [queryParams]="{ id: userId, name: userName,hideEdit: isActive }"> <a class="tab-item" id="year-tab"
                        aria-label="Workshop delivery details">Workshop delivery details</a></button>
            </li>
            <li class="tabHead" [routerLink]="['./booking-request']" routerLinkActive="active" queryParamsHandling="preserve"  *ngIf="isActive ==='false'">
                <button mat-button [routerLink]="['./booking-request']"
                    [queryParams]="{ id: userId, name: userName, operation: 'view',hideEdit: isActive }">
                    <a class="tab-item" id="year-tab" aria-label="Booking requests">Booking requests</a></button>
            </li>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve"  *ngIf="isActive ==='false'">
                <button mat-button [routerLink]="['./confirm-booking']"
                    [queryParams]="{ id: userId, name: userName, operation: 'view',hideEdit: isActive }">
                    <a class="tab-item" id="year-tab" aria-label="Confirmed bookings">Confirmed bookings</a></button>
            </li>
        </ul>

    </div>
</div>
<router-outlet></router-outlet>