import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { FieldType } from '@ngx-formly/core';
import { Observable, of, startWith, switchMap, map, catchError } from 'rxjs';
import { DynamicTableService } from 'src/app/framework/components/dynamic-table/dynamic-table.service';
import { AddMorePopupExternalComponent } from './add-more-popup-external/add-more-popup-external.component';

@Component({
  selector: 'app-external-relation-type',
  templateUrl: './external-relation-type.component.html',
  styleUrls: ['./external-relation-type.component.scss']
})
export class ExternalRelationTypeComponent extends FieldType implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterBy: { 'keyword': string };
  searchControl: FormControl = new FormControl();
  filteredOptionsList: Observable<{ id: number; name: string }[]> = of([]);
  optionsList: { id: string; name: string }[] = [];
  selectedOptions: Array<{ id: string;[key: string]: any }> = [];
  relationControl: FormControl = new FormControl<string[]>([]);
  oneToOneFlag = false;
  maxLimitFlag = false;
  optionSelectedFromDropdown = false;
  filteredData: any;
  excludedIds: any;
  locationId: any;
  filterByRelationId: any;
  mainField: any;
  tempList: any;

  get isManyRelation(): boolean {
    return this.to['relation'] === 'many';
  }

  constructor(private http: HttpClient,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private readonly dynamicTableService: DynamicTableService,
  ) {
    super();
  }

  async ngOnInit() {
    const sortField = this.to['sortField'];
    let sort = `${sortField},asc`;
    this.mainField = this.to['mainField'];
  
    // Define pagination settings
    const pagination = {
      page: 0,
      pageSize: 1000,
    };
      this.dynamicTableService
      .getExternalList(`${sort}`, pagination.page, pagination.pageSize, { keyword: "" }, this.to['api'], this.to['apiName']).subscribe(data => {
        if (data.content) {
          this.tempList = data.content
        }
        else {
          this.tempList = data
        }
        this.onClick()
      })
    this.relationControl.valueChanges.subscribe((val) => {
      if (val && val.length > 0) {
        this.selectedOptions = this.getRelationControlValues();
        if (this.field.formControl) {
          this.field.formControl.setValue(val);
        }
      }
    });


    this.filteredOptionsList = this.searchControl.valueChanges.pipe(
      startWith(''),
      switchMap((value) => {
        let filterById;
        const filterBy = { 'keyword': value };
        return this.fetchOptions(filterBy);
      })
    );


    this.waitForModel().then(() => {
      this.field.formControl.setErrors(null);
      if (this.selectedOptions.length === this.to.maxQuantity) {
        this.maxLimitFlag = true;
      } else {
        this.maxLimitFlag = false;
      }
      if (this.to.minQuantity && this.selectedOptions.length >= this.to.minQuantity) {
        this.to.required = false;
      }
    });
  }

  onClick() {
    if (!this.searchControl.value) {
      this.searchControl.setValue('');
    }
  }

  removeNullRelations() {
    if (this.formControl?.getRawValue()?.data === null) {
      this.formControl.setValue([]);
    }
  }

  getAllOptions() {
    if (this.to['relation'] === 'many') {
      const searchTerm = '';
      this.filteredOptionsList = this.fetchOptions({ 'keyword': searchTerm })
    }
  }

  waitForModel(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkModel = () => {
        if (this.formControl.getRawValue() && this.tempList !== undefined) {
        let rawData = this.formControl.getRawValue();
          this.removeNullRelations();
          if (rawData) {
            if (this.to['relation'] === 'one') {
              let selectedIdDetails = this.tempList.filter(item => {
                return item.id.toString() === rawData;
              });
              this.relationControl = this.formControl as FormControl;
              this.oneToOneFlag = true;
              const selectedOption = {
                id: selectedIdDetails[0].id,
                name: selectedIdDetails[0][this.mainField] ? selectedIdDetails[0][this.mainField] : null,
              };
              this.searchControl.setValue(selectedOption.name);
              this.selectedOptions.push(selectedOption);
              this.optionSelectedFromDropdown = true;
            } else if (this.to['relation'] === 'many') {
              rawData = rawData.split(',').filter(skill => skill.trim() !== '');
              this.relationControl = this.formControl as FormControl;
              this.selectedOptions = this.selectedOptions.concat(rawData.map((item: any) => {
                let selectedIdDetails = this.tempList.filter(temp => {
                  return temp.id.toString() === item;
                });
                const name = selectedIdDetails[0][this.mainField] ? selectedIdDetails[0][this.mainField] : null;
                if (name !== null) {
                  return {
                    id: selectedIdDetails[0].id + '',
                    name: selectedIdDetails[0][this.mainField] ? selectedIdDetails[0][this.mainField] : null,
                  };
                }
              }).filter(item => item !== undefined));
              this.searchControl.setValue('');
              this.relationControl.setValue(this.selectedOptions.map((opt) => opt.id));
            }
            resolve();
          }
        } else {
          setTimeout(checkModel, 100);
        }
      };
      checkModel();
    });
  }

  onOptionSelected(event: any): void {
    const selectedOption = {
      id: event.option.value.toString(),
      name: event.option?.viewValue,
    };
    if (this.isManyRelation) {
      this.selectedOptions.push(selectedOption);
      const newIds = this.selectedOptions.map((opt) => opt.id);
      this.relationControl.setValue(newIds);
      this.searchControl.setValue('');
    }
    else {
      this.selectedOptions = [];
      this.selectedOptions.push(selectedOption);
      this.relationControl.reset()
      this.relationControl.setValue(selectedOption.id);
      this.formControl.setValue(selectedOption.id);
      this.searchControl.setValue(selectedOption.name);
      this.oneToOneFlag = true;
    }
    if (this.selectedOptions.length === this.to.maxQuantity) {
      this.maxLimitFlag = true;
    }
    if (this.selectedOptions.length >= this.to.minQuantity) {
      this.to.required = false;
    }
    this.optionSelectedFromDropdown = true;
  }

  remove(id: string): void {
    this.searchControl.setValue(this.searchControl.value);
    const index = this.selectedOptions.findIndex((opt) => opt.id === id);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.relationControl.setValue(this.selectedOptions.map((opt) => opt.id));
    }
    if (this.selectedOptions?.length === 0) {
      this.relationControl = this.formControl as FormControl;
      this.formControl.setValue([]);
    }
    if (this.to['relation'] === 'one') {
      this.oneToOneFlag = false
    }
    if (this.selectedOptions.length < this.to.maxQuantity) {
      this.maxLimitFlag = false;
    }
    if (this.selectedOptions.length < this.to.minQuantity) {
      this.to.required = true;
    }
    if (this.relationControl.value.length === 0) {
      this.relationControl.setValue("")
    }
  }

  private getRelationControlValues(): Array<{ id: string; name: string }> {
    const ids = this.relationControl.value || [];
    return ids.map((id) => {
      const option = this.optionsList.find((opt) => opt.id === id);
      return option || { id, name: id };
    });
  }

  validationErrorKeys(): string[] {
    return Object.keys(this.field.validation?.messages || {});
  }

  getErrorMessage(errorKey: string): string | undefined {
    const message =
      this.field?.validation?.messages &&
      this.field.validation.messages[errorKey];
    if (typeof message === 'function') {
      const error = this.field.formControl?.errors ? this.field.formControl.errors[errorKey] : undefined;
      const result = message(error, this.field);
      if (typeof result === 'string') {
        return result;
      }
      return "required field";  // or you can provide a default message if you prefer
    }
    return message as string | undefined;
  }

  private fetchOptions(filterBy): Observable<{ id: number; name: string }[]> {

    // Extract the main field for sorting
    const sortField = this.to['sortField'];
    let sort = `${sortField},asc`;
    this.mainField = this.to['mainField'];

    // Remove the external list fetching
    // Define pagination settings if needed (though it's not used here)
    const pagination = {
      page: 0,
      pageSize: 200,
    };

    // Use tempList as the dataArray
    let dataArray = this.tempList;

    // Process the response data
    return of(dataArray).pipe(
      map((responseData) => {
        // Get the IDs to exclude from the current control values
        this.excludedIds = this.getRelationControlValues().map(
          (opt) => opt.id.toString()
        );

        // Filter the data to exclude already selected options
        this.filteredData = responseData.filter(
          (item: { id: any }) => !this.excludedIds.includes(item.id.toString())
        );

        // Map the filtered data to the required format
        const newOptions = this.filteredData.map(
          (item: { id: any; name: string }) => ({
            id: item.id.toString(),
            name: item[this.mainField],
          })
        );

        newOptions.forEach((opt: { id: string; name: string }) => {
          if (!this.optionsList.find((existingOpt) => existingOpt.id === opt.id)) {
            this.optionsList.push(opt);
          }
        });

        if (filterBy && filterBy.keyword) {
          const keyword = filterBy.keyword.toLowerCase();
          const filteredOptions = newOptions.filter((option) =>
            option.name.toLowerCase().includes(keyword)
          );
          // Return the filtered options
          return filteredOptions;
        }

        // Return the new options
        return newOptions;
      }),
      // Handle errors by showing a message and returning an empty array
      catchError(err => {
        if (err.error && err.error.error && err.error.error.message) {
          this.snackBar.open(err.error.error.message, 'Dismiss');
        }
        return of([]);
      })
    );
  }



  addMoreBtnCLick(): void {
    const dialogRef = this.dialog.open(AddMorePopupExternalComponent, {
      data: this.to,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getAllOptions();
      this.filteredOptionsList = this.searchControl.valueChanges.pipe(
        startWith(''),
        switchMap((value) => {
          const filterBy = { 'keyword': value };
          return this.fetchOptions(filterBy);
        })
      );
    });

  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      this.resetForOneToOne();
    }
  }

  resetForOneToOne() {
    if (this.to['relation'] === 'one') {
      this.relationControl = this.formControl as FormControl;
      this.relationControl.reset();
      this.relationControl.clearAsyncValidators();
      this.relationControl.setValue(null);
      this.selectedOptions = [];
    }
  }

  onBlur() {
    setTimeout(() => {
      if (!this.optionSelectedFromDropdown) {
        if (this.to['relation'] === 'one' && !this.relationControl.value) {
          this.searchControl.setValue('');
        }
        else if (this.to['relation'] === 'many') {
          this.searchControl.setValue('');
        }
      }
      this.optionSelectedFromDropdown = false;
    }, 100);
  }

  clearInput() {
    if (this.to['relation'] === 'one') {
      this.resetForOneToOne()
    }
    this.searchControl.setValue('');
  }

}
