<div *ngIf="checkForSpecificStrings()" class="form-container">
    <mat-form-field appearance="fill" [style.width]="'100%'">
        <mat-label>{{ to['label'] }}</mat-label>
        <mat-hint>{{to['description']}}</mat-hint>
        <input matInput [matAutocomplete]="auto" [formControl]="searchControl" [required]="to['required']"
            (keyup)="suggestOption($event)" id="postcode" (keydown.enter)="onEnterKeyPressed($event)">
        <button type="button" *ngIf="searchControl.value" mat-icon-button matSuffix aria-label="Clear"
            (click)="clearInput()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
            <mat-option *ngFor="let option of resultList" [value]="option" id="option">
                {{option}}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="noValidSelection">
            No valid suggestion available</mat-error>
        <mat-error *ngIf="to['required'] && (searchControl?.value?.length ==0 || searchControl?.value ==null) ">
            Postcode is required</mat-error>
        <mat-error *ngIf="noListSelection && !noValidSelection">
            Please select valid option from list</mat-error>
    </mat-form-field>
</div>