import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CourseService } from '../course.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionsService } from 'src/app/sessions/sessions.service';

@Component({
  selector: 'app-add-more-industry-popup',
  templateUrl: './add-more-popup.component.html',
  styleUrls: ['./add-more-popup.component.scss']
})
export class AddMorePopupComponent implements OnInit {
  inputValue: string;
  name: string;
  addForm!: FormGroup;
  isLoggedIn: boolean = true;

  constructor(public dialogRef: MatDialogRef<AddMorePopupComponent>,
    public courseService: CourseService,
    private readonly snackBar: SnackBarService,
    private readonly snackBarService: SnackBarService,
    private readonly fb: FormBuilder,
    private readonly sessionsService: SessionsService,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {}

  ngOnInit(): void {
    this.initDataForms();
    this.sessionsService.logoutEvent.subscribe(() => {
      this.isLoggedIn = false;
      this.dialogRef.close();
    });
  }

  initDataForms() {
    this.addForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(this.data.minPopupLength), Validators.maxLength(this.data.maxPopupLength)]],
    })
  }

  save(): void {
    const payload = this.addForm.getRawValue()
    this.courseService.createRefDateEntry({ data: payload }, this.data.createApi ? this.data.createApi : this.data.api).subscribe(resp => {
      this.snackBarService.success(resp?.message.applicationMessage);
    }, error => {
      this.snackBar.error(error.error.applicationMessage);
    })
    this.dialogRef.close(this.inputValue);
  }


  close(): void {
    this.dialogRef.close();
  }
}
