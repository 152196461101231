import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobsRoutingModule } from './jobs-routing.module';
import { JobsComponent } from './jobs.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { EmployerDetailsComponent } from './employer-details/employer-details.component';
import { JobsNavigation } from './jobs-nav';
import { DynamicTableModule } from 'src/app/framework/components/dynamic-table/dynamic-table.module';
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { ConfigOption, FormlyModule } from '@ngx-formly/core';
import { QuillModule } from 'ngx-quill';
import { MaterialModule } from 'src/app/framework/material/material.module';
import { SharedModule } from 'src/app/framework/shared/shared.module';
import { DynamicFormsModule } from '../dynamic-forms/dynamic-forms.module';
import { AddEmployerDetailsComponent } from './employer-details/add-employer-details/add-employer-details.component';
import { AddJobDetailsComponent } from './job-details/add-job-details/add-job-details.component';
import { ViewJobDetailsComponent } from './job-details/view-job-details/view-job-details.component';
import { ViewEmployerDetailsComponent } from './employer-details/view-employer-details/view-employer-details.component';
import { UserNotesComponent } from './user-notes/user-notes.component';

const config: ConfigOption = {
  validators: [
    { name: 'minDate', validation: minDateValidator(new Date(new Date().getTime() )) },
    { name: 'invalidDate', validation: invalidDateValidator() },
  ],
};

@NgModule({
  declarations: [
    JobsComponent,
    JobDetailsComponent,
    EmployerDetailsComponent,
    AddEmployerDetailsComponent,
    AddJobDetailsComponent,
    ViewJobDetailsComponent,
    ViewEmployerDetailsComponent,
    UserNotesComponent
  ],
  imports: [
    CommonModule,
    JobsRoutingModule,
    DynamicTableModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialModule,
    MatInputModule,
    MatChipsModule,
    QuillModule.forRoot(),
    DynamicFormsModule,
    FormlyModule.forRoot(config),
  ],
  providers: [
    JobsNavigation
  ]
})
export class JobsModule { }

function invalidDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const controlDate = new Date(control.value);
    return isNaN(controlDate.getTime()) ? { invalidDate: { value: control.value, message: 'Invalid delivery date.' } } : null;
  };
}

export function minDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const controlDate = new Date(control.value);
    return controlDate < minDate ? { minDate: { value: control.value, message: 'Date should be in tomorrow or later.' } } : null;
  };
}