import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../course-management-nav';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent {

  collection = 'courses';
  fields = ['courseName', 'courseId', 'createdAt'];
  searchFields = ['courseName', 'courseId'];
  buttonLable = "course";
  baseRoute = '/courses-events';

  dynamicFilters: any = {
    publishedAt: { null: true },
  };

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly router: Router,
    private readonly courseNavigation: CourseManagementNavigation,
  ) {
    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

}
