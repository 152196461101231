import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionsService } from 'src/app/sessions/sessions.service';

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private session: SessionsService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem("shortToken") && this.isTokenExpired()) {
      return true;
    }
    this.router.navigate(["sessions"]);
    return false;
  }

   isTokenExpired() :boolean{
    const Expirationime = JSON.parse(atob(localStorage.getItem('shortToken').split('.')[1])).exp;
    const currentTime = Math.floor(Date.now() / 1000);
    return Expirationime> currentTime;
  }
}