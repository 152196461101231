// state.actions.ts
import { createAction, props } from '@ngrx/store';


export const setSearchQuery = createAction(
  '[State] Set Search Query',
  props<{ query: string }>()
);

export const setCurrentPage = createAction(
  '[State] Set Current Page',
  props<{ page: number }>()
);

export const setFilterQuery = createAction(
  '[View Component] Set Table Filter Query',
  props<{ query: number | string }>()
);

export const setActiveTab = createAction(
  '[View Component] Set Active Tab',
  props<{ tab: string | boolean}>()
);

export const setActiveSubTab = createAction(
  '[View Component] Set Active SubTab',
  props<{ subTab: number }>()
);

export const setSubTabTableSearchQuery = createAction(
  '[View Component] Set SubTab Table Search Query',
  props<{ query: string }>()
);

export const setSubTabTableFilterQuery = createAction(
  '[View Component] Set SubTab Table Filter Query',
  props<{ query: number }>()
);

export const setSubTabTableCurrentPage = createAction(
  '[View Component] Set SubTab Table Current Page',
  props<{ page: number }>()
);

export const resetFilterQuery = createAction('[App] Filter Query Reset State');
export const resetSubNavigationSearchQuery = createAction('[App] Sub Navigation Search Query Reset State');
export const resetSubNavigationFilterQuery = createAction('[App] Sub Navigation Filter Query Reset State');
export const resetSubNavigationPageIndex = createAction('[App] Sub Navigation Page Index Reset State');
export const resetSubActiveTab = createAction('[App] Sub Active Tab Reset State');
export const resetNavigationState = createAction('[App] Reset State');
