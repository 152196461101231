import { Component, OnInit } from '@angular/core';
import { CourseManagementNavigation } from './course-management-nav';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';

@Component({
  selector: 'app-courses',
  templateUrl: './course-management.component.html',
  styleUrls: ['./course-management.component.scss']
})
export class CourseManagementComponent {

  constructor (
    private readonly inPageNavService: InPageNavService,
    private readonly courseManagementNavigation:CourseManagementNavigation
  ) {
    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.courseManagementNavigation.courseSubMenu);
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

}
