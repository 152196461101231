import { Injectable, OnInit } from '@angular/core';
import { AppState } from 'src/app/framework/state-management/state.reducer';
import { setSearchQuery, setCurrentPage, resetNavigationState, setActiveTab, setActiveSubTab, setSubTabTableCurrentPage, setSubTabTableSearchQuery, resetSubNavigationSearchQuery, resetSubNavigationPageIndex, resetSubNavigationFilterQuery, setSubTabTableFilterQuery, resetSubActiveTab, setFilterQuery, resetFilterQuery } from 'src/app/framework/state-management/state.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StateManagementService {

    searchQuery$: Observable<string>;
    pageIndex$: Observable<number>;
    filterQuery$: Observable<number | string>;
    activeTab$: Observable<string | boolean>;
    activeSubTab$: Observable<number>;
    subTabTableSearchQuery$: Observable<string>;
    subTabTableFilterQuery$: Observable<number>;
    subTabTableCurrentPageIndex$: Observable<number>;
  
    constructor(private store: Store<{ app: AppState }>) {

        this.searchQuery$ = this.store.select(state => state.app.searchQuery);
        this.pageIndex$ = this.store.select(state => state.app.currentPage);
        this.filterQuery$ = this.store.select(state => state.app.filterQuery);
        this.activeTab$ = this.store.select(state => state.app.activeTab);
        this.activeSubTab$ = this.store.select(state => state.app.activeSubTab);
        this.subTabTableSearchQuery$ = this.store.select(state => state.app.subTabTableSearchQuery);
        this.subTabTableFilterQuery$ = this.store.select(state => state.app.subTabTableFilterQuery);
        this.subTabTableCurrentPageIndex$ = this.store.select(state => state.app.subTabTableCurrentPage);
    }

    getStoreData = (field): Promise<any> =>{

        return new Promise(resolve=>{

            switch (field) {

                case 'searchQuery':
                    
                    this.searchQuery$.subscribe(searchQuery => resolve(searchQuery))
                    break;
                case 'pageIndex':
                    
                    this.pageIndex$.subscribe(pageIndex => resolve(pageIndex))
                    break;
                case 'filterQuery':
                        
                    this.filterQuery$.subscribe(filterQuery => resolve(filterQuery))
                    break;
                case 'activeTab':
                        
                    this.activeTab$.subscribe(activeTab => resolve(activeTab))
                    break;
                case 'activeSubTab':
                        
                    this.activeSubTab$.subscribe(activeSubTab => resolve(activeSubTab))
                    break;
                case 'subTabTableSearchQuery':
                        
                    this.subTabTableSearchQuery$.subscribe(subTabTableSearchQuery => resolve(subTabTableSearchQuery))
                    break;
                case 'subTabTableFilterquery':
                        
                    this.subTabTableFilterQuery$.subscribe(subTabTableFilterQuery => resolve(subTabTableFilterQuery))
                    break;
                case 'subTabTablePageIndex':
                        
                    this.subTabTableCurrentPageIndex$.subscribe(subTabTableCurrentPage => resolve(subTabTableCurrentPage))
                    break;            
            }
        })
    }

    setSearchQuery = (searchQuery) => this.store.dispatch(setSearchQuery({ query: searchQuery }));

    setPageIndex = (pageIndex) => this.store.dispatch(setCurrentPage({ page: pageIndex }));

    setFilterQuery = (filterQuery) => this.store.dispatch(setFilterQuery({ query: filterQuery }));

    setactiveTab = (activeTab) => this.store.dispatch(setActiveTab({ tab: activeTab }));

    setactiveSubTab = (activeSubTab) => this.store.dispatch(setActiveSubTab({ subTab: activeSubTab }));

    setsubTabTableSearchQuery = (subTabTableSearchQuery) => this.store.dispatch(setSubTabTableSearchQuery({ query: subTabTableSearchQuery }));

    setsubTabTablePageIndex = (subTabTableCurrentPage) => this.store.dispatch(setSubTabTableCurrentPage({ page: subTabTableCurrentPage }));

    setsubTabTableFilterQuery = (subTabTableCurrentFilterQuery) => this.store.dispatch(setSubTabTableFilterQuery({ query: subTabTableCurrentFilterQuery }));

    resetSubNavigationStateData = (resetSubTab = true) => { 

        this.store.dispatch(resetSubNavigationSearchQuery());
        this.store.dispatch(resetSubNavigationPageIndex());
        this.store.dispatch(resetFilterQuery());
        if(resetSubTab == true) this.store.dispatch(resetSubActiveTab());

    };

    resetNavigationStateData = ()=> this.store.dispatch(resetNavigationState());
}