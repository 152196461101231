<mat-card id='content_type'>
    <mat-card-header>
        <span class="spacer">
            <app-stepper-navigation></app-stepper-navigation>
        </span>
        <span class="action-btn-alignment">
            <button id="exit_button" mat-stroked-button [routerLink]="[exitButtonNavURL]"
                [queryParams]="{id: contentId, moduleId: moduleId}">Exit</button>
        </span>
    </mat-card-header>
    <mat-card-content>
        <div class="label-hr">
            <span>External</span>
        </div>

        <form [formGroup]="contentForm" (ngSubmit)="onSubmit(contentForm)" validate autocomplete="off">
            <div class="card-row">
                <div class="card-column">
                    <mat-form-field appearance="outline">
                        <mat-label>Title</mat-label>
                        <input matInput placeholder="Title" value="" formControlName="moduleName" required id="title">
                        <mat-hint *ngIf="contentForm.controls.moduleName.valid">
                            {{100 - contentForm.controls.moduleName.value.length}} characters left
                        </mat-hint>
                        <mat-error
                            *ngIf="contentForm.controls.moduleName.invalid && !contentForm.controls.moduleName.errors?.pattern && 
                            !contentForm.controls.moduleName.errors?.minlength && !contentForm.controls.moduleName.errors?.maxlength">
                            Title is required.
                        </mat-error>
                        <mat-error *ngIf="contentForm.controls.moduleName.errors?.pattern || contentForm.controls.moduleName.errors?.minlength || 
                        contentForm.controls.moduleName.errors?.maxlength">
                            Minimum 3 - Maximum 100, Alphanumeric, special characters only ! ? , . - / ( )
                        </mat-error>
                        <mat-hint *ngIf="contentForm.controls.moduleName.value?.length === 0">
                            Minimum 3 and maximum 100 alphanumeric and ! ? , . ' - / ( ) are allowed
                        </mat-hint>
                    </mat-form-field>
                </div>
                <div class="card-column">
                    <mat-form-field appearance="outline">
                        <mat-label>URL</mat-label>
                        <input type="url" matInput placeholder="Enter URL" formControlName="path" id="url" required>
                        <mat-error *ngIf="contentForm.controls.path.errors?.maxlength">
                            URL must be 255 characters or less
                        </mat-error>
                        <mat-error *ngIf="contentForm.controls.path.value?.length > 0 
                            && (contentForm.controls.path.value?.length < 255 || contentForm.controls.path.value?.length === 255)
                            && (contentForm.controls.path.invalid || !contentForm.get('path').hasError('invalidUrl'))">
                            Enter valid url
                        </mat-error>
                        <mat-error *ngIf="contentForm.controls.path.value?.length == 0">
                            Mandatory Field
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="label-hr">
                <span>Details</span>
            </div>

            <div class="card-row">
                <div class="card-column">
                    <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                        <textarea matInput formControlName="moduleDescription" rows="3" required minlength="3"
                            maxlength="200"></textarea>
                        <mat-hint *ngIf="contentForm.controls.moduleDescription.valid">
                            {{200 - contentForm.controls.moduleDescription.value.length}} characters left
                        </mat-hint>
                        <mat-hint *ngIf="contentForm.controls.moduleDescription.value.length === 0">
                            Minimum 3 and Maximum 200 characters allowed
                        </mat-hint>
                        <mat-error *ngIf="contentForm.controls.moduleDescription.invalid && !contentForm.controls.moduleDescription.errors?.minlength && 
                            !contentForm.controls.moduleDescription.errors?.maxlength">
                            Description is required.
                        </mat-error>
                        <mat-error
                            *ngIf="contentForm.controls.moduleDescription.errors?.minlength || contentForm.controls.moduleDescription.errors?.maxlength">
                            Minimum 3 and maximum 200 characters allowed
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <mat-card-footer>
                <span class="spacer"></span>
                <span>
                    <button type="submit" id="save_module_button" mat-flat-button color="primary"
                        [disabled]="saveButtonDisabled()">Save & Next</button>
                </span>
            </mat-card-footer>
        </form>
    </mat-card-content>
</mat-card>
