import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DocumentsResponse } from './data.interface';
import { BaseUrl } from './framework/constants/url-constants';

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {

  constructor(
    private http: HttpClient
    ) {}

  private generateQuery(
    collection: string,
    fields: string[],
    pagination: { page: number; pageSize: number },
    sort?: string,
    publicationState? : string,
    filters?: any
  ): string {
    let attributesQuery = 'attributes {';
  
    fields.forEach(field => {
      const parts = field.split('.');
      for (let i = 0; i < parts.length; i++) {
        attributesQuery += ` ${parts[i]}`;
        if (i < parts.length - 1) {
          attributesQuery += ' { data { attributes {';
        }
      }
  
      for (let i = 0; i < parts.length - 1; i++) {
        attributesQuery += ' } } }';
      }
    });
  
    attributesQuery += ' }';
  
    let query = `query { ${collection}(pagination: { page: ${pagination.page}, pageSize: ${pagination.pageSize} }`;
  
    if (sort && sort.split(":")[1].trim() !== "") {
      query += `, sort: "${sort}"`;
    }
  
    if (publicationState) {
        query += `, publicationState: ${publicationState}`;
    }
  
    if (filters) {
      query += `, filters: ${JSON.stringify(filters).replace(/"([^"]+)":/g, '$1:')}`;
    } 
  
    query += `) { data { id ${attributesQuery} } meta { pagination { page pageSize total pageCount } } } }`;
  
    return query;
  }
  
  fetchData(
    collection: string,
    fields: string[],
    pagination: { page: number; pageSize: number } = { page: 1, pageSize: 10 },
    sort?: string,
    publicationState? : string,
    filters?: any
  ): Observable<DocumentsResponse> {
    const query = this.generateQuery(collection, fields, pagination, sort, publicationState, filters);
    return this.http.post<DocumentsResponse>(BaseUrl.CMS_PROXY_API, { query } );
  }
}



