import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseUrl } from "../../../framework/constants/url-constants";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CourseProviderService {

    constructor(
        private readonly http: HttpClient,
    ) { }

    getAuditDatesByProviderId(id,schemaUrl,collection,sort: string, page, size,body): Observable<any> {
        const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/search/${id}/${schemaUrl}`
        return this.http.post<any>(href,body, {
          params: new HttpParams()
            .set('sort', sort.toString())
            .set('page', page.toString())
            .set('size', size.toString())
        });
      }
}