<ng-container *ngFor="let item of formData">
    <div class="card-row" *ngIf="showHideElement(item) && item.type === 'single'">
        <div class="card-column">
            <div class="label-hr">
                <span>{{ item.label }}</span>
            </div>
            <ng-container *ngFor="let element of item.value | keyvalue">
                <div *ngIf="element.key !== 'id' && element.key !== 'latitude' && element.key !== 'longitude' && element.key !== 'county' && showHideElement(element)">
                    <div *ngIf="!isObject(element.value)" class="flex">
                        <span class="details_label block">{{ transformKeyName(element.key) }}</span>
                        <span class="data-width" [innerHTML]="resolveValue(element)"></span>
                    </div>
                    <div *ngIf="isObject(element.value)">
                        <ng-container *ngFor="let nestedElement of element.value | keyvalue">
                            <div *ngIf="nestedElement.key !== 'id' && nestedElement.key !== 'latitude' && nestedElement.key !== 'longitude' && nestedElement.key !== 'county' && showHideElement(nestedElement)">
                                <div class="flex nested">
                                    <span class="details_label block">{{ transformKeyName(nestedElement.key) }}</span>
                                    <span class="data-width" [innerHTML]="resolveValue(nestedElement)"></span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    
    

    
    
    <div class="card-row" *ngIf="showHideElement(item) && item.type === 'postcode'">
        <div class="card-column">
            <div class="label-hr">
                <span>{{ item.label }}</span>
            </div>
            <ng-container *ngFor="let element of item.value | keyvalue">
                <div *ngIf="element.key !== 'id' && showHideElement(element)">
                    <div class="flex">
                        <span class="details_label block">{{ element.key }}</span>
                        <span class="data-width" [innerHTML]="resolveValue(element)"></span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="card-row" *ngIf="showHideElement(item) && item.type === 'repeat'">
        <div class="card-column">
            <ng-container *ngFor="let componentArray of item.value; let i = index">
                <div class="container-repete">
                    <span class="subheading_label">{{ item.label }} {{ i + 1 }}</span>
                    <div class="component-item-list"
                        [ngClass]="{'column-container': componentArray[0]?.key === 'providerContactFirstName' || componentArray[0]?.key === 'providerFirstName'}">
                        <ng-container *ngFor="let compObject of componentArray" class="row-container">
                            <div *ngIf="compObject.type != 'single'" class="flex">
                                <span class="details_label block">{{
                                    compObject.label }}</span>
                                <span class="data-width"
                                    [innerHTML]="resolveValue(compObject)"></span>
                            </div>
                            <ng-container *ngIf="compObject.type === 'single'">
                                <div *ngFor="let subObject of compObject?.value" class="flex">
                                    <span class="details_label block">{{ subObject.label }}</span>
                                    <span class="data-width">{{ subObject.value || 'N/A' }}</span>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    
    <div *ngIf="item.type === 'title'">
        <div class="label-hr">
            <span>{{ item.label }}</span>
        </div>
    </div>

    <div class="card-row" *ngIf="showHideElement(item) && item.type === 'relation'">
        <div class="card-column mb-20">
            <span class="details_label">{{ item.label }}</span>
            <ng-container *ngIf="item.relation === 'one'">
                <span class="data-width" [innerHTML]="resolveValue(item)"></span>
            </ng-container>
            <ng-container *ngIf="item.relation === 'many'">
                <ng-container *ngIf="item.value.data.length === 0">
                    <span class="data-width">N/A</span>
                </ng-container>
                <ng-container
                    *ngIf="showValuesAsList.includes(item.key) then showAsListView else showAsChips"></ng-container>
                <ng-template #showAsListView>
                    <span *ngFor="let data of item.value.data" class="data-width">{{ data.attributes[item.mainField]
                        }}</span>
                </ng-template>
                <ng-template #showAsChips>
                    <mat-chip-list>
                        <mat-chip *ngFor="let data of item.value.data"
                            [title]="data.attributes[item.mainField].length > 80 ? data.attributes[item.mainField] : ''">
                            {{ truncateText(data.attributes[item.mainField]) }}
                        </mat-chip>
                    </mat-chip-list>
                </ng-template>
            </ng-container>
        </div>
    </div>

    <div class="card-row" *ngIf="showHideElement(item) && item.type === 'file'">
        <div class="card-column mb-20">
            <span class="details_label">{{ item.label }}</span>
            <ng-container *ngIf="item.value.data !== null">
                <span class="data-width" *ngIf="item.loadFile">
                    <img [src]="item.value.data?.attributes?.url" alt="Image" />
                </span>
                <span>
                    <span class="data-width mt-5 wrap" *ngIf="!item.loadFile" [innerHTML]="resolveValue(item)"></span>
                    <mat-icon tabindex="0" class="download-icon" *ngIf="!item.loadFile"  [matTooltipShowDelay]="800" matTooltip="Download"  (keydown.enter)="downloadFile(item)" (click)="downloadFile(item)">get_app</mat-icon>
                </span>
               
                
            </ng-container>
            <span class="data-width" *ngIf="item.value.data === null" [innerHTML]="resolveValue(item)"></span>
        </div>
    </div>

    <div class="card-row" *ngIf="showHideElement(item) && notExceptionType(item.type)">
        <div class="card-column mb-20">
            <span class="details_label">{{ item.label }}</span>
            <span class="data-width" *ngIf="item.type !== 'richtext'" [innerHTML]="resolveValue(item)"></span>
            <span class="data-width ql-field" *ngIf="item.type === 'richtext'">
                <quill-view [content]="resolveValue(item)"></quill-view>
            </span>
        </div>
    </div>
</ng-container>