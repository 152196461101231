import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SessionsService } from 'src/app/sessions/sessions.service';
import { JobsNavigation } from '../../jobs-nav';

@Component({
  selector: 'app-view-employer-details',
  templateUrl: './view-employer-details.component.html',
  styleUrls: ['./view-employer-details.component.scss']
})
export class ViewEmployerDetailsComponent {
  
  identifier: any;
  routeIntent: any;
  schemaUrl = 'api::employer.employer';
  apiUrl = '/employers';
  collection = '/employers';
  addRelationFields: {};
  viewId : number;
  titleInformation: any[];
  pageName:any;
  CLid:any;
  baseRoute = '/jobs/employer-details';

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly jobsNavigation: JobsNavigation,
    private readonly route: ActivatedRoute,
    private readonly sessionService: SessionsService
    )
    {
      this.scrollToTop();
      this.inPageNavService.setNavItems(this.jobsNavigation.jobsSubMenu);
    }
      

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
      this.CLid = params.CLid;
      this.viewId=this.identifier
    });
    this.titleInformation =[{
      'empCont': 'Other employer contact details',
      'armedForceEmployer': 'Employer details'
    }];
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }
  
}
