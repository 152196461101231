import { Component } from '@angular/core';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../course-management-nav';

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.scss']
})
export class WorkshopComponent {

  collection = 'workshops';
  fields = ['workshopName', 'ref_workshop_type.name', 'workshopId','createdAt'];
  searchFields=['workshopName','workshopId'];
  buttonLable ="workshop";
  baseRoute = '/courses-events';

  dynamicFilters: any = {
    publishedAt: { null: true },
  };

  constructor (
    private readonly inPageNavService: InPageNavService,
      private readonly courseNavigation: CourseManagementNavigation,
    ) {
      window.scrollTo(0, 0);
      this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    }
  
    ngOnDestroy() {
      this.inPageNavService.setNavItems(null);
    }

}