import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobDetailsComponent } from './job-details/job-details.component';
import { EmployerDetailsComponent } from './employer-details/employer-details.component';
import { AddEmployerDetailsComponent } from './employer-details/add-employer-details/add-employer-details.component';
import { AddJobDetailsComponent } from './job-details/add-job-details/add-job-details.component';
import { ViewJobDetailsComponent } from './job-details/view-job-details/view-job-details.component';
import { ViewEmployerDetailsComponent } from './employer-details/view-employer-details/view-employer-details.component';
import { CanDeactivateGuard } from 'src/app/framework/guards/can-deactivate/can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'jobs',
    pathMatch: 'full',
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'jobs',
    component: JobDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'jobs/new',
    component: AddJobDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'jobs/edit',
    component: AddJobDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'jobs/view',
    component: ViewJobDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'employers',
    component: EmployerDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'employers/new',
    component: AddEmployerDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'employers/edit',
    component: AddEmployerDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'employers/view',
    component: ViewEmployerDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class JobsRoutingModule {
}
