import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { CanDeactivateGuard } from 'src/app/framework/guards/can-deactivate.guard';
import { Observable } from 'rxjs';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';

@Component({
  selector: 'app-add-edit-workshop',
  templateUrl: './add-edit-workshop.component.html',
  styleUrls: ['./add-edit-workshop.component.scss']
})
export class AddEditWorkshopComponent implements CanDeactivateGuard{

  form = new FormGroup({});
  isNotSaved: boolean = false;
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
            {
                "type": "title",
                "templateOptions": {
                  "label": "Workshop name"
                }
              },
              {
                  "key": "workshopName",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                      "messages": {
                          "required": "Workshop name is required.",
                          "minlength": "Minimum length should be 3 characters.",
                          "maxlength": "Maximum length should be 100 characters."
                      }
                  },
                  "templateOptions": {
                      "label": "Workshop name",
                      "placeholder": "",
                      "description": "Minimum 3 and maximum 100 characters",
                      "required": true,
                      "options": [],
                      "minLength": 3,
                      "maxLength": 100
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      },
      {
        "fieldGroupClassName": "display-flex",
        "fieldGroup": [
          {
            "key": "subtitle",
            "type": "input",
            "className": "col-md-6",
            "validation": {
              "messages": {
                "required": "Subtitle is required.",
                "minlength": "Minimum length should be 50 characters.",
                "maxlength": "Maximum length should be 150 characters."
              }
            },
            "templateOptions": {
              "label": "Subtitle",
              "placeholder": "",
              "description": "Minimum 50 and maximum 150 characters",
              "required": true,
              "options": [],
              "minLength": 50,
              "maxLength": 150
            },
            "fieldArray": null,
            "fieldGroupClassName": null,
            "fieldGroup": null
          }
        ]
      },
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
              {
                  "key": "workshopId",
                  "type": "input",
                  "className": "col-md-6",

                  "validation": {
                      "messages": {}
                  },
                  "templateOptions": {
                      "label": "Workshop ID",
                      "placeholder": "",
                      "description": "",
                      "options": []
                  },

                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      },
      {
        "fieldGroupClassName": "display-flex",
        "fieldGroup": [
          {
            "type": "title",
            "templateOptions": {
              "label": "Workshop details"
            }
          },
        ]
      },
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
              {
                  "key": "ref_workshop_type",
                  "type": "relation",
                  "className": "col-md-6",
                  "validation": {
                      "messages": {}
                  },
                  "templateOptions": {
                      "label": "Workshop type",
                      "placeholder": "",
                      "description": "Please select an option from the list",
                      "options": [],
                      "api": "ref-workshop-types",
                      "relation": "one",
                      "mainField": "name",
                      "required": true
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      },
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
              {
                  "key": "workshopOverview",
                  "type": "richtext",
                  "className": "col-md-12",
                  "validation": {
                      "messages": {
                          "required": "Workshop overview is required.",
                          "minlength": "Minimum length should be 5 characters."
                      }
                  },
                  "templateOptions": {
                      "label": "Workshop overview",
                      "placeholder": "",
                      "description": "Minimum 5 characters",
                      "required": true,
                      "options": [],
                      "minLength": 5,
                      "attributes": {
                        "aria-label": "Workshop overview"
                        }
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      },
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
              {
                  "key": "workshopContent",
                  "type": "richtext",
                  "className": "col-md-12",
                  "validation": {
                      "messages": {
                          "required": "Workshop content is required.",
                          "minlength": "Minimum length should be 5 characters."
                      }
                  },
                  "templateOptions": {
                      "label": "Workshop content",
                      "placeholder": "",
                      "description": "Minimum 5 characters",
                      "required": true,
                      "options": [],
                      "minLength": 5,
                      "attributes": {
                        "aria-label": "Workshop content"
                        }
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      },
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
              {
                  "key": "whyTakeThisWorkshop",
                  "type": "richtext",
                  "className": "col-md-12",
                  "validation": {
                      "messages": {
                          "required": "Why take this workshop? is required.",
                          "minlength": "Minimum length should be 5 characters."
                      }
                  },
                  "templateOptions": {
                      "label": "Why take this workshop?",
                      "placeholder": "",
                      "description": "Minimum 5 characters",
                      "required": true,
                      "options": [],
                      "minLength": 5,
                      "attributes": {
                        "aria-label": "Why take this workshop?"
                        }
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      },
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
              {
                  "key": "ref_course_course_status",
                  "type": "relation",
                  "className": "col-md-6",
                  "validation": {
                      "messages": {}
                  },
                  "templateOptions": {
                      "label": "Workshop status",
                      "placeholder": "",
                      "description": "Please select an option from the list",
                      "options": [],
                      "api": "ref-course-course-statuses",
                      "relation": "one",
                      "mainField": "name",
                      "required": true
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      },
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
              {
                  "key": "workshopImage",
                  "type": "file",
                  "className": "col-md-6",
                  "validation": {
                      "messages": {
                          "required": "Workshop image is required."
                      }
                  },
                  "templateOptions": {
                      "label": "Workshop image*",
                      "placeholder": "",
                      "description": "Upload image in JPEG/JPG, PNG, GIF, SVG, ICO format",
                      "required": true,
                      "options": [],
                      "allowedTypes": [
                          "images"
                      ],
                      "attributes": {
                      "aria-label": "Workshop image"
                      },
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      },
      {
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
              {
                  "key": "contentFile",
                  "type": "file",
                  "className": "col-md-6",
                  "validation": {
                      "messages": {
                          "fileSizeMax": "The maximum supported file size is 16 MB",
                      }
                  },
                  "templateOptions": {
                      "label": "Content file",
                      "placeholder": "",
                      "description": "Maximum file size is 16 MB. Allowed file types are pdf, ppt, pps, pptx, xls, xlsx, rtf, txt, doc, docx, pub, jpeg, png",
                      "options": [],
                      "allowedTypes": [
                          "images",
                          "files"
                      ],
                    "attributes": {
                    "aria-label": "Content file"
                    },
                      "fileSizeMax": 16777216,
                      "fileExtensions": "pdf,ppt,pps,pptx,xls,xlsx,rtf,txt,doc,docx,pub,jpg,jpeg,png"                        
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
              }
          ]
      }
  ]

  rowId: any;
  routeIntent: string;
  state: any;
  myGroup: FormGroup;
  courseProviderForm: FormGroup;
  disableButton: boolean;
  courseProviderData: any;
  providerId: any;

  identifier: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  routerPath = '/courses-events/workshops/view';
  queryParams: any;

  collection = "/workshops";

  constructor(
      private readonly activatedRoute: ActivatedRoute,
      private readonly inPageNavService: InPageNavService,
      private readonly courseNavigation: CourseManagementNavigation,
      private readonly router: Router,
      private readonly appConfirmService: AppConfirmService
  ) {
      this.scrollToTop();
      this.activatedRoute.queryParams.subscribe((params: any) => {
          if (params.id) {
              this.rowId = params.id;
              this.state = params.state;
              this.routeIntent = "edit";
          } else {
              this.routeIntent = "new"
          }
      });
      this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
        this.queryParams = { id: this.rowId };
      this.schemaUrl = 'api::workshop.workshop';
      this.apiUrl = '/workshops';
      this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams = params;

          if (params.id) {
              this.identifier = params.id;
              this.routeIntent = "edit";
          }

      });
  }

  ngOnInit(): void {
      this.addFieldHideExpression();
  }

  ngOnDestroy() {
      this.inPageNavService.setNavItems(null);
  }

  exitNavigation() {
    if (this.queryParams && this.queryParams.id) {
        // Navigate to the view page with both id and hideEdit parameters
        this.router.navigate(['/courses-events/workshops/view'], {
            queryParams: {
                id: this.queryParams.id,
                hideEdit: "Active" // Ensure hideEdit is included
            }
        });
    } else {
        // Navigate to the default page if id is not available
        this.router.navigate(['/courses-events/workshops']);
    }
  }
  addFieldHideExpression() {
      this.fields.forEach(fieldGroup => {
          fieldGroup.fieldGroup.forEach(field => {
              if (field.key == 'workshopId') {
                  if (this.routeIntent == 'edit') {
                      field.hideExpression = false;
                      field.templateOptions.disabled = true;

                  } else {
                      field.hideExpression = true;
                      field.templateOptions.disabled = false;
                  }
              }
          });
      });
  }

  scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateToRoute(){
      this.router.navigate([`courses-events/${this.collection}`]);
  }

  canDeactivate(): Observable<boolean> | boolean {

    if (this.isNotSaved) {
        return this.appConfirmService.confirm(
            {
                title: `Progress not saved`,
                message: 'If you navigate away from this page your progress will not be saved'
            });
    } else {
        return true
    }
}

}
