import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { CourseManagementService } from '../../course-management.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { tap } from 'rxjs';
import { IBooking } from '../booking.interface';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';

import { SessionsService } from 'src/app/features/shared/services/sessions.service';
@Component({
  selector: 'app-request-booking',
  templateUrl: './request-booking.component.html',
  styleUrls: ['./request-booking.component.scss']
})
export class RequestBookingComponent implements AfterViewInit {

  displayedColumns: string[] = ['serviceUserId','serviceNumber', 'createdDate','bookingReference','bookingStatusId','actions'];
  dataSource = new MatTableDataSource<IBooking>();

  sortColumn = 'bookingReference';
  sortDirection = 'asc';
  pageSize = 25;
  filterBy = { 'keyword': '', 'deliveryId': '', 'status':'','genericId': null };
  deleteReasonsRefData: any;
  licencesDetail;
  userType = 2
  searchTerm: string = '';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  identifier: any;
  pageName: any;
  isActive = false;
  requestStatusTypeList: any;
  filteredId: any;
  select = "all status";
  showFilteredList: boolean;
  allBookingList: boolean = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly inPageNavService: InPageNavService,
    private readonly courseManagementService: CourseManagementService,
    private readonly snackBarService: SnackBarService,
    private readonly sessionService: SessionsService,
    private readonly courseNavigation: CourseManagementNavigation,
    private cdr: ChangeDetectorRef,
  ) {
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
      this.isActive = params.hideEdit;
    });
  }
  ngOnInit(): void {
    this.getStatusRefData();
    this.filterBy.deliveryId = this.identifier;
    this.filterBy.status = "Booking Request";
    this.resolveBookings(this.filterBy);
  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  onFilter(filterString: string) {
    this.filterBy.keyword = filterString;
    this.select = "all status";
    this.paginator.pageIndex = 0;
    this.resolveBookings(this.filterBy);
    this.cdr.detectChanges();
  }

  onClickClose(): Promise<void> {
    return new Promise((resolve) => {
        this.searchTerm = '';
        this.onFilter(this.searchTerm);
        resolve();
    });
}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(data => {
      this.sortColumn = data.active;
      this.sortDirection = data.direction;
      this.paginator.pageIndex = 0;
      if (this.allBookingList) {
        this.resolveBookings(this.filterBy);
      } else {
        this.resolveFilteredBookings(this.filterBy);
      }
      
    });

    this.paginator.page
      .pipe(
        tap(() => {
          if (this.allBookingList) {
            this.resolveBookings(this.filterBy);
          } else {
            this.resolveFilteredBookings(this.filterBy);
          }
          document.querySelector('#request-booking').scrollIntoView();
        }
        )
      )
      .subscribe();
  }

  resolveBookings(filterBy) {
    let currentPageIndex = 0;
    if (this.paginator) {
      currentPageIndex = this.paginator.pageIndex;
    }
    if(this.paginator.pageSize==undefined)
    {
      this.paginator.pageSize=25
    }
    this.courseManagementService.findAllBookings(`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.paginator.pageSize, filterBy)
    .subscribe(allBookings => {
      this.dataSource = allBookings.content;
      this.paginator.length = allBookings.totalElements;
      this.dataSource.sort = this.sort;
    }, error => {
      this.snackBarService.error(error.error.applicationMessage);
    });
  }

    
 
  findRequestBookingByStatus(event: any) {
    this.filteredId = event.value;
    this.showFilteredList = true;
    this.paginator.pageIndex = 0;

    if (event.value === 'all status') {
      this.filterBy.genericId = null;
      this.resolveBookings(this.filterBy);
    } else if (event.value === 0) {
      this.filterBy.genericId = null;
      this.resolveBookings(this.filterBy);
    } else if (event.value !== 'all status') {
      this.filterBy.genericId = this.filteredId;
      this.filterBy.keyword = '';
        this.resolveFilteredBookings(this.filterBy);
    }
  }


  resolveFilteredBookings(filterBy: { keyword: string; deliveryId: string; status: string; genericId: any; }) {
    let currentPageIndex = 0;
    if (this.paginator) {
      currentPageIndex = this.paginator.pageIndex;
    }
    if(this.paginator.pageSize==undefined)
    {
      this.paginator.pageSize=25
    }
    this.courseManagementService.findFilteredBookings(`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.paginator.pageSize, filterBy)
    .subscribe(filteredBookings => {
      this.dataSource = filteredBookings.content;
      this.paginator.length = filteredBookings.totalElements;
      this.dataSource.sort = this.sort;
    }, error => {
      this.snackBarService.error(error.error.applicationMessage);
    });
   
  }

  getStatusRefData(){
    const excludedIds = [10,11,12,13,14,15,16,17];
    this.courseManagementService.getRequestBookingStatus(3).subscribe(response =>{
      if(response){
        this.requestStatusTypeList = response.filter(item => !excludedIds.includes(item.id));
      }
    })
  }

}
