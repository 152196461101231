import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-file-upload-type',
  templateUrl: './file-upload-type.component.html',
  styleUrls: ['./file-upload-type.component.scss']
})
export class FileUploadTypeComponent extends FieldType implements OnInit {
  characterCount = 0;
  disableSaveButton: boolean;
  fileName: null;
  invalidFileType: boolean;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  noFileSelected: boolean;
  fileMandatory: boolean = false;
  validFileExtensions: string[] = [];
  maxFileSize: number = 16 * 1024 * 1024;   // Defaults to 16MB
  fileSizeExceeded: boolean;
  fileSizeErrorMessage: string;
  fileNameTooLong: boolean;
  fileNameMaxLength: number = 176;    // Strapi limit    

  ngOnInit() {
    this.setValidationData();
  }

  setValidationData() {
    if (this.field.templateOptions?.required) {
      this.fileMandatory = this.field.templateOptions?.required;
    }
    if (this.field.templateOptions?.fileExtensions) {
      this.validFileExtensions = this.field.templateOptions.fileExtensions.replace(' ', '').toLowerCase().split(',');
    }
    if (this.field.templateOptions?.fileSizeMax) {
      this.maxFileSize = this.field.templateOptions.fileSizeMax;
    }
    if (this.field.validation?.messages?.fileSizeMax) {
      this.fileSizeErrorMessage = this.field.validation?.messages?.fileSizeMax.toString();
    }
    else if (this.field.templateOptions?.fileSizeMax === undefined) {
      this.fileSizeErrorMessage = 'The maximum supported file size is 16 MB'
    }
    else {
      this.fileSizeErrorMessage = 'File size exceeds the maximum supported';
    }
  }

  selectFile(event) {
    const files = event.target.files;

    if (this.isNameTooLongOrFileTooLarge(files[0])) {
      this.invalidFileType = false;
      return;
    }

    if (this.validFileExtensions.length > 0) {
      if (this.isValidExtension(files[0])) {
        this.setDataAfterSelect(files);
      }
      else {
        this.cancelUpload();
        this.invalidFileType = true;
        this.noFileSelected = false;
      }
      return;
    }

    if (this.isValidFileType(files[0].type)) {
      this.setDataAfterSelect(files);
    }
    else {
      this.invalidFileType = true;
      this.noFileSelected = false;
    }
  }

  isNameTooLongOrFileTooLarge(file: any): boolean {
    this.fileNameTooLong = false;
    this.fileSizeExceeded = false;
    const fileName: string = file?.name || null;
    if (fileName !== null) {
      this.fileNameTooLong = fileName.length > this.fileNameMaxLength;
    }
    if (!this.fileNameTooLong) {
      this.fileSizeExceeded = file.size > this.maxFileSize;
    }
    if (this.fileNameTooLong || this.fileSizeExceeded) {
      this.cancelUpload();
      this.noFileSelected = false;
      return true;
    }
    return false;
  }

  isValidExtension(file: any) {
    let isValid = true;
    const fileName: string = file?.name || null;
    if (fileName !== null) {
      if (fileName.lastIndexOf('.') < fileName.length - 1) {
        const extension: string = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        isValid = this.validFileExtensions.filter(ext => ext === extension).length > 0;
      }
      else {
        isValid = false;
      }
    }
    return isValid;
  }

  isValidFileType(fileType: string): boolean {
    const fileTypeWithoutSlash = fileType.split('/')[0];
    let isValid = false;

    if (this.field.templateOptions?.allowedTypes) {
      if (this.field.templateOptions.allowedTypes.includes('files') && fileTypeWithoutSlash !== 'audio' && fileTypeWithoutSlash !== 'video' && fileTypeWithoutSlash !== 'image') {
        isValid = true;
      }
      if (!isValid) {
        this.field.templateOptions.allowedTypes.forEach(allowedType => {
          if (allowedType.replace(/s$/, '') === fileTypeWithoutSlash) {
            isValid = true;
            return;
          }
        });
      }
    }
    if (isValid) {
      return true;
    } else {
      this.cancelUpload();
      return false;
    }
  }

  setDataAfterSelect(files: any) {
    this.formControl.setValue(files);
    this.fileName = files[0]?.name || null;
    this.invalidFileType = false;
    this.noFileSelected = false;
  }

  cancelUpload() {
    if (this.to.required) {
      this.formControl.setValue(null);
    }
    else {
      let nullobject = {
        'data': {
          'id': null
        }
      };
      this.formControl.setValue(nullobject);
    }
    this.fileName = null;
    this.fileInput.nativeElement.value = '';
    this.noFileSelected = true;
  }

  checkInstance() {
    return this.formControl.value instanceof FileList
  }
}
