<div class="label-hr">
    <span>{{ field?.templateOptions?.label }}</span>
</div>
<ng-container *ngFor="let subField of field?.fieldGroup">
    <formly-field *ngIf="subField.fieldGroup[0].key !== 'postcode'" [field]="subField"></formly-field>
    <div *ngIf="subField.fieldGroup[0].key === 'postcode'" class="card-column">
        <mat-form-field appearance="fill">
            <mat-label>{{ subField.fieldGroup[0].templateOptions?.label }}</mat-label>
            <mat-hint>{{subField.fieldGroup[0].templateOptions?.description}}</mat-hint>
            <input matInput [matAutocomplete]="auto" [formControl]="searchControl"
                [required]="subField.fieldGroup[0].templateOptions?.required" (input)="suggestOption($event)"
                id="postcode" aria-label="Postcode">
            <button type="button" *ngIf="searchControl.value" mat-icon-button matSuffix aria-label="Clear"
                (click)="clearInput()">
                <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)"
                [matAutocompleteMinLength]="0">
                <mat-option *ngFor="let option of resultList" [value]="option" id="option">
                    {{option}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="noValidSelection && !(searchControl.value?.length < 4) ">
                No valid suggestion available</mat-error>
            <mat-error
                *ngIf="subField.fieldGroup[0].templateOptions?.required && (searchControl?.value?.length ==0 || searchControl?.value ==null) ">
                Postcode is required</mat-error>
            <mat-error *ngIf="noListSelection && !noValidSelection && searchControl?.value?.length >= 4 ">
                Please select valid option from list</mat-error>
                <mat-error
                *ngIf="searchControl?.value?.length < 4 && !searchControl?.value?.length ==0">
                Minimum 4 characters required to get suggestions</mat-error>
            <mat-hint>{{to['description']}}</mat-hint>
        </mat-form-field>
    </div>
</ng-container>