import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { SessionsService } from 'src/app/sessions/sessions.service';
import { environment } from 'src/environments/environment';
import { CourseManagementNavigation } from '../../course-management-nav';
import { CourseManagementService } from '../../course-management.service';
import { PopUpComponent } from '../../pop-up/pop-up.component';
import { IBooking } from '../booking.interface';
import { CancelCourseDeliveryPopUpComponent } from './cancel-course-delivery-pop-up/cancel-course-delivery-pop-up.component';

@Component({
  selector: 'app-view-booking-details',
  templateUrl: './view-booking-details.component.html',
  styleUrls: ['./view-booking-details.component.scss']
})
export class ViewBookingDetailsComponent implements OnInit {

  status=[
    'RES',
    'PRBK',
    'REJ',
    'RESR',
    'CNREQ',
    'CNESC',
    'CNAPP',
    'CNREF',
    'CNNREF',
    'CNNOC',
    'CREFRES',
    'CNNREFR',
    'CNNORES',
    'CNNREFSB',
    'CNNOCSB',
    'CNNREFFS',
    'CNNOCFS',
    'STDB',
    'IBKNG',
    'CNCLAPD',
    'CNCLESC',
    'CRSTD',
    'CRFSPC',
    'REJFRD']
  identifier: any;
  cdId: any;
  pageName: any;
  routeIntent: any;
  booking: IBooking;
  isEnableBookingBtn: boolean;
  confirmedExitBtn: boolean;
  isRejectReason: boolean;
  isPaymentSectionEnable = false;
  elcasDetailsForm: FormGroup;

  elcasDocName: any;
  uploadDisable: Boolean = false;
  elcasDecumnetFile: File | null = null;
  bookingType: number;
  isELCASFunding: boolean;
  isShowReplaceBtn: Boolean = false;

  isDocumentUploaded = true;
  filePath;
  fileName;
  fileTypes: string[] = [
    'application/pdf',
  ];
  displayedColumns: string[] = ['type', 'amount', 'status', 'paymentDate'];
  dataSource = new MatTableDataSource<IBooking>();
  isActive = false;
  disableFormFeild: boolean = false;
  showCancelButton: boolean;
  invalidFileName: Boolean = false;
  invalidFileSize: boolean;
  disableRelpaceBtn: boolean = false;
  type: any;

  constructor(private readonly activatedRoute: ActivatedRoute,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly snackBarService: SnackBarService,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly appConfirmService: AppConfirmService,
    private readonly sessionService: SessionsService,
    private readonly courseManagementService: CourseManagementService,
    private dilogref: MatDialog) {
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.cdId = params.cdID;
      this.pageName = params.name;
      this.isActive = params.hideEdit;
      this.type=params.type;
    });
  }
  ngOnInit(): void {
    this.confirmedExitBtn = false;
    this.elcasForm();
    if (this.type == "waiting") {
      this.resolvedWaitingDetails();
    } else {
      this.resolveBooking();
    }
   }
   
  elcasForm() {
    this.elcasDetailsForm = this.fb.group({
      id: [null],
      claimNumber: ['', [Validators.pattern('^[a-zA-Z0-9]*$')]],
      ELCContribution: ['', [Validators.pattern('^[0-9]+(\\.[0-9]{1,2})?$')]],
      multipleCourses: [],
      file: [null],
    });
  }

  resolveBooking() {
    this.route.params.subscribe((params: any) => {
      const id = params.id;

      this.courseManagementService.getBookingDetails(this.identifier).subscribe(bookingData => {
        this.booking = bookingData;
        this.bookingType = this.booking.bookingTypeId;
        this.setPaymentDetails(this.booking);
        this.validateELCASDetails(this.booking);
        this.updateFormState();
        if (this.isELCASFunding) {
          this.patchValues(bookingData);
        }
        if (this.booking.bookingStatusIdentifier === "PRBK" || this.booking.bookingStatusIdentifier === "PSLA" ||  this.booking.bookingStatusIdentifier === "ELCASWT"
        || this.booking.bookingStatusIdentifier === "APMNT" || this.booking.bookingStatusIdentifier === "CNREQ") {
          this.isEnableBookingBtn = true;
        } else {
          this.isEnableBookingBtn = false;
          let parts = this.booking.bookingStatus.split("-");
          let prefix = parts[0].trim();
          if (prefix === "Confirmed") {
            this.confirmedExitBtn = true;
          }
        }
        if (this.booking.rejectedReason != null) {
          this.isRejectReason = true;
        }
      })
    })
  }
  validateELCASDetails(bookingDetails) {
    if (bookingDetails.elcasdetailsDTO != null) {
      this.isELCASFunding = bookingDetails.elcasdetailsDTO.isSLUsingELCASFunding
    }
  }

  setPaymentDetails(bookingDetails) {
    this.dataSource = bookingDetails.paymentDetailsDTO;
    if (this.booking.courseTotalCost != null && this.booking.courseTotalCost > 0) {
      this.isPaymentSectionEnable = true;
    }
  }

  updateValidators(): void {
    const claimNumberControl = this.elcasDetailsForm.get('claimNumber');
    const elcContributionControl = this.elcasDetailsForm.get('ELCContribution');
    const fileControl = this.elcasDetailsForm.get('file'); 

    claimNumberControl.setValidators([Validators.required,Validators.pattern('^[a-zA-Z0-9]*$')]);
    elcContributionControl.setValidators([Validators.required, Validators.pattern('^[0-9]+(\\.[0-9]{1,2})?$')]);
    fileControl.setValidators([Validators.required]);


    claimNumberControl.updateValueAndValidity();
    elcContributionControl.updateValueAndValidity();
    fileControl.updateValueAndValidity();
  }

  updateFormState() {
    if (this.booking.bookingStatusIdentifier === 'PSLA' || this.booking.bookingStatusIdentifier === 'PRBK') {
      this.disableFormFeild = false;
      this.disableRelpaceBtn = false;
    } else if (this.booking.bookingStatusIdentifier === 'ELCASWT') {
      this.updateValidators();
    } else {
      this.disableFormFeild = true;
      this.disableRelpaceBtn = true;
    }

    if (this.bookingType === 2 && this.booking.bookingStatusIdentifier === "APMNT") {
      this.showCancelButton = true;
    }
  }

  onRejectBookingClick() {
    let payload = {
      "id": this.identifier,
      "serviceLeaverId": this.booking?.serviceUserId,
      "courseId": this.booking?.courseId,
      "courseDeliveryId": this.cdId,
      "reasonId": null,
      "bookingType": "Course",
      "bookingTypeId": this.booking.bookingTypeId,
      "hideEdit": this.isActive,
      "bookingStatus": this.booking?.bookingStatus,
    };
    const dialogRef = this.dilogref.open(PopUpComponent, {
      backdropClass: 'disable-backdrop',
      data: payload,
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterClosed().subscribe(confirmed => {

    })
  }
  onApproveBookingClick(data) {
    if (this.bookingType === 2) {
      this.approveNCFBooking(data);
    } else {
      this.approveCFBooking();
    }
  }
  approveCFBooking() {
    const payload = {
      "id": this.identifier,
      "serviceLeaverId": this.booking?.serviceUserId,
      "courseId": this.booking?.courseId,
      "courseDeliveryId": this.cdId,
      "reasonId": null
    };
    this.courseManagementService.approveBooking(payload).subscribe(() => {
      const message = 'Booking approved successfully';
      this.snackBarService.success(message);
      this.router.navigate(this.isPaymentSectionEnable ?
        ['/courses-events/courseDeliveries/view/booking-request'] :
        ['/courses-events/courseDeliveries/view/confirm-booking'],
        {
          relativeTo: this.route,
          queryParams: {
            id: this.cdId,
            operation: 'view',
            hideEdit: this.isActive
          }
        }
      );
    },
      (error) => {
        this.snackBarService.error(error.error.applicationMessage);
      })
  }

  approveNCFBooking(data) {
    const elcasAmount = data?.ELCContribution;
    if (elcasAmount > 2000 || elcasAmount > this.booking.courseFees) {
      this.snackBarService.error("ELCAS amount shoud be less or equal to course fee");
    } else {
      let formData = new FormData();
      if (this.isELCASFunding) {
        if (this.elcasDecumnetFile != null) {
          formData.append('file', this.elcasDecumnetFile);
        } else {
          formData.append('file', null);
        }
      }
      let postData = {
        "canNumber": data?.claimNumber,
        "elcasAmount": data?.ELCContribution,
        "bookingUserMappingId": this.identifier
      };
      formData.append('dto', JSON.stringify(postData));
  
      this.courseManagementService.approveNCFBooking(formData).then(response => {
        this.snackBarService.success('Booking approved successfully');
        this.router.navigate(
          ['/courses-events/courseDeliveries/view/booking-request'],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.cdId,
              operation: 'view',
              hideEdit: this.isActive
            }
          })
      }, error => {
        this.snackBarService.error(`${error.error.applicationMessage}`);
      });
    }
  }
    
  onCancelBookingClick(status)
  {
    const dialogRef = this.appConfirmService.confirm({
      message: `Are you sure you want to cancel?`,
      okButtonLabel: 'Confirm',
      cancelButtonLabel: 'Close'
    });

    let payload = {
      bookingUserMappingId : + this.identifier,
      status:status
    }
    dialogRef.subscribe(result => {
      if (result) {
        const dialogRef = this.dilogref.open(CancelCourseDeliveryPopUpComponent, {
          backdropClass: 'disable-backdrop',
          disableClose: true,
          data: payload,
          panelClass: 'custom-dialog-container',
        });
        dialogRef.afterClosed().subscribe(confirmed => {
          this.router.navigate(
            ['/courses-events/courseDeliveries/view/booking-request'],
            {
              relativeTo: this.route,
              queryParams: {
                id: this.cdId,
                operation: 'view',
                hideEdit: this.isActive
              }
            }
          );
        })

      }
    });
   
  }


  onDiscontinueBookingClick()
  {
    {
      const dialogRef = this.appConfirmService.confirm({
        message: `Are you sure you want to discontinue?`,
        okButtonLabel: 'Confirm',
        cancelButtonLabel: 'Close'
      });
  
      let payload = {
        bookingUserMappingId : + this.identifier
      }
      dialogRef.subscribe(result => {
        if (result) {
          this.courseManagementService.discontinueBooking(payload).subscribe(data=>{
            this.snackBarService.success(data.message.applicationMessage);
            this.router.navigate(
              ['/courses-events/courseDeliveries/view/booking-request'],
              {
                relativeTo: this.route,
                queryParams: {
                  id: this.cdId,
                  operation: 'view',
                  hideEdit: this.isActive
                }
              }
            );
          })

        }
      })
    }
  }
  
  validateElcasAmount(elcasAmount) {
    if(elcasAmount >= 2000 || elcasAmount >= this.booking.courseFees){
      this.snackBarService.error("ELCAS amount shoud be less or equalt to course fee");
      return;
    } 
  }

  private parseData(file: File): FormData {
    const formData = new FormData();
    if (file != null) {
      formData.append('file', file);
    }
    return formData;
  }

  selectFile(event) {
    this.filePath = null;
    this.elcasDocName = null;

    if (event.target.files.length > 0) {
        const file = event.target.files[0];
        
        if (file.name.length > 255) {
            this.invalidFileName = true;
            this.disableFormFeild = true;
            return;
        }else {
          this.disableFormFeild = false;
          this.invalidFileName = false;
        }

        // Check if the file size exceeds 5 MB
        const maxSizeInMB = 5;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            this.invalidFileSize = true;
            this.disableFormFeild = true;
            return;
        }else{
          this.invalidFileSize = false;
          this.disableFormFeild = false;
        }

        // Check if the file type is valid
        if (this.validFileType(file)) {
            this.uploadDisable = false;
            this.elcasDecumnetFile = file;
            this.elcasDocName = file.name;
        } else {
            this.uploadDisable = true;
        }
    }
}

  validFileType(file) {

    for (let i = 0; i < this.fileTypes.length; i++) {
      if (file.type === this.fileTypes[i]) {
        return true;
      }
    }
    return false;
  }
  patchValues(bookingData) {
    console.log("bookingData.elcasdetailsDTO.elcasAmount...",bookingData.elcasdetailsDTO.elcasAmount)
    this.filePath = bookingData.elcasdetailsDTO.documentPath;
    this.fileName = bookingData.elcasdetailsDTO.documentName;

    const formData = {
      claimNumber: bookingData.elcasdetailsDTO.canNumber || '',
      ELCContribution: bookingData.elcasdetailsDTO.elcasAmount ? bookingData.elcasdetailsDTO.elcasAmount.toFixed(2) : '',
      multipleCourses: bookingData.elcasdetailsDTO.isCanMultipleCourses,
    };
    this.elcasDetailsForm.patchValue(formData);
    this.elcasDetailsForm.get('multipleCourses').disable()
    if (bookingData.elcasdetailsDTO.isCanMultipleCourses == false) {
      this.elcasDetailsForm.get('multipleCourses').setValue('false')
    }
    else {
      this.elcasDetailsForm.get('multipleCourses').setValue('true')
    }
    this.elcasDocName = bookingData.elcasdetailsDTO.documentName || '';

    if (this.elcasDocName != '') {
      if (this.elcasDocName != null || this.elcasDocName != undefined || this.elcasDocName != '') {
        this.isShowReplaceBtn = true;
      } else {
        this.isShowReplaceBtn = false;
      }
    } else {
      this.isShowReplaceBtn = false;
    }
    
  }

  openApproveBookingDialog(data) {
    if (data) {
      const dialogRef = this.appConfirmService.confirm({
        message: `Are you sure you want to approve?`,
        okButtonLabel: 'Approve',
        cancelButtonLabel: 'Close'
      });
      dialogRef.subscribe(result => {
        if (result) {
          this.onApproveBookingClick(data);
        }
      });
    }
  }

  openRejectBookingDialog() {
    const dialogRef = this.appConfirmService.confirm({
      message: `Are you sure you want to reject?`,
      okButtonLabel: 'Reject',
      cancelButtonLabel: 'Close'
    });
    dialogRef.subscribe(result => {
      if (result) {
        this.onRejectBookingClick();
      }
    });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  downloadFile(element): void {
    const blobPath = { 'path': this.filePath}
    this.courseManagementService.downloadAttendance(blobPath,this.identifier).subscribe((res:any) => {
      const safeUrl = res.path;
      const a = document.createElement("a");
      a.href = safeUrl as string;
      a.download = "";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
      (error) => {
        this.snackBarService.error(error.error.applicationMessage);
        console.error("Error downloading the file.", error);
      });
  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }

  getStatusForPayment(status){
  
    if (status== 'Success') {
        return 'Paid'
    } else if (status== 'Pending') {
      return 'N/A'
    }else if (status== 'In Progress') {
      return 'In Progress'
    } else {
      return 'Pending';
    }
  }

  resolvedWaitingDetails() {
    this.courseManagementService.getwaitingDetails(this.identifier).subscribe(bookingData => {
      this.booking = bookingData;
    })
  }
}

