<mat-card id='contents'>

  <mat-card-header>
    <span class="example-form">
      <mat-form-field id="search_service_user_form" class="example-full-width" appearance="fill">
        <mat-label>Search</mat-label>
        <input id="search_contents_input" matInput type="text" [(ngModel)]="searchTerm" (keydown.enter)="onFilter(search_contents_input.value)" placeholder="Search by"  #search_contents_input>
        <button *ngIf="!searchTerm" id="do_search_button" matSuffix mat-icon-button aria-label="Search">
          <mat-icon>search</mat-icon>
        </button>
        <button id="clear_search_button" *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear"
          (click)="onClosedClick('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </span>
    <span class="spacer"></span>
    <span >
      <button *auth="[172,1]" id="create_content_button" mat-stroked-button class="right" [routerLink]="['./content']">Add
      </button>
    </span>
  </mat-card-header>

  <mat-card-content class="contents_container">

    <mat-table id="contents_table" #table [dataSource]="dataSource" class="isMobile" matSort matSortDirection="asc">

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef id="contents_name_button" mat-sort-header>
          Title
        </mat-header-cell>
        <mat-cell *matCellDef="let element" > <span class="mobile-label">Title:
          </span>{{element.title}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mainCategory">
        <mat-header-cell  *matHeaderCellDef id="mainMenuCategory_button" mat-sort-header>Main Category
        </mat-header-cell>
        <mat-cell  *matCellDef="let element"> <span class="mobile-label">MainMenuCategory: </span>{{element.mainCategory}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="subCategory">
        <mat-header-cell  *matHeaderCellDef id="subMenuCategory_button" mat-sort-header>Sub Category
        </mat-header-cell>
        <mat-cell  *matCellDef="let element"> <span class="mobile-label">SubMenuCategory: </span>{{element.subCategory}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell  *matHeaderCellDef id="uploadDate_button" mat-sort-header> Upload Date
        </mat-header-cell>
        <mat-cell  *matCellDef="let element"> <span class="mobile-label">UploadDate: </span>{{element.uploadDate
          | customMonthFormat }} 
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <mat-header-cell  *matHeaderCellDef id="status_button" mat-sort-header> Status
        </mat-header-cell>
        <mat-cell  *matCellDef="let element"> <span class="mobile-label call-status-label">Status: </span><span class="status">{{element.isActive}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">

        <mat-header-cell class="actions-column" *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell class="actions-column" *matCellDef="let element"> <span class="mobile-label"></span>

          <button *auth="[172,2]" id="view_contents_button_{{element.id}}" mat-icon-button color="primary" [routerLink]="['./view-contents']"
           
          [queryParams]="{ id : element.id, content: element.title}" queryParamsHandling="merge"
          
          matTooltip="View" [matTooltipShowDelay]="800" attr.aria-label="View {{element.title}}">
            <mat-icon>visibility</mat-icon>
          </button>

          <button *auth="[172,3]" id="edit_contents_button_{{element.id}}" mat-icon-button (click)="onEditClicked(element.id)" matTooltip="Edit"
            [matTooltipShowDelay]="800" attr.aria-label="Edit {{element.title}}">
            <mat-icon color="primary">create</mat-icon>
          </button>

          <button *auth="[172,4]" id="delete_contents_button_{{element.id}}" mat-icon-button (click)="onDeleteClicked(element.id)"
            attr.aria-label="Delete {{element.title}}" matTooltip="Delete">
            <mat-icon color="accent">delete</mat-icon>
          </button>

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator id="contents_pagination"  [pageSizeOptions]="[25, 50, 100]" [showFirstLastButtons]="true" aria-label="Select page" >
    </mat-paginator>

  </mat-card-content>




</mat-card>
