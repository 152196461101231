<mat-card id='view_content'>
    <mat-card-header>
        <span class="spacer">
            <app-stepper-navigation></app-stepper-navigation>
        </span>
        <span class="action-btn-alignment">
          <button id="add_button" mat-stroked-button *ngIf="!externalModuleData"  
          [routerLink]="['../']" queryParamsHandling="preserve"> Add </button>
            <button id="exit_button" mat-stroked-button [routerLink]="[exitButtonBehaviour]"
                [queryParams]="{id: contentId}">Exit</button>
        </span>
    </mat-card-header>
    <div class="label-hr">
        <span>External</span>
    </div>
    <mat-card-content class="view_user-plan_container">
      <div *ngIf="externalModuleData" class="custom-card-row-container mb20">
        <div class="custom-row-container-left">
          <div class="card-column-40 custom-card-column">
            <div class="card-row" *ngIf="externalModuleData?.moduleName">
              <p class="info">
                  <span class="details_label">Title</span>
                  <span id="title">{{externalModuleData.moduleName}}</span>
              </p>
            </div>
          </div>
          <div class="card-column-60 custom-card-column">
            <div class="card-row ml10" *ngIf="externalModuleData?.path">
              <p class="info">
                  <span class="details_label">URL</span>
                  <span id="url">{{externalModuleData.path}}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="custom-row-container-right">
          <span class="action-btn-alignment">
            <div class="action-btn-wrapper">
              <button 
                *auth="[13,3]" 
                id="edit_contents_button" 
                mat-stroked-button 
                color="primary" 
                [routerLink]="['../']"
                [queryParams]="{moduleId: externalModuleData?.id}" 
                queryParamsHandling="merge"
                matTooltip="Edit" 
                [matTooltipShowDelay]="800"
              >
                Edit
              </button>
            </div>
            <div class="action-btn-wrapper">
              <button 
                *auth="[13,4]" 
                id="delete_contents_button" 
                mat-stroked-button 
                color="primary"
                (click)="onDeleteClicked(externalModuleData?.id)" 
                title="Delete" 
                aria-label="Delete"
              >
                Delete
              </button>
            </div>
          </span>
        </div>
      </div>
      <div class="card-row custom-row" *ngIf="externalModuleData?.moduleDescription">
        <p class="info">
          <span class="details_label">Description</span>
          <span id="description">{{externalModuleData.moduleDescription}}</span>
        </p>
    </div>
    </mat-card-content>
</mat-card>
