import { Component } from '@angular/core';
import { CourseManagementNavigation } from '../course-management-nav';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent {
  collection = 'events';
  fields = ['eventName', 'eventType.name', 'eventId', 'createdAt'];
  searchFields = ['eventName', 'eventType.name', 'eventId'];
  buttonLable = "event";
  baseRoute = '/courses-events';

  dynamicFilters: any = {
    publishedAt: { null: true },
  };

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly router: Router,
    private readonly courseNavigation: CourseManagementNavigation,
  ) {
    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

}
