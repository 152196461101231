import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-title-type',
  templateUrl: './title-type.component.html',
  styleUrls: ['./title-type.component.scss']
})
export class TitleTypeComponent extends FieldType{
}
