import { Component, OnDestroy, OnInit } from '@angular/core';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicTableService } from 'src/app/framework/components/dynamic-table/dynamic-table.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';

@Component({
  selector: 'app-view-course',
  templateUrl: './view-course.component.html',
  styleUrls: ['./view-course.component.scss']
})
export class ViewCourseComponent implements OnInit, OnDestroy {

  identifier: any;
  schemaUrl = 'api::course.course';
  apiUrl = '/courses';
  collection = "/courses";
  addRelationFields: {};
  collectionData = "courses";
  titleInformation: any[];
  pageName:any;

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly route: ActivatedRoute,
    private readonly dynamicTableService: DynamicTableService,
    private readonly snackBarService: SnackBarService,
    private readonly router: Router,
    )
    {
      this.scrollToTop();
      this.titleInformation = [
        { 'courseprovider': 'Course details' },
        { 'tags': 'Course tags' }
      ];
      this.addRelationFields = {
        'providers': [{'attribute': 'telephoneNumber', 'label': 'Telephone number', 'type': 'input'}]
      };
      this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    }
      

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
    });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  routePath(){
    this.dynamicTableService.getBookingCountDetailsById(this.collectionData, this.identifier).subscribe({
      next: res => {
        if(res > 0){
          let formattedCollectionName = this.formatCollectionName(this.collectionData);
          this.snackBarService.error("There are bookings for this " + formattedCollectionName + ". Please cancel these bookings to enable the edit functionality.");
        } else {
          this.router.navigate(['/courses-events/courses/edit'],{queryParams:{id: this.identifier}});
        }
        
      }
    });
  }

  formatCollectionName(data: string): string {
    let formattedName = data.endsWith('s') ? data.slice(0, -1) : data;
    formattedName = formattedName
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase(); 
    if (formattedName.endsWith('deliverie')) {
      formattedName = formattedName.replace('deliverie', 'delivery');
    }
    return formattedName;
  }

}
