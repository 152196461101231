<mat-hint class="align-text" > {{ to['description'] }} </mat-hint>    
<div class="repetable-container">
  <mat-accordion [togglePosition]="'before'">
    <mat-expansion-panel *ngFor="let field of field.fieldGroup; let i = index" [expanded]="panelExpanded[i]">
      <mat-expansion-panel-header [ngClass]="{ 'error': showError }">
        <mat-panel-title>
          {{ to['label'] }} {{ i+1 }}<span *ngIf="to['required']">*</span>
        </mat-panel-title>
        
        <mat-panel-description>
          {{ field?.templateOptions?.description }}
        </mat-panel-description>
        <button mat-icon-button (click)="removeRow(i)" *ngIf="minLimitFlag">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </mat-expansion-panel-header>

      <mat-card-content>
        <formly-field [field]="field"></formly-field>
      </mat-card-content>
    </mat-expansion-panel>

    <div class="button-container" *ngIf="maxLimitFlag">
      <span>Add another {{to['label']}}</span>
      <button attr.aria-label="Add another {{to['label']}}" type="button" mat-mini-fab class="addMoreBtn" (click)="addMore()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-accordion>
</div>