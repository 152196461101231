<div class="header">
    <div class="tabWrapper">
        <ul>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve"  *ngIf="isActive ==='false'">
                <button mat-button [routerLink]="['./event-delivery']"
                    [queryParams]="{ id: userId, name: userName,hideEdit: isActive }"> <a class="tab-item" id="year-tab"
                        aria-label="Event delivery details">Event delivery details</a></button>
            </li>
            <li class="tabHead" routerLinkActive="active" queryParamsHandling="preserve"  *ngIf="isActive ==='false'">
                <button mat-button [routerLink]="['./confirm-booking']"
                    [queryParams]="{ id: userId, name: userName, operation: 'view',hideEdit: isActive }">
                    <a class="tab-item" id="year-tab" aria-label="Confirmed bookings">Confirmed bookings</a></button>
            </li>
        </ul>

    </div>
</div>
<router-outlet></router-outlet>