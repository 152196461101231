import { Component, OnInit, OnDestroy } from '@angular/core';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { ActivatedRoute } from '@angular/router';
import { CourseProviderService } from '../course-provider.service';

@Component({
  selector: 'app-view-course-provider',
  templateUrl: './view-course-provider.component.html',
  styleUrls: ['./view-course-provider.component.scss']
})
export class ViewCourseProviderComponent implements OnInit, OnDestroy {

  identifier: any;
  schemaUrl = 'api::provider.provider';
  apiUrl = '/providers';
  titleInformation: any[];
  providerId: string;
  auditDatesHistory: any[] = [];
  collection = "/providers";
  pageName:any;
  CPid:any;
  routeIntent: any;
  courseProviderData: any;
  recordData: any;
  
  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly courseProviderService: CourseProviderService,
    private readonly route: ActivatedRoute,
  )
  {
    this.scrollToTop();
    this.providerId = this.route.snapshot.queryParamMap.get('id');
    this.titleInformation = [{
      'courseProvider': 'Provider name'
    },{
      'provContactDtls': 'Provider contact details'
    },{
      'providerDescription': 'Provider information'
    }];
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }

  ngOnInit(): void {
    this.courseProviderService.getAuditDatesByProviderId(this.providerId,this.schemaUrl,"/providerAuditDates","createdAt,desc",1,200,null).subscribe (resp => {
      this.recordData = resp?.body?.data?.providerAuditDates?.data;
      this.recordData?.forEach((obj: any) => {
        this.auditDatesHistory.push(obj.attributes.auditDate);
      })
      if (this.auditDatesHistory.length > 0) {
        this.auditDatesHistory = this.auditDatesHistory.slice(1);
      }
    })
    this.route.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
      //this.CPid = params.CPid;
    });
  }
  
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
      this.inPageNavService.setNavItems(null);
  }

}
