import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseUrl } from 'src/app/framework/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private readonly http: HttpClient) { }

  createRefDateEntry(payload,url) {
    return this.http.post<any>(`${BaseUrl.CMS_PROXY_API}/proxy/${url}/create`, payload);
  }
  
}
