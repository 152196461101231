<mat-card id='edit_content'>
  <mat-card-header>
    <span class="spacer">
      <app-stepper-navigation></app-stepper-navigation>
    </span>
    <span>
      <button id="exit_button" mat-stroked-button color="primary" [routerLink]="['../']"
        [queryParams]="{id: contentId}">Exit</button>
    </span>
  </mat-card-header>
  <mat-card-content style="padding-left: 0px;">
    <div class="info-text">
      <p class="mt0"> Maximum file size is {{maxfileSizeLimit}}. Allowed file types are zip,pdf,flv,ppt,pps,pptx,xls,xlsx,mp4,mp3,wav,rtf,txt,
        doc,docx,odf,html,odp,ods,odt,pub,jpeg,png. 
        You can sequence the modules after uploading files and selecting 'Save & Exit'. </p>
    </div>
    <div class="label-hr">
      <span>Upload File *</span>
    </div>
    <div>
      <file-upload [option]="option" [disabled]="false" [showSelector]="false" [showReset]="false" [required]="isNew"
        (fileSelected)="onFileSelected($event)"></file-upload>
    </div>

    <div class="label-hr">
      <span>Details</span>
    </div>
    <form [formGroup]="contentForm" autocomplete="off">

      <div class="card-row">
        <div class="card-column">
          <mat-form-field appearance="outline">
            <mat-label>Module Name</mat-label>
            <input matInput type="text" formControlName="moduleName" id="moduleName" value="" required 
            minlength="3" maxlength="100">
            <mat-error
              *ngIf="contentForm.controls.moduleName.invalid && !contentForm.controls.moduleName.errors?.pattern && 
              !contentForm.controls.moduleName.errors?.minlength && !contentForm.controls.moduleName.errors?.maxlength">
              Mandatory Field
            </mat-error>
            <mat-error *ngIf="contentForm.controls.moduleName.errors?.pattern || contentForm.controls.moduleName.errors?.minlength || 
            contentForm.controls.moduleName.errors?.maxlength">
            Minimum 3 - Maximum 100, Alphanumeric, special characters only ! ? , . - / ( )
            </mat-error>
            <mat-hint *ngIf="contentForm.controls.moduleName.value.length === 0">
              Minimum 3 and maximum 100 alphanumeric and ! ? , . ' - / ( ) are allowed
          </mat-hint>
          <mat-hint *ngIf="contentForm.controls.moduleName.valid">
            {{100 - contentForm.controls.moduleName.value.length}} characters left
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="card-row">
        <div class="card-column">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="moduleDescription" rows="3" required minlength="3" maxlength="200"></textarea>
            <mat-hint *ngIf="contentForm.controls.moduleDescription.valid && contentForm.controls.moduleDescription.value.length !=(200-1) && (200) >= contentForm.controls.moduleDescription.value.length"> {{200 - contentForm.controls.moduleDescription.value.length}} characters left</mat-hint>
            <mat-hint *ngIf="contentForm.controls.moduleDescription.value.length == (200-1)">
              1 character left
            </mat-hint>
            <mat-hint *ngIf="contentForm.controls.moduleDescription.value.length === 0">
              Minimum 3 and maximum 200 characters allowed
            </mat-hint>
            <mat-error
              *ngIf="contentForm.controls.moduleDescription.invalid && !contentForm.controls.moduleDescription.errors?.minlength && 
              !contentForm.controls.moduleDescription.errors?.maxlength">
              Mandatory Field
            </mat-error>
            <mat-error *ngIf="contentForm.controls.moduleDescription.errors?.minlength || contentForm.controls.moduleDescription.errors?.maxlength">
              Minimum 3 and maximum 200 characters allowed
            </mat-error>
          </mat-form-field>
        </div>
      </div>

    </form>

  </mat-card-content>

  <mat-card-footer>
    <span class="spacer"></span>
    <span>
      <button type="submit" id="save_module_button" mat-flat-button color="primary"
        [disabled]="saveButtonDisabled()" (click)="saveContentModule()">Save & Exit</button>
    </span>
  </mat-card-footer>
</mat-card>