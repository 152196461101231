<mat-card id="edit_keyword">
    <mat-card-content>
      <form [formGroup]="cancellationForm"  validate autocomplete="off">
        <div class="card-row scroll">
          <mat-form-field appearance="fill">
            <mat-label>Please select cancellation status</mat-label>
            <mat-select aria-label="cancelationReason" [value]="" formControlName="cancellationReasonId" id="cancelationReason">
              <mat-option *ngFor="let option of resons" [value]="option.id" id="cancelationReason-{{option}}">
                {{option.description}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="cancellationForm.controls.cancellationReasonId.errors?.required">
              Rejected reason is required
            </mat-error>
          </mat-form-field>
        </div>  
        <div class="card-row" *ngIf="onCancellationEscalated('cancellationReasonId')">
            <mat-label>Has SL provided documents for extenuating circumstances?</mat-label>
            <mat-radio-group formControlName="isExtenuatingCircumstances"  name="isExtenuatingCircumstances" required>
              <div class="radio-button-container">
                <mat-radio-button value=true>Yes</mat-radio-button>
                <mat-radio-button value=false>No</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>  
         
        <mat-card-footer>
          <div class="btn-fix">
            <span class="mr-top">
              <button id="exit_keyword_button" type="button" mat-stroked-button (click)="cancel()"> Cancel</button>
            </span>
            <span>
              <button type="button"  id="save_keyword_button" [disabled]="cancellationForm.invalid"  mat-raised-button color="primary" (click)="onSubmit()">Confirm</button>
            </span>
          </div>
        </mat-card-footer>
      </form>
      
    </mat-card-content>
  </mat-card>
  