import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DynamicTableService } from '../dynamic-table.service';
import { SnackBarService } from "../../../service/snack-bar.service";
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-overWrite-popup',
  templateUrl: './overWrite-popup.component.html',
  styleUrls: ['./overWrite-popup.component.scss']
})
export class OverWritePopupComponent implements OnInit {

  entryId: any;
  collectionName: any;
  repostForNewApplications: any;

  constructor(
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dynamicFormService: DynamicTableService,
    private readonly snackBarService: SnackBarService,
    private readonly dialogRef: MatDialogRef<OverWritePopupComponent>
  ) {
    this.entryId = this.data.entryId;
    this.collectionName = this.data.collection;
    this.repostForNewApplications = this.data.repostForNewApplications;
  }

  ngOnInit() {

  }

  navigateToRoute() {
    this.router.navigate([`courses-events/${this.collectionName}`]);
  }

  saveEntry() {
    let payload = { repostForNewApplications: false };
    this.repostForNewApplications = !(this.repostForNewApplications);
    payload.repostForNewApplications = this.repostForNewApplications;
    this.dynamicFormService.updateSingleCollectionEntry(payload, this.entryId, this.collectionName).subscribe(response => {
      this.snackBarService.success(response?.message.applicationMessage);
      this.navigateToRoute();
    }, error => {
      this.snackBarService.error(error.error.body.error.message);
    })
    this.dialogRef.close();
    this.navigateToRoute();
  }

  close(): void {
    this.dialogRef.close();
  }

}

