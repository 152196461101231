import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'boldPrefixPipe'
})
export class BoldPrefixPipePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    const index = value.indexOf('-');
    if (index !== -1) {
      const boldPart = value.substring(0, index);
      const restPart = value.substring(index);
      return this.sanitizer.bypassSecurityTrustHtml(`<strong>${boldPart}</strong>${restPart}`);
    } 
    else {
      return this.sanitizer.bypassSecurityTrustHtml(`${value}`);
    }
  }

}
