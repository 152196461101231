



export const environment = {
  production: false,
  apiURL: 'https://reedctpdevservices.sequation.net',
  logo: `./assets/logo/Captr_Logo_kw.png`,
  appTitle: window.location.host.split('-')[0].toUpperCase(),
  appInsights: {
    instrumentationKey: 'c9ec818c-08a2-492a-9f73-a77fa5637474'
  },
  azureBlobStorage: 'https://streedctpdev.blob.core.windows.net',
  fileSizeLimit: '1GB',
  reportsBiUrl:'https://pefreports.azurewebsites.net/api/reportEmbedToken?code=0Ka4OeDVa4v/sUks1N0mEsJPTrneC9INGemW0muwfCc5kg4ZYyyYdQ==',
  cdnUrl:'https://cdn-reedctp-dev.captr.online',
  env:'DEV',
  buildId: "20241115045405",
  proxyURL :'https://reedctpdevservices.sequation.net',
  issuer: 'https://login.microsoftonline.com/5778ddf3-0ec2-4912-9f7c-6eb3674b2682',
  clientId: '79b4d9e7-696b-44d3-afed-d21c44ca30ed',
  redirectUri:'https://staff-ctp-dev.captr.online/sessions/signin',
};
