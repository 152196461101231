<mat-card id="edit_keyword">
  <mat-card-header>
    <span style="margin-bottom: 15px;"> Please select the reason for rejection </span>
    <span class="spacer"></span>
    
  </mat-card-header>
  <mat-card-content>
    
    <form [formGroup]="rejectBookingForm"  validate autocomplete="off">
      <div class="card-row scroll">
        <mat-form-field appearance="fill">
          <mat-label>Rejected reason</mat-label>
          <mat-select aria-label="rejectionReason" [value]="" formControlName="rejectionReason" id="rejectionReason">
            <mat-option *ngFor="let option of rejectionReason" [value]="option.id" id="rejectionReason-{{option}}">
              {{option.description}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="rejectBookingForm.controls.rejectionReason.errors?.required">
            Rejected reason is required
          </mat-error>
          <mat-hint>Please select an option from the list</mat-hint>
        </mat-form-field>
      </div>  
       
      <mat-card-footer>
        <div class="btn-fix">
          <span class="mr-top">
            <button type="button" (click)="onSubmit()" id="save_keyword_button" [disabled]="!rejectBookingForm.valid" mat-raised-button color="primary">Reject</button>
          </span>
          <span>
            <button id="exit_keyword_button" type="button" mat-stroked-button (click)="cancel()"> Cancel</button>
          </span>
        </div>
      </mat-card-footer>
    </form>
    
  </mat-card-content>
</mat-card>
