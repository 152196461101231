import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicTableService } from 'src/app/framework/components/dynamic-table/dynamic-table.service';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { SessionsService } from 'src/app/sessions/sessions.service';
import { CourseManagementNavigation } from '../../course-management-nav';

@Component({
  selector: 'app-view-event-delivery',
  templateUrl: './view-event-delivery.component.html',
  styleUrls: ['./view-event-delivery.component.scss']

})
export class ViewEventDeliveryComponent {

  identifier: any;
  routeIntent: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  collection = '/eventDeliveries';
  pageName: any;
  CLid: any;
  isActive: any;
  collectionData = "eventDeliveries";

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly sessionService: SessionsService,
    private readonly dynamicTableService: DynamicTableService,
    private readonly snackBarService: SnackBarService,
    private readonly router: Router,
  ) {
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.titleInformation = [{
      'deliveryDateTime': 'Delivery days and times'
    }];
    this.schemaUrl = 'api::event-delivery.event-delivery';
    this.apiUrl = '/event-deliveries';
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
      this.CLid = params.CLid;
      this.isActive=params.hideEdit;
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }

  routePath(){
    this.dynamicTableService.getBookingCountDetailsById(this.collectionData, this.identifier).subscribe({
      next: res => {
        if(res > 0){
          let formattedCollectionName = this.formatCollectionName(this.collectionData);
          this.snackBarService.error("There are bookings for this " + formattedCollectionName + ". Please cancel these bookings to enable the edit functionality.");
        } else {
          this.router.navigate(['/courses-events/eventDeliveries/edit'],{queryParams:{id: this.identifier}});
        }
        
      }
    });
  }

  formatCollectionName(data: string): string {
    let formattedName = data.endsWith('s') ? data.slice(0, -1) : data;
    formattedName = formattedName
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase(); 
    if (formattedName.endsWith('deliverie')) {
      formattedName = formattedName.replace('deliverie', 'delivery');
    }
    return formattedName;
  }

}
