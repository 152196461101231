import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FileError, FileUploadOptions } from './file-upload.options';

export interface ErrorInput {
  id: ('filesize' | 'filetype' | 'filename'| 'required'),
  message: string
}

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit{

  @Input() option: FileUploadOptions;
  @Input() disabled: boolean;
  @Input() showReset: boolean;
  @Input() required: boolean;
  @Input() errorInput: ErrorInput[] = [];
  @Input() showSelector: boolean;

  @Output() fileSelected: EventEmitter<File[]> = new EventEmitter();
  

  @ViewChild('fileInput', {static:false}) fileInput: ElementRef<HTMLInputElement>;

  fileList: File[] = [];
  errors: ErrorInput[] = [];
  defaultErrorInput: ErrorInput[] = [
    { id: 'filesize', message: 'This file is too large to upload. The maximum supported file size is 16MB' },
    { id: 'filetype', message: 'File type not allowed' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onFilesSelected(files: FileList) {
    this.errors = [];
    this.fileList = Array.from(files);

    for (const file of this.fileList) {
      if (file.name.length > 100) {
        this.errors.push({ id: 'filename', message: 'File name too large. Maximum file name length is 100 characters. Please rename and upload again' });
        continue;
      }

      if (!this.isValidFileSize(file)) {
        this.errors.push({ id: 'filesize', message: 'This file is too large to upload. The maximum supported file size is 16MB' });
        continue;
      }

      if (!this.isValidFileType(file)) {
        this.errors.push({ id: 'filetype', message: 'File type not allowed' });
        continue;
      }
    }

    if (!this.option.multiple && this.errors.length > 0) {
      this.fileList = [];
    }

    this.fileSelected.emit(this.fileList);
  }

  isValidFileType(file: File): boolean {
    if (!this.option.accept) {
      return true;
    }

    if (file.type !== '') {
      return this.option.accept.includes(file.type);
    } else {
      const format = file.name.split('.').pop();
      return this.option.formats.includes(format.toLowerCase());
    }
  }

  isValidFileSize(file: File): boolean {
    return !(this.option.maxFileSize && file.size > this.option.maxFileSize);
  }

  removeFile(file: File) {
    this.fileList.splice(this.fileList.indexOf(file), 1);
    if (this.required && this.fileList.length === 0) {
      this.errors.push({ id: 'required', message: 'File is mandatory' });
    }
    this.fileSelected.emit(this.fileList);

    // Reset file input value to clear selection
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  reset() {
    this.fileList = [];
    this.fileSelected.emit(this.fileList);

    // Reset file input value to clear selection
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }
}