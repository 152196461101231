<ng-container *ngIf="collapsible">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="label-hr">
            <span>Team(s) *</span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="1===1 then estbSelectionList"></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<ng-container *ngIf="!collapsible">
  <div class="label-hr">
    <span>Team(s) *</span>
  </div>
  <ng-container *ngIf="1===1 then estbSelectionList"></ng-container>
</ng-container>


<ng-template #estbSelectionList>
  <mat-card-content>
  <div class="card-row">
    <div class="card-column-third">
      <mat-form-field id="orgNameFilter" class="example-full-width" appearance="fill">
        <mat-label class="blur_color">Search</mat-label>
        <input matInput type="text" id="suNameFilter" [formControl]="orgNameFilter" [(ngModel)]="searchTerm"
          (keydown.enter)="applySearch()" placeholder="Search by">
        <button *ngIf="!searchTerm" id="do_search_button" matSuffix mat-icon-button aria-label="Search">
          <mat-icon>search</mat-icon>
        </button>
        <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClose()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="card-column-third">
      <mat-form-field appearance="fill">
        <mat-label class="blur_color">Teams filter</mat-label>
        <mat-select id="lotNameFilter" [formControl]="lotNameFilter" multiple>
          <mat-option *ngFor="let lot of lotNames" value="{{lot.name}}">{{lot.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="card-column-third">
      <span class="mat-form-field spacer">
        <button id="clear" class="clear-selection" mat-stroked-button color="primary" type="button"
          (click)="lotNameFilter.setValue([])">Clear</button>
        <mat-checkbox *ngIf="multiple" class="orgSelectAll" [(ngModel)]="selectAll" id="orgSelectAll"
          (change)="toggleSelectAll()">Select
          All</mat-checkbox>
      </span>
    </div>
  </div>
  <div class="card-row">
    <div class="estb-container">
      <mat-selection-list #orgSection *ngIf="filteredOrganizations" class="org-list"
        (selectionChange)="onSelectedOptionsChange()">
        <mat-list-option *ngFor="let organization of filteredOrganizations" class="chip_margin_top"
          [selected]="organization.checked" class="chip" title="{{organization.organizationName}}"
          value="{{organization.id}}" (click)="toggleCheckedOption(organization)">
          <span matLine>{{organization.organizationName}}</span>
        </mat-list-option>
      </mat-selection-list>
      <p *ngIf="filteredOrganizations?.length === 0">No results found</p>
    </div>
  </div>
  </mat-card-content>
</ng-template>