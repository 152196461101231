
<mat-card id="edit-service">
    <mat-card-header class="flex-end">
        <h1 class="page-title" *ngIf="this?.routeIntent === 'edit'">Edit course delivery</h1>
        <h1 class="page-title" *ngIf="this?.routeIntent != 'edit'">Add course delivery</h1>
        <span class="spacer"></span>
        <span>
            <button id="exit_service_button" mat-stroked-button
            (click)="exitNavigation()">Exit</button>
        </span>
    </mat-card-header>

    <mat-card-content style="padding-left: 0px;">
       <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="fields" [@fadeIn]>
            <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
            <button *ngIf="!disableSubmit" type="submit" mat-raised-button color="primary" [disabled]="!form.valid">Save</button>
          </form>
    </mat-card-content>
</mat-card>