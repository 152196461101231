<vg-player (onPlayerReady)="onPlayerReady($event)">

    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>

    <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-controls>
        <vg-play-pause></vg-play-pause>
        <vg-time-display vgProperty="current" vgFormat="hh:mm:ss"></vg-time-display>
        <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
        <vg-time-display vgProperty="total" vgFormat="hh:mm:ss"></vg-time-display>
        <vg-mute></vg-mute>
        <vg-volume></vg-volume>
        <vg-fullscreen></vg-fullscreen>
    </vg-controls>

    <img src="../../../../../assets/images/playerNew.jpg" class="img-fix" *ngIf="defaultImageFlag">

    <video #media [vgMedia]="media" [id]="idx" preload="auto" crossorigin>
        <source [src]="src" [type]="type">
        <track label="English" kind="captions" srclang="en" default>
        <track label="Deutsch" kind="captions" srclang="de" >
        <track label="Español" kind="captions" srclang="es" >
    </video>


</vg-player>