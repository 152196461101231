import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { DirectoryOfServiceNavigation } from 'src/app/features/directory-of-service/directory-of-service-nav';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { RegexPattern } from 'src/app/framework/constants/regex-constant';
import { JobsNavigation } from '../../jobs-nav';
import { CanDeactivateGuard } from 'src/app/framework/guards/can-deactivate.guard';
import { Observable } from 'rxjs';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';

@Component({
  selector: 'app-add-employer-details',
  templateUrl: './add-employer-details.component.html',
})
export class AddEmployerDetailsComponent implements CanDeactivateGuard {

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  telephoneCodePattern = /^0\d{9,10}$/;
  isNotSaved: boolean = false;
  
  fields: FormlyFieldConfig[] = [
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "employerName",
          "type": "input",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Employer name is required.",
              "minlength": "Minimum length should be 2 characters.",
              "maxlength": "Maximum length should be 100 characters."
            }
          },
          "templateOptions": {
            "label": "Employer name",
            "placeholder": "",
            "description": "Minimum 2 and maximum 100 characters",
            "required": true,
            "options": [],
            "minLength": 2,
            "maxLength": 100
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "employerId",
          "type": "input",
          "className": "col-md-6",
          "validation": {
            "messages": {

            }
          },
          "templateOptions": {
            "label": "Employer ID",
            "placeholder": "",
            "description": "",
            "options": []
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "primaryCont",
          "type": "single",
          "className": "col-md-12",
          "validation": {
            "messages": {

            }
          },
          "templateOptions": {
            "label": "Primary employer contact details",
            "placeholder": "",
            "description": "",
            "options": []
          },
          "fieldArray": null,
          "fieldGroupClassName": "display-flex",
          "fieldGroup": [
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "empFirstName",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {
                      "required": "Employer contact first name is required.",
                      "minlength": "Minimum length should be 3 characters.",
                      "maxlength": "Maximum length should be 100 characters."
                    }
                  },
                  "templateOptions": {
                    "label": "Employer contact first name",
                    "placeholder": "",
                    "description": "Minimum 3 and maximum 100 characters",
                    "required": true,
                    "options": [],
                    "minLength": 3,
                    "maxLength": 100
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "empLastName",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {
                      "required": "Employer contact last name is required.",
                      "minlength": "Minimum length should be 3 characters.",
                      "maxlength": "Maximum length should be 100 characters."
                    }
                  },
                  "templateOptions": {
                    "label": "Employer contact last name",
                    "placeholder": "",
                    "description": "Minimum 3 and maximum 100 characters",
                    "required": true,
                    "options": [],
                    "minLength": 3,
                    "maxLength": 100
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "empRole",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {
                      "required": "Employer contact role is required.",
                      "minlength": "Minimum length should be 3 characters.",
                      "maxlength": "Maximum length should be 100 characters."
                    }
                  },
                  "templateOptions": {
                    "label": "Employer contact role",
                    "placeholder": "",
                    "description": "Minimum 3 and maximum 100 characters",
                    "required": true,
                    "options": [],
                    "minLength": 3,
                    "maxLength": 100
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "empTeleNo",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {
                      "required": "Employer contact telephone number is required.",
                      "pattern": "Employer contact telephone number format is invalid."
                    }
                  },
                  "templateOptions": {
                    "label": "Employer contact telephone number",
                    "placeholder": "",
                    "description": "Maximum 11 digit telephone number starts with 0",
                    "required": true,
                    "options": [],
                    "pattern": this.telephoneCodePattern
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "empEmail",
                  "type": "input",
                  "className": "col-md-6",
                  "validation": {
                    "messages": {
                      "required": "Employer contact email address is required.",
                      "pattern": "Invalid email address"
                    }
                  },
                  "templateOptions": {
                    "label": "Employer contact email address",
                    "placeholder": "",
                    "description": "",
                    "required": true,
                    "options": [],
                    "pattern": RegexPattern.email
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": null,
                  "fieldGroup": null
                }
              ]
            },
            {
              "fieldGroupClassName": "display-flex",
              "fieldGroup": [
                {
                  "key": "address",
                  "type": "postcode",
                  "className": "col-md-12",
                  "validation": {
                    "messages": {

                    }
                  },
                  "templateOptions": {
                    "label": "Address",
                    "placeholder": "",
                    "description": "",
                    "options": [],
                    "attributes": {
                      "aria-label": "Address"
                      }
                  },
                  "fieldArray": null,
                  "fieldGroupClassName": "display-flex",
                  "fieldGroup": [
                    {
                      "fieldGroupClassName": "display-flex",
                      "fieldGroup": [
                        {
                          "key": "postcode",
                          "type": "input",
                          "className": "col-md-6",
                          "validation": {
                            "messages": {

                            }
                          },
                          "templateOptions": {
                            "label": "Postcode",
                            "placeholder": "",
                            "description": "Minimum 4 characters required to get suggestions",
                            "required": true,
                            "options": [],
                            "attributes": {
                              "aria-label": "Postcode"
                              }

                          },
                          "fieldArray": null,
                          "fieldGroupClassName": null,
                          "fieldGroup": null
                        }
                      ]
                    },
                    {
                      "fieldGroupClassName": "display-flex",
                      "fieldGroup": [
                        {
                          "key": "addressLine1",
                          "type": "input",
                          "className": "col-md-6",
                          "validation": {
                            "messages": {
                              "minlength": "Minimum length should be 3 characters.",
                              "maxlength": "Maximum length should be 100 characters."
                            }
                          },
                          "templateOptions": {
                            "label": "Address line 1",
                            "placeholder": "",
                            "description": "Minimum 3 and maximum 100 characters",
                            "required": false,
                            "options": [],
                            "minLength": 3,
                            "maxLength": 100
                          },
                          "fieldArray": null,
                          "fieldGroupClassName": null,
                          "fieldGroup": null
                        }
                      ]
                    },
                    {
                      "fieldGroupClassName": "display-flex",
                      "fieldGroup": [
                        {
                          "key": "addressLine2",
                          "type": "input",
                          "className": "col-md-6",
                          "validation": {
                            "messages": {
                              "minlength": "Minimum length should be 3 characters.",
                              "maxlength": "Maximum length should be 100 characters."
                            }
                          },
                          "templateOptions": {
                            "label": "Address line 2",
                            "placeholder": "",
                            "description": "Minimum 3 and maximum 100 characters",
                            "options": [],
                            "minLength": 3,
                            "maxLength": 100
                          },
                          "fieldArray": null,
                          "fieldGroupClassName": null,
                          "fieldGroup": null
                        }
                      ]
                    },
                    {
                      "fieldGroupClassName": "display-flex",
                      "fieldGroup": [
                        {
                          "key": "townCity",
                          "type": "input",
                          "className": "col-md-6",
                          "validation": {
                            "messages": {

                            }
                          },
                          "templateOptions": {
                            "label": "Town/City",
                            "placeholder": "",
                            "description": "",
                            "required": false,
                            "options": [],
                            "disabled": true
                          },
                          "fieldArray": null,
                          "fieldGroupClassName": null,
                          "fieldGroup": null
                        }
                      ]
                    },
                    {
                      "fieldGroupClassName": "display-flex",
                      "fieldGroup": [
                        {
                          "key": "region",
                          "type": "input",
                          "className": "col-md-6",
                          "validation": {
                            "messages": {

                            }
                          },
                          "templateOptions": {
                            "label": "Region",
                            "placeholder": "",
                            "description": "",
                            "required": false,
                            "options": [],
                            "disabled": true
                          },
                          "fieldArray": null,
                          "fieldGroupClassName": null,
                          "fieldGroup": null
                        }
                      ]
                    },
                    {
                      "fieldGroupClassName": "display-flex",
                      "fieldGroup": [
                        {
                          "key": "country",
                          "type": "input",
                          "className": "col-md-6",
                          "validation": {
                            "messages": {

                            }
                          },
                          "templateOptions": {
                            "label": "Country",
                            "placeholder": "",
                            "description": "",
                            "options": [],
                            "disabled": true
                          },
                          "fieldArray": null,
                          "fieldGroupClassName": null,
                          "fieldGroup": null
                        }
                      ]
                    },
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "type": "title",
          "templateOptions": {
            "label": "Other employer contact details"
          }
        },
        {
          "key": "empCont",
          "type": "repeat",
          "className": "col-md-12",
          "validation": {
            "messages": {
              "required": "Employer contact details is required.",
              "min": "Minimum number should be 1.",
              "max": "Maximum number should be 5."
            }
          },
          "templateOptions": {
            "label": "Employer contact details",
            "placeholder": "",
            "description": "Maximum 5 other employer contact details are allowed",
            "options": [],
            "minQuantity": 0,
            "maxQuantity": 5
          },
          "fieldArray": {
            "fieldGroupClassName": "display-flex-column",
            "fieldGroup": [
              {
                "fieldGroupClassName": "display-flex",
                "fieldGroup": [
                  {
                    "key": "empFirstName",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                      "messages": {
                        "required": "Employer contact first name is required.",
                        "minlength": "Minimum length should be 3 characters.",
                        "maxlength": "Maximum length should be 100 characters."
                      }
                    },
                    "templateOptions": {
                      "label": "Employer contact first name",
                      "placeholder": "",
                      "description": "Minimum 3 and maximum 100 characters",
                      "options": [],
                      "minLength": 3,
                      "maxLength": 100
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                  }
                ]
              },
              {
                "fieldGroupClassName": "display-flex",
                "fieldGroup": [
                  {
                    "key": "empLastName",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                      "messages": {
                        "required": "Employer contact lastname is required.",
                        "minlength": "Minimum length should be 3 characters.",
                        "maxlength": "Maximum length should be 100 characters."
                      }
                    },
                    "templateOptions": {
                      "label": "Employer contact last name",
                      "placeholder": "",
                      "description": "Minimum 3 and maximum 100 characters",
                      "options": [],
                      "minLength": 3,
                      "maxLength": 100
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                  }
                ]
              },
              {
                "fieldGroupClassName": "display-flex",
                "fieldGroup": [
                  {
                    "key": "empRole",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                      "messages": {
                        "required": "Employer contact role is required.",
                        "minlength": "Minimum length should be 3 characters.",
                        "maxlength": "Maximum length should be 100 characters."
                      }
                    },
                    "templateOptions": {
                      "label": "Employer contact role",
                      "placeholder": "",
                      "description": "Minimum 3 and maximum 100 characters",
                      "options": [],
                      "minLength": 3,
                      "maxLength": 100
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                  }
                ]
              },
              {
                "fieldGroupClassName": "display-flex",
                "fieldGroup": [
                  {
                    "key": "empTeleNo",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                      "messages": {
                        "required": "Employer contact telephone number is required.",
                        "pattern": "Employer contact telephone number format is invalid."
                      }
                    },
                    "templateOptions": {
                      "label": "Employer contact telephone number",
                      "placeholder": "",
                      "description": "Maximum 11 digit telephone number starts with 0",
                      "options": [],
                      "pattern": this.telephoneCodePattern
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                  }
                ]
              },
              {
                "fieldGroupClassName": "display-flex",
                "fieldGroup": [
                  {
                    "key": "empEmail",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                      "messages": {
                        "required": "Employer contact email address is required.",
                        "pattern": "Invalid email address"
                      }
                    },
                    "templateOptions": {
                      "label": "Employer contact email address",
                      "placeholder": "",
                      "description": "",
                      "options": [],
                      "pattern": RegexPattern.email
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                  }
                ]
              },
              {
                "fieldGroupClassName": "display-flex",
                "fieldGroup": [
                  {
                    "key": "address",
                    "type": "postcode",
                    "className": "col-md-12",
                    "validation": {
                      "messages": {

                      }
                    },
                    "templateOptions": {
                      "label": "Address",
                      "placeholder": "",
                      "description": "",
                      "options": []
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": "display-flex",
                    "fieldGroup": [
                      {
                        "fieldGroupClassName": "display-flex",
                        "fieldGroup": [
                          {
                            "key": "postcode",
                            "type": "input",
                            "className": "col-md-6",
                            "validation": {
                              "messages": {

                              }
                            },
                            "templateOptions": {
                              "label": "Postcode",
                              "placeholder": "",
                              "description": "Minimum 4 characters required to get suggestions",
                              "options": []
                            },
                            "fieldArray": null,
                            "fieldGroupClassName": null,
                            "fieldGroup": null
                          }
                        ]
                      },
                      {
                        "fieldGroupClassName": "display-flex",
                        "fieldGroup": [
                          {
                            "key": "addressLine1",
                            "type": "input",
                            "className": "col-md-6",
                            "validation": {
                              "messages": {
                                "minlength": "Minimum length should be 3 characters.",
                                "maxlength": "Maximum length should be 100 characters."
                              }
                            },
                            "templateOptions": {
                              "label": "Address line 1",
                              "placeholder": "",
                              "description": "Minimum 3 and maximum 100 characters",
                              "required": false,
                              "options": [],
                              "minLength": 3,
                              "maxLength": 100
                            },
                            "fieldArray": null,
                            "fieldGroupClassName": null,
                            "fieldGroup": null
                          }
                        ]
                      },
                      {
                        "fieldGroupClassName": "display-flex",
                        "fieldGroup": [
                          {
                            "key": "addressLine2",
                            "type": "input",
                            "className": "col-md-6",
                            "validation": {
                              "messages": {
                                "minlength": "Minimum length should be 3 characters.",
                                "maxlength": "Maximum length should be 100 characters."
                              }
                            },
                            "templateOptions": {
                              "label": "Address line 2",
                              "placeholder": "",
                              "description": "Minimum 3 and maximum 100 characters",
                              "options": [],
                              "minLength": 3,
                              "maxLength": 100
                            },
                            "fieldArray": null,
                            "fieldGroupClassName": null,
                            "fieldGroup": null
                          }
                        ]
                      },
                      {
                        "fieldGroupClassName": "display-flex",
                        "fieldGroup": [
                          {
                            "key": "townCity",
                            "type": "input",
                            "className": "col-md-6",
                            "validation": {
                              "messages": {

                              }
                            },
                            "templateOptions": {
                              "label": "Town/City",
                              "placeholder": "",
                              "description": "",
                              "required": false,
                              "options": [],
                              "disabled": true
                            },
                            "fieldArray": null,
                            "fieldGroupClassName": null,
                            "fieldGroup": null
                          }
                        ]
                      },
                      {
                        "fieldGroupClassName": "display-flex",
                        "fieldGroup": [
                          {
                            "key": "region",
                            "type": "input",
                            "className": "col-md-6",
                            "validation": {
                              "messages": {

                              }
                            },
                            "templateOptions": {
                              "label": "Region",
                              "placeholder": "",
                              "description": "",
                              "required": false,
                              "options": [],
                              "disabled": true
                            },
                            "fieldArray": null,
                            "fieldGroupClassName": null,
                            "fieldGroup": null
                          }
                        ]
                      },
                      {
                        "fieldGroupClassName": "display-flex",
                        "fieldGroup": [
                          {
                            "key": "country",
                            "type": "input",
                            "className": "col-md-6",
                            "validation": {
                              "messages": {

                              }
                            },
                            "templateOptions": {
                              "label": "Country",
                              "placeholder": "",
                              "description": "",
                              "options": [],
                              "disabled": true
                            },
                            "fieldArray": null,
                            "fieldGroupClassName": null,
                            "fieldGroup": null
                          }
                        ]
                      },
                    ]
                  }
                ]
              }
            ]
          },
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },

    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "type": "title",
          "templateOptions": {
            "label": "Employer details"
          }
        },
        {
          "key": "armedForceEmployer",
          "type": "radio",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Recognised armed forces employer? is required."
            }
          },
          "templateOptions": {
            "label": "Recognised armed forces employer?",
            "placeholder": "",
            "description": "",
            "required": true,
            "options": [
              {
                "label": "Yes",
                "value": true
              },
              {
                "label": "No",
                "value": false
              }
            ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null,
          "defaultValue": false
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "armedForcesLevel",
          "type": "select",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Armed forces employer level is required."
            }
          },
          "templateOptions": {
            "label": "Armed forces employer level",
            "placeholder": "",
            "description": "",
            "options": [
              {
                "label": "Gold",
                "value": "Gold"
              },
              {
                "label": "Silver",
                "value": "Silver"
              },
              {
                "label": "Bronze",
                "value": "Bronze"
              }
            ]
          },
          "hideExpression": "!model.armedForceEmployer",
          "expressionProperties": {
            "templateOptions.required": "model.armedForceEmployer === true"
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "disabilityConfident",
          "type": "radio",
          "className": "col-md-6",
          "validation": {
            "messages": {

            }
          },
          "templateOptions": {
            "label": "Disability confident employer",
            "placeholder": "",
            "description": "",
            "required": false,
            "options": [
              {
                "label": "Yes",
                "value": true
              },
              {
                "label": "No",
                "value": false
              }
            ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null,
          "defaultValue": false
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "employerBio",
          "type": "richtext",
          "className": "col-md-12",
          "validation": {
            "messages": {
              "required": "Employer bio is required."
            }
          },
          "templateOptions": {
            "label": "Employer bio",
            "placeholder": "",
            "description": "Minimum 3 characters",
            "required": true,
            "options": [],
            "minLength": 3,
            "attributes": {
              "aria-label": "Employer bio"
              }
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "employerLogo",
          "type": "file",
          "className": "col-md-6",
          "validation": {
            "messages": {

            }
          },
          "templateOptions": {
            "label": "Employer logo*",
            "placeholder": "",
            "description": "Upload image in JPEG/JPG, PNG, GIF, SVG, ICO format",
            "required": true,
            "options": [],
            "allowedTypes": [
              "images"
            ],
            "attributes": {
              "aria-label": "Employer logo"
              }
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "partnerRegStatus",
          "type": "radio",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Partner registration status is required."
            }
          },
          "templateOptions": {
            "label": "Partner registration status",
            "placeholder": "",
            "description": "",
            "required": true,
            "options": [
              {
                "label": "Yes",
                "value": true
              },
              {
                "label": "No",
                "value": false
              }
            ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null,
          "defaultValue": false
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "status",
          "type": "select",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Employer status is required."
            }
          },
          "templateOptions": {
            "label": "Employer status",
            "placeholder": "",
            "description": "",
            "required": true,
            "options": [
              {
                "label": "Active",
                "value": "Active"
              },
              {
                "label": "Pending",
                "value": "Pending"
              },
              {
                "label": "Closed",
                "value": "Closed"
              }
            ]
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "ref_shared_industries",
          "type": "relation",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Industry is required.",
              "minlength": "Minimum length should be 5 characters.",
              "maxlength": "Maximum length should be 500 characters."
            }
          },
          "templateOptions": {
            "label": "Industry",
            "placeholder": "Industry",
            "description": "Minimum 0 and maximum 30 industry.",
            "options": [],
            "api": "industries",
            "relation": "many",
            "mainField": "name",
            "maxQuantity": 30,
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
    {
      "fieldGroupClassName": "display-flex",
      "fieldGroup": [
        {
          "key": "recordOwnerId",
          "type": "external-relation",
          "className": "col-md-6",
          "validation": {
            "messages": {
              "required": "Record owner is required."
            }
          },
          "templateOptions": {
            "label": "Record owner",
            "placeholder": "",
            "description": "Please select a record owner from the list",
            "options": [],
            "api": 'ctp-user/getFilteredUserList',
            "mainField": "fullName",
            "relation": "one",
            "sortField": "fullname",
            "apiName": 'filter-user',
            "required": true,
          },
          "fieldArray": null,
          "fieldGroupClassName": null,
          "fieldGroup": null
        }
      ]
    },
  ];
  identifier: any;
  routeIntent: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  routerPath = '/jobs/employers/view';
  collection = '/employers';
  rowId: any;
  state: any;
  queryParams: any;

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly jobsNavigation: JobsNavigation,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly appConfirmService: AppConfirmService
  ) {
    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.jobsNavigation.jobsSubMenu);
    this.queryParams = { id: this.rowId };
    this.schemaUrl = 'api::employer.employer';
    this.apiUrl = '/employers'
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.queryParams = params;
      if (params.id) {
        this.rowId = params.id;
        this.state = params.state;
        this.routeIntent = "edit";
      } else {
        this.routeIntent = "new"
      }
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  navigateToRoute() {
    this.router.navigate([`jobs/${this.collection}`]);
  }

  ngOnInit(): void {
    this.addFieldHideExpression();
  }
  exitNavigation() {
    if (this.queryParams && this.queryParams.id) {
        this.router.navigate(['/jobs/employers/view'], {
            queryParams: {
                id: this.queryParams.id,
                hideEdit: "Active"
            }
        });
    } else {
        this.router.navigate(['/jobs/employers']);
    }
}
  addFieldHideExpression() {
    this.fields.forEach(fieldGroup => {
      fieldGroup.fieldGroup.forEach(field => {
        if (field.key == 'employerId') {
          if (this.routeIntent == 'edit') {
            field.hideExpression = false;
            field.templateOptions.disabled = true;

          } else {
            field.hideExpression = true;
            field.templateOptions.disabled = false;
          }
        }
      });
    });
  }

  canDeactivate(): Observable<boolean> | boolean {

    if (this.isNotSaved) {
        return this.appConfirmService.confirm(
            {
                title: `Progress not saved`,
                message: 'If you navigate away from this page your progress will not be saved'
            });
    } else {
        return true
    }
}
}
