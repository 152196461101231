<mat-card>
    <mat-card-header class="flex-end border-lefts mb-20">
      <span style="width: 80%;">  <h1 class="page-title"> View workshop delivery</h1></span>
      <span class="spacer"></span>
      <span class="action-buttons">
        <button *ngIf="isAuthorized(188,3) && isActive === 'false'" id="edit_button" mat-stroked-button (click)="routePath()"
          [queryParams]="{id: this.identifier}">
          Edit
        </button>
        <button id="exit_button" mat-stroked-button [routerLink]="['/courses-events/workshopDeliveries']">Exit</button>
      </span>
    </mat-card-header>
    <mat-card-content>
      <app-dynamic-form-view [identifier]="identifier" [routeIntent]="routeIntent" [schemaUrl]="schemaUrl"
        [apiUrl]="apiUrl" [titleInformation]="titleInformation" [collection]="collection"></app-dynamic-form-view>
    </mat-card-content>
  </mat-card>