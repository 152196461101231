<div class="image-viewer">
  <div class="controls">
    <button mat-stroked-button (click)="zoomIn()" aria-label="Example icon button with a menu icon">
      Zoom in<mat-icon>zoom_in</mat-icon>
    </button>
    <button mat-stroked-button (click)="zoomOut()" aria-label="Example icon button with a menu icon">
      Zoom Out<mat-icon>zoom_out</mat-icon>
    </button>
    <button mat-stroked-button (click)="rotateLeft()" aria-label="Example icon button with a menu icon">
      Rotate Left<mat-icon>rotate_left</mat-icon>
    </button>
    <button mat-stroked-button (click)="rotateRight()" aria-label="Example icon button with a menu icon">
      Rotate Right<mat-icon>rotate_right</mat-icon>
    </button>
    <button mat-stroked-button (click)="reset()" aria-label="Example icon button with a menu icon">
      Clear<mat-icon>Reset</mat-icon>
    </button>
  </div>
  <div class="image-wrapper">
    <div class="image-container">
      <img [src]="images" [style.transform]="transform" alt="Image Viewer" />
    </div>
  </div>
</div>