import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mark-attendance-tab',
  templateUrl: './mark-attendance-tab.component.html',
  styleUrls: ['./mark-attendance-tab.component.scss']
})
export class MarkAttendanceTabComponent {

  userName :any;
  userId : any

  constructor(
    private readonly route: ActivatedRoute

  ) {
    this.route.queryParams.subscribe((params: any) => {
      if (params.hasOwnProperty('id')) {
        this.route.queryParams.subscribe((params: any) => {
          this.userId = params.id;
          this.userName = params.name;
        })
      }
    })
  }
  
}
