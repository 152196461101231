<div class="abcd">
  <div>
    <div class="mb-20">
      <label *ngIf="to.label" [style.color]="formControl.invalid && formControl.touched ? 'red' : ''">
        {{ to.label }}
        <span *ngIf="to.required" [style.color]="formControl.invalid && formControl.touched ? 'red' : 'grey'">*</span>
      </label>
      <div class="description" *ngIf="to.description">{{ to.description }}</div>
    </div>
    <quill-editor [formControl]="quillFormControl" [formlyAttributes]="field" [modules]="{ toolbar: toolbarOptions }"
      (onContentChanged)="onContentChanged($event)"
      [ngClass]="{'quill-editor-invalid': !quillFormControl.valid && quillFormControl.touched, 'quill-editor': true }">
    </quill-editor>

  </div>
  <div class="character-count-error-container">
    <div *ngIf="formControl.invalid && formControl.touched" class="error-message" style="float: left">
      <formly-validation-message *ngIf="!isTouched" [field]="field"></formly-validation-message>
      {{ getErrorMessage() }}
    </div>
  </div>
</div>