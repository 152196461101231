import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { ImageUrl } from 'src/app/framework/constants/image--url-constant';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { Utility } from 'src/app/framework/utils/utility';
import { Attendance } from '../../course-delivery/attendance.interface';
import { AttendancePayload } from '../../course-delivery/AttendancePayload.interface';
import { ConfirmDeleteDialogPopupComponent } from '../../course-delivery/confirm-delete-dialog-popup/confirm-delete-dialog-popup.component';
import { CourseManagementNavigation } from '../../course-management-nav';
import { CourseManagementService } from '../../course-management.service';

@Component({
  selector: 'app-workshop-delivery-mark-attendance',
  templateUrl: './workshop-delivery-mark-attendance.component.html',
  styleUrls: ['./workshop-delivery-mark-attendance.component.scss']
})
export class WorkshopDeliveryMarkAttendanceComponent {
  fileFormGroup: FormGroup;
  displayedColumns: string[] = ['serviceLeaverName', 'attended', 'ReasonForNotAttended'];
  dataSource = new MatTableDataSource<Attendance>([]);
  paylode: AttendancePayload = { attendance: [] };
  passedFailed: any[] = [];
  reasonNotAttended: any[] = [];
  attendedStatus: any[] = [];
  identifier: any;
  bookingData: any;
  attendancebookingData: any;
  holeNewData: any[] = [];
  hasNonNullId: Attendance[];
  isInvalid: boolean = false; 
  attendanceMap = new Map<string, any>();
  hasNullId: Attendance[];
  passedFailedVisibal: boolean=false;
  logoName: any;
  fileData: unknown;
  fileCtrl: FormControl;
  fileNameMaxLength: number = 180; 
  allIdsAreNull: boolean;
  courseName: any;
  selectedFile: File | null = null;
  file: any;
  filePath: any;
  fileName: any;
  isFilenamePresent: boolean;
  showBrowsebutton: boolean=false;
  disabledSaveButton: boolean=false;
  saveEnabled: boolean=false;

  fileTypes: string[] = [
    'application/pdf', // .pdf
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12', // .pps
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/rtf', // .rtf
    'text/plain', // .txt
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/x-mspublisher', // .pub
    'image/jpeg', // .jpg and .jpeg
    'image/png', // .png
    'application/vnd.ms-publisher', // .pub
    'text/csv' // .csv
  ];
  workshopDelivryBookingData: any;
  

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly courseManagementService: CourseManagementService,
    private readonly router: Router,
    private readonly snackBarService: SnackBarService,
    private readonly fb: FormBuilder,
    public dialog: MatDialog
  ) {
    this.courseManagementService.getBookingRefDataForReedCtp().subscribe();

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.courseName=params.courseName;
    });

    if (this.identifier) {
      this.resolveBooking();
    }
  }

  ngOnInit(): void {
    this.inatilizeFrom();
    this.AttendanceRefData();
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }

  inatilizeFrom(){
    this.fileCtrl = new FormControl(null, []);
    this.fileFormGroup = this.fb.group({
      file: ['', Validators.required]
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  AttendanceRefData() {
    this.reasonNotAttended = Utility.filterMapByKey("Reason_Not_Attended", "refData_booking");
    this.attendedStatus = Utility.filterMapByKey("Attended_Status", "refData_booking");
    this.passedFailed = Utility.filterMapByKey("Passed_Failed", "refData_booking");
  }

  resolveBooking() {
    forkJoin({
      bookingData: this.courseManagementService.getWorkshopAttendanceDetails(this.identifier),
      allBookingData: this.courseManagementService.getWorkshopAllAttendanceBookingDetails(this.identifier)
    }).subscribe(results => {
      this.bookingData = results.bookingData;
      this.workshopDelivryBookingData=results.allBookingData || [];
      this.attendancebookingData = results.allBookingData;
      if(this.attendancebookingData.length>0){
        this.showBrowsebutton=true;
      }
      this.holeNewData = []; 
      this.attendanceMap = new Map<string, any>(); 
      if (this.bookingData && this.attendancebookingData) {
        this.attendancebookingData.forEach(item => {
          this.attendanceMap.set(item.bookingReference + '-' + item.serviceUserId, item);
        });
        this.bookingData.forEach(element => {
          this.logoName=element.fileName;
          this.filePath=element.filePath;
          const key = element.bookingReferenceId + '-' + element.serviceUserId;
          if (this.attendanceMap.has(key)) {
            const matchingElement = this.attendanceMap.get(key);
            const newDataItem = {
              attendanceId: element.attendanceId,
              bookingReferenceId: element.bookingReferenceId,
              notAttended: element.notAttended,
              passedFailed: element.passedFailed,
              serviceUserId: element.serviceUserId,
              attendedStatus: element.attendedStatus,
              serviceUserName: matchingElement.serviceUserName,
              modified: null,
              invalid: false,
              passedFailedVisibal: element.attendedStatus === 40 || element.attendedStatus==null ,
              ReasonVisibal: element.attendedStatus === 39 || element.attendedStatus === 38 || element.attendedStatus==null,
              fileName:element.fileName,
              filePath:element.filePath
            };
            this.holeNewData.push(newDataItem);
            this.attendanceMap.delete(key);
          }
        });
        this.attendanceMap.forEach((element3) => {
          this.holeNewData.push({
            attendanceId: null,
            bookingReferenceId: element3.bookingReference,
            notAttended: null,
            passedFailed: null,
            serviceUserId: element3.serviceUserId,
            attendedStatus: null,
            serviceUserName: element3.serviceUserName,
            modified: null,
            invalid: false,
            passedFailedVisibal: true,
            ReasonVisibal: true,
            fileName:null,
            filePath:null
          });
        });
      }
      if(this.attendancebookingData.length>0){
        this.dataSource.data = this.holeNewData;
      }
      const modifiedRows = this.dataSource.data.filter(row => row.modified);
      const allIdsAreNotNull = this.dataSource.data.every(row => row.attendanceId != null);
      if(modifiedRows.length==0 && allIdsAreNotNull){
        this.disabledSaveButton=false;
        this.saveEnabled=true;
      }
    });
  }
  
  validateRows(): boolean {
    let isValid = true;
    this.dataSource.data.forEach((row, index) => {
      if (row.attendedStatus === 40 && row.notAttended==null) {
        this.holeNewData[index] = {
          ...this.holeNewData[index],
          invalid: true
        };
        isValid = false;
      }
    });
    this.dataSource.data = [...this.holeNewData];
    return isValid;
  }
  
  onSave() {
    const isValid = this.validateRows();
    if (isValid && this.fileCtrl.errors==null) {
      const modifiedRows = this.dataSource.data.filter(row => row.modified);
      const allIdsAreNull = this.dataSource.data.every(row => row.attendanceId === null);
      if (allIdsAreNull ) {
        this.hasNullId = this.dataSource.data.filter(row => row.attendanceId == null);
      }if(!allIdsAreNull && modifiedRows.length==0){
        this.hasNonNullId = this.dataSource.data.filter(row => row.attendanceId != null);
      }
      this.paylode.courseDeliveryId = this.identifier;
      if (allIdsAreNull) {
        this.paylode.attendance = this.hasNullId.map(this.mapToPayload);
      }else if(!allIdsAreNull && modifiedRows.length==0){
        this.paylode.attendance=this.hasNonNullId.map(this.mapToPayload);
      } else {
        this.paylode.attendance = modifiedRows.map(this.mapToPayload);
      }
      const formData = new FormData();
      formData.append('file', this.file);
      const payloadJson = JSON.stringify(this.paylode);
      formData.append('payload', payloadJson);
      if (!allIdsAreNull) {
        this.courseManagementService.updateWorkshopAttendancDetails(formData,'POST').then((response: any)=> {
          const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;
          if (parsedResponse && parsedResponse.message && parsedResponse.message.applicationMessage) {
            this.snackBarService.success(parsedResponse.message.applicationMessage);
          }
               this.router.navigate([`courses-events/workshopDeliveries`], { queryParamsHandling: 'merge', queryParams: {  }});
          },
          error => {
          }
        );
      } else {
        this.courseManagementService.saveNewWorkshopAttendancDetails(formData,'POST').then((response: any)=> {
                this.resolveBooking()
                this.router.navigate([`courses-events/workshopDeliveries`], { queryParamsHandling: 'merge', queryParams: {  }});
          const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;
          if (parsedResponse && parsedResponse.message && parsedResponse.message.applicationMessage) {
            this.snackBarService.success(parsedResponse.message.applicationMessage);
          }
          },
          error => {
          }
        );
      }
    } else {
      this.isInvalid = true;
    }
  }


  markAsModified(element) {
    const index = this.dataSource.data.findIndex(el => el.bookingReferenceId === element?.bookingReferenceId);
    if (index !== -1) {
      this.holeNewData[index].modified = true;
      this.saveEnabled=true;
    }
    this.dataSource.data = [...this.holeNewData]; 
  }
  
  isHintVisible(element): boolean {
    return element.attendedStatus === 40 && element.notAttended == null && element.invalid;
  }

  AttendanceChange(event: any, index: number) {
    if (event) {
      if(event.value==null){
        this.holeNewData[index].ReasonVisibal=false;
        this.holeNewData[index].passedFailedVisibal=false;
      }else if (event.value != 40){
        this.holeNewData[index].invalid = false;
        this.holeNewData[index].passedFailedVisibal=false
        this.holeNewData[index].notAttended = null;
        this.holeNewData[index].ReasonVisibal=true;
      } else if (event.value == 40) {
        this.holeNewData[index].invalid = true;
        this.holeNewData[index].ReasonVisibal=false;
        this.holeNewData[index].passedFailed = null;
        this.holeNewData[index].passedFailedVisibal=true;
      }
      this.dataSource.data = [...this.holeNewData];
      this.disabled();
    }
    
  }

  descriptionChange(event: any, index: number){
    if(event.value){
      this.holeNewData[index].invalid = false;
    }
    this.dataSource.data = [...this.holeNewData];
    this.disabled();
  }
  
  navigateToRoute(){
    this.router.navigate([`courses-events/workshopDeliveries`], { queryParamsHandling: 'merge', queryParams: {  } });
  }

  Visible(element){
    return element.attendedStatus === 40
  }

  private mapToPayload(row: any): any {
    return {
      bookingReferenceId: row.bookingReferenceId,
      serviceUserId: row.serviceUserId,
      attendedStatus: row.attendedStatus,
      notAttended: row.notAttended,
      passedFailed: row.passedFailed,
      attendanceId: row.attendanceId,
      courseDeliveryId: row.courseDeliveryId
    };
  }

  selectFile(event: any): void {
    this.file = event.target.files[0];
    this.fileName=this.file.name;
    this.fileCtrl.setErrors(null); // Reset previous errors
    const uploadfileName: string = this.file?.name || null;
   if (this.file) {
     if (!this.validFileType(this.file)) {
       this.fileCtrl.setErrors({ fileType: true });
       this.fileName = null;
     } else if (this.file.size > 16 * 1024 * 1024) { // 16MB in bytes
       this.fileCtrl.setErrors({ fileSize: true });
       this.fileName = null;
     }else if(uploadfileName.length > this.fileNameMaxLength){
       this.fileCtrl.setErrors({ filelength: true });
       this.fileName = null;
     } else {
       this.selectedFile = this.file;
       this.fileName = this.file.name;
       this.isFilenamePresent = true;
       this.fileCtrl.setValue(this.selectedFile);
     }
   } else {
     this.fileName = null;
   }
 }

  validFileType(file: File): boolean {
    return this.fileTypes.includes(file.type);
  }

  downloadFile(element): void {
    const blobPath = { 'path': this.filePath}
    this.courseManagementService.downloadAttendance(blobPath,this.identifier).subscribe((res:any) => {
      const safeUrl = res.path;
      const a = document.createElement("a");
      a.href = safeUrl as string;
      a.download = "";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
      (error) => {
        this.snackBarService.error(error.error.applicationMessage);
        console.error("Error downloading the file.", error);
      });
  }

  formUrl(url: string): string {
    const cdnUrl = `${ImageUrl.LOGO_IMAGE}${url}`;
    return cdnUrl;
  }

  cancelUpload(){
    const dialogRef = this.dialog.open(ConfirmDeleteDialogPopupComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
         this.deleteFile(this.identifier);
      }
    });
  }

  deleteFile(identifier:any): void {
    this.courseManagementService.deleteWorkshopAttendanceFile(identifier).subscribe(responce=>{
      if (responce ) {
        this.resolveBooking();
      }
      this.snackBarService.success(responce.message.applicationMessage);
    })
  }

  disabled(){
    const allinvalidTrue = this.dataSource.data.filter(row => row.invalid === true);
    if(allinvalidTrue.length>0){
      this.disabledSaveButton=true;
      this.saveEnabled=false;
    }else{
      this.disabledSaveButton=false;
    }
  }

  addButtonDisabled(): boolean {
    return this.fileCtrl.valid;
  }

  removeFile(fileInput: HTMLInputElement): void {
    this.fileName = '';
    this.file = undefined;
    this.fileFormGroup.get('file').reset();
    if (fileInput) {
      fileInput.value = '';
    }
  }

}
