import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { DynamicFormService } from "../dynamic-form/dynamic-form.service";
import { interval, Subscription } from "rxjs";



@Component({
  selector: 'app-region-type',
  templateUrl: './region-type.component.html',
  styleUrls: ['./region-type.component.scss']
})
export class RegionTypeComponent extends FieldType implements OnInit {

  searchControl: FormControl = new FormControl();
  resultList: any;
  newList: any;
  valueWithUnderscore: any;
  noValidSelection: boolean;
  suggestList: any;
  offset = 0;
  totalResults: any;
  selectedOption: any;
  selectedIndex: any;
  noListSelection: boolean;
  sendCountryNames: any[];
  private subscription: Subscription;
  locations;

  constructor(
    private readonly dynamicFormService: DynamicFormService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.subscription = interval(1000).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.waitForModel().then(() => {
      this.checkForSpecificStrings()
      this.searchControl.setValue(this.formControl.value)
      this.suggestOption({"target":{"value":""}})
    });
    this.searchControl.valueChanges.subscribe(() => {
      if (this.to['required']) {
        this.formControl?.setErrors({ required: true });
      }
    });
  }

  waitForModel(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkModel = () => {
        if (this.formControl?.value) {
          resolve();
        } else {
          setTimeout(checkModel, 100);
        }
      };
       checkModel();
    });
  }

  clearInput() {
    this.searchControl.setValue('');
    this.resultList = [];
    if (this.to['required']) {
      this.formControl?.setErrors({ required: true });
    }
  }

  onOptionSelected(event: any): void {
    if (event.option != null) {
      this.selectedOption = event.option.value;
      if (this.selectedOption) {
        this.noValidSelection = false;
        this.searchControl.setErrors(null);
        this.selectedOption = event.option.value;
      } else {
        this.noValidSelection = true;
        this.searchControl.setErrors({ incorrect: true });
        this.formControl?.setErrors({ incorrect: true });
      }
    } else {
      this.selectedOption = event;
    }
    this.selectedIndex = event.source.options.toArray().indexOf(event.option);
    const selectedDetails = this.newList[this.selectedIndex];
    if (selectedDetails.level2) {
      this.searchControl.setValue(selectedDetails.level2);

    } else {
      this.searchControl.setValue("N/A");
    }
    this.formControl.setValue(this.searchControl.value);
    this.searchControl.setErrors(null);
    this.model['region'] = this.searchControl.value
  }

  suggestOption(event) {
    let limit = 100;
    const whereValue = event.target.value;
    if (whereValue.startsWith(" ")) {
      this.valueWithUnderscore = whereValue.replace(" ", "");
    } else {
      this.valueWithUnderscore = whereValue;
    }
    this.searchControl.valueChanges.subscribe(() => {
      this.offset = 0;
      this.suggestList = [];
      this.noValidSelection = false;

      if (this.searchControl?.value?.length == 0) {
        if (this.to['required']) {
          this.formControl?.setErrors({ required: true });
        }
      }
    });

    this.sendCountryNames = []
    this.model['ref_news_locations']?.forEach(data => {
      if(this.setCountrySwitch(data)){
        this.sendCountryNames.push(this.setCountrySwitch(data))
      }
    })

    let payload = {
      "keyword": this.valueWithUnderscore,
      "countries": this.sendCountryNames
    }
    this.dynamicFormService.getRegionList(this.to['api'], payload).subscribe((response) => {
      this.suggestList = this.suggestList || [];
      this.suggestList = response
      this.setDropDownData();
    })
  }

  setDropDownData() {
    if (this.valueWithUnderscore.match(/^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/i)) {
      this.suggestList = this.suggestList;
    } else {
      if (this.suggestList.length == 0) {
        this.noValidSelection = true;
        this.searchControl.setErrors({ incorrect: true });
      } else {
        this.noValidSelection = false;
        this.searchControl.setErrors(null);
      }
    }
    this.resultList = this.suggestList.map(item => item.level1 + ' - ' + item.level2);
    this.newList = this.suggestList;
    const myArray = Array.from(this.resultList);
    if (myArray.length == 0) {
      this.noValidSelection = true;
      this.searchControl.setErrors({ incorrect: true });
    } else {
      this.noValidSelection = false;
      this.searchControl.setErrors(null);
    }
    if (this.searchControl.value && this.searchControl.value.trim() !== '') {
      this.noListSelection = true;
      // this.searchControl.setErrors({ 'noListSelection': true });
      // this.formControl?.setErrors({ 'noListSelection': true });
    } else {
      this.noValidSelection = false;
      this.searchControl.setErrors(null);
      this.formControl?.setErrors(null);
    }
  }

  onEnterKeyPressed(event: Event) {
    event.preventDefault();
  }

  setCountrySwitch(id) {
    switch (id + "") {
      case "2":
        return "England";

      case "3":
        return "Wales";

      case "4":
        return "Scotland";

      case "5":
        return "Northern Ireland";

      default:
        break;
    }
  }

  checkForSpecificStrings(): boolean {
    if(!this.model['ref_news_locations']?.data){
    const targetStrings = ["2", "3", "4", "5"];
    this.locations = this.model['ref_news_locations']?.map(location => String(location));
    const currentLocations = this.locations?.map(data => {
      return this.setCountrySwitch(data)
    }
    ).filter(data => data !== undefined);
    let showFlag: boolean = targetStrings?.some(target => this.locations?.includes(target))
    
    let currentEntityCountry = this.newList?.find(data => data.level2 === this.model['region']);

    if (!showFlag || (currentEntityCountry?.level1 && !currentLocations?.includes(currentEntityCountry.level1))) {
      this.clearInput()
    }
    return showFlag;
  }
}
}

