import { SessionsService } from '../sessions.service';
import { SnackBarService } from '../../framework/service/snack-bar.service';
import { AuthenticationResponse, IUserMessage, OtpAuthType } from './signin.interface';
import { AppConfirmService } from '../../framework/components/app-confirm/app-confirm.service';
import { AppInsightsService } from '../../framework/service/app-insights.service';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { MsalService } from '@azure/msal-angular';
import { AuthError, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';


const kwMsalConfig = {
    auth: {
      clientId: environment.clientId,
      authority: environment.issuer,
      redirectUri: environment.redirectUri
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  };
@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
    providers: [SessionsService],
})

export class SigninComponent implements OnInit {

    signinForm: FormGroup;
    signinError: IUserMessage;
    @ViewChild(MatButton, { static: false }) submitButton: MatButton;
    private isMsalInitialized: boolean = false;
    private app: PublicClientApplication;

    authResponse: AuthenticationResponse = {};
    OtpAuthType = OtpAuthType;
   
    constructor(
        private readonly sessionsService: SessionsService,
        private readonly builder: FormBuilder,
        private readonly snackBar: SnackBarService,
        private readonly appConfirmService: AppConfirmService,
        private readonly appInsightsService: AppInsightsService,
        private authService: MsalService,
        private router: Router
    ) {
        const config = this.getMsalConfigBasedOnApp();
        this.app = new PublicClientApplication(config);
        
    }

    ngOnInit() {
        this.initForm();
    }

    signin() {
        this.appInsightsService.logEvent('KW Login', { username: this.signinForm.get('userName').value });
        this.sessionsService.getClientDetails().subscribe(data => {
            if (data.id) {
                localStorage.setItem('clientId', data.id);
                localStorage.setItem('logoPath', data.logoPath);
                localStorage.setItem('ApplicationID', data.appId);
                localStorage.setItem('suAppId', data.serviceUserAppId);
                localStorage.setItem('landingPage', data.landingPageUrl);
                localStorage.setItem('primaryAppColour', data.primaryAppColour);
                localStorage.setItem('secondaryAppColour', data.secondaryAppColour)
                document.documentElement.style.setProperty('--primary-color', data.primaryAppColour);
                document.documentElement.style.setProperty('--secondary-color', data.secondaryAppColour);
                this.signinService();
            }
        });
    }

    signinService() {
        this.signinForm.controls['pass'].setValue(btoa(this.signinForm.controls['pass'].value));
        this.sessionsService.signin(this.signinForm.value)
            .subscribe((data: HttpResponse<any>) => {
                
                if (data.body.mfaEnabled) {
                    localStorage.removeItem('token');
                     this.authResponse = data.body;
                    localStorage.setItem('otpDuration', this.authResponse.otpDuration);
                    localStorage.setItem('otpExpirationTime', this.authResponse.otpExpirationTime);
                    this.router.navigate(['/signin/otp'])
                } else {
                    if (data.body.landingPageUrl != null) {
                        localStorage.setItem('landingPage', data.body.landingPageUrl);
                        this.router.navigate([ data.body.landingPageUrl]);
                    } else {
                        this.router.navigate([localStorage.getItem('landingPage')]);
                    }
                    if (data.body.isPasswordAboutToExpire) {
                        this.changePassword(data)
                    }
                }
              },
                (error: any) => {
                    this.snackBar.error(error.error.applicationMessage || error.message);
                    this.signinForm.controls['pass'].setValue(atob(this.signinForm.controls['pass'].value));
                    if (error.error.applicationMessageCode === 'U4006') {
                        localStorage.setItem('username', this.signinForm.controls['userName'].value);
                        this.router.navigate(['sessions/change-expired-password']);
                    }
                }
            );
    }

    initForm() {
        this.signinForm = this.builder.group({
            userName: ['', Validators.required],
            pass: ['', Validators.required],
        });
    }
    forgotPassword() {
        this.router.navigate(['/sessions/forgot-password']);
    }


    async loginWithAzureAd() {
        
        if (!this.isMsalInitialized) {
            await this.initializeMsal();
        }
        try {
            const loginResponse = await this.app.loginPopup({
                scopes: ['openid', 'profile', 'User.Read'],
            });
            this.checkAndSetActiveAccount();
            if (loginResponse && loginResponse.account) {
                this.loginWithSequation(loginResponse.accessToken);
            }
        } catch (error) {
            if (error instanceof AuthError) {
                console.error('Authentication error:', error.errorMessage);
            } else {
                console.error('Unexpected error during login:', error);
            }
        }
    }


    private async initializeMsal(): Promise<void> {
        try {
            await this.app.initialize();
            await this.app.handleRedirectPromise();
            this.isMsalInitialized = true;
        } catch (error) {
            console.error('MSAL initialization error:', error);
        }
    }

    checkAndSetActiveAccount() {
        let activeAccount = this.authService.instance.getActiveAccount();
        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }
      


    loginWithSequation(azToken) {
        this.setClientDetails(azToken);
    }


    getMsalConfigBasedOnApp() {
        return kwMsalConfig;
    }

    setClientDetails(azToken) {
        this.sessionsService.getClientDetails().subscribe(data => {
            if (data.id) {
                localStorage.setItem('clientId', data.id);
                localStorage.setItem('logoPath', data.logoPath);
                localStorage.setItem('ApplicationID', data.appId);
                localStorage.setItem('suAppId', data.serviceUserAppId);
                localStorage.setItem('landingPage', data.landingPageUrl);
                localStorage.setItem('primaryAppColour', data.primaryAppColour);
                localStorage.setItem('secondaryAppColour', data.secondaryAppColour)
                localStorage.setItem('identifier', data.identifier);
                document.documentElement.style.setProperty('--primary-color', data.primaryAppColour);
                document.documentElement.style.setProperty('--secondary-color', data.secondaryAppColour);
                const payload = {
                    "appId": data.appId, "clientId": data.id, "token": azToken,
                    "authStrategy": "SINGLE_SIGN_ON", "clientIdentifier": data.identifier
                };
                this.sessionsService.ssoLogin(payload).subscribe(data => {
                    if (data.body.landingPageUrl != null) {
                        localStorage.setItem('landingPage', data.body.landingPageUrl);
                        this.router.navigate([data.body.landingPageUrl]);
                    } else {
                        this.router.navigate([localStorage.getItem('landingPage')]);
                    }
                },
                    (error: any) => {
                        const err = error.error.errorMessage || error.message;
                        this.snackBar.error(error.error.applicationMessage || error.message);
                        this.signinForm.controls['password'].setValue(atob(this.signinForm.controls['password'].value));
                        if (error.error.applicationMessageCode === 'U4006') {
                            localStorage.setItem('username', this.signinForm.controls['userName'].value);
                            this.router.navigate(['sessions/change-expired-password']);
                        }
                    }
                )
            }
        });
    }


    
    changePassword(data: any) {
        this.sessionsService.setPasswordAboutToExpireFlag(true)
        const numberOfdaysLeft = data.body.passwordAboutToExpireDays;
        const dialogRef = this.appConfirmService.confirm({
            title: `Please change your password`,
            message: `Your password will expire in ${numberOfdaysLeft} day/s.
      Select \'OK\' to change your password now or \'Cancel\' to change it later.`,
        });
        dialogRef.subscribe(result => {
            if (result) {
                this.router.navigateByUrl('/user-setting/change-password');
            }
        });
    }

 
}

