<div class="container">
  <mat-dialog-content>
    <h2 class="title">Delivery Clash</h2>
    <p *ngIf="parentLocation">
      If you wish to proceed with the delivery, select Proceed, if you wish to change this delivery, please select Cancel.
    </p>
    <ng-container *ngIf="!parentLocation">
      <p>There is a delivery in this room at this time. Please select a different time or different room.</p>
      <p>The following deliveries are clashing:</p>
      <div class="delivery-item" *ngFor="let element of data.error?.error?.body?.error?.details.courseConflicts">
        <a [href]="'/courses-events/courseDeliveries/view?id=' + element.id + '&hideEdit=false'" target="_blank">
          {{element.identifier}} - {{ element.course}}</a>
      </div>
      <div class="delivery-item" *ngFor="let element of data.error?.error?.body?.error?.details.eventConflicts">
        <a [href]="'/courses-events/eventDeliveries/view?id=' + element.id + '&hideEdit=false'" target="_blank">
          {{element.identifier}} - {{ element.event}}</a>
      </div>
      <div class="delivery-item" *ngFor="let element of data.error?.error?.body?.error?.details.workshopConflicts">
        <a [href]="'/courses-events/workshopDeliveries/view?id=' + element.id + '&hideEdit=false'" target="_blank">
          {{element.identifier}} - {{ element.workshop}}</a>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions class="action-buttons">
    <button class="btn-ok" *ngIf="!parentLocation" mat-dialog-close mat-stroked-button type="button">OK</button>
    <button class="btn-proceed" *ngIf="parentLocation" color="primary" mat-raised-button type="button" (click)="onSubmit()">Proceed</button>
    <button *ngIf="parentLocation" mat-dialog-close mat-stroked-button type="button">Cancel</button>
  </mat-dialog-actions>
</div>
