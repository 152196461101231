import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CourseProviderComponent } from "./course-provider/course-provider.component";
import { CourseLocationComponent } from "./course-location/course-location.component";
import { AddCourseLocationComponent } from "./course-location/add-course-location/add-course-location.component";
import { ViewCourseLocaionComponent } from "./course-location/view-course-locaion/view-course-locaion.component";
import { AddEditCourseProviderComponent } from "./course-provider/add-edit-course-provider/add-edit-course-provider.component";
import { ViewCourseProviderComponent } from "./course-provider/view-course-provider/view-course-provider.component";
import { CourseComponent } from "./course/course.component";
import { AddEditCourseComponent } from "./course/add-edit-course/add-edit-course.component";
import { ViewCourseComponent } from "./course/view-course/view-course.component";
import { EventDetailsComponent } from "./event-details/event-details.component";
import { AddEditEventDetailsComponent } from "./event-details/add-edit-event-details/add-edit-event-details.component";
import { ViewEventDetailsComponent } from "./event-details/view-event-details/view-event-details.component";
import { AddEditCourseDeliveryComponent } from "./course-delivery/add-edit-course-delivery/add-edit-course-delivery.component";
import { ViewCourseDeliveryComponent } from "./course-delivery/view-course-delivery/view-course-delivery.component";
import { CourseDeliveryComponent } from "./course-delivery/course-delivery.component";
import { EventDeliveryComponent } from "./event-delivery/event-delivery.component";
import { AddEditEventDeliveryComponent } from "./event-delivery/add-edit-event-delivery/add-edit-event-delivery.component";
import { ViewEventDeliveryComponent } from "./event-delivery/view-event-delivery/view-event-delivery.component";
import { CourseDeliveryTabComponent } from "./course-delivery/course-delivery-tab/course-delivery-tab.component";
import { RequestBookingComponent } from "./course-delivery/request-booking/request-booking.component";
import { ConfirmBookingComponent } from "./course-delivery/confirm-booking/confirm-booking.component";
import { ViewBookingDetailsComponent } from "./course-delivery/view-booking-details/view-booking-details.component";
import { MarkAttendanceCorceDeliveryComponent } from "./course-delivery/mark-attendance-corce-delivery/mark-attendance-corce-delivery.component";
import { MarkAttendanceTabComponent } from "./course-delivery/mark-attendance-tab/mark-attendance-tab.component";
import { WorkshopComponent } from "./workshop/workshop.component";
import { WorkshopDeliveryComponent } from "./workshop-delivery/workshop-delivery.component";
import { AddEditWorkshopComponent } from "./workshop/add-edit-workshop/add-edit-workshop.component";
import { ViewWorkshopComponent } from "./workshop/view-workshop/view-workshop.component";
import { AddEditWorkshopDeliveryComponent } from "./workshop-delivery/add-edit-workshop-delivery/add-edit-workshop-delivery.component";
import { ViewWorkshopDeliveryComponent } from "./workshop-delivery/view-workshop-delivery/view-workshop-delivery.component";
import { EventDeliveryTabComponent } from "./event-delivery/event-delivery-tab/event-delivery-tab.component";
import { EventConfirmBookingComponent } from "./event-delivery/event-confirm-booking/event-confirm-booking.component";
import { ViewEventBookingDetailsComponent } from "./event-delivery/view-event-booking-details/view-event-booking-details.component";
import { EventDeliveryMarkAttendanceComponent } from "./event-delivery/event-delivery-mark-attendance/event-delivery-mark-attendance.component";
import { WorkshopDeliveryTabComponent } from "./workshop-delivery/workshop-delivery-tab/workshop-delivery-tab.component";
import { WorkshopConfirmBookingComponent } from "./workshop-delivery/workshop-confirm-booking/workshop-confirm-booking.component";
import { ViewWorkshopBookingDetailsComponent } from "./workshop-delivery/view-workshop-booking-details/view-workshop-booking-details.component";
import { WorkshopDeliveryMarkAttendanceComponent } from "./workshop-delivery/workshop-delivery-mark-attendance/workshop-delivery-mark-attendance.component";
import { WorkshopRequestBookingComponent } from "./workshop-delivery/workshop-request-booking/workshop-request-booking.component";
import { ViewValidatorBookingDetailsComponent } from "./course-delivery/view-validator-booking-details/view-validator-booking-details.component";
import { BookingRefundComponent } from "./course-delivery/view-booking-details/booking-refund/booking-refund.component";
import { WaitingListComponent } from "./course-delivery/waiting-list/waiting-list.component";
import { ManullyAssignCourseComponent } from "./course-delivery/manully-assign-course/manully-assign-course.component";
import { CanDeactivateGuard } from "src/app/framework/guards/can-deactivate/can-deactivate.guard";
import { ManullyAssignWorkshopComponent } from "./workshop-delivery/manully-assign-workshop/manully-assign-workshop.component";
import { ManualAssignEventComponent } from "./event-delivery/manual-assign-event/manual-assign-event.component";


const routes: Routes = [
  {
    path: '',
    data: { title: 'Providers'},
    redirectTo: 'providers',
    pathMatch: 'full'
  },
  {
    path: 'providers',
    component: CourseProviderComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'providers/new',
    component: AddEditCourseProviderComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'providers/edit',
    component: AddEditCourseProviderComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'providers/view',
    component: ViewCourseProviderComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courseLocations',
    component: CourseLocationComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courseLocations/new',
    component: AddCourseLocationComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courseLocations/view',
    component: ViewCourseLocaionComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courseLocations/edit',
    component: AddCourseLocationComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courses',
    component: CourseComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courses/new',
    component: AddEditCourseComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courses/edit',
    component: AddEditCourseComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courses/view',
    component: ViewCourseComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'events',
    component: EventDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'events/new',
    component: AddEditEventDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'events/edit',
    component: AddEditEventDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'events/view',
    component: ViewEventDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courseDeliveries',
    component: CourseDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courseDeliveries/new',
    component: AddEditCourseDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courseDeliveries/edit',
    component: AddEditCourseDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'courseDeliveries/mark-attendance',
    component: MarkAttendanceTabComponent,
    canDeactivate: [CanDeactivateGuard],
     children: [
      {
        path: '',
        redirectTo: 'Attendance',
        pathMatch: 'full',
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "Attendance",
        component: MarkAttendanceCorceDeliveryComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ]
  },
  {
    path: 'courseDeliveries/view',
    component: CourseDeliveryTabComponent,
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: '',
        redirectTo: 'details',
        pathMatch: 'full',
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "details",
        component: ViewCourseDeliveryComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "booking-request",
        component: RequestBookingComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "confirm-booking",
        component: ConfirmBookingComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "waiting-list",
        component: WaitingListComponent,
        canDeactivate: [CanDeactivateGuard],
      }
    ]
  },
  {
    path: 'courseDeliveries/view/booking-details',
    component: ViewBookingDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: '', auth: [140] }
  },
  {
    path: 'courseDeliveries/view/booking-refund',
    component: BookingRefundComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: '', auth: [189] }
  },{
    path: 'courseDeliveries/view/validatior-booking-details',
    component: ViewValidatorBookingDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: '', auth: [190] }
  },
  {
    path: 'courseDeliveries/view/booking-refund',
    component: BookingRefundComponent,
    data: { title: '', auth: [189] }
  },
  {
    path: 'courseDeliveries/assign-course',
    component: ManullyAssignCourseComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'eventDeliveries',
    component: EventDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'eventDeliveries/assign-event',
    component: ManualAssignEventComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'eventDeliveries/new',
    component: AddEditEventDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'eventDeliveries/edit',
    component: AddEditEventDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'eventDeliveries/view',
    component: EventDeliveryTabComponent,
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: '',
        redirectTo: 'event-delivery',
        pathMatch: 'full',
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "event-delivery",
        component: ViewEventDeliveryComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "confirm-booking",
        component: EventConfirmBookingComponent,
        canDeactivate: [CanDeactivateGuard],
      }
    ]
  },
  
  {
    path: 'workshopDeliveries/view',
    component: WorkshopDeliveryTabComponent,
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: '',
        redirectTo: 'workshop-delivery',
        pathMatch: 'full',
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "workshop-delivery",
        component: ViewWorkshopDeliveryComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "confirm-booking",
        component: WorkshopConfirmBookingComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "booking-request",
        component: WorkshopRequestBookingComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ]
  },
  {
    path: 'eventDeliveries/view/event-booking-details',
    component: ViewEventBookingDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshops',
    component: WorkshopComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshops/new',
    component: AddEditWorkshopComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshops/edit',
    component: AddEditWorkshopComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshops/view',
    component: ViewWorkshopComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshopDeliveries',
    component: WorkshopDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshopDeliveries/new',
    component: AddEditWorkshopDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshopDeliveries/edit',
    component: AddEditWorkshopDeliveryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshopDeliveries/view',
    component: WorkshopDeliveryTabComponent,
    children: [
      {
        path: '',
        redirectTo: 'workshop-delivery',
        pathMatch: 'full'
      },
      {
        path: "workshop-delivery",
        component: ViewWorkshopDeliveryComponent,
      },
      {
        path: "confirm-booking",
        component: WorkshopConfirmBookingComponent,
      }
    ]
  },
  {
    path: 'workshopDeliveries/view/workshop-booking-details',
    component: ViewWorkshopBookingDetailsComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'workshopDeliveries/workshop-deliveries-mark-attendance',
    component: MarkAttendanceTabComponent,
    canDeactivate: [CanDeactivateGuard],
     children: [
      {
        path: '',
        redirectTo: 'Attendance',
        pathMatch: 'full',
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "Attendance",
        component: WorkshopDeliveryMarkAttendanceComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ]
  },
  {
    path: 'eventDeliveries/event-Deliveries-mark-attendance',
    component: MarkAttendanceTabComponent,
    canDeactivate: [CanDeactivateGuard],
     children: [
      {
        path: '',
        redirectTo: 'Attendance',
        pathMatch: 'full',
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "Attendance",
        component: EventDeliveryMarkAttendanceComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ]
  },
  {
    path: 'workshopDeliveries/assign-workshop',
    component: ManullyAssignWorkshopComponent,
    canDeactivate: [CanDeactivateGuard],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CourseManagementRoutingModule { }
