import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { DynamicFormService } from './dynamic-form.service';
import { BaseUrl } from '../../../framework/constants/url-constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Utility } from 'src/app/framework/utils/utility';
import { SnackBarService } from '../../../framework/service/snack-bar.service';
import { SpinnerVisibilityService } from 'ng-http-loader';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class DynamicFormComponent implements OnInit { // Using '!' to satisfy TypeScript's strict checks.
  @Input() identifier!: string;
  @Input() routeIntent!: string;
  @Input() schemaUrl!: string;
  @Input() apiUrl!: string;
  @Input() titleInformation!: any[];
  @Input() schemaJson!: any;
  @Input() routerPath!: string;
  @Input() collection!: string;
  @Output() onDataLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output() progressStatus : EventEmitter<boolean> = new EventEmitter();
  @Input() queryParams: any;
  @Output() onErrorResponse: EventEmitter<any> = new EventEmitter<any>();

  form = new FormGroup({});
  model = {};
  fields!: FormlyFieldConfig[]; // Using '!' to satisfy TypeScript's strict checks.
  invalidFields: any[];
  disableSubmit: boolean = false;
  count = 0;
  imageId: any;
  formSubmitted: boolean = false;
  foundField: any;
  uploadPromises = [];
  initialFormValue : any;
  isOutlineFormEdited: boolean = false;
  saveId: any;

  constructor(
    private http: HttpClient,
    private dynamicFormService: DynamicFormService,
    private snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly snackBarService: SnackBarService,
    private spinner: SpinnerVisibilityService
  ) { 

    setTimeout(() => {
      this.initialFormValue = this.form?.value;
      this.form?.valueChanges.subscribe((newFormValue) => {
        if (this.form.dirty) {
          this.isOutlineFormEdited = !(JSON.stringify(newFormValue) === JSON.stringify(this.initialFormValue));
          this.progressStatus.emit(this.isOutlineFormEdited);
        }
      });
    }, 2000);
  }

  ngOnInit() {
    let foundField1 = false;
    let foundField2 = false;
    if (this.schemaJson) {
      this.fields = this.schemaJson
      if (this.titleInformation) {
        this.titleInformation?.forEach(element => {
          for (const [fieldKey, title] of Object.entries(element)) {
            let found = false;

            this.fields?.forEach((fieldGroup, index) => {
              if (found) return; // Break out of the loop if already found
              fieldGroup.fieldGroup.forEach(field => {
                const key = field?.key?.toString();

                if (key === fieldKey) {
                  // Create a new fieldGroup with the title as label
                  const newFieldGroup = {
                    fieldGroup: [
                      {
                        key: title.toString().replace(/\s/g, ""), // Provide a unique key for the new field
                        type: "title",
                        templateOptions: {
                          label: title.toString(), // Explicitly cast title to string
                          placeholder: "",
                          description: "",
                          options: [],
                          minLength: 3,
                          maxLength: 100,
                          disabled: false
                        },
                      }
                    ],
                  };
                  this.fields.splice(index, 0, newFieldGroup);
                  found = true;
                }
              });
            });
          }
        });
      }
    }
    else {
    this.http.get(`${BaseUrl.CMS_PROXY_API}/open/${this.schemaUrl}/getFormSchema`, { observe: 'response' })
    .subscribe((response: HttpResponse<any>) => {
    this.fields = response.body.body as FormlyFieldConfig[];
        if (!foundField1) {
          this.fields.forEach((fieldGroup, index) => {
            fieldGroup.fieldGroup.forEach((field: any) => {
              if (field.key.includes('Id') && /Id/.test(field.key) && field.type == 'input') {
                field.hideExpression = true;
                foundField1 = true;
              }
            })
          })
        }
        if (this.identifier) {
          if (!foundField2) {
            this.fields.forEach((fieldGroup, index) => {
              fieldGroup.fieldGroup.forEach((field: any) => {
                if (field.key.includes('Id') && /Id/.test(field.key) && field.type == 'input') {
                  field.hideExpression = false;
                  field.templateOptions.disabled = true;
                  foundField2 = true;
                }
              })

            })
          }
        }
        if (this.titleInformation) {
          this.titleInformation.forEach(element => {
            for (const [fieldKey, title] of Object.entries(element)) {
              let found = false;

              this.fields.forEach((fieldGroup, index) => {
                if (found) return; // Break out of the loop if already found
                fieldGroup.fieldGroup.forEach(field => {
                  const key = field.key.toString();

                  if (key === fieldKey) {
                    // Create a new fieldGroup with the title as label
                    const newFieldGroup = {
                      fieldGroup: [
                        {
                          key: title.toString().replace(/\s/g, ""), // Provide a unique key for the new field
                          type: "title",
                          templateOptions: {
                            label: title.toString(), // Explicitly cast title to string
                            placeholder: "",
                            description: "",
                            options: [],
                            minLength: 3,
                            maxLength: 100,
                            disabled: false
                          },
                        }
                      ],
                    };
                    this.fields.splice(index, 0, newFieldGroup);
                    found = true;
                  }
                });
              });
            }
          });
        }
      });
    }
    if (this.identifier) {
      this.dynamicFormService.getCollectionEntry(this.identifier, this.collection).subscribe(response => {
        this.model = response.body.data.attributes;
        this.onDataLoad.emit(this.model);
      }, (errors) => {
        this.snackBar.open(errors.error.body.error.message, 'Error', { duration: 3000 });
      })
    }
  }

  async onSubmit() {
    this.isOutlineFormEdited = false;
    this.progressStatus.emit(this.isOutlineFormEdited);
    this.fields.forEach(data => {
      let field = data.fieldGroup[0]
      if (field.type === 'time') {
        console.log(this.model[field.key + '']);
        this.model[field.key + ''] = this.convertTimeFormat(this.model[field.key + '']);
      }
      if (field.type === 'datepicker') {
        const parsedDate = Date.parse(this.model[field.key + '']);
        if (!isNaN(parsedDate) && parsedDate > 0) {
          const dateObj = new Date(parsedDate);
          const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
          this.model[field.key + ''] = formattedDate;
        }
      }
      if (this.model[field.key + ''] instanceof FileList) {
        for (let i = 0; i < this.model[field.key + ''].length; i++) {
          this.uploadPromises.push(this.uploadFile(this.model[field.key + ''].item(i), field.key + ''));
        }
      }
    })
    if (!this.foundField) {
      this.processForm();
    }
  }

  async uploadFile(file, key) {
    this.spinner.show();
    return new Promise<void>((resolve, reject) => {
      this.dynamicFormService.uploadFile(file, file.name, this.collection, 'POST').then(
        (response: any) => {
          const responseNew = JSON.parse(response);
          this.model[key] = responseNew[0]?.id;
          resolve();
        },
        (error) => {
          this.snackBarService.error(error.error.body.error.message);
          reject(error);
        }
      );
    });
  }

  async processForm() {
    for (const key in this.model) {
      if (this.model.hasOwnProperty(key)) {
        const fieldValue = this.model[key];
        if (fieldValue && fieldValue.data && Array.isArray(fieldValue.data)) {
          const ids = fieldValue.data.map((item: any) => item.id);
          this.model[key] = ids;
        }
      }
    }
    this.fields.forEach((fieldGroup: any) => {
      if (Array.isArray(fieldGroup.fieldGroup)) {
        fieldGroup.fieldGroup.forEach((field: any) => {
          if (field.type === 'datepicker') {
            const dateField = this.model[field.key];
            const formattedDateNew = Utility.transformDateToString(dateField);
            this.model[field.key] = formattedDateNew;
          }
          if (field.type === 'file') {
            this.imageId = this.model[field.key];
            if (this.imageId?.data) {
              this.model[field.key] = this.imageId?.data?.id;
            } else {
              this.model[field.key] = this.imageId;
            }   
          }
          if (field.type === 'repeat') {
            field.fieldGroup.forEach(data => {
              data.fieldGroup.forEach(field1 => {
                field1.fieldGroup.forEach(newField => {
                  if (newField.type === 'time') {
                    if (Array.isArray(this.model[field.key])) {
                      this.model[field.key].forEach(element => {
                        if (element && element[newField.key]) {
                          const value = element[newField.key].startTime ? element[newField.key].startTime : element[newField.key];
                          element[newField.key].startTime ? element[newField.key].startTime : element[newField.key] = this.convertTimeFormat(value);
                        }
                      });
                    }
                  }
                })
              })
            })
          }
          if(field.type === 'external-relation'){
            if(field.templateOptions.relation === 'many'){
              let finalExternalString ='';         
              if(this.model[field.key]?.length>0 && this.model[field.key] !== null){
                this.model[field.key]?.forEach(data =>{
                  finalExternalString = finalExternalString + data +','
                })
                this.model[field.key]=finalExternalString
              }
            }
          }
          if (field.type === 'input' && (this.model[field.key] !== undefined && this.model[field.key] !== null && this.model[field.key] !== "")) {
            this.model[field.key] = this.model[field.key].replace(/\s+/g, ' ').trim();
          }
          this.foundField = true;
        });
      }
    });
    await Promise.all(this.uploadPromises);
    if (!this.formSubmitted) {
      this.submitForm();
    }
  }


  async submitForm() {
    try {
      this.formSubmitted = true;
      const payload = this.formattedPayload(this.model);
      this.formatRepeateComponent();
      let response;
      if (this.identifier) {
        response = await this.dynamicFormService.updateCollectionEntry({ data: payload }, this.identifier, this.collection).toPromise();
        this.saveId = response.responseObject.body.data.id; 
        this.spinner.hide();
      } else {
        response = await this.dynamicFormService.saveCollectionEntry({ data: payload }, this.collection).toPromise();
        this.saveId = response.responseObject.body.data.id; 
        this.spinner.hide();
      }
      
      this.snackBarService.success(response?.message?.applicationMessage ?? 'Operation successful');
      
      this.router.navigate([this.routerPath], {
            queryParams: {id: this.saveId}
         });     
    } catch (errors) {
      this.spinner.hide();
      if(errors.error.status==500){
        this.snackBarService.error(errors.error.error);
      }
      else{
        const errorMessage = errors?.error?.body?.error?.message ?? 'An unexpected error occurred.';
        this.snackBarService.error(errorMessage);
      }
      this.foundField = false;
      this.reFormatExternalRelationalFields();
      this.onErrorResponse.emit(this.model);
    } finally {
      this.formSubmitted = false;
      this.foundField = false;
    }
  }

  reFormatExternalRelationalFields() {
    this.fields.forEach((fieldGroup: any) => {
      if (Array.isArray(fieldGroup.fieldGroup)) {
        fieldGroup.fieldGroup.forEach((field: any) => {
          if (field.type === 'external-relation') {
            if (field.templateOptions.relation === 'many') {
              let finalExternalArray = this.model[field.key].split(',').filter(skill => skill.trim() !== '');
              this.model[field.key] = finalExternalArray
            }
          }
          this.foundField = true;
        });
      }
    });
  }

  formatRepeateComponent() {
    this.fields.forEach((fieldGroup: any) => {
      if (Array.isArray(fieldGroup.fieldGroup)) {
        fieldGroup.fieldGroup.forEach((field: any) => {
          if(field.type === 'repeat' && this.model[field.key][0] === undefined){
            this.model[field.key] = []
          }
        });
      }
    });
  }


  formattedPayload(payload) {
    Object.keys(payload).forEach(key => {
      const controlValue = payload[key];
      if (controlValue === '') {
        payload[key] = null;
      }
      if (Array.isArray(controlValue?.data) && controlValue?.data?.length === 0) {
        payload[key] = [];
      }
     if (typeof controlValue === 'object' && controlValue !== null) {
      this.formattedPayload(controlValue);
    }
    });
    return payload;
  }

  convertTimeFormat(inputTime: string): string {
    const [hoursStr, minutesStr] = inputTime?.split(':');

    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);

    const seconds = 0;
    const milliseconds = 0;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    const formattedMilliseconds = milliseconds.toString().padStart(3, '0');

    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;

    return formattedTime;
  }
}
