import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { InPageNavService } from 'src/app/framework/shared/components/in-page-nav/in-page-nav.service';
import { Utility } from 'src/app/framework/utils/utility';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { CourseManagementService } from '../course-management.service';
import { ViewBookingDetailsComponent } from '../course-delivery/view-booking-details/view-booking-details.component';
import { CourseManagementNavigation } from '../course-management-nav';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {

  rejectBookingForm: FormGroup;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  rejectionReason: any[];
  filteredReasons: any [];
  deliveryId: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly snackbar: SnackBarService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly courseManagementService: CourseManagementService,
    private readonly inPageNavService: InPageNavService,
    public dialogRef: MatDialogRef<ViewBookingDetailsComponent>,
    private readonly courseNavigation: CourseManagementNavigation,

    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.scrollToTop();
    this.bookingRefData();
    if (this.data.bookingType === "Event") {
      this.deliveryId = this.data.eventDeliveryId;
    } else if (this.data.bookingType === "Workshop") {
      this.deliveryId = this.data.workshopDeliveryId;
    } else {
      this.deliveryId = this.data.courseDeliveryId;
    }

  }

  ngOnInit() {
    this.initDataForm();
  }

  confirmDelete(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(true);
  }

  initDataForm() {
    this.rejectBookingForm = this.fb.group({
      rejectionReason: [null, Validators.required]
    });
  }

  onSubmit() {
    const payload = this.rejectBookingForm.getRawValue()
    this.data.reasonId = payload.rejectionReason;
    if (this.data.bookingType === "Event") {
      this.rejectEventBooking();
    } else if (this.data.bookingType === "Workshop") {
      this.rejectWorkshopBooking();
    } else if (this.data.bookingTypeId === 2 && this.data.bookingStatus === 'Awaiting Validation') {
      this.validatorRejectCourseBooking();
    } else {
      this.rejectCourseBooking();
    }
  }

  rejectCourseBooking() {
    this.courseManagementService.rejectBooking(this.data).subscribe(() => {
      const message = 'Booking rejected successfully';
      this.dialogRef.close(false);
      this.snackbar.success(message);
      this.router.navigate(
        ['/courses-events/courseDeliveries/view/booking-request'],
        {
          relativeTo: this.route,
          queryParams: {
            id: this.deliveryId,
            operation: 'view',
            hideEdit: this.data.hideEdit
          }
        }
      );
    },
      (error) => {
        this.snackbar.error(error.error.applicationMessage);
        this.dialogRef.close(false);
        this.router.navigate(
          ['/courses-events/courseDeliveries/view/booking-request'],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.deliveryId,
              operation: 'view',
              hideEdit: this.data.hideEdit
            }
          }
        );
      })
  }

  rejectEventBooking() {
    this.courseManagementService.rejectEventBooking(this.data).subscribe(() => {
      const message = 'Event booking rejected successfully';
      this.dialogRef.close(false);
      this.snackbar.success(message);
      this.router.navigate(
        ['./courses-events/eventDeliveries/view/confrim-booking'],
        {
          relativeTo: this.route,
          queryParams: {
            id: this.deliveryId,
            operation: 'view',
            hideEdit: this.data.hideEdit
          }
        }
      );
    },
      (error) => {
        this.snackbar.error(error.error.applicationMessage);
        this.dialogRef.close(false);
        this.router.navigate(
          ['./courses-events/eventDeliveries/view/confrim-booking'],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.deliveryId,
              operation: 'view',
              hideEdit: this.data.hideEdit
            }
          }
        );
      })
  }

  rejectWorkshopBooking() {
    this.courseManagementService.rejectWorkshopBooking(this.data).subscribe(() => {
      const message = 'Workshop booking rejected successfully';
      this.dialogRef.close(false);
      this.snackbar.success(message);
      this.router.navigate(
        ['./courses-events/workshopDeliveries/view/booking-request'],
        {
          relativeTo: this.route,
          queryParams: {
            id: this.deliveryId,
            operation: 'view',
            hideEdit: this.data.hideEdit
          }
        }
      );
    },
      (error) => {
        this.snackbar.error(error.error.applicationMessage);
        this.dialogRef.close(false);
        this.router.navigate(
          ['/courses-events/workshopDeliveries/view/booking-request'],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.deliveryId,
              operation: 'view',
              hideEdit: this.data.hideEdit
            }
          }
        );
      })
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  filterByMultipleIdentifiers(identifiers: string[]): void {
    this.rejectionReason = this.rejectionReason.filter(reason => identifiers.includes(reason.identifier));
  }

  bookingRefData() {
    this.setRejectionReason();
    this.applyConditionalFiltering();
  }
  
  private setRejectionReason(): void {
    const key = this.getRejectionReasonKey();
    this.rejectionReason = Utility.filterMapByKey(key, "refData_booking");
  }
  
  private getRejectionReasonKey(): string {
    switch (this.data.bookingType) {
      case 'Event':
        return 'Event_Rejected_Reason';
      case 'Workshop':
        return 'Workshop_Rejected_Reason';
      default:
        return 'Rejected_Reason';
    }
  }
  
  private applyConditionalFiltering(): void {
    if (this.data.bookingStatus == null || this.data.bookingTypeId == null) {
      return;
    }
  
    const rejectedReasons = this.getRejectedReasons();
    this.filterByMultipleIdentifiers(rejectedReasons);
  }
  
  private getRejectedReasons(): string[] {
    if (this.data.bookingTypeId === 1) {
      return ['ICBR', 'NPMNT'];
    } else if (this.data.bookingTypeId === 2 && this.data.bookingStatus === 'Awaiting Validation') {
      return ['PSRLA', 'REJFRD'];
    } else {
      return ['DECIEG', 'DWCNST'];
    }
  }

  validatorRejectCourseBooking() {
    let payload = {
      "bookingUserMappingId": this.data.id,
      "bookingRejectionReasonId": this.data.reasonId
    }
    this.courseManagementService.elcasValidatorRejectBooking(payload).subscribe(() => {
      const message = 'Booking rejected successfully';
      this.dialogRef.close(false);
      this.snackbar.success(message);
      this.router.navigate(
        ['/courses-events/courseDeliveries/view/booking-request'],
        {
          relativeTo: this.route,
          queryParams: {
            id: this.deliveryId,
            operation: 'view',
            hideEdit: this.data.hideEdit
          }
        }
      );
    },
      (error) => {
        this.snackbar.error(error.error.applicationMessage);
        this.dialogRef.close(false);
        this.router.navigate(
          ['/courses-events/courseDeliveries/view/booking-request'],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.deliveryId,
              operation: 'view',
              hideEdit: this.data.hideEdit
            }
          }
        );
      })
  }
}
