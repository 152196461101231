import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionsService } from 'src/app/features/shared/services/sessions.service';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { IBooking } from '../../course-delivery/booking.interface';
import { CourseManagementNavigation } from '../../course-management-nav';
import { CourseManagementService } from '../../course-management.service';
import { PopUpComponent } from '../../pop-up/pop-up.component';
import { IEventBooking } from '../event-booking.interface';

@Component({
  selector: 'app-view-event-booking-details',
  templateUrl: './view-event-booking-details.component.html',
  styleUrls: ['./view-event-booking-details.component.scss']

})
export class ViewEventBookingDetailsComponent implements OnInit {
  identifier: any;
  edId:any;
  pageName: any;
  routeIntent: any;
  booking: IEventBooking;
  isEnableRejectBtn : boolean;
  isActive;

  constructor(private readonly activatedRoute: ActivatedRoute,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBarService: SnackBarService,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly courseManagementService: CourseManagementService,
    private readonly appConfirmService: AppConfirmService,
    private dilogref: MatDialog,
    private readonly sessionService: SessionsService
    ) {
      this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
      this.activatedRoute.queryParams.subscribe((params: any) => {
        this.identifier = params.id;
        this.edId = params.edID;
        this.pageName = params.name;
        this.isActive=params.hideEdit;
      });
  }
  ngOnInit(): void {
    this.resolveBooking();
  }

  resolveBooking() {
    this.route.params.subscribe((params: any) => {
      this.courseManagementService.getEventBookingDetails(this.identifier).subscribe(bookingData => {
        this.booking = bookingData;
        this.checkRejectButton();
      })
      
    })
  }

  checkRejectButton(): void {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const eventDeliveryStartDate = new Date(this.booking.deliveryStartDate);
    eventDeliveryStartDate.setHours(0, 0, 0, 0);
    
    if (eventDeliveryStartDate > todayDate) {
      this.isEnableRejectBtn = true;
    } else {
      this.isEnableRejectBtn = false;
    }
  }

  onCancelBookingClick() {
    let payload = {
      "id":null,
      "serviceLeaverId":this.booking?.serviceUserId,
      "eventId": this.booking?.eventId,
      "eventDeliveryId" :this.edId,
      "reasonId" : null,
      "bookingType" : "Event",
      "hideEdit": this.isActive
    };
    const dialogRef = this.appConfirmService.confirm({
      title: `Event cancellation`,
      message: `Are you sure you want to cancel event request?`,
      okButtonName: 'Ok',
      cancelButtonName: 'Cancel'
    });

    dialogRef.subscribe(result => {
      if (result) {
        this.courseManagementService.rejectEventBooking(payload).subscribe(
          data => {
            this.snackBarService.success(data?.message?.applicationMessage);
            
            this.router.navigate(
              ['/courses-events/eventDeliveries/view/confirm-booking'],
              {
                relativeTo: this.route,
                queryParams: {
                  id: this.edId,
                  operation: 'view',
                  "hideEdit": this.isActive
                }
              }
            );
          },
          (error: any) => {
            this.snackBarService.error(`${error.error.applicationMessage}`);
          }
        );
      }
    });
  }
  

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }
}
