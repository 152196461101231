import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseUrl } from "../../../framework/constants/url-constants";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DynamicFormService {
  constructor(
    private readonly http: HttpClient,
  ) { }

  uploadFile1(formData, collection) {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/upload`;
    return this.http.post<any>(href, formData);
  }

  saveCollectionEntry(payload, collection) {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/create`;
    return this.http.post<any>(href, payload);
  }

  updateCollectionEntry(payload, id, collection) {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/update/${id}`;
    return this.http.put<any>(href, payload);
  }

  getCollectionEntry(id, collection) {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/read/${id}`;
    return this.http.get<any>(href);
  }

  uploadFile(file: File, fileName: string, collection, method: 'POST') {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/upload`;
    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileName', fileName);
    return this.uploadFileHeader(href, method, fd);
  }

  public uploadFileHeader(url: string, method: ('POST'), formData: FormData) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else if (xhr.status === 401) {

          } else if (xhr.status === 500) {

          } else {
            reject(JSON.parse(xhr.response));
          }
        }
      }
      xhr.open(method, url, true);
      xhr.setRequestHeader("X-Authorization", 'Bearer ' + localStorage.getItem('token'));
      xhr.send(formData);
    });
  }

  suggestOption(text: string) {
    const href = `${BaseUrl.REFERENCE_DATA}/postcode/autoSuggest/${text}`;
    return this.http.get<any>(href);
  }

  getAllNotes(id, schemaUrl,collection,sort: string, page, size,body): Observable<any> {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/search/${id}/${schemaUrl}`
    return this.http.post<any>(href,body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getAllAuditDateHistory(id, schemaUrl,collection,sort: string, page, size,body): Observable<any> {
    const href = `${BaseUrl.CMS_PROXY_API}/proxy${collection}/search/${id}/${schemaUrl}`
    return this.http.post<any>(href,body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getRegionList(externalEndpoint,data): Observable<any> {
    const href = `${BaseUrl.REFERENCE_DATA}/${externalEndpoint}`;
    return this.http.post<any>(href,data);
  }

  saveUserNotesCollectionEntry(payload, collection) {
    const href = `${BaseUrl.CMS_PROXY_API}/open${collection}/create`;
    return this.http.post<any>(href, payload);
  }

}