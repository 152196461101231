// state.reducer.ts
import { createReducer, on } from '@ngrx/store';
import { setSearchQuery, setCurrentPage, resetNavigationState, setActiveTab, setActiveSubTab, setSubTabTableSearchQuery, setSubTabTableCurrentPage, resetSubNavigationSearchQuery, resetSubNavigationPageIndex, resetSubNavigationFilterQuery, setSubTabTableFilterQuery, resetSubActiveTab, setFilterQuery, resetFilterQuery } from './state.actions';

export interface AppState {
  searchQuery: string;
  currentPage: number;
  filterQuery: number | string;
  activeTab: string | boolean;
  activeSubTab: number;
  subTabTableSearchQuery: string;
  subTabTableFilterQuery: number;
  subTabTableCurrentPage: number;
}

export const initialState: AppState = {
  searchQuery: '',
  currentPage: 0,
  filterQuery: '',
  activeTab: '',
  activeSubTab: 0,
  subTabTableSearchQuery: '',
  subTabTableFilterQuery: null,
  subTabTableCurrentPage: 0,
};

export const appReducer = createReducer(
  initialState,
  on(setSearchQuery, (state, { query }) => ({ ...state, searchQuery: query })),
  on(setCurrentPage, (state, { page }) => ({ ...state, currentPage: page })),
  on(setFilterQuery, (state, { query }) => ({ ...state, filterQuery: query })),
  on(setActiveTab, (state, { tab }) => ({ ...state, activeTab: tab })),
  on(setActiveSubTab, (state, { subTab }) => ({ ...state, activeSubTab: subTab })),
  on(setSubTabTableSearchQuery, (state, { query }) => ({ ...state, subTabTableSearchQuery: query })),
  on(setSubTabTableFilterQuery, (state, { query }) => ({ ...state, subTabTableFilterQuery: query })),
  on(setSubTabTableCurrentPage, (state, { page }) => ({ ...state, subTabTableCurrentPage: page })),
  on(resetNavigationState, () => initialState),
  on(resetFilterQuery, (state) => ({ ...state, filterQuery: '' })),
  on(resetSubNavigationSearchQuery, (state) => ({ ...state, subTabTableSearchQuery: '' })),
  on(resetSubNavigationFilterQuery, (state) => ({ ...state, subTabTableFilterQuery: null })),
  on(resetSubNavigationPageIndex, (state) => ({ ...state, subTabTableCurrentPage: 0 })),
  on(resetSubActiveTab, (state) => ({ ...state, activeSubTab: 0 }))

);
