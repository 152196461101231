import { Component, Input, OnInit } from '@angular/core';

export const CONFIG = {
  btnClass: 'default', // The CSS class(es) that will apply to the buttons
  zoomFactor: 0.1, // The amount that the scale will be increased by
  containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
  wheelZoom: true, // If true, the mouse wheel can be used to zoom in
  allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
  allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
  btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
    zoomIn: 'fa fa-plus',
    zoomOut: 'fa fa-minus',
    rotateClockwise: 'fa fa-repeat',
    rotateCounterClockwise: 'fa fa-undo',
    next: 'fa fa-arrow-right',
    prev: 'fa fa-arrow-left',
    fullscreen: 'fa fa-arrows-alt',
  },
  btnShow: {
    zoomIn: true,
    zoomOut: true,
    rotateClockwise: true,
    rotateCounterClockwise: true,
    next: true,
    prev: true
  }
};

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewComponent implements OnInit {
  @Input() images: string;

  imageSrc = 'https://streedctpdev.blob.core.windows.net/images/RCTP/original/mehdi-messrro-yef79KkAguA-unsplash (1)001670.jpg';
  ngOnInit() {
    const img = document.getElementById('imageviewer');
  }
  scale: number = 1;
  rotation: number = 0;

  get transform(): string {
    return `scale(${this.scale}) rotate(${this.rotation}deg)`;
  }

  zoomIn(): void {
    this.scale += 0.1;
  }

  zoomOut(): void {
    if (this.scale > 0.1) {
      this.scale -= 0.1;
    }
  }

  rotateLeft(): void {
    this.rotation -= 90;
  }

  rotateRight(): void {
    this.rotation += 90;
  }


  reset(): void {
    this.scale = 1;
    this.rotation = 0;
  }
}
