import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LearnerNavigation } from 'src/app/features/captr-learners/learner-nav';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';

@Component({
  selector: 'app-course-delivery-tab',
  templateUrl: './course-delivery-tab.component.html',
  styleUrls: ['./course-delivery-tab.component.scss']
})
export class CourseDeliveryTabComponent {
  userName :any;
  userId : any
  isActive;

  constructor(
    private readonly route: ActivatedRoute

  ) {
    this.route.queryParams.subscribe((params: any) => {
      if (params.hasOwnProperty('id')) {
        this.route.queryParams.subscribe((params: any) => {
          this.userId = params.id;
          this.userName = params.name;
          this.isActive= params.hideEdit;
        })
      }
    })
  }
  
}
