<mat-tab-group (selectedIndexChange)="onTabChanged($event)" [selectedIndex]="activeIndex" mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight fitInkBarToContent>
    <mat-tab label="Active">
      <ng-template matTabContent>
          <app-dynamic-table [tabHead]="archiveTab" [sortingBy]="sortBy" [onlyList]="true" [collection]="collection" [fields]="fields" [search]="searchFields" [buttonLable]="buttonLable" [baseRoute]="baseRoute">
          </app-dynamic-table>
      </ng-template>
    </mat-tab>
    <mat-tab label="Cancelled">
      <ng-template matTabContent>
          <app-dynamic-table [tabHead]="archiveTab1" [sortingBy]="sortBy1" [onlyList]="true" [collection]="collection1" [fields]="fields1" [search]="searchFields1" [baseRoute]="baseRoute">
          </app-dynamic-table>
      </ng-template>
    </mat-tab>
  </mat-tab-group>