import { Component } from '@angular/core';
import { CourseManagementNavigation } from '../course-management-nav';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-course-location',
  templateUrl: './course-location.component.html',
  styleUrls: ['./course-location.component.scss']
})
export class CourseLocationComponent {

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly router: Router,
    private readonly courseNavigation: CourseManagementNavigation,
  ) {
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }
  collection = 'courseLocations'; // Users collection name
  fields = ['locationName', 'locationId', 'createdAt']; // Fields to display
  searchFields = ['locationName', 'locationId'];
  buttonLable = "location";
  baseRoute = '/courses-events';

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }
}
