<mat-card id='edit_content'>
  <mat-card-header>
    <span class="spacer">
      <app-stepper-navigation></app-stepper-navigation>
    </span>
    <span>
      <button id="exit_user_button" mat-stroked-button color="primary"
        [routerLink]="['/content-management']">Exit</button>
    </span>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="contentForm" autocomplete="off">
      <div class="info-text">
        <p class="mt0">
          When you select a Main category and a Sub category, these automatically become keywords and then you can add a
          maximum of 3 more keywords.
          Please be mindful when selecting keywords as this is how a Service User searches for resource.
        </p>
      </div>
      <div class="label-hr">
        <span>Category</span>
      </div>
      <div class="card-row">
        <div class="card-column">
          <mat-form-field appearance="fill">
            <mat-label>Main category</mat-label>
            <mat-select placeholder="Select category" formControlName="mainCategoryId" required
              (selectionChange)="onMainCatChange($event.value)">
              <mat-option *ngFor="let category of mainCatList" [value]="category.id">{{category.categoryName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="contentForm.controls.mainCategoryId.invalid">Select at least one option</mat-error>
          </mat-form-field>
        </div>
        <div class="card-column">
          <mat-form-field appearance="fill">
            <mat-label>Sub category</mat-label>
            <mat-select placeholder="Select sub category" formControlName="subCategoryId" required>
              <mat-option *ngFor="let subCat of subCatList" [value]="subCat.id">
                {{subCat.categoryTitle}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="contentForm.controls.subCategoryId.invalid">
              Select at least one option
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="label-hr">
        <span>Keywords</span>
      </div>
      <div class="card-row mb20">
        <p *ngIf="contentForm.get('keywordName').value.length === 0">Your selected keywords will be displayed here</p>
        <div class="keyword-container">
          <mat-chip-list #chipList formArrayName="keywordName">
            <mat-chip *ngFor="let word of contentForm.get('keywordName')['controls']; let i=index;" color="primary"
              class="input_chip" [selectable]="false" [removable]="word.value.removable"
              (removed)="remove(i, word.value)" [formGroupName]="i" selected style="margin-right: 10px;">
              <span matLine>{{word.value.keywordName}}</span>
              <mat-icon matChipRemove *ngIf="word.value.removable">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <mat-error
            *ngIf="contentForm.get('keywordName').touched && contentForm.get('keywordName').hasError('maxLengthArray')">
            Maximum keywords allowed is 3
          </mat-error>
          <mat-error
            *ngIf="contentForm.get('keywordName').touched && contentForm.get('keywordName').hasError('required')">
            You must select at least 1 keyword
          </mat-error>
        </div>
      </div>
      <div class="card-row">
          <span>
            <mat-form-field class="example-full-width width-33" appearance="fill">
              <mat-label class="blur_color">Search</mat-label>
              <input matInput type="text" #keywordFilter id="keywordFilter" [formControl]="keywordFilterCtrl"
                [(ngModel)]="searchTerm" placeholder="Search by">
              <button *ngIf="!searchTerm" id="do_search_button" matSuffix mat-icon-button aria-label="Search">
                <mat-icon>search</mat-icon>
              </button>
              <button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="onClickClose()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </span>
      </div>
      <div class="card-row mb20">
        <div class="keyword-container keywordScroll">
          <ng-container *ngFor="let option of keywordOptions">
            <mat-chip color="accent" class="input_chip" (keyup)="onKeyPress($event.keyCode, option)"
              title="{{option.keywords}}" value="{{option.keywords}}" (click)="select(option)" *ngIf="!option.invisible"
              style="margin-right: 10px;margin-bottom: 5px;">
              <span matLine>{{option.keywordName}}</span>
            </mat-chip>
          </ng-container>
          <p class="text-center" *ngIf="keywordOptions && keywordOptions?.length === 0">No results found.</p>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-footer>
    <span class="spacer"></span>
    <span>
      <button type="submit" id="save_user_button" mat-flat-button color="primary" [disabled]="!contentForm.valid"
        (click)="saveAndNext()">Save &
        Next</button>
    </span>
  </mat-card-footer>
</mat-card>