<mat-card id="edit_keyword">
    <mat-dialog-content>
        <p>Do you want to assign for free?</p>
        <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button value=true  [disabled]="data.courseType==1">Yes</mat-radio-button>
            <mat-radio-button value=false>No</mat-radio-button>
        </mat-radio-group>
        <mat-dialog-actions class="ml-20" style="display: flex;justify-content: space-between; padding-top: 60px">
        
            <button type="button" mat-raised-button color="primary" (click)="onConfirm()"
                aria-label="Delete">Confirm</button>
            <button type="button" mat-stroked-button (click)="onCancel()" aria-label="close">Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</mat-card>