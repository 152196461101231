import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SessionsService } from 'src/app/sessions/sessions.service';
import { JobsNavigation } from '../../jobs-nav';

@Component({
  selector: 'app-view-job-details',
  templateUrl: './view-job-details.component.html',
  styleUrls: ['./view-job-details.component.scss']
})
export class ViewJobDetailsComponent {

  identifier: any;
  schemaUrl = 'api::job.job';
  apiUrl = '/jobs';
  collection = '/jobs';
  addRelationFields: {};

  titleInformation: any[];
  pageName:any;

  constructor(
    private readonly inPageNavService: InPageNavService,
    private readonly jobsNavigation: JobsNavigation,
    private readonly route: ActivatedRoute,
    private readonly sessionService: SessionsService
    )
    {
      this.scrollToTop();
      this.inPageNavService.setNavItems(this.jobsNavigation.jobsSubMenu);
    }
      

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
    });
    this.titleInformation =[{
      'employer': 'Job details',
    }];
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }
}
