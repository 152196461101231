import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { CanDeactivateGuard } from 'src/app/framework/guards/can-deactivate.guard';
import { Observable } from 'rxjs';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';

@Component({
  selector: 'app-add-course-location',
  templateUrl: './add-course-location.component.html',
})
export class AddCourseLocationComponent implements CanDeactivateGuard{
  identifier: any;
  routeIntent: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  routerPath = '/courses-events/courseLocations/view';
  queryParams: any;
  id:any;
  isNotSaved: boolean = false;

  collection = '/courseLocations';
  rowId: any;

  constructor (
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly appConfirmService: AppConfirmService
  ) {
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.id) {
          this.rowId = params.id;
          
          this.routeIntent = "edit";
      } else {
          this.routeIntent = "new"
      }
  });
    this.titleInformation =[{
      'courseLocationName': 'Location name'
    },{
      'addressLine1': 'Location details'
    }];
    this.queryParams = { id: this.rowId };
    this.schemaUrl = 'api::course-location.course-location';
    this.apiUrl = '/course-locations'
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams = params;
      this.identifier = params.id;
    });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
}

exitNavigation() {
  if (this.queryParams && this.queryParams.id) {
      // Navigate to the view page with both id and hideEdit parameters
      this.router.navigate(['/courses-events/courseLocations/view'], {
          queryParams: {
              id: this.queryParams.id,
              hideEdit: "Active" // Ensure hideEdit is included
          }
      });
  } else {
      // Navigate to the default page if id is not available
      this.router.navigate(['/courses-events/courseLocations']);
  }
}

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isNotSaved) {
      return this.appConfirmService.confirm(
        {
          title: `Progress not saved`,
          message: 'If you navigate away from this page your progress will not be saved'
        });
    } else {
      return true
    }
  }
}
