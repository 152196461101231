<div class="uploadFile">
    <span>
        <div>
            <label>{{to.label}}</label> <br>
        </div>
        <mat-hint>{{to.description}}</mat-hint>
        <div class="mt15 max-width">
            <button type="button" id="browse_button" (click)="fileInput.click()" mat-raised-button color="primary">
                Browse
            </button>
            <input [formlyAttributes]="field" type="file" id="fileInput" (change)="selectFile($event)"
                formControlName="formControl" #fileInput>
                
            <span class="mat-error-css" *ngIf="invalidFileType">Not a valid file type. Please update your selection.
            </span>
            <span class="mat-error-css" *ngIf="fileSizeExceeded">
                {{ fileSizeErrorMessage }}
            </span>
            <span class="mat-error-css" *ngIf="fileNameTooLong">
                File name has too many characters.
            </span>

            <mat-error class="error mt5" *ngIf="noFileSelected && fileMandatory">File is required.</mat-error>

            <span class="world-break" style="margin-left:10px" *ngIf="formControl.value?.data?.attributes?.name || fileName">
                {{formControl.value?.data?.attributes?.name || fileName}}
            </span>
            <span *ngIf="formControl.value?.data?.id || checkInstance() || formControl.value?.id"
                [class.isError]="invalidFileType ? true : false" type="button" class="button cancel_button" 
                (click)="cancelUpload()">
                <mat-icon (click)="cancelUpload()" (keydown.enter)="cancelUpload()" tabindex="0">cancel</mat-icon>
            </span>
        </div>
    </span>
</div>