import { Component, OnInit, ViewChild } from '@angular/core';
import { CourseManagementNavigation } from '../../../course-management-nav';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseManagementService } from '../../../course-management.service';
import { IBooking } from '../../booking.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/framework/components/date-adapter/date-adapter-sl';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Utility } from 'src/app/framework/utils/utility';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { SessionsService } from 'src/app/features/shared/services/sessions.service';

@Component({
  selector: 'app-booking-refund',
  templateUrl: './booking-refund.component.html',
  styleUrls: ['./booking-refund.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class BookingRefundComponent  implements OnInit {
  identifier: any;
  cdId:any;
  isActive = false;
  isAnyRefundSelected = false;
  pageName: any;
  confirmedExitBtn: boolean;
  booking: IBooking;
  isRefundEnabled: boolean = true;
  routeIntent: any;
  isEnableBookingBtn : boolean;
  isRejectReason: boolean;
  isPaymentSectionEnable = false;
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatTable) table: MatTable<any>;
  confirmBooking: FormGroup;
  displayedColumns: string[] = ['paymentType','paymentAmount','status','paymentDate','refund','refundDate','transactionId'];
  constructor( private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly courseManagementService: CourseManagementService,
    private readonly fb: FormBuilder,
    private readonly appConfirmService: AppConfirmService,
    private readonly snackBarService: SnackBarService,
    private readonly sessionService: SessionsService){

      this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
      this.route.queryParams.subscribe((params: any) => {
        this.identifier = params.id;
        this.cdId = params.cdID;
        this.pageName = params.name;
        this.isActive = params.hideEdit;
      });
      this.confirmBooking = this.fb.group({
        isconfirmBooking: ['true'],
      });
  
  }

  ngOnInit(): void {
    this.confirmedExitBtn = false;
    this.resolveBooking();
  
  }

  resolveBooking() {
      this.courseManagementService.getBookingDetails(this.identifier).subscribe(bookingData => {
        this.booking = bookingData;
        this.dataSource.data=bookingData.paymentDetailsDTO.filter(payment => payment !== null)
        if(this.booking.courseTotalCost != null && this.booking.courseTotalCost > 0){
          this.isPaymentSectionEnable = true;
        }
        if(this.booking.bookingStatus ===  "Provisional Booking" || this.booking.bookingStatus === "Awaiting Payment"){
          this.isEnableBookingBtn = true; 
        } else {
          this.isEnableBookingBtn = false;
          let parts = this.booking.bookingStatus.split("-");
          let prefix = parts[0].trim(); 
          if(prefix === "Confirmed"){
            this.confirmedExitBtn = true;
          }
        }
        if(this.booking.rejectedReason != null){
          this.isRejectReason = true;
        }
      })
  }

  

  onSubmit(form) {


    const dialogRef = this.appConfirmService.confirm({
      message: `Are you sure you want to approve?`,
      okButtonLabel: 'Confirm',
      cancelButtonLabel: 'Close'
    });


    const formValue = this.confirmBooking.value;
    let  tableData = this.dataSource.data.map(element => ({
      paymentDetailsId: element.paymentId,
      refundedDate: Utility.transformDateToString( element.refundDate),
      transactionId: element.transactionId,
      refund: element.refund,
    }));
    tableData=tableData.filter(ref=>ref.refund==true)
    const payload = {
      bookingUserMappingId:+ this.identifier,
      isBookingRefunded: formValue.isconfirmBooking,
      paymentRefundDTOList: tableData
    };
    dialogRef.subscribe(result => {
      if (result) {
    this.courseManagementService.refoundBooking(payload).subscribe(response=>{
      this.snackBarService.success(response.message.applicationMessage);
      this.router.navigate(
        ['/courses-events/courseDeliveries/view/booking-request'],
        {
          relativeTo: this.route,
          queryParams: {
            id: this.cdId,
            operation: 'view',
            hideEdit: this.isActive
          }
        }
      );
    })
   }
  })
}


  onConfirmBookingChange(): void {
    this.isRefundEnabled = this.confirmBooking.get('isconfirmBooking').value === "true";
    if (!this.isRefundEnabled) {
      this.dataSource.data.forEach(element => {
        element.refund = false;
        element.refundDate = null;
        element.transactionId = null;
      });
      this.table.renderRows();
    }
    this.updateRefundCheckboxes();
  }
  refundChanged(event: MatCheckboxChange, element: any): void {
    element.refund = event.checked;
    if (!element.refund) {
      element.refundDate = null;
      element.transactionId = null;
    }
    this.table.renderRows();
    this.updateRefundCheckboxes();
  }


  onDiscontinuedClick()
  {
    {
      const dialogRef = this.appConfirmService.confirm({
        message: `Are you sure you want to discontinue?`,
        okButtonLabel: 'Confirm',
        cancelButtonLabel: 'Close'
      });
  
      let payload = {
        bookingUserMappingId : + this.identifier
      }
      dialogRef.subscribe(result => {
        if (result) {
          this.courseManagementService.discontinueBooking(payload).subscribe(data=>{
            this.snackBarService.success(data.message.applicationMessage);
            this.router.navigate(
              ['/courses-events/courseDeliveries/view/booking-request'],
              {
                relativeTo: this.route,
                queryParams: {
                  id: this.cdId,
                  operation: 'view',
                  hideEdit: this.isActive
                }
              }
            );
          })

        }
      })
    }
  }

  updateRefundCheckboxes() {
    this.isAnyRefundSelected = this.dataSource.data.some(item => item.refund);
  }

  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }
}