
/* This constant file is created to store PageTitleIdentifier, title will update from label.json*/
export const PageTitleIdentifier = {
    CUSTOMERS: 'CUSTOMERS',
    VIEW_CUSTOMER: 'VIEW_CUSTOMER',
    EDIT_CUSTOMER: 'EDIT_CUSTOMER',
    ADD_CUSTOMER: 'ADD_CUSTOMER',
    Content_Sync_Data: 'Content_Sync_Data',
    Track_Lite_Sync_Data: 'Track_Lite_Sync_Data',
    Participants: 'Participants',
    Cvs: 'Cvs',
    ADMIN: 'ADMIN',
    Application_set_up: 'Application_set_up',
    View_role: 'View_role',
    Role: 'Role',
    RBAC_Permissions: 'RBAC_Permissions',
    Role_Configuration: 'Role_Configuration',
    Overview: 'Overview',
    Edit_link: 'Edit_link',
    Add_link: 'Add_link',
    Manage_Organisations: 'Manage_Organisations',
    Edit_Organisation: 'Edit_Organisation',
    Create_New_Organisation: 'Create_New_Organisation',
    Add_Participant: 'Add_Participant',
    Edit_Participant: 'Edit_Participant',
    Further_information: 'Further_information',
    Enrolment: 'Enrolment',
    Edit_Enrolment: 'Edit_Enrolment',
    Employment_Outcome: 'Employment_Outcome',
    Outcome_List: 'Outcome_List',
    Edit_Outcome: 'Edit_Outcome',
    Add_Outcome: 'Add_Outcome',
    Programme_Management: 'Programme_Management',
    Add_programme: 'Add_programme',
    Edit_programme: 'Edit_programme',
    View_Programme: 'View_Programme',
    Attendance: 'Attendance',
    Resource_Categories: 'Resource_Categories',
    Edit_Resource_Category: 'Edit_Resource_Category',
    Add_Resource_Category: 'Add_Resource_Category',
    Resource_Keywords: 'Resource_Keywords',
    Add_Resource_Keywords: 'Add_Resource_Keywords',
    Resource_Images: 'Resource_Images',
    Add_Image: 'Add_Image',
    Edit_Image_Keywords: 'Edit_Image_Keywords',
    Manage_Resources: 'Manage_Resources',
    Resource_Description: 'Resource_Description',
    Digital_courses: 'Digital_courses',
    Asset_builder: 'Asset_builder',
    Resource_Details: 'Resource_Details',
    Add_New_Asset: 'Add_New_Asset',
    View_Asset: 'View_Asset',
    Edit_Asset: 'Edit_Asset',
    Asset_Dashboard: 'Asset_Dashboard',
    Jobs: 'Jobs',
    Reporting_and_Dashboards: 'Reporting_and_Dashboards',
    Dashboard: 'Dashboard',
    Data_Extract: 'Data_Extract',
    Manage_Users: 'Manage_Users',
    Create_New_User: 'Create_New_User',
    View_User: 'View_User',
    Edit_User: 'Edit_User',
    Upload: 'Upload',
    My_To_Do: 'My_To_Do',
    Documents: 'Documents',
    Job_Opportunities: 'Job_Opportunities',
    performance_review: 'performance_review',
    digital_course_progress: 'digital_course_progress',
    Programme_enrolment: 'Programme_enrolment',
    programme_outcomes: 'programme_outcomes',
    system_interactions: 'system_interactions',
    profile: 'profile',
    Case_Notes: 'Case_Notes',
    professional_document: 'professional_document',
    Risk_Assessment: 'Risk_Assessment',
    Referrals: 'Referrals',
    Resource_Teams: 'Resource_Teams',
    Resource_Image: 'Resource_Image',
    Resource_Upload: 'Resource_Upload',
    Resource_Module: 'Resource_Module',
    Job_Details: 'Job_Details',
    Add_Local_Job: 'Add_Local_Job',
    Edit_Job: 'Edit_Job',
    Job_Adverts: 'Job_Adverts'
}

export const ProfileUrlIdentifier = {
    PROFILE_URL: 'profile_url'
}