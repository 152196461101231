<div class="container">
    <form [formGroup]="addForm">
        <mat-dialog-content>
            <h2 class="title ml-20">Add {{data['placeholder']}}</h2>
            <mat-form-field class="full-width">
                <mat-label>Add {{data['placeholder']}}</mat-label>
                <input matInput formControlName="name" placeholder="Enter text" maxPopupLength="100">
                <mat-hint *ngIf="!addForm.get('name').value || addForm.get('name').value.length >= data['minPopupLength'] ">Minimum {{data['minPopupLength']}} and maximum {{data['maxPopupLength']}} characters</mat-hint>
                <mat-error *ngIf="addForm.get('name').invalid && (addForm.get('name').dirty || addForm.get('name').touched)">
                    <span *ngIf="addForm.get('name').errors.required">{{data['placeholder']}} is required</span>
                    <span *ngIf="addForm.get('name').value.length >0 && addForm.get('name').value.length < data['minPopupLength']">Minimum {{data['minPopupLength']}} characters required.</span>
                    <span *ngIf="addForm.get('name').value.length >0 && addForm.get('name').value.length > data['maxPopupLength']">Maximum {{data['maxPopupLength']}} characters required.</span>
                </mat-error>
            </mat-form-field>            
            <mat-dialog-actions class="ml-20" style="display: flex;justify-content: space-between;">
                <button type="button" mat-raised-button color="primary" (click)="save()" [disabled]="!addForm.valid">Save</button>
                <button type="button" mat-stroked-button (click)="close()">Close</button>
            </mat-dialog-actions>
        </mat-dialog-content>
    </form>
</div>