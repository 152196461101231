import { Injectable } from '@angular/core';

const courseRoute = 'courses-events'; 
 
@Injectable()
export class CourseManagementNavigation {
 courseSubMenu = {

    sectionName: 'Courses',
    description: ``,
    state: null,
    icon: 'bar_chart',
    menuItems: [
      {
        name: 'Providers',
        description: ``,
        state: `${courseRoute}/providers`,
        icon: null,
        submenu: [],
        featureId:[139]
      },
      {
        name: 'Locations',
        description: ``,
        state: `${courseRoute}/courseLocations`,
        icon: null,
        submenu: [],
        featureId:[138]
      }, 
      {
        name: 'Course Details',
        description: ``,
        state: `${courseRoute}/courses`,
        icon: null,
        submenu: [],
        featureId:[141]
      },
      {
        name: 'Event Details',
        description: ``,
        state: `${courseRoute}/events`,
        icon: null,
        submenu: [],
        featureId:[150]
      },
      {
        name: 'Workshop Details',
        description: ``,
        state: `${courseRoute}/workshops`,
        icon: null,
        submenu: [],
        featureId:[187]
      },
      {
        name: "Course Delivery",
        description: ``,
        state: `${courseRoute}/courseDeliveries`,
        icon: null,
        submenu: [],
        featureId:[140]
      },
      {
        name: "Event Delivery",
        description: ``,
        state: `${courseRoute}/eventDeliveries`,
        icon: null,
        submenu: [],
        featureId:[157]
      },
      {
        name: "Workshop Delivery",
        description: ``,
        state: `${courseRoute}/workshopDeliveries`,
        icon: null,
        submenu: [],
        featureId:[188]
      }
    ]
  };

  
}
