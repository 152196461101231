import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseUrl } from 'src/app/framework/constants/url-constants';
import { Observable, tap } from 'rxjs';
import { FileUploadService } from 'src/app/features/shared/services/file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class CourseManagementService {
 
  constructor(private readonly http: HttpClient,
    private readonly uploadService: FileUploadService) { }
  
  findAllBookings(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getBookingDetails(id) {
    const href = `${BaseUrl.BOOKING}/booking/booking/${id}`;
    return this.http.get<any>(href);
  }

  rejectBooking(body){
    const href = `${BaseUrl.BOOKING}/booking/update/decline`;
    return this.http.put<any>(href, body);
  }

  approveBooking(body){
    const href = `${BaseUrl.BOOKING}/booking/update/approve`;
    return this.http.put<any>(href, body);
  }

  approveNCFBooking(formData){
      const href = `${BaseUrl.BOOKING}/elcas-detail/elcas`;
      return this.uploadService.uploadFile(href, formData,'POST');
  }

  validatorApproveNCFBooking(formData){
    const href = `${BaseUrl.BOOKING}/elcas-detail/elcas-validation`;
    return this.uploadService.uploadFile(href, formData,'POST');
  }

  elcasValidatorRejectBooking(body){
    const href = `${BaseUrl.BOOKING}/elcas-detail/elcas-validation/rejection`;
    return this.http.put<any>(href, body);
  }

  getBookingRefDataForReedCtp() {
    const href = `${BaseUrl.BOOKING}/ref-data-choice/all`;
    return this.http.get<any>(href).pipe(
      tap(data => {
       const jsonData= JSON.stringify(data)
       const encodedData = encodeURIComponent(jsonData)
        localStorage.setItem('refData_booking', encodedData);
      })
    );
  }

  getAttendanceDetails(id) {
    const href = `${BaseUrl.BOOKING}/attendance/${id}`;
    return this.http.get<any>(href);
  }

  getAllAttendanceBookingDetails(id) {
    const href = `${BaseUrl.BOOKING}/attendance/booking/${id}`;
    return this.http.get<any>(href);
  }

  saveNewAttendancDetails(formData: FormData, methodType: string) {
    const href = `${BaseUrl.BOOKING}/attendance/new`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }
 
  updateAttendancDetails(formData,methodType) {
    const href = `${BaseUrl.BOOKING}/attendance/update`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }

  findAllEventBookings(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking-event/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
    
  getRequestBookingStatus(id) {
    const href = `${BaseUrl.BOOKING}/ref-data-choice/${id}`;
    return this.http.get<any>(href);
  }

  findFilteredBookings(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking/filteredBookings`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getEventBookingDetails(id) {
    const href = `${BaseUrl.BOOKING}/booking-event/booking/${id}`;
    return this.http.get<any>(href);
  }

  uplodeAttendancFile(formData: FormData, methodType: string,id:any){
    const href = `${BaseUrl.BOOKING}/attendance/uplode/${id}`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }

  deleteAttendanceFile(id: number): Observable<any> {
    const href = `${BaseUrl.BOOKING}/attendance/delete/${id}`;
    return this.http.delete<any>(href);
  }
  
  rejectEventBooking(body){
    const href = `${BaseUrl.BOOKING}/event-cancel/cancel`;
    return this.http.put<any>(href, body);
  }

  findAllWorkshopBookings(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking-workshop/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getWorkshopBookingDetails(id) {
    const href = `${BaseUrl.BOOKING}/booking-workshop/booking/${id}`;
    return this.http.get<any>(href);
  }

  rejectWorkshopBooking(body){
    const href = `${BaseUrl.BOOKING}/booking-workshop/update/decline`;
    return this.http.put<any>(href, body);
  }

  getEventAttendanceDetails(id) {
    const href = `${BaseUrl.BOOKING}/event-attendance/${id}`;
    return this.http.get<any>(href);
  }

  getEventAllAttendanceBookingDetails(id) {
    const href = `${BaseUrl.BOOKING}/event-attendance/eventDelivery/${id}`;
    return this.http.get<any>(href);
  }

  updateEventAttendancDetails(formData,methodType) {
    const href = `${BaseUrl.BOOKING}/event-attendance/update`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }

  saveNewEventAttendancDetails(formData: FormData, methodType: string) {
    const href = `${BaseUrl.BOOKING}/event-attendance/new`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }

  uplodeEventAttendancFile(formData: FormData, methodType: string,id:any){
    const href = `${BaseUrl.BOOKING}/event-attendance/uplode/${id}`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }

  deleteEventAttendanceFile(id: number): Observable<any> {
    const href = `${BaseUrl.BOOKING}/event-attendance/delete/${id}`;
    return this.http.delete<any>(href);
  }

  getWorkshopAttendanceDetails(id) {
    const href = `${BaseUrl.BOOKING}/workshop-attendance/${id}`;
    return this.http.get<any>(href);
  }

  getWorkshopAllAttendanceBookingDetails(id) {
    const href = `${BaseUrl.BOOKING}/workshop-attendance/workshopDelivery/${id}`;
    return this.http.get<any>(href);
  }

  updateWorkshopAttendancDetails(formData: FormData, methodType: string) {
    const href = `${BaseUrl.BOOKING}/workshop-attendance/update`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }

  saveNewWorkshopAttendancDetails(formData: FormData, methodType: string) {
    const href = `${BaseUrl.BOOKING}/workshop-attendance/new`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }

  uplodeWorkshopAttendancFile(formData: FormData, methodType: string,id:any){
    const href = `${BaseUrl.BOOKING}/workshop-attendance/uplode/${id}`;
    return this.uploadService.uploadFile(href, formData,methodType);
  }

  deleteWorkshopAttendanceFile(id: number): Observable<any> {
    const href = `${BaseUrl.BOOKING}/workshop-attendance/delete/${id}`;
    return this.http.delete<any>(href);
  }


  approveWorkshopBooking(body){
    const href = `${BaseUrl.BOOKING}/booking-workshop/update/approve`;
    return this.http.put<any>(href, body);
  }


  cancelWorkshopBooking(body) {
    const href = `${BaseUrl.BOOKING}/workshop-cancellation/approve`;
    return this.http.put<any>(href, body);
  }

  discountinedWorkshopBooking(body) {
    const href = `${BaseUrl.BOOKING}/workshop-cancellation/discountined`;
    return this.http.put<any>(href, body);
  }

  getAllSUUserPaginated(deliveryId,sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/manual-assign-course/getServiceUser/${deliveryId}`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  findAllWaitingBookings(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking-waiting-list/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  manuallyAssignCourseSL( payload: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/manual-assign-course/new`;
    return this.http.post<any>(href, payload);
  }

  findFilteredWaitingList(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking-waiting-list/filteredBookings`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getwaitingDetails(id: any) {
    const href = `${BaseUrl.BOOKING}/booking-waiting-list/${id}`;
    return this.http.get<any>(href);
  }
 
  downloadAttendance(data: any,documentId:any) {
    const href = `${BaseUrl.BOOKING}/attendance/download?documentId=${documentId}`;
    return this.http.post<string>(href, data);
  }

  confirmRequest(payload: any) {
    const href = `${BaseUrl.BOOKING}/booking-course-cancellation/approval`;
    return this.http.put<any>(href, payload);
  }
  discontinueBooking(payload) {
    const href = `${BaseUrl.BOOKING}/booking-course-cancellation/discontinued`;
    return this.http.put<any>(href, payload);
  }


  refoundBooking(payload) {
    const href = `${BaseUrl.BOOKING}/booking-course-cancellation/refund`;
    return this.http.put<any>(href, payload);
  }


  cancelBookingByCC(payload: any) {
    const href = `${BaseUrl.BOOKING}/booking-course-cancellation/bookingCancellation`;
    return this.http.put<any>(href, payload);
  }

  getAllSLForAssignWorkshop(deliveryId,sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/manual-assign-workshop/getServiceUser/${deliveryId}`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getAllEventSUUserPaginated(deliveryId,sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/manual-assign-event/getServiceUser/${deliveryId}`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  manuallyAssignWorkshopSL( payload: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/manual-assign-workshop/new`;
    return this.http.post<any>(href, payload);
  }

  manuallyAssignEventSL( payload: any): Observable<any> {
    const href = `${BaseUrl.BOOKING}/manual-assign-event/new`;
    return this.http.post<any>(href, payload);
  }

  cancelWorkshopBookingByCC(body) {
    const href = `${BaseUrl.BOOKING}/workshop-cancellation/bookingCancellation`;
    return this.http.put<any>(href, body);
  }
}
