import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { BaseUrl } from "src/app/framework/constants/url-constants";



@Injectable({
  providedIn: 'root'
})
export class CaptrLearnersService {
  constructor(private readonly http: HttpClient) { }

  findAllPaginated(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/service-leaver-details/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  getUserDetails(id) {
    const href = `${BaseUrl.USER}/service-leaver-details/${id}`;
    return this.http.get<any>(href);
  }

  resolveLoggedInUserRole() {
    return JSON.parse(atob(localStorage.getItem('token').split('.')[1])).roleId;
  }

  getRefDataForReedCtp() {
    const href = `${BaseUrl.USER}/ref-data-choice/all`;
    return this.http.get<any>(href).pipe(
      tap(data => {
       const jsonData= JSON.stringify(data)
       const encodedData = encodeURIComponent(jsonData)
        localStorage.setItem('refData_ServiceLeaver', encodedData);
      })
    );
  }

  getSLDetailsRefData() {
    const href = `${BaseUrl.USER}/service-leaver-details/refData`;
    return this.http.get<any>(href);
  }

  saveNewLearners(payload) {
    const href = `${BaseUrl.USER}/service-leaver-details/create`;
    return this.http.post<any>(href, payload);
  }

  updateLearners(payload) {
    const href = `${BaseUrl.USER}/service-leaver-details/update`;
    return this.http.put<any>(href, payload);
  }

  getFilteredSUList(sort: string, size: number, page: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/user/filterUserList`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  createAndSubmitForm(url: string): void {
    const fd = document.createElement('form');
    fd.setAttribute('action', url);
    fd.setAttribute('method', 'POST');
    const inputElem = document.createElement('input');
    inputElem.setAttribute('name', 'access_token');
    inputElem.setAttribute('value', 'Bearer ' + localStorage.getItem('token'));
    fd.appendChild(inputElem);
    const holder = document.getElementById('form_holder');
    holder.appendChild(fd);
    fd.submit();
    holder.removeChild(fd);
  }

  findAllCaseNotes(sort: string,size: number, page: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/rctp-case-note/search`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  viewUserCaseNote(id: any) {
    const href = `${BaseUrl.USER}/user-case-note/view/${id}`;
    return this.http.get<any>(href);
  }

  deleteUserCaseNote(id:any) {
    const href = `${BaseUrl.USER}/user-case-note/${id}`;
    return this.http.delete<any>(href);
  }

  printcaseNote(payload) {
    const href = (`${BaseUrl.USER}/user-case-note/print`);
    return this.http.post(href, payload,{responseType: 'blob',observe: 'response'});
  }

  getInterventionList(userId) {
    const href = `${BaseUrl.PLAN}/meeting/intervention/${userId}`;
    return this.http.get<any>(href); 
  }

  getActivityRefData() {
    const href = `${BaseUrl.PLAN}/activity-refData/captr`;
    return this.http.get<any>(href); 
  }

  createCalendarIntervention(payload) {
    const href = `${BaseUrl.PLAN}/calendarIntervention`;
    return this.http.post<any>(href, payload);
  }

  createMeeting(payload) {
    const href = `${BaseUrl.PLAN}/appointment`;
    return this.http.post<any>(href, payload);
  }

  getEventByUserId(userId: string) {
    const href = `${BaseUrl.PLAN}/calendar?serviceUserId=${userId}`;
    return this.http.get<any>(href)
  }

  getSingleAppointmentData(appointmentId) {
    const href = `${BaseUrl.PLAN}/appointment/${appointmentId}`;
    return this.http.get<any>(href); 
  }

  getRefIntOrganizationList(interventionId) {
    const href = `${BaseUrl.PLAN}/meeting/organisation/${interventionId}`;
    return this.http.get<any>(href); 
  }

  getInterventionData() {
    const href = `${BaseUrl.PLAN}/activity-refData/ref-intervention`;
    return this.http.get<any>(href);
  }

  getCohortRefData() {
    const href = `${BaseUrl.USER}/ref-data/cohort`;
    return this.http.get<any>(href);
  }
  
  getOutcomeContent(id: number) {
    const href = `${BaseUrl.USER}/outcome/${id}`;
    return this.http.get<any>(href);
  }
  getPlanRefDataForReedCtp() {
    const href = `${BaseUrl.PLAN}/ref-data-choice/all`;
    return this.http.get<any>(href).pipe(
      tap(data => {
       const jsonData= JSON.stringify(data)
       const encodedData = encodeURIComponent(jsonData)
        localStorage.setItem('refData_Plan_v2', encodedData);
      })
    );
  }

  updateOutcomeJob(payload) {
    const href = `${BaseUrl.USER}/outcome`;
    return this.http.put<any>(href, payload);
  }

  getRefData(string: any) {
    const href = `${BaseUrl.REFERENCE_DATA}/location/town-city/${string}`;
    return this.http.get<any>(href);
  }


  getClaimsDetails(userId):Observable<any> {
    const href = `${BaseUrl.USER}/claims/${userId}`;
    return this.http.get<any>(href);
  }

  updateClaims(payload) {
    const href = `${BaseUrl.USER}/claims/update`;
    return this.http.put<any>(href, payload);
  }

  getPersonalStatementInfo() {
    const href = `${BaseUrl.CVB}/personal-statement`;
    return this.http.get(href);
  }

  createUpdateContactDetails(payload: any) {
    const href = `${BaseUrl.USER}/serviceLeverContactDetails/update`;
    return this.http.put<any>(href, payload);  
  }

  getSlContactDetails(id) {
    const href = `${BaseUrl.USER}/serviceLeverContactDetails/${id}`;
    return this.http.get<any>(href);
  }

  updateSkillDetails(payload) {
    const href = `${BaseUrl.USER}/skillQualification/update`;
    return this.http.put<any>(href, payload);
  }

  getSLSkillsDetails(id) {
    const href = `${BaseUrl.USER}/skillQualification/get/${id}`;
    return this.http.get<any>(href);
  }

  updateMeeting(payload) {
    const href = `${BaseUrl.PLAN}/appointment`;
    return this.http.put<any>(href, payload);
  }

  getAppontmentById(userId: string) {
    const href = `${BaseUrl.PLAN}/appointment/${userId}`;
    return this.http.get<any>(href)
  }

  getLegacyDetailsById(userId: string) {
    const href = `${BaseUrl.USER}/legacy/${userId}`;
    return this.http.get<any>(href)
  }
  
  findAllCourseBookings(sort: string, page: number, size: number, body: any,userId): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking/${userId}`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }


  findAllActiveJobApplication(sort: string,size: number, page: number, body: any): Observable<any> {
    const href = `${BaseUrl.JOBS}/strapi-job/activeJobApplication`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }
  sendRegistrationLink(id) {
    const href = `${BaseUrl.USER}/service-leaver-details/sendLink/${id}`;
    return this.http.get<any>(href);
  }

  findAllNegativeJobApplication(sort: string,size: number, page: number, body: any): Observable<any> {
    const href = `${BaseUrl.JOBS}/strapi-job/negativeJobApplication`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString())
        .set('sort', sort.toString())
    });
  }

  findAlllegacyJouranalNotes(sort: string,size: number, page: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/legacy/legacyJournalsearch`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('page', page.toString())
        .set('size', size.toString()) 
        .set('sort', sort.toString())
    });
  }

  findAllEventBookings(sort: string, page: number, size: number, body: any,userId): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking-event/${userId}`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
  
  findAllUnassignedServiceLevers(sort: string, page: number, size: number, body: any): Observable<any> {
    const href = `${BaseUrl.USER}/service-leaver-details/unAssignedSL`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }

  findAllWorkshopBookings(sort: string, page: number, size: number, body: any,userId): Observable<any> {
    const href = `${BaseUrl.BOOKING}/booking-workshop/${userId}`;
    return this.http.post<any>(href, body, {
      params: new HttpParams()
        .set('sort', sort.toString())
        .set('page', page.toString())
        .set('size', size.toString())
    });
  }
  
  updateUsername(payload) {
    const href = `${BaseUrl.USER}/service-leaver-details/updateUsername`;
    return this.http.put<any>(href, payload);
  }

}
