<mat-card id="view-booking">
    <mat-card-header>
        <span class="action-buttons">
            <button id="exit_button" mat-stroked-button
                    [routerLink]="['../confirm-booking']"
                    [queryParams]="{ id: edId, operation: 'view',hideEdit: isActive }">
              Exit
            </button>
        </span>
    </mat-card-header>
    <mat-card-content>
        <dl>
            <div class="label-hr">
                <span>Event booking details</span>
            </div>
            <div>
                <dt class="details-label">Event name</dt>
                <dd>{{ booking?.eventName || "N/A"}}</dd>
            </div>
        
            <div>
                <dt class="details-label">Service leaver name</dt>
                <dd>{{ booking?.serviceLeaverName || "N/A"}}</dd>
            </div>
        
            <div>
                <dt class="details-label">Email address</dt>
                <dd>{{ booking?.email || "N/A" }}</dd>
            </div>
            <div>
                <dt class="details-label">Rank</dt>
                <dd>{{ booking?.rankName || "N/A"}}</dd>
            </div>
            <div>
                <dt class="details-label">Length of service</dt>
                <dd>{{ booking?.lengthOfService || "N/A"}}</dd>
            </div>
            <div>
                <dt class="details-label">Entitlement to CTP service name</dt>
                <dd>{{ booking?.entitlementToCTPServiceName || "N/A"}}</dd>
            </div>
        </dl>
        <dl>
            <div class="label-hr">
                <span>Action</span>
            </div>
        </dl>
        <mat-card-footer>
            <span class="spacer"></span>
            <span class="action-buttons" *ngIf="isEnableRejectBtn && isAuthorized(157,11)">
                <button type="button" id="reject_button" (click)="onCancelBookingClick()" mat-stroked-button >Cancel booking</button>  
            </span>
          </mat-card-footer>
    </mat-card-content>
</mat-card>