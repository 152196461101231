<mat-card>
  <mat-card-header class="flex-end border-left mb-20">
    <span style="width: 80%;">  <h1 class="page-title"> View course details</h1></span>
    <span class="spacer"></span>
    <span class="action-buttons">
      <button *auth="[141, 3]" id="edit_button" mat-stroked-button (click)="routePath()">
        Edit
      </button>
      <button id="exit_button" mat-stroked-button [routerLink]="['../']">Exit</button>
    </span>
  </mat-card-header>
  <mat-card-content>
    <div style="justify-content: center; align-items: center;">
      <app-dynamic-form-view [identifier]="identifier" [schemaUrl]="schemaUrl" [apiUrl]="apiUrl"
        [titleInformation]="titleInformation" [collection]="collection" [addRelationFields]="addRelationFields">
      </app-dynamic-form-view>
    </div>
  </mat-card-content>
</mat-card>
