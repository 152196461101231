<mat-tab-group (selectedIndexChange)="onTabChanged($event)" [selectedIndex]="activeIndex" mat-stretch-tabs="false" mat-align-tabs="start" dynamicHeight fitInkBarToContent>

    <mat-tab label="Active">
      <ng-template matTabContent>
        <app-dynamic-table [onlyList]="true" [collection]="collection" [fields]="fields" [search]="searchFields"
          [buttonLable]="buttonLable" [tabHead]="tabHead" [baseRoute]="baseRoute">
        </app-dynamic-table>
      </ng-template>
    </mat-tab>

    <mat-tab label="Pending">
      <ng-template matTabContent>
        <app-dynamic-table [onlyList]="true" [collection]="collection" [fields]="fields" [search]="searchFields"
        [tabHead]="tabHead1" [baseRoute]="baseRoute">
        </app-dynamic-table>
      </ng-template>
    </mat-tab>

    <mat-tab label="Closed">
        <ng-template matTabContent>
          <app-dynamic-table [onlyList]="true" [collection]="collection" [fields]="fields" [search]="searchFields"
          [tabHead]="tabHead2" [baseRoute]="baseRoute">
          </app-dynamic-table>
        </ng-template>
      </mat-tab>

  </mat-tab-group>