import { Routes } from '@angular/router';
import { SessionsComponent } from './sessions.component';
import { SigninComponent } from './signin/signin.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangeExpiredPasswordComponent } from './change-expired-password/change-expired-password.component';
import { VerifyOtpComponent } from './signin/verify-otp/verify-otp.component';
import { AuthGuard } from '../framework/guards/auth.guard';

export const SessionsRoutes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: SessionsComponent,
    children: [
      {
        path: 'signin',
        component: SigninComponent,
      }, {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password' }
      }, {
        path: 'set-password',
        component: ResetPasswordComponent,
      }, {
        path: 'change-expired-password',
        component: ChangeExpiredPasswordComponent,
      },
      {
        path: 'signin/otp',
        component: VerifyOtpComponent,
        canActivate: [AuthGuard],
      }
      
    ]
  }
];
