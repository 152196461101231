<mat-card id='edit_content'>
    <mat-card-header>
        <span class="spacer">
            <app-stepper-navigation></app-stepper-navigation>
        </span>
        <span class="action-btn-alignment">
            <button id="add_button" mat-stroked-button [routerLink]="['module']"
                queryParamsHandling="preserve"> Add </button>
            <button id="exit_button" mat-stroked-button [routerLink]="[exitButtonBehaviour]"
                [queryParams]="{id: contentId}">Exit</button>
        </span>
    </mat-card-header>
    <div class="label-hr">
        <span>Uploaded Modules</span>
    </div>
    <mat-card-content>

        <mat-table id="content_modules_table" #moduleTable [dataSource]="dataSource" class="isMobile" cdkDropList
            (cdkDropListDropped)="updateSequence($event)" [cdkDropListData]="dataSource">

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef id="contents_name_button">Module Name 1</mat-header-cell>
                <mat-cell *matCellDef="let element" class="wordBreak"> <span class="mobile-label">Module Name:</span>{{element.name}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef id="subMenuCategory_button">Status</mat-header-cell>
                <mat-cell *matCellDef="let element"> <span class="mobile-label call-status-label">Status:
                    </span>{{element.statusName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell class="actions-column" *matHeaderCellDef>Action</mat-header-cell>
                <mat-cell class="actions-column" *matCellDef="let element"> <span class="mobile-label"></span>

                    <button id="download_contents_button_{{element.id}}" mat-icon-button color="primary"
                        matTooltip="Refresh" [matTooltipShowDelay]="800" (click)="refreshStatus(element)"
                        *ngIf="showRefreshButton(element.statusName)">
                        <mat-icon>refresh</mat-icon>
                    </button>

                    <button *auth="[172,3]" id="edit_contents_button_{{element.id}}" mat-icon-button
                        [routerLink]="['module']" [queryParams]="{moduleId: element.id}" queryParamsHandling="merge"
                        matTooltip="Edit" [matTooltipShowDelay]="800">
                        <mat-icon color="primary">create</mat-icon>
                    </button>

                    <button *auth="[172,2]" id="delete_contents_button_{{element.id}}" mat-icon-button
                        (click)="onDeleteClicked(element.id)" title="Delete" attr.aria-label="Delete {{element.id}}">
                        <mat-icon color="accent">delete</mat-icon>
                    </button>

                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"
                cdkDragBoundary=".mat-table"></mat-row>

        </mat-table>
    </mat-card-content>

    <mat-card-footer>
        <span class="spacer"></span>
    </mat-card-footer>

</mat-card>