import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { debounceTime, switchMap, startWith, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AddMorePopupComponent } from './add-more-popup/add-more-popup.component';
import { DynamicTableService } from 'src/app/framework/components/dynamic-table/dynamic-table.service';


@Component({
  selector: 'formly-relation-type',
  templateUrl: './relation-type.component.html',
  styleUrls: ['./relation-type.component.scss']
})
export class RelationTypeComponent extends FieldType implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  filterBy: { 'keyword': string };
  searchControl: FormControl = new FormControl();
  filteredOptionsList: Observable<{ id: number; name: string }[]> = of([]);
  optionsList: { id: string; name: string }[] = [];
  selectedOptions: Array<{ id: string;[key: string]: any }> = [];
  relationControl: FormControl = new FormControl<string[]>([]);
  oneToOneFlag = false;
  maxLimitFlag = false;
  optionSelectedFromDropdown = false;
  filteredData: any;
  excludedIds: any;
  locationId: any;
  filterByRelationId: any;
  mainField: any;
  tempList: any;

  get isManyRelation(): boolean {
    return this.to['relation'] === 'many';
  }

  constructor(private http: HttpClient,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private readonly dynamicTableService: DynamicTableService,
  ) {
    super();
  }

  async ngOnInit() {
    const mainField = this.to['mainField'];
    let sort;
    const pagination = {
      page: 1,
      pageSize: 1000,
    };
    sort = `${mainField},asc`;
    this.dynamicTableService
      .getRefGraphQLList(`${sort}`, pagination.page, pagination.pageSize, { keyword: "" }, this.to['api']).subscribe(data => {
        this.tempList = data;
        this.onClick()
      })

    this.relationControl.valueChanges.subscribe((val) => {
      if (val && val.length > 0) {
        this.selectedOptions = this.getRelationControlValues();
        if (this.field.formControl) {
          this.field.formControl.setValue(val);
        }
      }
    });


    this.filteredOptionsList = this.searchControl.valueChanges.pipe(
      startWith(''),
      switchMap((value) => {
        let filterById;
        if (this.field?.templateOptions?.filterByRelation) {
          this.filterByRelationId = this.model[this.to['filterByRelation']];
          const newData = this.filterByRelationId?.data;
          if (newData) {
            filterById = Array.isArray(newData)
              ? newData.map(item => item.id.toString())
              : [newData.id.toString()];
          } else {
            filterById = this.filterByRelationId.map(item => item.toString());
          }
          return this.fetchOptions({ 'keyword': value == null ? "" : value, 'tabHead': filterById });
        } else {
          const filterBy = { 'keyword': value, 'tabHead': this.getTabhead() };
          return this.fetchOptions(filterBy);
        }
      })
    );


    this.waitForModel().then(() => {
      this.field.formControl.setErrors(null);
      if (this.selectedOptions.length === this.to.maxQuantity) {
        this.maxLimitFlag = true;
      } else {
        this.maxLimitFlag = false;
      }
      if (this.to.minQuantity && this.selectedOptions.length >= this.to.minQuantity) {
        this.to.required = false;
      }
      this.setInitialInputValue();
      this.setInitialInputValue1();
    });
  }

  onClick() {
    if (!this.searchControl.value) {
      this.searchControl.setValue('');
    }
  }

  removeNullRelations() {
    if (this.formControl?.getRawValue()?.data === null) {
      this.formControl.setValue([]);
    }
  }

  private setInitialInputValue() {
    if (this.selectedOptions.length > 0) {
      const selectedOptionNames = this.selectedOptions.map(option => option.name).join(', ');
      this.relationControl.setValue(selectedOptionNames);
      if (this.to['relation'] !== 'one') {
        this.searchControl.setValue('');
      }
    }
  }

  private setInitialInputValue1() {
    if (this.selectedOptions.length > 0) {
      const selectedIds = this.selectedOptions.map(option => option.id);
      this.relationControl.setValue(selectedIds);
    }
  }

  getAllOptions() {
    if (this.to['relation'] === 'many') {
      const searchTerm = '';
      this.filteredOptionsList = this.fetchOptions({ 'keyword': searchTerm })
    }
  }

  waitForModel(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkModel = () => {
        if (this.formControl.getRawValue()) {
          const rawData = this.formControl.getRawValue().data;
          this.removeNullRelations();
          if (rawData && typeof rawData === 'object') {
            if (this.to['relation'] === 'one') {
              this.relationControl = this.formControl as FormControl;
              let mainField = this.to['mainField'];
              this.oneToOneFlag = true;
              const selectedOption = {
                id: rawData.id,
                name: rawData.attributes ? rawData.attributes[mainField] : null,
              };
              this.searchControl.setValue(selectedOption.name);
              this.selectedOptions.push(selectedOption);
              this.optionSelectedFromDropdown = true;
            } else if (this.to['relation'] === 'many' && Array.isArray(rawData) && rawData.length > 0) {
              this.relationControl = this.formControl as FormControl;
              let mainField = this.to['mainField'];
              this.selectedOptions = this.selectedOptions.concat(rawData.map((item: any) => {
                return {
                  id: item.id,
                  name: item.attributes ? item.attributes[mainField] : null,
                };
              }));
              this.optionSelectedFromDropdown = true;
            }
            resolve();
          }
        } else {
          setTimeout(checkModel, 100);
        }
      };
      checkModel();
    });
  }

  onOptionSelected(event: any): void {
    const selectedOption = {
      id: event.option.value.toString(),
      name: event.option?.viewValue,
    };
    if (this.isManyRelation) {
      this.selectedOptions.push(selectedOption);
      const newIds = this.selectedOptions.map((opt) => opt.id);
      this.relationControl.setValue(newIds);
      this.searchControl.setValue('');
    }
    else {
      this.selectedOptions = [];
      this.selectedOptions.push(selectedOption);
      const newIds = this.selectedOptions.map((opt) => opt.id);
      this.relationControl.reset()
      this.relationControl.setValue(newIds);
      this.searchControl.setValue(selectedOption.name);
      this.oneToOneFlag = true;
    }
    if (this.selectedOptions.length === this.to.maxQuantity) {
      this.maxLimitFlag = true;
    }
    if (this.selectedOptions.length >= this.to.minQuantity) {
      this.to.required = false;
    }
    this.optionSelectedFromDropdown = true;
  }

  remove(id: string): void {
    this.searchControl.setValue(this.searchControl.value);
    const index = this.selectedOptions.findIndex((opt) => opt.id === id);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.relationControl.setValue(this.selectedOptions.map((opt) => opt.id));
    }
    if (this.selectedOptions?.length === 0) {
      this.relationControl = this.formControl as FormControl;
      this.formControl.setValue([]);
    }
    if (this.to['relation'] === 'one') {
      this.oneToOneFlag = false
    }
    if (this.selectedOptions.length < this.to.maxQuantity) {
      this.maxLimitFlag = false;
    }
    if (this.selectedOptions.length < this.to.minQuantity) {
      this.to.required = true;
    }
  }

  private getRelationControlValues(): Array<{ id: string; name: string }> {
    const ids = this.relationControl.value || [];
    return ids.map((id) => {
      const option = this.optionsList.find((opt) => opt.id === id);
      return option || { id, name: id };
    });
  }

  validationErrorKeys(): string[] {
    return Object.keys(this.field.validation?.messages || {});
  }

  getErrorMessage(errorKey: string): string | undefined {
    const message =
      this.field?.validation?.messages &&
      this.field.validation.messages[errorKey];
    if (typeof message === 'function') {
      const error = this.field.formControl?.errors ? this.field.formControl.errors[errorKey] : undefined;
      const result = message(error, this.field);
      if (typeof result === 'string') {
        return result;
      }
      return "required field";  // or you can provide a default message if you prefer
    }
    return message as string | undefined;
  }

  private fetchOptions(filterBy): Observable<{ id: number; name: string }[]> {

    const mainField = this.to['mainField'];
    let sort;
    const pagination = {
      page: 1,
      pageSize: 1000,
    };
    let dataArray = this.tempList;

    // Process the response data
    return of(dataArray).pipe(
      map((response) => {
        const responseData = response;
        let dataArray;

        for (const key in responseData.data) {
          if (responseData.data.hasOwnProperty(key)) {
            if (Array.isArray(responseData.data[key].data)) {
              dataArray = responseData.data[key].data;
              break;
            }
          }
        }


        this.excludedIds = this.getRelationControlValues().map(
          (opt) => opt.id.toString()
        );

        this.filteredData = dataArray?.filter(
          (item: { id: any }) => !this.excludedIds.includes(item.id.toString())
        );

        const newOptions = this.filteredData.map(
          (item: { id: any; attributes: { [key: string]: string } }) => ({
            id: item.id.toString(),
            name: item.attributes[this.to['mainField']] || item.attributes[mainField],
          })
        );
        newOptions.forEach((opt: { id: string; name: string }) => {
          if (!this.optionsList.find((existingOpt) => existingOpt.id === opt.id)) {
            this.optionsList.push(opt);
          }
        });

        if (filterBy && filterBy.keyword) {
          const keyword = filterBy.keyword.toLowerCase();
          const filteredOptions = newOptions.filter((option) =>
            option.name.toLowerCase().includes(keyword)
          );
          // Return the filtered options
          return filteredOptions;
        }

        return newOptions;
      }),
      catchError(err => {
        if (err.error && err.error.error && err.error.error.message) {
          this.snackBar.open(err.error.error.message, 'Dismiss');
        }
        return of([]);
      })
    );
  }

  addMoreBtnCLick(): void {
    const dialogRef = this.dialog.open(AddMorePopupComponent, {
      data: this.to,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getAllOptions();
      this.filteredOptionsList = this.searchControl.valueChanges.pipe(
        startWith(''),
        switchMap((value) => {
          const filterBy = { 'keyword': value, 'tabHead': 'Active' };
          return this.fetchOptions(filterBy);
        })
      );
    });

  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      this.resetForOneToOne();
    }
  }

  resetForOneToOne() {
    if (this.to['relation'] === 'one') {
      this.relationControl = this.formControl as FormControl;
      this.relationControl.reset();
      this.relationControl.clearAsyncValidators();
      this.selectedOptions = [];
    }
  }

  onBlur() {
    setTimeout(() => {
      if (!this.optionSelectedFromDropdown) {
        if (this.to['relation'] === 'one' && !this.relationControl.value) {
          this.searchControl.setValue('');
        }
        else if (this.to['relation'] === 'many') {
          this.searchControl.setValue('');
        }
      }
      this.optionSelectedFromDropdown = false;
    }, 100);
  }

  clearInput() {
    if (this.to['relation'] === 'one') {
      this.resetForOneToOne()
    }
    this.searchControl.setValue('');
  }

  getTabhead() {

    switch (this.field['key']) {
      case 'courses':
      case 'events':
      case 'workshops':
        return '2';

      case 'employer':
      case 'dos_service_provider':
      case 'provider':
        return 'Active';

      default:
        return '';
    }
  }

}
