<div>
  <mat-form-field style="width: 100%;">
    <mat-label>
      {{to['label']}}
    </mat-label>
    <input matInput [formControl]="timeFormControl" [ngxMatTimepicker]="pickerB" format="24" readonly [required]="to['required']"/>
    <mat-icon matSuffix (click)="pickerB.open()">
      watch_later
    </mat-icon>
    <mat-error *ngIf="to['required']">
      {{to['label']}} is required
    </mat-error>
  </mat-form-field>
  <ngx-mat-timepicker dottedMinutesInGap enableKeyboardInput color="accent" #pickerB></ngx-mat-timepicker>
</div>