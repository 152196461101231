import { Component, OnDestroy } from '@angular/core';
import { CourseManagementNavigation } from '../course-management-nav';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-course-provider',
  templateUrl: './course-provider.component.html',
  styleUrls: ['./course-provider.component.scss']
})
export class CourseProviderComponent{

  collection = 'providers';
  fields = ['providerName', 'providerId','createdAt'];
  searchFields=['providerName','providerId'];
  buttonLable ="provider";
  baseRoute = '/courses-events';

  dynamicFilters: any = {
    publishedAt: { null: true },
  };

  constructor (
  private readonly inPageNavService: InPageNavService,
  private readonly router: Router,
    private readonly courseNavigation: CourseManagementNavigation,
  ) {
    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

}
