import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';

@Component({
  selector: 'formly-repeat-section',
  templateUrl: './repeat-type.component.html',
  styleUrls: ['./repeat-type.component.scss']
})
export class RepeatTypeComponent extends FieldArrayType implements OnInit {
  maxLimitFlag = true;
  minLimitFlag = false;
  panelExpanded: boolean[] = [];
  hasError: boolean = false;
  private isUpdating = false;

  ngOnInit() {
    this.panelExpanded[0] = true;
    this.waitForModel().then(() => {
      if (this.formControl.value.length > 0) {

        this.formControl.value.forEach(data => {
          this.panelExpanded.push(true)
        })
        if (this.field.fieldGroup.length >= this.to['maxQuantity']) {
          this.maxLimitFlag = false;
        }
        if (this.field.fieldGroup.length > this.to['minQuantity'] && (this.field.fieldGroup.length != 1)) {
          this.minLimitFlag = true;
        }
      } else {
        this.addMore();
      }
    }).catch(err => {
      this.addMore();
    }
    );

    this.formControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (!this.isUpdating) {
          this.updateFields();
        }else{
          this.isUpdating = false
        }
      });

      this.formControl.valueChanges.subscribe(value => {
        this.isUpdating = false
      });

  }

  waitForModel(): Promise<void> {
    let count = 0;
    return new Promise<void>((resolve, reject) => {
      const checkModel = () => {
        count++;
        if (count > 20) {
          reject();
        }
        if (this.formControl.value.length > 0) {
          this.updateFields();
          resolve();
        } else {
          setTimeout(checkModel, 100);
        }
      };
      checkModel();
    });
  }

  addMore() {
    this.add();
    this.panelExpanded.push(true);
    if ((this.field.fieldGroup.length > this.to['minQuantity']) && (this.field.fieldGroup.length != 1)) {
      this.minLimitFlag = true;
    }
    if (this.field.fieldGroup.length >= this.to['maxQuantity']) {
      this.maxLimitFlag = false;
    }
  }

  removeRow(i) {
    this.remove(i);
    if ((this.field.fieldGroup.length <= this.to['minQuantity'])) {
      this.minLimitFlag = false;
    }
    if (this.field.fieldGroup.length < this.to['maxQuantity']) {
      this.maxLimitFlag = true;
    }
    if (this.field.fieldGroup.length == 1) {
      this.minLimitFlag = false;
    }
  }

  updateFields() {
    if (this.isUpdating) return;
    for (let i = 0; i < this.model.length; i++) {
      const value = this.model[i];

      if (!value['closed'] && !value['openHours']) {
        this.field.fieldGroup.forEach((field, index) => {
          if (index === i) {
            field.fieldGroup.forEach(data => {
              data.fieldGroup.forEach(field => {
                if (field.type === 'time') {
                  field.templateOptions['disabled'] = false;
                }
                if (field.type === 'toggle' && value['startTime']) {
                  field.formControl.reset();
                  field.formControl.setValue(null);
                  field.templateOptions['disabled'] = true;
                }
              });
            });
          }
        });
        this.isUpdating = true;
      } else {
        if (value['closed'] === true) {
          this.handleClosedField(i);
        }
        if (value['openHours'] === true) {
          this.handleOpenHoursField(i);
        }
      }
    }
  }

  private handleClosedField(i: number) {
    this.field.fieldGroup.forEach((field, fieldIndex) => {
      if (fieldIndex === i) {
        field.fieldGroup.forEach(data => {
          data.fieldGroup.forEach(field => {
            if (field.type === 'toggle' && field.key === 'openHours') {
              field.formControl.reset();
              field.formControl.setValue(false);
            }
            if (field.type === 'time') {
              field.formControl.reset();
              field.formControl.setValue(null);
              field.templateOptions['disabled'] = true;
            }
          });
        });
      }
    });
    this.isUpdating = true;
  }

  private handleOpenHoursField(i: number) {
    this.field.fieldGroup.forEach((field, fieldIndex) => {
      if (fieldIndex === i) {
        field.fieldGroup.forEach(data => {
          data.fieldGroup.forEach(field => {
            if (field.type === 'toggle' && field.key === 'closed') {
              field.formControl.reset();
              field.formControl.setValue(false);
            }
            if (field.type === 'time') {
              field.formControl.reset();
              field.formControl.setValue(null);
              field.templateOptions['disabled'] = true;
            }
          });
        });
      }
    });
    this.isUpdating = true;
  }

}