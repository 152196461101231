import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicTableComponent } from './dynamic-table.component';
import { MaterialModule } from '../../material/material.module';
import { AppConfirmModule } from '../app-confirm/app-confirm.module';
import { RoomPopupComponent } from './room-popup/room-popup.component';
import { DynamicFormsModule } from 'src/app/features/dynamic-forms/dynamic-forms.module';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { FileUploadTypeComponent } from 'src/app/features/dynamic-forms/file-upload-type/file-upload-type.component';
import { QuillFieldTypeComponent } from 'src/app/features/dynamic-forms/quill-field-type/quill-field-type.component';
import { RepeatTypeComponent } from 'src/app/features/dynamic-forms/repeat-type/repeat-type.component';
import { SingleTypeComponent } from 'src/app/features/dynamic-forms/single-type/single-type.component';
import { TimeTypeComponent } from 'src/app/features/dynamic-forms/time-type/time-type.component';
import { TitleTypeComponent } from 'src/app/features/dynamic-forms/title-type/title-type.component';
import { RelationTypeComponent } from 'src/app/features/dynamic-forms/relation-type/relation-type.component';
import { OverWritePopupComponent } from './overWrite-popup/overWrite-popup.component';
@NgModule({
  declarations: [DynamicTableComponent, RoomPopupComponent, OverWritePopupComponent],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AppConfirmModule,
    DynamicFormsModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'relation', component: RelationTypeComponent },
        { name: 'single', component: SingleTypeComponent },
        { name: 'title', component: TitleTypeComponent }
        // ... your other types
      ],
    }),
    FormlyMaterialModule,
    FormlyMatToggleModule,
    FormlyMatDatepickerModule
  ],
  exports: [DynamicTableComponent]
})
export class DynamicTableModule { }
