import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { CanDeactivateGuard } from 'src/app/framework/guards/can-deactivate.guard';
import { Observable } from 'rxjs';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { RegexPattern } from 'src/app/framework/constants/regex-constant';

@Component({
    selector: 'app-add-edit-course-provider',
    templateUrl: './add-edit-course-provider.component.html',
    styleUrls: ['./add-edit-course-provider.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 }))
            ])
        ])
    ]
})
export class AddEditCourseProviderComponent implements OnInit, OnDestroy, CanDeactivateGuard {

    form = new FormGroup({});
    websitePatten = /^(https?:\/\/|ftp:\/\/|www\.)[\w.-]+(?:\.[\w\.-]+)*(:\d+)?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/;
    isNotSaved: boolean = false;
    postCodePattern = /^(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z])))) [0-9][A-Z]{2}$/;
    model: any = {};
    options: FormlyFormOptions = {};
    fields: FormlyFieldConfig[] = [
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "type": "title",
                    "templateOptions": {
                        "label": "Provider name"
                    }
                },
                {
                    "key": "providerName",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Provider name is required.",
                            "minlength": "Minimum length should be 3 characters.",
                            "maxlength": "Maximum length should be 100 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Provider name",
                        "placeholder": "",
                        "description": "Minimum 3 and maximum 100 characters.",
                        "required": true,
                        "options": [],
                        "minLength": 3,
                        "maxLength": 100
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "providerId",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {}
                    },
                    "templateOptions": {
                        "label": "Provider ID",
                        "placeholder": "",
                        "description": "",
                        "options": []
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]

        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "type": "title",
                    "templateOptions": {
                        "label": "Provider contact details"
                    }
                },
                {
                    "key": "provContactDtls",
                    "type": "repeat",
                    "className": "col-md-12",
                    "validation": {
                        "messages": {
                            "min": "Minimum number should be 1.",
                            "max": "Maximum number should be 5."
                        }
                    },
                    "templateOptions": {
                        "label": "Provider contact details",
                        "placeholder": "",
                        "description": "At least 1 and up to 5 Provider contact details are allowed.",
                        "options": [],
                        "maxQuantity": 5,
                        "minQuantity": 1,
                        "required": true
                    },
                    "fieldArray": {
                        "fieldGroupClassName": "display-flex-column",
                        "fieldGroup": [
                            {
                                "fieldGroupClassName": "display-flex",
                                "fieldGroup": [
                                    {
                                        "key": "providerFirstName",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "required": "Provider contact first name is required.",
                                                "minlength": "Minimum length should be 3 characters.",
                                                "maxlength": "Maximum length should be 100 characters."
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Provider contact first name",
                                            "placeholder": "",
                                            "description": "Minimum 3 and maximum 100 characters.",
                                            "required": true,
                                            "options": [],
                                            "minLength": 3,
                                            "maxLength": 100
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    },
                                    {
                                        "key": "providerLastName",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "required": "Provider contact last name is required.",
                                                "minlength": "Minimum length should be 3 characters.",
                                                "maxlength": "Maximum length should be 100 characters."
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Provider contact last name",
                                            "placeholder": "",
                                            "description": "Minimum 3 and maximum 100 characters.",
                                            "required": true,
                                            "options": [],
                                            "minLength": 3,
                                            "maxLength": 100
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    }
                                ]
                            },
                            {
                                "fieldGroupClassName": "display-flex",
                                "fieldGroup": [
                                    {
                                        "key": "providerContactRole",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "required": "Provider contact role is required.",
                                                "minlength": "Minimum length should be 3 characters.",
                                                "maxlength": "Maximum length should be 100 characters."
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Provider contact role",
                                            "placeholder": "",
                                            "description": "Minimum 3 and maximum 100 characters.",
                                            "required": true,
                                            "options": [],
                                            "minLength": 3,
                                            "maxLength": 100
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    }
                                ]
                            },
                            {
                                "fieldGroupClassName": "display-flex",
                                "fieldGroup": [
                                    {
                                        "key": "telephoneNumber",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "required": "Telephone number is required.",
                                                "pattern": "Invalid telephone number"
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Telephone number",
                                            "placeholder": "",
                                            "description": "",
                                            "required": true,
                                            "options": [],
                                            "pattern": "^0\\d{9,10}$",
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    },
                                    {
                                        "key": "providerEmail",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "required": "Provider contact email address is required.",
                                                "pattern": "Invalid provider contact email address"
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Provider contact email address",
                                            "placeholder": "",
                                            "description": "",
                                            "required": true,
                                            "options": [],
                                            "pattern": RegexPattern.email
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    }
                                ]
                            },
                            {
                                "fieldGroupClassName": "display-flex",
                                "fieldGroup": [
                                    {
                                        "key": "addressLine1",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "required": "Address line 1 is required.",
                                                "minlength": "Minimum length should be 3 characters.",
                                                "maxlength": "Maximum length should be 100 characters."
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Address line 1",
                                            "placeholder": "",
                                            "description": "Minimum 3 and maximum 100 characters.",
                                            "required": true,
                                            "options": [],
                                            "minLength": 3,
                                            "maxLength": 100
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    },
                                    {
                                        "key": "addressLine2",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "minlength": "Minimum length should be 3 characters.",
                                                "maxlength": "Maximum length should be 100 characters."
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Address line 2",
                                            "placeholder": "",
                                            "description": "Minimum 3 and maximum 100 characters.",
                                            "options": [],
                                            "minLength": 3,
                                            "maxLength": 100
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    }
                                ]
                            },
                            {
                                "fieldGroupClassName": "display-flex",
                                "fieldGroup": [
                                    {
                                        "key": "townOrCity",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "minlength": "Minimum length should be 3 characters.",
                                                "maxlength": "Maximum length should be 100 characters."
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Town/City",
                                            "placeholder": "",
                                            "description": "Minimum 3 and maximum 100 characters.",
                                            "options": [],
                                            "minLength": 3,
                                            "maxLength": 100
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    },
                                    {
                                        "key": "county",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "minlength": "Minimum length should be 3 characters.",
                                                "maxlength": "Maximum length should be 100 characters."
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "County",
                                            "placeholder": "",
                                            "description": "Minimum 3 and maximum 100 characters.",
                                            "options": [],
                                            "minLength": 3,
                                            "maxLength": 100
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    }
                                ]
                            },
                            {
                                "fieldGroupClassName": "display-flex",
                                "fieldGroup": [
                                    {
                                        "key": "country",
                                        "type": "select",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "required": "Country is required."
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Country",
                                            "placeholder": "",
                                            "description": "",
                                            "required": true,
                                            "options": [
                                                {
                                                    "label": "England",
                                                    "value": "England"
                                                },
                                                {
                                                    "label": "Wales",
                                                    "value": "Wales"
                                                },
                                                {
                                                    "label": "Scotland",
                                                    "value": "Scotland"
                                                },
                                                {
                                                    "label": "Northern Ireland",
                                                    "value": "Northern Ireland"
                                                }
                                            ]
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    },
                                    {
                                        "key": "postcode",
                                        "type": "input",
                                        "className": "col-md-6",
                                        "validation": {
                                            "messages": {
                                                "required": "Postcode is required.",
                                                "pattern": "Invalid postcode"
                                            }
                                        },
                                        "templateOptions": {
                                            "label": "Postcode",
                                            "placeholder": "",
                                            "description": "",
                                            "required": true,
                                            "options": [],
                                            "pattern": this.postCodePattern
                                        },
                                        "fieldArray": null,
                                        "fieldGroupClassName": null,
                                        "fieldGroup": null
                                    }
                                ]
                            }

                        ]
                    },
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "type": "title",
                    "templateOptions": {
                        "label": "Provider information"
                    }
                },
                {
                    "key": "providerDescription",
                    "type": "richtext",
                    "className": "col-md-12",
                    "validation": {
                        "messages": {
                            "required":"Provider description is required",
                            "minlength": "Minimum length should be 5 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Provider description",
                        "placeholder": "",
                        "description": "Minimum 5 characters.",
                        "required": true,
                        "options": [],
                        "minLength": 5,
                        "attributes": {
                            "aria-label": "Provider description"
                            }
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                },
                {
                    "key": "providerWebsite",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "pattern": "Invalid provider website url."
                        }
                    },
                    "templateOptions": {
                        "label": "Provider website",
                        "placeholder": "",
                        "description": "URL must contain http:// or https://",
                        "options": [],
                        "pattern": this.websitePatten,
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                },
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "providerLogo",
                    "type": "file",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {

                        }
                    },
                    "templateOptions": {
                        "label": "Provider logo*",
                        "placeholder": "",
                        "description": "Upload image in JPEG/JPG, PNG, GIF, SVG, ICO format",
                        "required": true,
                        "options": [],
                        "allowedTypes": [
                            "images"
                        ],
                        "attributes": {
                        "aria-label": "Provider logo"
                        }
                    },
                    
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "providerLogoAlt",
                    "type": "input",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Alt text is required.",
                            "minlength": "Minimum length should be 3 characters.",
                            "maxlength": "Maximum length should be 300 characters."
                        }
                    },
                    "templateOptions": {
                        "label": "Alt text",
                        "placeholder": "",
                        "description": "Minimum 3 and maximum 300 characters",
                        "required": true,
                        "options": [],
                        "minLength": 3,
                        "maxLength": 300
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "ref_shared_programmes",
                    "type": "relation",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {}
                    },
                    "templateOptions": {
                        "label": "Programme",
                        "placeholder": "",
                        "description": "Please select an option from the list",
                        "required": true,
                        "options": [],
                        "api": "ref-shared-programmes",
                        "relation": "many",
                        "mainField": "name",
                        "minQuantity": 1,
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                },
                {
                    "key": "elcasProvider",
                    "type": "select",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "ELCAS approved provider is required."
                        }
                    },
                    "templateOptions": {
                        "label": "ELCAS approved provider?",
                        "placeholder": "",
                        "description": "",
                        "required": true,
                        "options": [
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            },
                            {
                                "label": "N/A",
                                "value": "N/A"
                            }
                        ]
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "reedApproved",
                    "type": "select",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Reed preferred provider is required."
                        }
                    },
                    "templateOptions": {
                        "label": "Reed preferred provider?",
                        "placeholder": "",
                        "description": "",
                        "required": true,
                        "options": [
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            }
                        ]
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                },
                {
                    "key": "passedDueDiligence",
                    "type": "select",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Passed due diligence is required."
                        }
                    },
                    "templateOptions": {
                        "label": "Passed due diligence?",
                        "placeholder": "",
                        "description": "",
                        "required": true,
                        "options": [
                            {
                                "label": "Yes",
                                "value": "Yes"
                            },
                            {
                                "label": "No",
                                "value": "No"
                            }
                        ]
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "status",
                    "type": "select",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                            "required": "Status is required."
                        }
                    },
                    "templateOptions": {
                        "label": "Status",
                        "placeholder": "",
                        "description": "",
                        "required": true,
                        "options": [
                            {
                                "label": "Active",
                                "value": "Active"
                            },
                            {
                                "label": "Closed",
                                "value": "Closed"
                            }
                        ]
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                },
                {
                    "key": "statusReason",
                    "type": "select",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {}
                    },
                    "templateOptions": {
                        "label": "Status reason",
                        "placeholder": "",
                        "description": "",
                        "options": [
                            {
                                "label": "Poor observation feedback",
                                "value": "Poor observation feedback"
                            },
                            {
                                "label": "Poor Service Leaver feedback",
                                "value": "Poor Service Leaver feedback"
                            },
                            {
                                "label": "Contract handed back",
                                "value": "Contract handed back"
                            },
                            {
                                "label": "Price increase (financial reasons)",
                                "value": "Price increase (financial reasons)"
                            },
                            {
                                "label": "Low course demand",
                                "value": "Low course demand"
                            }
                        ]
                    },
                    "hideExpression": "!model.status || model.status !== 'Closed'",
                    "expressionProperties": {
                        "templateOptions.required": "model.status === 'Closed'"
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "key": "lastAuditDate",
                    "type": "datepicker",
                    "className": "col-md-6",
                    "validation": {
                        "messages": {
                        }
                    },
                    "validators": {
                        "futureDate": {
                          "expression": (control) => {
                            if (!control.value) {
                              return true;
                            }
                            const selectedDate = new Date(control.value);
                            const today = new Date();
                            today.setHours(0, 0, 0, 0); 
                            selectedDate.setHours(0, 0, 0, 0);
                            return selectedDate <= today;
                          },
                          "message": "Last audit date cannot be in the future."
                        }
                      },
                    "templateOptions": {
                        "label": "Last audit date",
                        "placeholder": "",
                        "description": "Must be today or earlier",
                        "required": false,
                        "options": [],
                        "datepickerOptions": {
                            "max": new Date()
                          }
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                }
            ]
        },
        {
            "fieldGroupClassName": "display-flex",
            "fieldGroup": [
                {
                    "template": "Provider contact details visible to Service Leaver?"
                },
                {
                    "key": "providerContactVisibleToServiceLeaver",
                    "type": "radio",
                    "defaultValue": false,
                    "className": "col-md-6 hide-radio-bg",
                    "validation": {
                        "messages": {
                            "required": "Provider contact details visible to Service Leaver is required."
                        }
                    },
                    "templateOptions": {
                        "label": "",
                        "placeholder": "",
                        "description": "",
                        "options": [{
                            "label": "Yes",
                            "value": true
                        },
                        {
                            "label": "No",
                            "value": false
                        },
                        ],
                        "defaultValue": false
                    },
                    "fieldArray": null,
                    "fieldGroupClassName": null,
                    "fieldGroup": null
                },

            ]
        },


    ]

    rowId: any;
    routeIntent: string;
    state: any;
    myGroup: FormGroup;
    courseProviderForm: FormGroup;
    disableButton: boolean;
    courseProviderData: any;
    providerId: any;

    identifier: any;
    schemaUrl: any;
    apiUrl: any;
    titleInformation: any[];
    routerPath;
    queryParams: any;
    id:any;

    collection = "/providers";

    constructor(
        private readonly fb: FormBuilder,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly inPageNavService: InPageNavService,
        private readonly courseNavigation: CourseManagementNavigation,
        private readonly appConfirmService: AppConfirmService
    ) {
        this.activatedRoute.queryParams.subscribe((params: any) => {
            if (params.id) {
                this.rowId = params.id;
                this.state = params.state;
                this.routeIntent = "edit";
            } else {
                this.routeIntent = "new";

            }
        });
        this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
        this.titleInformation = [{
            'courseProvider': 'Provider name'
        }, {
            'addressLine1': 'Provider contact details'
        }];
        
        this.routerPath = '/courses-events/providers/view';
        this.queryParams = { id: this.rowId };
        // this.routerPath = `./courses-events/providers/view?id=${this.rowId}`;
        this.schemaUrl = 'api::courseprovider.courseprovider';
        this.apiUrl = '/providers';
        this.activatedRoute.queryParams.subscribe((params: any) => {
        this.queryParams = params;

            if (params.id) {
                this.identifier = params.id;
                this.routeIntent = "edit";
            }

        });
    }

    ngOnDestroy() {
        this.inPageNavService.setNavItems(null);
    }

    ngOnInit(): void {
        this.addFieldHideExpression();
    }

    exitNavigation() {
        if (this.queryParams && this.queryParams.id) {
            // Navigate to the view page with both id and hideEdit parameters
            this.router.navigate(['/courses-events/providers/view'], {
                queryParams: {
                    id: this.queryParams.id,
                    hideEdit: "Active" // Ensure hideEdit is included
                }
            });
        } else {
            // Navigate to the default page if id is not available
            this.router.navigate(['/courses-events']);
        }
    }

    addFieldHideExpression() {
        this.fields.forEach(fieldGroup => {
            fieldGroup.fieldGroup.forEach(field => {
                if (field.key === 'providerId') {
                    if (this.routeIntent == 'edit') {
                        field.hideExpression = false;
                        field.templateOptions.disabled = true;

                    } else {
                        field.hideExpression = true;
                        field.templateOptions.disabled = false;
                    }
                }
            });
        });
    }

    navigateToRoute() {
        this.router.navigate([`courses-events/${this.collection}`]);
    }

    progressStatus(progressStatus){

        this.isNotSaved = progressStatus;
      }

    canDeactivate(): Observable<boolean> | boolean {

        if (this.isNotSaved) {
            return this.appConfirmService.confirm(
                {
                    title: `Progress not saved`,
                    message: 'If you navigate away from this page your progress will not be saved'
                });
        } else {
            return true
        }
    }
}
