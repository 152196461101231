import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { SessionsService } from '../../sessions.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
  providers: [SessionsService],
})
export class VerifyOtpComponent implements OnInit {
  signinForm: FormGroup;
  private countdownTimer: any;
  buttonText: string = 'Resend OTP';
  isButtonDisabled: boolean = false;
  otpCode = '';
  otpButtonDisabled: boolean = true;
  message: any;
  constructor( private readonly sessionsService: SessionsService,
    private router: Router,
    private readonly snackBar: SnackBarService,
    private readonly appConfirmService: AppConfirmService,
    private readonly builder: FormBuilder) {
      this.startCountdown();
     }

  ngOnInit() {
   
    this.initForm();
    
  }

  initForm() {
    this.signinForm = this.builder.group({
        userName: ['', Validators.required],
        pass: ['', Validators.required],
    });
}

  private startCountdown(): void {
    const expirationTime = localStorage.getItem('otpExpirationTime'); 
    const expTime = moment(expirationTime);
    const currentTime = moment();
    const timeDiffInMilliseconds = expTime.diff(currentTime);
    this.isButtonDisabled=true;
    let countdown = Math.floor(timeDiffInMilliseconds / 1000);
    this.countdownTimer = setInterval((): void => {
        const minutes: number = Math.floor(countdown / 60);
        const seconds: number = countdown % 60;
        this.buttonText = `Resend OTP in ${minutes}m ${seconds}s`;
        countdown--;
        if (countdown < 0) {
            clearInterval(this.countdownTimer);
            this.isButtonDisabled = false;
            this.buttonText = 'Resend OTP';
        }
    }, 1000);
}



verifyOTP() {

  const userName = JSON.parse(atob(localStorage.getItem('shortToken').split('.')[1])).userName;
  const payload = {
      "genratedOTP": this.otpCode, userName: userName, "token": localStorage.getItem('shortToken'),
  };
  payload.userName =
          this.sessionsService.verifyOTP(payload).subscribe((data: HttpResponse<any>) => {
            localStorage.removeItem('shortToken');
            if (data.body.landingPageUrl != null) {
              localStorage.setItem('landingPage', data.body.landingPageUrl);
              this.router.navigate([ data.body.landingPageUrl]);
          } else {
              this.router.navigate([localStorage.getItem('landingPage')]);
          }
          if (data.body.isPasswordAboutToExpire) {
              this.changePassword(data)
          }
         },
          (error: any) => {
              this.snackBar.error(error.error.applicationMessage || error.message);
              this.signinForm.controls['pass'].setValue(atob(this.signinForm.controls['pass'].value));
              if (error.error.applicationMessageCode === 'U4006') {
                  localStorage.setItem('username', this.signinForm.controls['userName'].value);
                  this.router.navigate(['sessions/change-expired-password']);
              }
          }
      );
}

sendOtp(otpForm) {
  this.otpCode='';
  otpForm.resetForm();
  this.otpButtonDisabled = true;
  this.sessionsService.sendOTP().subscribe(response => {
      this.isButtonDisabled = true;
      localStorage.setItem('otpDuration', response.otpDuration);
      localStorage.setItem('otpExpirationTime', response.otpExpirationTime);
      this.startCountdown();
      this.snackBar.success("OTP send ");
  },(error:any)=>{
                  this.snackBar.error(error.error.applicationMessage || error.message);
                  this.router.navigate(['/sessions/signin'])
  })
}


changePassword(data: any) {
  this.sessionsService.setPasswordAboutToExpireFlag(true)
  const numberOfdaysLeft = data.body.passwordAboutToExpireDays;
  const dialogRef = this.appConfirmService.confirm({
      title: `Please change your password`,
      message: `Your password will expire in ${numberOfdaysLeft} day/s.
Select \'OK\' to change your password now or \'Cancel\' to change it later.`,
  });
  dialogRef.subscribe(result => {
      if (result) {
          this.router.navigateByUrl('/user-setting/change-password');
      }
  });
}
onOtpChange() {
  this.otpButtonDisabled = !this.otpCode || this.otpCode.length < 6;
}
}
