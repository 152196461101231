<mat-card>
  <mat-card-header class="flex-end border-left mb-20">
    <span style="width: 80%;">  <h1 class="page-title"> View provider</h1></span>
  
    <span class="spacer"></span>
    <span class="action-buttons">
      <button *auth="[139, 3]" id="edit_button"mat-stroked-button [routerLink]="['../edit']"
        [queryParams]="{id: providerId}">
        Edit
      </button>
      <button id="exit_button" mat-stroked-button [routerLink]="['../']">Exit</button>
    </span>
  </mat-card-header>
  <mat-card-content>
    <div style="justify-content: center; align-items: center;">
      <app-dynamic-form-view [identifier]="providerId" [schemaUrl]="schemaUrl" [apiUrl]="apiUrl"
        [titleInformation]="titleInformation" [collection]="collection">
      </app-dynamic-form-view>
      <ng-container *ngIf="auditDatesHistory.length > 0">
        <span class="details_label">Previous last audit dates</span>
        <div class="card-column" *ngFor="let element of auditDatesHistory">
          <span>{{element | date: 'dd/MM/yyyy'}}</span>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
