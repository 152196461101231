<div class="form-container" [class.width-120]="to['addMoreBtn']">
    <mat-label class="label" *ngIf="maxLimitFlag">{{ to['label'] }}</mat-label>
    <mat-form-field (keydown)="onKeyDown($event)" *ngIf="!maxLimitFlag" appearance="fill"
        [style.width]="'100%'">
        <mat-label>{{ to['label'] }}</mat-label>
        <mat-hint>{{to['description']}}</mat-hint>
        <input matInput [matAutocomplete]="auto" [formControl]="searchControl" [required]="to['required']"  (click)="onClick()"/>
        <button color="primary" type="button" *ngIf="!searchControl.value" mat-icon-button matSuffix aria-label="drop_down">
            <img class="ddl-icon" src="../../../../assets/f-structure-icons/dropdown.svg" aria-label="drop_down icon" alt="dropdown">
        </button>
        <button type="button" *ngIf="searchControl.value" mat-icon-button matSuffix aria-label="Clear" (click)="clearInput()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
            <mat-option *ngFor="let option of filteredOptionsList | async" [value]="option.id">
                {{ option?.name }}
            </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="to['required']">
            {{ to['label'] }} is required
        </mat-error>
    </mat-form-field>
        <div class="button-container" *ngIf="to['addMoreBtn']">
        <ng-container *ngIf="!maxLimitFlag">
            <button attr.aria-label="Add {{to['label']}}" class="addMoreBtn" type="button" mat-mini-fab color="primary" [matTooltip]="'Add ' + to['label']"
                [matTooltipShowDelay]="100" (click)="addMoreBtnCLick(to['addMoreBtn'])">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
    </div>
</div>
<span class="flex relation-chip" *ngIf="!oneToOneFlag && selectedOptions" [class.mb-20]="selectedOptions && selectedOptions.length > 0">
    <mat-chip-list [formControl]="relationControl">
        <mat-chip *ngFor="let option of selectedOptions" class="chip-color" [removable]="true"
            (removed)="remove(option.id)">
            {{ option.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
</span>