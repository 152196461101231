import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { Utility } from 'src/app/framework/utils/utility';
import { CourseManagementService } from '../../../course-management.service';

@Component({
  selector: 'app-cancel-course-delivery-pop-up',
  templateUrl: './cancel-course-delivery-pop-up.component.html',
  styleUrls: ['./cancel-course-delivery-pop-up.component.scss']
})
export class CancelCourseDeliveryPopUpComponent {
  cancellationForm: FormGroup;
  cancelationReason =[ 'Hello','World','Java']
  resons:any

  constructor(
    private readonly fb: FormBuilder,
    private readonly snackbar: SnackBarService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly courseManagementService :CourseManagementService,
    public dialogRef: MatDialogRef<CancelCourseDeliveryPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly snackBarService: SnackBarService,
  ) {
    this.initDataForm();
    this.resons = Utility.filterMapByKey("Cancellation_Reason", "refData_booking");
   
  }

  ngOnInit() {
  }

  confirmDelete(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(true);
  }

  initDataForm() {
    this.cancellationForm = this.fb.group({
      cancellationReasonId: [null, Validators.required],
      isExtenuatingCircumstances: ['true']
    });
  }

  onCancellationEscalated(control) {
    if (this.cancellationForm.get(control)?.value === 70) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit() {
    const payload = this.cancellationForm.getRawValue()
    payload.bookingUserMappingId=this.data.bookingUserMappingId
    if( payload.cancellationReasonId==69){
      payload.isExtenuatingCircumstances=false;
    }
    if(this.data.status=='Cancellation Requested')
    {
      this.courseManagementService.confirmRequest(payload).subscribe(response=>{
        this.snackBarService.success(response.message.applicationMessage);
        this.dialogRef.close(true);
      })
    } 
     else {
      this.courseManagementService.cancelBookingByCC(payload).subscribe(response=>{
        this.snackBarService.success(response.message.applicationMessage);
        this.dialogRef.close(true);
      })
     }
   
   
  }



  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }


  shouldShowDocumentVerification(): boolean {
    return this.cancellationForm.controls.cancelationReason.value === 70;
  }
}
