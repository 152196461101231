<mat-card id="view-booking">
    <mat-card-header>
        <span class="action-buttons">
    <button id="exit_button" mat-stroked-button
    [routerLink]="type === 'waiting' ? ['../waiting-list'] : (confirmedExitBtn ? ['../confirm-booking'] : ['../booking-request'])"
    [queryParams]="{ id: cdId, operation: 'view',hideEdit: isActive }"> 
    Exit
  </button>
        </span>
    </mat-card-header>
    <mat-card-content>
        <dl>
            <div class="label-hr">
                <span>Course booking details</span>
            </div>
            <div>
                <dt class="details-label">Course name</dt>
                <dd>{{ booking?.courseName || "N/A"}}</dd>
            </div>

            <div>
                <dt class="details-label">Service leaver name</dt>
                <dd>{{ booking?.serviceLeaverName || "N/A"}}</dd>
            </div>

            <div>
                <dt class="details-label">Email address</dt>
                <dd>{{ booking?.email || "N/A" }}</dd>
            </div>
            <div>
                <dt class="details-label">Rank</dt>
                <dd>{{ booking?.rankName || "N/A"}}</dd>
            </div>
            <div>
                <dt class="details-label">Length of service</dt>
                <dd>{{ booking?.lengthOfService || "N/A"}}</dd>
            </div>
            <div>
                <dt class="details-label">Entitlement to CTP service name</dt>
                <dd>{{ booking?.entitlementToCTPServiceName || "N/A"}}</dd>
            </div>
            <div *ngIf="isRejectReason">
                <dt class="details-label">Status</dt>
                <dd>{{ booking?.bookingStatus || "N/A"}}</dd>
            </div>
            <div *ngIf="isRejectReason">
                <dt class="details-label">Status reason</dt>
                <dd>{{ booking?.rejectedReason || "N/A"}}</dd>
            </div>
        </dl>
        <dl *ngIf="isPaymentSectionEnable">
            <div class="label-hr">
                <span>Payment details</span>
            </div>
            <mat-card-content class="pl-0">
                <mat-table id="content_modules_table" [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef id="fullName_button" style="font-weight: bold;">
                            Type
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="m-20">
                            {{ element.paymentType || "N/A"}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef id="serviceNumber_button" style="font-weight: bold;">
                            Amount
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="m-20">
                            {{ element.paymentAmount || "N/A" | currency:'GBP':'symbol':'1.2-2'}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef id="createdDate_button" mat-sort-header
                            style="font-weight: bold;">
                            Status
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="m-20">
                            {{ getStatusForPayment(element.paymentDescription)}}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="paymentDate">
                        <mat-header-cell *matHeaderCellDef id="bookingReference_button" mat-sort-header
                            style="font-weight: bold;">
                            Payment date
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="m-20">
                            {{ element.paymentDate ? (element.paymentDate | date: 'dd/MM/yyyy HH:mm') : 'N/A' }}
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-card-content>
        </dl>
        <dl *ngIf="isELCASFunding">
            <form [formGroup]="elcasDetailsForm" validate autocomplete="off">
                <div class="label-hr">
                    <span>ELCAS Information</span>
                </div>

                <div class="card-row imageBlock" *ngIf="activeImageId !==1 && contentImage !== undefined">
                    <div class="image-list">
                        <mat-card>
                            <img [src]="getImagePath(contentImage.imageName)" alt="{{contentImage.imageName}}">
                        </mat-card>
                    </div>
                </div>
                <div class="card-row">
                    <div class="uploadImg showUploadImg">
                        <mat-label>ELCAS document</mat-label>
                        <p class="custom-hint">Maximum file size is 5 MB. Allowed file types are
                            pdf</p>
                        <div class="uploadSection">
                            <button class="upload_button" type="button" *ngIf="isAuthorized(140,11) && !isShowReplaceBtn && isEnableBookingBtn" id="browse_button"
                                (click)="file.click()" mat-raised-button color="primary">
                                Upload</button>
                            <button class="upload_button" [disabled]="disableRelpaceBtn" type="button"
                                *ngIf="isAuthorized(140,11) && isShowReplaceBtn && isEnableBookingBtn" id="browse_button" (click)="file.click()" mat-raised-button
                                color="primary">
                                Replace</button>
                            <p class="fileName">{{elcasDocName}}</p> 
                            <img *ngIf="filePath !=null || filePath != undefined" style="width:30px ;height:30px; cursor: pointer;"
                                src="../../../../../assets/logo/download.svg" alt="download_icon"
                                [matTooltipShowDelay]="500" matTooltip="Download" 
                                (click)="downloadFile()">
                            <input aria-label="Upload" type="file" id="image_uploads" (change)="selectFile($event)"
                                value="" formControlName="file" #file>
                        </div>
                        <mat-error *ngIf="uploadDisable">Invalid file type</mat-error>
                        <mat-error *ngIf="invalidFileName">File name is too long. Maximum allowed is 255 characters.</mat-error>
                        <mat-error *ngIf="invalidFileSize">File size exceeds the maximum allowed size of 5 MB.</mat-error>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-column">
                        <mat-form-field appearance="fill">
                            <mat-label>Claim number</mat-label>
                            <input matInput formControlName="claimNumber" maxlength="300" id="claimNumber">
                            <mat-error *ngIf="elcasDetailsForm.get('claimNumber').hasError('required')">
                                Claim number is required
                            </mat-error>
                            <mat-error *ngIf="elcasDetailsForm.get('claimNumber').hasError('pattern')">
                                Invalid claim number
                              </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-column">
                        <mat-form-field appearance="fill" style="margin-right: 0px;">
                            <mat-label>ELC contribution</mat-label>
                            <input matInput formControlName="ELCContribution" id="ELCContribution">
                            <mat-error *ngIf="elcasDetailsForm.get('ELCContribution').hasError('pattern')">
                                Invalid ELC contribution
                              </mat-error>
                              <mat-error *ngIf="elcasDetailsForm.get('ELCContribution').hasError('required')">
                                ELC contribution is required
                            </mat-error>
                        </mat-form-field>
                        
                    </div>
                </div>
                <div class="card-row">
                    <div class="card-column mb-20">
                        <mat-radio-group formControlName="multipleCourses" (change)="onMultipleCourses($event.value)"
                            name="multipleCourses">
                            <mat-label>Multiple courses</mat-label>
                            <div class="radio-button-container">
                                <mat-radio-button value=true>Yes</mat-radio-button>
                                <mat-radio-button value=false>No</mat-radio-button>
                            </div>
                        </mat-radio-group>
                    </div>
                </div>
            </form>
        </dl>
        <mat-card-footer>
            <span class="spacer"></span>
            <span class="action-buttons">
                <button id="approve_button" [disabled]="disableFormFeild || !elcasDetailsForm.valid" (click)="openApproveBookingDialog(elcasDetailsForm.value)" mat-raised-button color="primary" *ngIf="(booking.bookingStatusIdentifier =='PRBK' || booking.bookingStatusIdentifier =='ELCASWT' || booking.bookingStatusIdentifier =='PSLA') && isAuthorized(140,11)">Approve booking</button>
                <button id="reject_button" (click)="openRejectBookingDialog()" *ngIf="booking.bookingStatusIdentifier =='PRBK' && isAuthorized(140,11)" mat-stroked-button >Reject booking</button> 
                <button id="reject_button" (click)="onCancelBookingClick('')" *ngIf="!status.includes(booking.bookingStatusIdentifier) && isAuthorized(140,11)" mat-stroked-button >Cancel booking</button>
                <button id="reject_button" (click)="onCancelBookingClick('Cancellation Requested')" *ngIf="booking.bookingStatusIdentifier =='CNREQ' && isAuthorized(140,11)" mat-raised-button color="primary" >Approve</button> 
                <button id="reject_button" (click)="onDiscontinueBookingClick()" *ngIf="booking.bookingStatusIdentifier =='CNREQ' && isAuthorized(140,11)" mat-stroked-button >Discontinue</button>     
            </span>
        </mat-card-footer>
    </mat-card-content>
</mat-card>