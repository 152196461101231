import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionsService } from 'src/app/features/shared/services/sessions.service';
import { AppConfirmService } from 'src/app/framework/components/app-confirm/app-confirm.service';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { CourseManagementService } from '../../course-management.service';
import { PopUpComponent } from '../../pop-up/pop-up.component';
import { IWorkshopBooking } from '../workshop-booking.interface';

@Component({
  selector: 'app-view-workshop-booking-details',
  templateUrl: './view-workshop-booking-details.component.html',
  styleUrls: ['./view-workshop-booking-details.component.scss']

})
export class ViewWorkshopBookingDetailsComponent implements OnInit {
  identifier: any;
  wdId: any;
  pageName: any;
  routeIntent: any;
  booking: IWorkshopBooking;
  isEnableRejectBtn: boolean;
  approveOrRejectBooking: boolean;
  confirmedExitBtn: boolean;
  confirmBooking:boolean=false;
  isActive;

  constructor(private readonly activatedRoute: ActivatedRoute,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBarService: SnackBarService,
    private readonly inPageNavService: InPageNavService,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly courseManagementService: CourseManagementService,
    private readonly appConfirmService: AppConfirmService,
    private dilogref: MatDialog,
    private readonly sessionService: SessionsService) {
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.wdId = params.wdID;
      this.pageName = params.name;
      this.isActive = params.hideEdit;
    });
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }
  ngOnInit(): void {
    this.confirmedExitBtn = false;
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
    this.resolveBooking();
  }

  resolveBooking() {
    this.route.params.subscribe((params: any) => {
      this.courseManagementService.getWorkshopBookingDetails(this.identifier).subscribe(bookingData => {
          this.booking = bookingData;
          this.checkRejectButton();
      })
    })
  }

  checkRejectButton(): void {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const workshopDeliveryStartDate = new Date(this.booking.deliveryStartDate);
    workshopDeliveryStartDate.setHours(0, 0, 0, 0);
  
    if(this.booking.bookingStatusIdentifier == "CNCH" && (this.booking.isdiscontinued== null || this.booking.isdiscontinued==true ))
    {
      this.confirmBooking =true;
    }
    if (this.booking.cancelStatus == "Cancellation Requested" && (this.booking.isdiscontinued== null || this.booking.isdiscontinued==false ) ) {
      this.isEnableRejectBtn = false;
      this.approveOrRejectBooking = true;
    } 
    else if (
      this.booking.bookingStatus === "Provisional Booking" ||
      this.booking.bookingStatus === "Awaiting Payment"
    ) {
      this.isEnableRejectBtn = true;
      this.approveOrRejectBooking = false;
    } else {
      this.isEnableRejectBtn = false;
      this.approveOrRejectBooking = false;
      let parts = this.booking.bookingStatus.split("-");
      let prefix = parts[0].trim();
      if (prefix === "Confirmed") {
        this.confirmedExitBtn = true;
      }
    }
  }

  onRejectBookingClick() {
    let payload = {
      "id": this.identifier,
      "serviceLeaverId": this.booking?.serviceUserId,
      "workshopId": this.booking?.workshopId,
      "workshopDeliveryId": this.wdId,
      "reasonId": null,
      "bookingType": "Workshop",
      "hideEdit": this.isActive
    };
    const dialogRef = this.dilogref.open(PopUpComponent, {
      backdropClass: 'disable-backdrop',
      data: payload,
      disableClose: true,
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterClosed().subscribe(confirmed => {

    })
  }

  onApproveBookingClick() {

    const dialogRef = this.appConfirmService.confirm({
      title:'Workshop booking approve',
      message: `Are you sure you want to approve?`,
      okButtonLabel: 'Approve',
      cancelButtonLabel: 'Close'
    });

    dialogRef.subscribe(result => {
      if(result){

    const payload = {
      "id": this.identifier,
      "serviceLeaverId": this.booking?.serviceUserId,
      "workshopId": this.booking?.workshopId,
      "workshopDeliveryId": this.wdId,
      "reasonId": null
    };
    this.courseManagementService.approveWorkshopBooking(payload).subscribe((response) => {
      this.snackBarService.success(response.message.applicationMessage);
        this.router.navigate(
        ['/courses-events/workshopDeliveries/view/booking-request'],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.wdId,
            operation: 'view',
            hideEdit: this.isActive
          }
          }
        );
      },
      (error) => {
        this.snackBarService.error(error.error.applicationMessage);
      })
    }})
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }


  cancelWorkshopBooking(title) {

    const dialogRef = this.appConfirmService.confirm({
      title: title,  
      message: `Are you sure you want to cancel?`,
      okButtonLabel: 'OK',
      cancelButtonLabel: 'Cancel'
    });
    dialogRef.subscribe(result => {
      if(result){
      let payload = {
        id: this.identifier,
        serviceLeaverId: this.booking?.serviceUserId,
        workshopId: this.booking?.workshopId,
        workshopDeliveryId: this.wdId,
        reasonId: null,
      };
  
      this.courseManagementService.cancelWorkshopBooking(payload).subscribe(
        (response) => {
          this.snackBarService.success(response.message.applicationMessage);
          this.router.navigate(
            ["/courses-events/workshopDeliveries/view/booking-request"],
            {
              relativeTo: this.route,
              queryParams: {
                id: this.wdId,
                operation: "view",
                hideEdit: this.isActive,
              },
            }
          );
        },
        (error) => {
          this.snackBarService.error(error.error.applicationMessage);
        }
      );
    }
    })

  }

 
 
  discountinedBookingClick() {
    const dialogRef = this.appConfirmService.confirm({
      title: `Workshop discontinue`,
      message: `Are you sure you want to discontinue?`,
      okButtonLabel: 'Ok',
      cancelButtonLabel: 'Cancel'
    });
    dialogRef.subscribe(result => {
      if(result){
    
     let payload = {
      id: this.identifier,
      serviceLeaverId: this.booking?.serviceUserId,
      workshopId: this.booking?.workshopId,
      workshopDeliveryId: this.wdId,
      reasonId: null,
    };

    this.courseManagementService.discountinedWorkshopBooking(payload).subscribe(
      (response) => {
        this.snackBarService.success(response.message.applicationMessage);
        this.router.navigate(
          ["/courses-events/workshopDeliveries/view/booking-request"],
          {
            relativeTo: this.route,
            queryParams: {
              id: this.wdId,
              operation: "view",
              hideEdit: this.isActive,
            },
          }
        );
      },
      (error) => {
        this.snackBarService.error(error.error.applicationMessage);
      }
      );
    }
    })
  }
  isAuthorized(fid, opId) {
    return this.sessionService.hasResource([fid.toString(), opId.toString()])
  }

  cancelWorkshopBookingByCC(title) {

    const dialogRef = this.appConfirmService.confirm({
      title: title,  
      message: `Are you sure you want to cancel?`,
      okButtonLabel: 'OK',
      cancelButtonLabel: 'Cancel'
    });
    dialogRef.subscribe(result => {
      if(result){
      let payload = {
        id: this.identifier,
        serviceLeaverId: this.booking?.serviceUserId,
        workshopId: this.booking?.workshopId,
        workshopDeliveryId: this.wdId,
        reasonId: null,
      };
  
      this.courseManagementService.cancelWorkshopBookingByCC(payload).subscribe(
        (response) => {
          this.snackBarService.success(response.message.applicationMessage);
          this.router.navigate(
            ["/courses-events/workshopDeliveries/view/booking-request"],
            {
              relativeTo: this.route,
              queryParams: {
                id: this.wdId,
                operation: "view",
                hideEdit: this.isActive,
              },
            }
          );
        },
        (error) => {
          this.snackBarService.error(error.error.applicationMessage);
        }
      );
    }
    })

  }
}
