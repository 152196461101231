import { Injectable } from '@angular/core';

@Injectable()
export class ContentManagementSteps {

    stepsConfig = [
        {
            name: 'Description',
            state: '/content-management/content-keywords/active/content',
            id: 'D',
            featureId: [172]
        },
        {
            name: 'Teams',
            state: '/content-management/establishment',
            id: 'T',
            featureId: [172]
        },
        {
            name: 'Keywords',
            state: '/content-management/keyword',
            id: 'K',
            featureId: [172]
        },
        {
            name: 'Image',
            state: '/content-management/image',
            id: 'I',
            featureId: [172]
        },
        {
            name: 'Upload',
            state: '/content-management/upload2345',
            id: 'U',
            featureId: [172]
        }
    ];
}