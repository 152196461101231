<div class="case-notes-section"> 
  <div class="label-hr">
    <span>Notes</span>
  </div>
  <div class="message-list-right">
    <div>
    <div  class="chat-wrapper" *ngIf="messagesDataSource.length > 0">
      <div class="convesation-div" (scroll)="scrolled($event)" id="messageBody" #mainScreen>
        <div *ngFor="let caseNotes of messagesDataSource" id="message_{{caseNotes.messageId}}">
          <div class="message-item message-item--sent">
            <div class="message-item__text message-item__text--sent" id="msg_text_{{caseNotes.noteId}}">
              {{caseNotes.notes}}</div>

            <div class="message-item__meta">
              <span class="message-item__meta--sender">{{caseNotes.createdUser}} </span>
              <time class="message-item__meta--timestamp" id="msg_time_{{caseNotes.noteId}}">at {{ caseNotes.createdAt |
                date:'HH:mm' }} </time>
              <time class="message-item__meta--timestamp" id="msg_day_date_{{caseNotes.noteId}}">on
                {{caseNotes.createdAt | date: 'dd MMM yyyy'}}</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="chat-container" style="margin-top: 20px;">
      <div class="chat-preview">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Type notes here...</mat-label>
          <textarea id="text" matInput placeholder="Type notes here..." minlength="0" maxlength="1000" #message
            matTextareaAutosize matAutosizeMinRows=6 matAutosizeMaxRows=6 rows="2" [disabled]="!(isAuthorized('create') || isAuthorized('update'))"></textarea>
          <mat-hint *ngIf="message.value.length !=(1000-1) && (1000) >= 
                      message.value.length && message.value.length != 0">
            {{1000 - message.value.length}} characters left
          </mat-hint>
          <mat-hint *ngIf="message.value.length == 999" id="msg_length">1 character left</mat-hint>
          <mat-hint *ngIf="message.value.length === 0" id="msg_length">Minimum 1 and maximum 1000 characters
            allowed</mat-hint>
        </mat-form-field>
      </div>
      <div class="float-right">
        <button type="button" id="send_button" mat-raised-button color="primary" (click)="onSubmit(message)"
          [disabled]=" message.value.length === 0 || disableAddButton || (!(isAuthorized('create') || isAuthorized('update')))">Add</button>
      </div>
    </div>
  </div>
</div>