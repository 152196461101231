import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { DynamicFormService } from '../../dynamic-forms/dynamic-form/dynamic-form.service';

@Component({
  selector: 'app-delivery-clash',
  templateUrl: './delivery-clash.component.html',
  styleUrls: ['./delivery-clash.component.scss']
})
export class DeliveryClashComponent {

  parentLocation: boolean;
  saveId: any;

  constructor(
    public dialogRef: MatDialogRef<DeliveryClashComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly snackBarService: SnackBarService,
    private readonly router: Router,
    private dynamicFormService: DynamicFormService,
  ) {}

  ngOnInit(): void {
    this.parentLocation = this.data.error.error.body.error.details.parentLocation;
  }

  onSubmit(){
    const payload = this.data.payload;
    payload['overrideConflict'] = true;
    if (this.data.id) {
      this.dynamicFormService.updateCollectionEntry({ data: payload }, this.data.id, this.data.collection).subscribe ( response => {
        this.saveId = response.responseObject.body.data.id; 
        this.navigateTO();
        this.snackBarService.success(response?.message.applicationMessage);
      }, error => {
        this.snackBarService.error(error.error.applicationMessage);
      });
    } else {
      this.dynamicFormService.saveCollectionEntry({ data: payload }, this.data.collection).subscribe ( response => {
        this.saveId = response.responseObject.body.data.id; 
        this.navigateTO();
        this.snackBarService.success(response?.message.applicationMessage);
      }, error => {
        this.snackBarService.error(error.error.applicationMessage);          
      })
    }
    this.dialogRef.close();
    }

  navigateTO(){
    this.router.navigate([this.data.routerPath], { queryParams: { id: this.saveId } });
  }

}
