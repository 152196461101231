<mat-card id='request-booking'>
    <mat-card-header>
        <span class="example-form width-30">
            <mat-form-field id="search_service_user_form" class="example-full-width" appearance="fill">
              <mat-label>Booking status</mat-label>
              <mat-select (selectionChange)="findRequestBookingByStatus($event)" [(ngModel)]="select">
                <mat-option value="all status">All</mat-option>
                <mat-option *ngFor="let option of requestStatusTypeList" [value]="option.id">{{option.description}}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        <span class="spacer"></span>
        <span class="action-buttons">
            <button id="exit_button" mat-stroked-button [routerLink]="['/courses-events/courseDeliveries/view/details']" queryParamsHandling="merge">Exit</button>
        </span>
    </mat-card-header>
    <mat-card-content class="pl-0">
        <mat-table id="content_modules_table" [dataSource]="dataSource" >
            <ng-container matColumnDef="serviceUserId">
                <mat-header-cell *matHeaderCellDef id="fullName_button" style="font-weight: bold;">
                    Service leaver name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.serviceUserName || "N/A"}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="serviceNumber">
                <mat-header-cell *matHeaderCellDef id="serviceNumber_button" style="font-weight: bold;">
                    Service number
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.serviceNumber || "N/A" }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdDate" matSort>
                <mat-header-cell *matHeaderCellDef id="createdDate_button" mat-sort-header style="font-weight: bold;">
                    Date requested
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.createdDate | date: 'dd/MM/yyyy' || "N/A"}}
                </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="bookingStatusId">
                <mat-header-cell *matHeaderCellDef id="bookingStatus_button" style="font-weight: bold;">
                    Booking status
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="m-20">
                    {{ element.bookingStatus || "N/A" }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef style="font-weight: bold;">Actions</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button id="view_user_button_{{element.id}}"  mat-icon-button color="primary" matTooltip="View"
                    [matTooltipShowDelay]="800"  [routerLink]="['../booking-details']" aria-label="view button"
                    [queryParams]="{ id: element.id,cdID:identifier,operation:'view',hideEdit: isActive,'type':'waiting'}" aria-label="view {{ element.serviceUserName || 'N/A' }}">
                    <mat-icon>visibility</mat-icon>
                </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator id="sl_booking_pagination" [pageSizeOptions]="[25, 50, 100]" [showFirstLastButtons]="true" aria-label="Select page">
        </mat-paginator>

    </mat-card-content>
</mat-card>