import { SnackBarService } from '../../../framework/service/snack-bar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IAuthorNameList } from './iauthor-name-list';
import { ContentManagementService } from './../content-management.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ContentManagementSteps } from '../content.steps';
import { StepperNavigationService } from '../../shared/components/stepper-navigation/stepper-navigation.service';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../framework/components/date-adapter/date-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Utility } from '../../../framework/utils/utility';
import { DynamicTableService } from 'src/app/framework/components/dynamic-table/dynamic-table.service';
import { catchError } from 'rxjs/operators';
import { AddMorePopupComponent } from '../../dynamic-forms/relation-type/add-more-popup/add-more-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-content',
  templateUrl: './edit-content.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class EditContentComponent implements OnInit {

  private contentId: number;
  manageContentForm: FormGroup;
  isNew = false;
  learningStyleNameList: any[];
  authorNameList: IAuthorNameList[] = [];
  filteredAuthorNameList: Observable<IAuthorNameList[]>;

  minStartDate: Date;
  minEndDate: Date;
  data;
  logoName: null;
  uploadDisable: boolean;
  imageFile: File;
  isEdit: Boolean = false;
  filteredOptionsList: Observable<{ id: number; name: string }[]> = of([]);
  filteredOptionsRank: Observable<{ id: number; name: string }[]> = of([]);
  optionsList: { id: string; name: string }[] = [];
  selectedOptions: Array<{ id: string;[key: string]: any }> = [];
  optionSelectedFromDropdown = false;
  filteredData: any;
  excludedIds: any;
  industryNameList: any
  locationValues = [{ id: 1, label: 'England' }, { id: 2, label: 'Scotland' }, { id: 3, label: 'Wales' }, { id: 4, label: 'Northern Ireland' }];
  contentTypes = [{ id: 1, label: 'PDF' }, { id: 2, label: 'Video' }, { id: 3, label: 'Podcast' },
  { id: 4, label: 'Presentation' }, { id: 5, label: 'Document' }, { id: 6, label: 'Image' },
  { id: 7, label: 'Webinar' }];
  searchControl: FormControl = new FormControl();
  imageId: any;
  contentDetailsCTPId: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly snackBarService: SnackBarService,
    private readonly contentManagementService: ContentManagementService,
    private readonly contentSteps: ContentManagementSteps,
    private readonly stepperNavigationService: StepperNavigationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dynamicTableService: DynamicTableService,
    private dialog: MatDialog,
  ) {
    this.stepperNavigationService.stepper(this.contentSteps.stepsConfig);
    this.minStartDate = new Date();
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minStartDate.getDate() + 1);
  }

  ngOnInit() {
    this.initContentForm();
    this.getLearningStyleList();
    this.contentManagementService.getAllAuthorList().subscribe(
      authorList => {
        this.authorNameList = authorList;
        return this.authorNameList;
      });
    this.filteredAuthorNameList = this.manageContentForm.controls.author.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    this.filteredOptionsList = this.manageContentForm.controls.industryId.valueChanges.pipe(
      startWith(''),
      switchMap(value => this._filterIndustry(value))
    );
    this.filteredOptionsRank = this.manageContentForm.controls.rankId.valueChanges.pipe(
      startWith(''),
      switchMap(value => this.fetchOptionsRank({ 'keyword': "" }))
    );
  }

  private _filter(value: string): IAuthorNameList[] {
    if (!value) {
      return this.authorNameList;;
    }
    const filterValue = value.toLowerCase();
    return this.authorNameList.filter((option: any) => option.authorName.toLowerCase().includes(filterValue));
  }

  private _filterIndustry(value: string) {
    if (!value) {
      const filterBy = { 'keyword': value };
      return this.fetchOptionsIndustry(filterBy);
    }else{
      const filterValue = value?.toLowerCase();
      const filterBy = { 'keyword': filterValue };
      return this.fetchOptionsIndustry(filterBy);
    }
   
  }

  // private _filterRank(value: string){
  //   if(!value) {
  //     const filterBy = { 'keyword': value };
  //           return this.fetchOptionsRank(filterBy);
  //   }
  //   const filterValue = value.toLowerCase(); 
  //   const filterBy = { 'keyword': filterValue };
  //   return this.fetchOptionsRank(filterBy);
  // }

  initContentForm() {
    this.manageContentForm = this.fb.group({
      contentId: [''],
      title: ['', [Validators.required, Validators.pattern("([A-Za-z0-9\s\!\ \'\.\,\-\/]+)")]],
      author: ['', [Validators.pattern('\\s*([A-Za-z\\s]+)*\\s*')]],
      contentTypeId: ['', Validators.required],
      description: ['', Validators.required],
      curationStartDate: ['', Validators.required],
      curationEndDate: [''],
      reviewDate: [''],
      industryId: [''],
      rankId: [''],
      locationId: [null, Validators.required],
      lastAuditDate: ['']
    })

    this.manageContentForm.get('curationStartDate').valueChanges
      .subscribe(date => {
        if (date) {
          this.setCurationDateConstraints(new Date(date));
        }
      });

    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.contentId = params.id;
        this.isEdit = true;
        this.resolveUploadStepNavigation();
        this.contentManagementService.getContentDetails(params.id).subscribe(data => {
          this.isNew = false;
          this.manageContentForm.patchValue(data);
          if (data.curationStartDate) {
            this.manageContentForm.get('curationStartDate').setValue(new Date(data.curationStartDate));
          }
          if (data.curationEndDate) {
            this.manageContentForm.get('curationEndDate').setValue(new Date(data.curationEndDate));
          }
          this.manageContentForm.get('curationStartDate').disable();
          this.setCurationDateConstraints(new Date(data.curationStartDate));
          if (data.contentDetailsCTP) {
            const CTPData = data.contentDetailsCTP;
            this.contentDetailsCTPId=data.contentDetailsCTP.id
            this.manageContentForm.get('contentTypeId').setValue(JSON.parse(CTPData.contentTypeId));
            this.manageContentForm.get('industryId').setValue(JSON.parse(CTPData.industryId));
            this.manageContentForm.get('rankId').setValue(CTPData.rankId);
            this.manageContentForm.get('locationId').setValue(CTPData.locationId);
            if (CTPData.reviewDate) {
              this.manageContentForm.get('reviewDate').setValue(new Date(CTPData.reviewDate));
            }
            if (CTPData.lastAuditDate) {
            this.manageContentForm.get('lastAuditDate').setValue(new Date(CTPData.lastAuditDate));
            }
          }
        });
      } else {
        this.isNew = true;
      }
    });
  }
  resolveUploadStepNavigation() {
    const contentUploadURL: any = '/content-management/upload';
    this.contentManagementService.getContent(this.contentId).subscribe(data => {
      if (data.modules.length > 0) {
        const moduleId = data.modules[0].id;
        if (data.modules[0].type === "URL") {
          const uploadStep = Utility.getObjectFromArrayByKeyAndValue(this.contentSteps.stepsConfig, 'id', 'U');
          uploadStep.state = contentUploadURL + '/external/view';
          uploadStep.queryParams = { moduleId: moduleId };
        } else {
          const uploadStep = Utility.getObjectFromArrayByKeyAndValue(this.contentSteps.stepsConfig, 'id', 'U');
          uploadStep.state = contentUploadURL + '/internal';
        }
      } else {
        const uploadStep = Utility.getObjectFromArrayByKeyAndValue(this.contentSteps.stepsConfig, 'id', 'U');
        uploadStep.state = contentUploadURL;
        uploadStep.queryParams = { moduleId: null };
      }
    });
  }

  getLearningStyleList() {
    this.contentManagementService.getLearningStyleList().subscribe(
      learningStyleList => {
        this.learningStyleNameList = learningStyleList;

      });
  }
  onSubmit(data) {
    if (this.isNew) {
      this.createNewContent(data);
    } else {
      this.editContent(data)
    }
  }

  createNewContent(form: FormGroup) {
    this.data = this.formatPayload(form.getRawValue());
    // this.data.curationStartDate = Utility.transformDateToString(this.data.curationStartDate);
    // this.data.curationEndDate = Utility.transformDateToString(this.data.curationEndDate);
    this.contentManagementService.postNewContent(this.data).subscribe(
      response => {
        this.router.navigate(['/content-management/keyword'], { queryParams: { id: response.responseObject.contentId } });
        this.snackBarService.success(response.message.applicationMessage); 
      },
      error => {
        this.snackBarService.error(`${error.error.applicationMessage}`);
      });
  }

  formatPayload(payload) {
    let industryIds
    if(payload?.industryId){
     industryIds = payload?.industryId?.map(id => parseInt(id, 10));
    }
    let rankIds = parseInt(payload?.rankId);
    const obj = {
      contentId: payload?.contentId,
      title: payload?.title,
      author: payload?.author,
      description: payload?.description,
      curationStartDate: Utility.transformDateToString(payload?.curationStartDate),
      curationEndDate: Utility.transformDateToString(payload?.curationEndDate),
      contentDetailsCTP: {
        id:this.contentDetailsCTPId,
        contentTypeId: JSON.stringify(payload?.contentTypeId),
        reviewDate: payload?.reviewDate,
        industryId: JSON.stringify(industryIds),
        rankId: rankIds,
        locationId: payload?.locationId,
        lastAuditDate: Utility.transformDateToString(payload.lastAuditDate)
      },

    }

    return obj;

  }


  editContent(form: FormGroup) {
    this.data = this.formatPayload(form.getRawValue());
    // this.data.curationStartDate = Utility.transformDateToString(this.data.curationStartDate);
    // this.data.curationEndDate = Utility.transformDateToString(this.data.curationEndDate);
    this.contentManagementService.updateContentDescription(this.data).subscribe(
      response => {
        this.router.navigate(['/content-management/establishment'], { queryParams: { id: response.responseObject.contentId } });
        this.snackBarService.success(response.message.applicationMessage);
      },
      error => {
        this.snackBarService.error(`${error.error.applicationMessage}`);
      }
    )
  }

  setCurationDateConstraints(date?: Date) {
    if (this.isNew) {
      this.minEndDate = new Date(date);
      this.minEndDate.setDate(date.getDate() + 1);
    } else {
      this.minStartDate = new Date(date);
      this.minEndDate = new Date(this.minStartDate);
      this.minEndDate.setDate(date.getDate() + 1);
    }
  }

  selectFile(event) {
    this.logoName = null;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (this.validFileType(file)) {
        this.uploadDisable = false;
        this.imageFile = file;
        this.logoName = file.name;
      } else {
        this.uploadDisable = true;
      }
    }
  }

  validFileType(file) {
    const fileTypes = ['image/jpeg', 'image/png'];
    for (let i = 0; i < fileTypes.length; i++) {
      if (file.type === fileTypes[i]) {
        return true;
      }
    }
    return false;
  }

  private fetchOptionsIndustry(filterBy): Observable<{ id: number; name: string }[]> {
    const mainField = 'name';
    let sort;
    const pagination = {
      page: 1,
      pageSize: 200,
    };
    sort = `${mainField},asc`;
    return this.dynamicTableService
      .getRefGraphQLList(`${sort}`, pagination.page, pagination.pageSize, filterBy, 'industries')
      .pipe(
        map((response) => {
          const responseData = response;
          let dataArray;

          dataArray = responseData.data.industries.data;
          const newOptions = dataArray.map(
            (item: { id: any; attributes: { [key: string]: string } }) => ({
              id: item.id.toString(),
              name: item.attributes[mainField],
            })
          );
          return newOptions;
        }),
        catchError(err => {
          if (err.error && err.error.error && err.error.error.message) {
            //this.snackBar.open(err.error.error.message, 'Dismiss');
          }
          return of(null);
        })
      );
  }

  private fetchOptionsRank(filterBy): Observable<{ id: number; name: string }[]> {
    const mainField = 'name';
    let sort;
    const pagination = {
      page: 1,
      pageSize: 200,
    };
    sort = `${mainField},asc`;
    return this.dynamicTableService
      .getRefGraphQLList(`${sort}`, pagination.page, pagination.pageSize, filterBy, 'ref-event-ranks')
      .pipe(
        map((response) => {
          const responseData = response;
          let dataArray;

          dataArray = responseData.data.refEventRanks.data;
          const newOptions = dataArray.map(
            (item: { id: any; attributes: { [key: string]: string } }) => ({
              id: item.id.toString(),
              name: item.attributes[mainField],
            })
          );
          return newOptions;
        }),
        catchError(err => {
          if (err.error && err.error.error && err.error.error.message) {
            //this.snackBar.open(err.error.error.message, 'Dismiss');
          }
          return of(null);
        })
      );
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      this.resetForOneToOne();
    }
  }

  clearInput() {
    this.resetForOneToOne()
    this.manageContentForm.get('industryId').setValue('');
  }

  resetForOneToOne() {
    this.manageContentForm.get('industryId').reset();
    this.manageContentForm.get('industryId').clearAsyncValidators();
    this.selectedOptions = [];
  }

  onOptionSelected(event: any): void {
    const selectedOption = {
      id: event.option.value.toString(),
      name: event.option?.viewValue,
    };
    if (this.manageContentForm.get('industryId')) {
      this.selectedOptions.push(selectedOption);
      const newIds = this.selectedOptions.map((opt) => opt.id);
      this.manageContentForm.get('industryId').setValue(newIds);
      //this.searchControl.setValue('');
    }
    else {
      this.selectedOptions = [];
      this.selectedOptions.push(selectedOption);
      const newIds = this.selectedOptions.map((opt) => opt.id);
      // this.relationControl.reset()
      // this.relationControl.setValue(newIds);
      // this.searchControl.setValue(selectedOption.name);
      // this.oneToOneFlag = true;
    }

    this.optionSelectedFromDropdown = true;
  }

  addMoreBtnCLick(): void {
    const dialogRef = this.dialog.open(AddMorePopupComponent, {
      data: this.searchControl.value,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.getAllOptions();
      this.filteredOptionsList = this.searchControl.valueChanges.pipe(
        startWith(''),
        switchMap((value) => {
          const filterBy = { 'keyword': value };
          return this.fetchOptionsIndustry(filterBy);
        })
      );
    });

  }

  createImage(): Promise<string> {
    const formData: FormData = new FormData();
    formData.append("file", this.imageFile);
    formData.append("keyword", "test");

    return this.contentManagementService.uploadData(formData)
      .then(response => {

        const responseData = typeof response === 'string' ? JSON.parse(response) : response;

        // Check if the response contains the id property
        if (responseData && responseData.id) {
          // If it does, return the id
          return responseData.id;
        } else {
          // Handle error if id is missing
          return Promise.reject(new Error("Invalid response format: id is missing"));
        }
      })
      .catch(error => {
        if (error.status === 0) {
          // Handle error
        } else {
          // Handle other errors
        }
        return Promise.reject(error); // Propagate the error
      });
  }



}
