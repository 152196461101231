import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DynamicTableService } from '../dynamic-table.service';
import { SnackBarService } from "../../../../framework/service/snack-bar.service";
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Utility } from 'src/app/framework/utils/utility';

@Component({
  selector: 'app-room-popup',
  templateUrl: './room-popup.component.html',
  styleUrls: ['./room-popup.component.scss']
})
export class RoomPopupComponent implements OnInit {

  model = {};
  identifier: any;
  routeIntent: any;
  schemaUrl: any;
  apiUrl: any;
  titleInformation: any[];
  collection = '/roomLocations';
  viewId: any;
  fields: FormlyFieldConfig[] =[
    {
        "fieldGroupClassName": "display-flex",
        "fieldGroup": [
            {
                "key": "roomName",
                "type": "input",
                "className": "col-md-12",
                "validation": {
                    "messages": {
                        "required": "Room name is required.",
                        "minlength": "Minimum length should be 3 characters.",
                        "maxlength": "Maximum length should be 100 characters."
                    }
                },
                "templateOptions": {
                    "label": "Room name",
                    "placeholder": "",
                    "description": "Minimum 3 and maximum 100 characters",
                    "required": true,
                    "options": [],
                    "minLength": 3,
                    "maxLength": 100
                },
                "fieldArray": null,
                "fieldGroupClassName": null,
                "fieldGroup": null
            }
        ]
    },
    {
        "fieldGroupClassName": "display-flex",
        "fieldGroup": [
            {
                "key": "roomId",
                "type": "input",
                "className": "col-md-12",
                "validation": {
                    "messages": {}
                },
                "templateOptions": {
                    "label": "Room ID",
                    "placeholder": "",
                    "description": "",
                    "options": []
                },
                "fieldArray": null,
                "fieldGroupClassName": null,
                "fieldGroup": null
            }
        ]
    },
    {
        "fieldGroupClassName": "display-flex",
        "fieldGroup": [
            {
                "key": "roomCapacity",
                "type": "number",
                "className": "col-md-12",
                "validation": {
                    "messages": {
                        "required": "Room capacity is required.",
                        "pattern": "This field format is invalid.",
                        "min": "Minimum number should be 1.",
                        "max": "Maximum number should be 999.",
                    }
                },
                "templateOptions": {
                    "label": "Room capacity",
                    "placeholder": "",
                    "description": "Minimum 1 and maximum 999",
                    "required": true,
                    "options": [],
                    "pattern": "^[0-9]*$",
                    "max": 999,
                    "min": 1
                },
                "fieldArray": null,
                "fieldGroupClassName": null,
                "fieldGroup": null
            }
        ]
    },
    {
        "fieldGroupClassName": "display-flex",
        "fieldGroup": [
            {
                "key": "parentLocation",
                "type": "radio",
                "className": "col-md-12",
                "validation": {
                    "messages": {}
                },
                "templateOptions": {
                    "label": "Parent location",
                    "placeholder": "",
                    "description": "",
                    "required": false,
                    "options": [
                        {
                            "label": "Yes",
                            "value": true
                        },
                        {
                            "label": "No",
                            "value": false
                        }
                    ]
                },
                "fieldArray": null,
                "fieldGroupClassName": null,
                "fieldGroup": null,
                "defaultValue": false
            }
        ]
    },
  
]
  formSubmitted: boolean;
  foundField: boolean;
  form = new FormGroup({});
  routerPath: string;
  

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dynamicFormService: DynamicTableService,
    private snackBar: MatSnackBar,
    private readonly snackBarService: SnackBarService,
    private readonly dialogRef: MatDialogRef<RoomPopupComponent>
  ) {
    this.viewId = this.data.viewId;
    this.routerPath = `/courses-events/courseLocations/view/${this.viewId}`
    this.schemaUrl = 'api::room-location.room-location';
    this.apiUrl = '/room-location'
    // this.activatedRoute.queryParams.subscribe((params: any) => {
    //   this.identifier = params.id;
    // });
    
    this.addFieldHideExpression();
  }

  ngOnInit() {
    this.fields =this.fields;
    if (this.data && this.data.editData) {
      this.model = this.data.editData;
      this.form.patchValue(this.model);
    }
    if (this.identifier) {
      // this.dynamicFormService.getCollectionEntry(this.identifier, this.collection).subscribe(response => {
      //   //this.model = response.body.data.attributes;

      // }, (errors) => {
      //   this.snackBar.open(errors.error.body.error.message, 'Error', { duration: 3000 });
      // })
    }
  }

  navigateToRoute() {
    this.router.navigate([`courses-events/${this.collection}`]);
  }

  addFieldHideExpression() {
    this.fields.forEach(fieldGroup => {
        fieldGroup.fieldGroup.forEach(field => {
            if (field.key === 'roomId') {
                if (this.routeIntent == 'edit') {
                    field.hideExpression = false;
                    field.templateOptions.disabled = true;

                } else {
                    field.hideExpression = true;
                    field.templateOptions.disabled = false;
                }
            }
        });
    });
}

async onSubmit() {
  this.fields.forEach(data => {
    let field = data.fieldGroup[0]
    if (field.type === 'time') {
      console.log(this.model[field.key + '']);
      this.model[field.key + ''] = this.convertTimeFormat(this.model[field.key + '']);
    }
    if (field.type === 'datepicker') {
      console.log(this.model[field.key + '']);
      const parsedDate = Date.parse(this.model[field.key + '']);
      if (!isNaN(parsedDate) && parsedDate > 0) {
        const dateObj = new Date(parsedDate);
        const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`;
        this.model[field.key + ''] = formattedDate;
      }
    }
  })
  if (!this.foundField) {
    this.processForm();
  }
}

async processForm() {
  for (const key in this.model) {
    if (this.model.hasOwnProperty(key)) {
      const fieldValue = this.model[key];
      if (fieldValue && fieldValue.data && Array.isArray(fieldValue.data)) {
        const ids = fieldValue.data.map((item: any) => item.id);
        this.model[key] = ids;
      }
    }
  }
  this.fields.forEach((fieldGroup: any) => {
    if (Array.isArray(fieldGroup.fieldGroup)) {
      fieldGroup.fieldGroup.forEach((field: any) => {
        if (field.type === 'datepicker') {
          const dateField = this.model[field.key];
          const formattedDateNew = Utility.transformDateToString(dateField);
          this.model[field.key] = formattedDateNew;
        }
        if (field.type === 'repeat') {
          field.fieldGroup.forEach(data => {
            data.fieldGroup.forEach(field1 => {
              field1.fieldGroup.forEach(newField => {
                if (newField.type === 'time') {
                  if (Array.isArray(this.model[field.key])) {
                    this.model[field.key].forEach(element => {
                      if (element && element[newField.key]) {
                        const value = element[newField.key].startTime ? element[newField.key].startTime : element[newField.key];
                        element[newField.key].startTime ? element[newField.key].startTime : element[newField.key] = this.convertTimeFormat(value);
                      }
                    });
                  }
                }
              })
            })
          })
        }
        if(field.type === 'external-relation'){
          if(field.templateOptions.relation === 'many'){
            let finalExternalString ='';         
            if(this.model[field.key]?.length>0 && this.model[field.key] !== null){
              this.model[field.key]?.forEach(data =>{
                finalExternalString = finalExternalString + data +','
              })
              this.model[field.key]=finalExternalString
            }
          }
        }
        this.foundField = true;
      });
    }
  });
  if (!this.formSubmitted) {
    this.submitForm();
  }
}


async submitForm() {
  try {
    this.formSubmitted = true;
    const payload = this.formattedPayload(this.model)
    let response;
    if (this.data.roomId) {
      response = await this.dynamicFormService.updateCollectionEntry({ data: payload }, this.data.roomId, this.collection).toPromise();
    } else {
      response = await this.dynamicFormService.saveCollectionEntry({ data: payload }, this.collection).toPromise();
    }
    this.snackBarService.success(response?.message.applicationMessage);
    this.dialogRef.close();
    // this.router.navigate([this.routerPath]);
  } catch (errors) {
    this.snackBarService.error(errors.error.body.error.message);
    this.foundField = false
  } finally {
    this.formSubmitted = false;
  }
  
}


formattedPayload(payload) {
  Object.keys(payload).forEach(key => {
    const controlValue = payload[key];
    if (controlValue === '') {
      payload[key] = null;
    }
  });
  payload['location'] = [this.viewId]
  return payload;
}

convertTimeFormat(inputTime: string): string {
  const [hoursStr, minutesStr] = inputTime?.split(':');

  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10);

  const seconds = 0;
  const milliseconds = 0;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');
  const formattedMilliseconds = milliseconds.toString().padStart(3, '0');

  const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;

  return formattedTime;
}

}
