import { Component, OnInit } from '@angular/core';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { StateManagementService } from 'src/app/framework/state-management/state-management.service';
import { JobsNavigation } from '../jobs-nav';


@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
})
export class JobDetailsComponent implements OnInit{
  collection = 'jobs';
  fields = ['jobTitle', 'jobId', 'employer.employerName', 'createdUser','createdAt'];
  searchFields=['jobTitle','jobId'];
  buttonLable ="job";
  baseRoute = '/jobs';
  tabHead = '"Active"';
  tabHead1 = '"Pending"';
  tabHead2 = '"Closed"';
  activeIndex : any = 0;
  dynamicFilters: any = {
    publishedAt: { null: true },
  };

  constructor( private readonly inPageNavService: InPageNavService,
    private readonly jobsNavigation: JobsNavigation,
    private readonly smService: StateManagementService) {

    window.scrollTo(0, 0);
    this.inPageNavService.setNavItems(this.jobsNavigation.jobsSubMenu);
  }

  async ngOnInit() {
    
    this.activeIndex = await this.smService.getStoreData('activeSubTab');
  }

  ngOnDestroy() {

    this.inPageNavService.setNavItems(null);
  }

 async onTabChanged(tabIndex){

    let previousStateTabIndex = await this.smService.getStoreData('activeSubTab');
    if(previousStateTabIndex != tabIndex){

      this.smService.resetSubNavigationStateData(false);
    }
    this.smService.setactiveSubTab(tabIndex); 
  }
}
