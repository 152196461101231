import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { DynamicFormService } from '../dynamic-form/dynamic-form.service';
import { startWith, switchMap } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-address-type',
  templateUrl: './address-type.component.html',
})
export class AddressTypeComponent extends FieldType implements OnInit {

  searchControl: FormControl = new FormControl();
  resultList: any;
  newList: any;
  valueWithUnderscore: any;
  noValidSelection: boolean;
  suggestList: any;
  offset = 0;
  totalResults: any;
  selectedOption: any;
  selectedIndex: any;
  noListSelection: boolean;

  constructor(
    private readonly dynamicFormService: DynamicFormService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    console.log("this.formControl....",this.formControl)
    this.waitForModel().then(() => {   
      this.searchControl.setValue(this.formControl.get('postcode').value)
    });
  }

  waitForModel(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkModel = () => {
        if (this.formControl?.get('postcode')?.value) {
          resolve();
        } else {
          setTimeout(checkModel, 100);
        }
      };
      checkModel();
    });
  }

  clearInput() {
    this.searchControl.setValue(null);
    this.resultList = [];
    this.formControl.get('townCity').reset();
        this.formControl.get('townCity').setValue(null);
        this.formControl.get('region').reset();
        this.formControl.get('region').setValue(null);
        this.formControl.get('country').reset();
        this.formControl.get('country').setValue(null);
        this.model['county'] = null;
        this.model['longitude'] = null;
        this.model['latitude'] = null;
        this.model['postcode'] = null;
        if (this.field?.fieldGroup[0]?.fieldGroup[0]?.templateOptions?.required) {
          this.searchControl.setErrors({ required: true });
          this.formControl?.get('postcode').setErrors({ required: true });
        }else{
          this.searchControl.setErrors(null);
          this.formControl?.get('postcode').setErrors(null);
        }
  }

  onOptionSelected(event: any): void {
    if (event.option != null) {
      this.selectedOption = event.option.value;
      if (this.selectedOption) {
        this.noValidSelection = false;
        this.searchControl.setErrors(null);
        this.selectedOption = event.option.value;
        this.searchControl.setErrors(null);
        this.formControl?.get('postcode').setErrors(null);
      } else {
        this.noValidSelection = true;
        this.searchControl.setErrors({ incorrect: true });
        this.formControl?.get('postcode').setErrors({ incorrect: true });
      }
    } else {
      this.selectedOption = event;
    }
    this.selectedIndex = event.source.options.toArray().indexOf(event.option);
    const selectedDetails = this.newList[this.selectedIndex];
    if (selectedDetails.postcode) {
      this.searchControl.setValue(selectedDetails.postcode);

    } else {
      this.searchControl.setValue("N/A");
    }
    this.formControl.get('postcode').setValue(this.searchControl.value);
    this.searchControl.setErrors(null);
    this.setFormFields(selectedDetails);
    this.model['postcode'] = this.searchControl.value
  }

  setFormFields(selectedDetails: any) {
    this.formControl.get('country').setValue(selectedDetails.level1);
    this.formControl.get('townCity').setValue(selectedDetails.level4);
    this.formControl.get('region').setValue(selectedDetails.level2);
    this.model['county'] = selectedDetails.level3
    this.model['longitude'] = selectedDetails.location.lon + ''
    this.model['latitude'] = selectedDetails.location.lat + ''
  }

  suggestOption(event) {
    let limit = 100;
    const whereValue = event.target.value;
    if (whereValue.startsWith(" ")) {
      this.valueWithUnderscore = whereValue.replace(" ", "");
    } else {
      this.valueWithUnderscore = whereValue;
    }
    this.searchControl.valueChanges.subscribe(() => {
      this.offset = 0;
      this.suggestList = [];
      this.noValidSelection = false;

      if (this.searchControl?.value?.length == 0) {
        this.formControl.get('townCity').reset();
        this.formControl.get('townCity').setValue(null);
        this.formControl.get('region').reset();
        this.formControl.get('region').setValue(null);
        this.formControl.get('country').reset();
        this.formControl.get('country').setValue(null);
        this.model['county'] = null;
        this.model['longitude'] = null;
        this.model['latitude'] = null;
        this.model['postcode'] = null;
        if (this.field?.fieldGroup[0]?.fieldGroup[0]?.templateOptions?.required) {
          this.searchControl.setErrors({ required: true });
          this.formControl?.get('postcode').setErrors({ required: true });
        }else{
          this.searchControl.setErrors(null);
          this.formControl?.get('postcode').setErrors(null);
        }
      }
    });
    if (this.valueWithUnderscore.length >= 4) {
      this.dynamicFormService.suggestOption(this.valueWithUnderscore).subscribe((response) => {
        this.suggestList = response;
        this.setDropDownData();
      })
    }
  }

  setDropDownData() {   
      if (this.suggestList.length == 0) {
        this.noValidSelection = true;
        this.searchControl.setErrors({ incorrect: true });
      } else {
        this.noValidSelection = false;
        this.searchControl.setErrors(null);
      }
    this.resultList = this.suggestList.map(item => item.postcode);
    this.newList = this.suggestList;
    const myArray = Array.from(this.resultList);
    if (myArray.length == 0) {
      this.noValidSelection = true;
      this.searchControl.setErrors({ incorrect: true });
    } else {
      this.noValidSelection = false;
      this.searchControl.setErrors(null);
    }
    if (this.searchControl.value && this.searchControl.value.trim() !== '') {
      this.noListSelection = true;
      this.searchControl.setErrors({ 'noListSelection': true });
      this.formControl?.get('postcode').setErrors({ 'noListSelection': true });
    } else {
      this.noValidSelection = false;
      this.searchControl.setErrors(null);
      this.formControl?.get('postcode').setErrors(null);
    }

    this.cdr.detectChanges();
  }
}