<mat-card>
  <mat-card-header class="flex-end mb-20">
    <h1 class="page-title" *ngIf="identifier">Edit location</h1>
    <h1 class="page-title" *ngIf="!identifier">Add location</h1>
    <span class="spacer"></span>
    <span>
      <button id="exit_user_button" mat-stroked-button (click)="exitNavigation()">Exit</button>
    </span>
  </mat-card-header>
  <mat-card-content style="padding-left: 0px;">
    <app-dynamic-form [titleInformation]="titleInformation" [schemaUrl]="schemaUrl" [identifier]="identifier"
      [routeIntent]="routeIntent" [schemaUrl]="schemaUrl" [apiUrl]="apiUrl"
      [routerPath]="routerPath" [queryParams]="queryParams" [collection]="collection" (progressStatus)="isNotSaved = $event"></app-dynamic-form>
  </mat-card-content>
</mat-card>