import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { CourseManagementService } from '../../course-management.service';
import { CourseManagementNavigation } from '../../course-management-nav';
import { tap } from 'rxjs';
import { SnackBarService } from 'src/app/framework/service/snack-bar.service';
import { IBooking } from '../booking.interface';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';

@Component({
  selector: 'app-confirm-booking',
  templateUrl: './confirm-booking.component.html',
  styleUrls: ['./confirm-booking.component.scss']
})
export class ConfirmBookingComponent implements AfterViewInit {

  displayedColumns: string[] = ['serviceUserId', 'serviceNumber','createdDate','bookingReferenceId','bookingStatusId','actions'];
  dataSource = new MatTableDataSource<IBooking>();

  sortColumn = 'bookingReferenceId';
  sortDirection = 'asc';
  pageSize = 25;
  filterBy = { 'keyword': '', 'deliveryId': '', 'status':'','genericId': null};
  deleteReasonsRefData: any;
  licencesDetail;
  userType = 2
  searchTerm: string = '';
  isActive;
  requestStatusTypeList: any;
  filteredId: any;
  select = "all status";
  showFilteredList: boolean;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  identifier: any;
  pageName: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly inPageNavService: InPageNavService,
    private readonly courseManagementService: CourseManagementService,
    private readonly snackBarService: SnackBarService,
    private readonly courseNavigation: CourseManagementNavigation,
  ) {
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.identifier = params.id;
      this.pageName = params.name;
      this.isActive = params.hideEdit;
    });
  }
  ngOnInit(): void {
    this.filterBy.status = "Confirm Booking";
    this.filterBy.deliveryId = this.identifier;
    this.resolveBookings(this.filterBy);
    this.getStatusRefData()
  }


  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }

  onFilter(filterString: string) {
    this.filterBy.keyword = filterString;
    this.paginator.pageIndex = 0;
    this.resolveBookings(this.filterBy);
  }

  onClickClose() {
    this.searchTerm = '';
    this.filterBy.keyword = this.searchTerm
    this.resolveBookings(this.filterBy);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(data => {
      this.sortColumn = data.active;
      this.sortDirection = data.direction;
      this.paginator.pageIndex = 0;
      this.resolveBookings(this.filterBy);
    });

    this.paginator.page
      .pipe(
        tap(() => {
          this.resolveBookings(this.filterBy);
        }
        )
      )
      .subscribe();
  }

  resolveBookings(filterBy) {
    let currentPageIndex = 0;
    if (this.paginator) {
      currentPageIndex = this.paginator.pageIndex;
    }
    if(this.paginator.pageSize==undefined)
    {
      this.paginator.pageSize=25
    }
    this.courseManagementService.findAllBookings(`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.paginator.pageSize, filterBy)
    .subscribe(allBookings => {
      this.dataSource = allBookings.content;
      this.paginator.length = allBookings.totalElements;
      this.dataSource.sort = this.sort;
    }, error => {
      this.snackBarService.error(error.error.applicationMessage);
    });
  }


  findRequestBookingByStatus(event: any) {
    this.filteredId = event.value;
    this.showFilteredList = true;
    this.paginator.pageIndex = 0;

    if (event.value === 'all status') {
      this.filterBy.genericId = null;
      this.resolveBookings(this.filterBy);
    } else if (event.value === 0) {
      this.filterBy.genericId = null;
      this.resolveBookings(this.filterBy);
    } else if (event.value !== 'all status') {
      this.filterBy.genericId = this.filteredId;
      this.filterBy.keyword = '';
        this.resolveFilteredBookings(this.filterBy);
    }
  }

  getStatusRefData(){
    const excludedIds = [10,11,12,13,14,15,16,17];
    this.courseManagementService.getRequestBookingStatus(3).subscribe(response =>{
      if(response){
        this.requestStatusTypeList = response.filter(item => excludedIds.includes(item.id));
      }
    })
  }


  resolveFilteredBookings(filterBy: { keyword: string; deliveryId: string; status: string; genericId: any; }) {
    let currentPageIndex = 0;
    if (this.paginator) {
      currentPageIndex = this.paginator.pageIndex;
    }
    if(this.paginator.pageSize==undefined)
    {
      this.paginator.pageSize=25
    }
    this.courseManagementService.findFilteredBookings(`${this.sortColumn},${this.sortDirection}`, currentPageIndex, this.paginator.pageSize, filterBy)
    .subscribe(filteredBookings => {
      this.dataSource = filteredBookings.content;
      this.paginator.length = filteredBookings.totalElements;
      this.dataSource.sort = this.sort;
    }, error => {
      this.snackBarService.error(error.error.applicationMessage);
    });
   
  }
}
