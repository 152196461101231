<img tabindex="0" src='../../assets/logo/Logo.svg' alt="ctp-logo" class="app-logo width-172">
<div class="login_container white material-13">
  <h2>Reset password </h2>

  <form [formGroup]="changeExpiredPasswordForm" (ngSubmit)="submit()">
    <h3>Please fill in your details, {{this.username}}</h3>
    <mat-form-field class="full-width darkModeBorderNone">
      <mat-label class="white">Existing Password</mat-label>
      <input matInput maxlength="30" type="password" name="password" formControlName="existingPassword" required id="password" autocomplete="off">
      <mat-error *ngIf="changeExpiredPasswordForm.get('existingPassword').getError('required')" class="form-error-msg"> Existing password is required </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width darkModeBorderNone">
      <mat-label class="white">New Password</mat-label>
      <input matInput maxlength="30" type="password" name="newPassword" formControlName="newPassword" required id="newPassword" autocomplete="off">
      <mat-error *ngIf="changeExpiredPasswordForm.get('newPassword').getError('required')" class="form-error-msg"> New password is required </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width darkModeBorderNone">
      <mat-label class="white">Confirm Password</mat-label>
      <input matInput maxlength="30" type="password" name="confirmPassword" formControlName="confirmPassword" required id="confirmPassword" autocomplete="off">
      <mat-error *ngIf="changeExpiredPasswordForm.get('confirmPassword').getError('required')" class="form-error-msg"> Confirm password is required </mat-error>
      <mat-error *ngIf="changeExpiredPasswordForm.get('confirmPassword').hasError('notEquivalent') && changeExpiredPasswordForm.get('confirmPassword').value !== ''" class="form-error-msg">Passwords do not match</mat-error>
    </mat-form-field>

    <button mat-raised-button [disabled]="changeExpiredPasswordForm.invalid" color="primary" type="submit" class="button">Submit</button>
  </form>

</div>
