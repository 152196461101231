import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InPageNavService } from 'src/app/framework/components/in-page-nav/in-page-nav.service';
import { StateManagementService } from 'src/app/framework/state-management/state-management.service';
import { CourseManagementNavigation } from '../course-management-nav';

@Component({
  selector: 'app-workshop-delivery',
  templateUrl: './workshop-delivery.component.html'
})
export class WorkshopDeliveryComponent implements OnInit{
  constructor(
    private readonly inPageNavService: InPageNavService,
    private router: Router,
    private readonly courseNavigation: CourseManagementNavigation,
    private readonly smService: StateManagementService

  ) {
    this.scrollToTop();
    this.inPageNavService.setNavItems(this.courseNavigation.courseSubMenu);
  }
  collection = 'workshopDeliveries'; 
  fields = ['workshops.workshopName', 'workshopDeliveryId', 'ref_wsdl_types.name', 'location.locationName', 'room_location.roomName', 'deliveryStartDate'];
  searchFields = ['workshops.workshopName', 'workshopDeliveryId'];
  buttonLable = "workshop delivery";
  baseRoute = '/courses-events';
  sortBy="deliveryStartDate";
  archiveTab='false'

  collection1 = 'workshopDeliveries'
  fields1 = ['workshops.workshopName', 'workshopDeliveryId', 'ref_wsdl_types.name', 'location.locationName', 'room_location.roomName', 'deliveryStartDate','workshopDeliveryCancelledDate'];
  searchFields1 = ['workshops.workshopName', 'workshopDeliveryId'];
  sortBy1 = "workshopDeliveryCancelledDate"
  archiveTab1 = 'true';

  activeIndex : any = 0;


  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async ngOnInit() {
    
    this.activeIndex = await this.smService.getStoreData('activeSubTab');
  }

  ngOnDestroy() {
    this.inPageNavService.setNavItems(null);
  }
  async onTabChanged(tabIndex){

    let previousStateTabIndex = await this.smService.getStoreData('activeSubTab');
    if(previousStateTabIndex != tabIndex){

      this.smService.resetSubNavigationStateData(false);
    }
    this.smService.setactiveSubTab(tabIndex); 
  }
}
