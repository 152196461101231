import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'formly-field-quill',
  styleUrls: ['./quill-field-type.component.scss'],
  templateUrl: './quill-field-type.component.html',
})

export class QuillFieldTypeComponent extends FieldType implements OnInit {
  characterCount = 0;
  isTouched: boolean;

  ngOnInit() {
    // this.characterCount = this.quillFormControl.value?.length || 0 ;
    // this.checkCharacterCount();
    // this.quillFormControl.valueChanges.subscribe((value) => {
    //   this.characterCount = value?.length || 0;
    //   this.checkCharacterCount();
    // });
    setTimeout(() => {
      this.quillFormControl.statusChanges.subscribe(status => {
        if(status){
          this.isTouched = true;
        }
        else if(this.quillFormControl.dirty){
          this.isTouched = true;
        }
      });
    }, 100);
  }

  onContentChanged(event: any) {
    this.characterCount = event.text.trim().length;
    this.checkCharacterCount();
  }

  checkCharacterCount() {
    const value = this.characterCount;
    this.quillFormControl.setErrors(null); // Reset errors

    if (this.to.minLength && value < this.to.minLength && this.to['required']) {
      this.quillFormControl.setErrors({ minLength: true });
    }else if(this.to.minLength && value < this.to.minLength && value!=0){
      this.quillFormControl.setErrors({ minLength: true });
    }

    // if (this.to.maxLength && value > this.to.maxLength) {
    //   this.quillFormControl.setErrors({ maxLength: true });
    // }

    // if (this.to.minLength && value < this.to.minLength && value) {
    //   this.quillFormControl.setErrors({ minLength: true });
    // }
  }

  getErrorMessage() {
    const value = this.characterCount;
    if (this.quillFormControl.errors) {
      if (this.isTouched && value < 1) {
        return (
          ''+ this.field.validation.messages.required +''
        );
      }
      if (this.quillFormControl.errors['minLength'] && value) {
        return (
          'The value is too short (min: ' + this.to.minLength + ' characters).'
        );
      }
      // if (this.quillFormControl.errors['maxLength']) {
      //   return (
      //     'The value is too long (max: ' + this.to.maxLength + ' characters).'
      //   );
      // }
    }
    return '';
  }

  get quillFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ 'header': [1, 2, 3, false] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ];
}
